import styled from "styled-components";

import { IconName } from "../types/icon";

interface IconProps {
	name: IconName;
	className?: "string";
}

const PATHS: Record<IconName, any> = {
	"sort-none": <circle cx="50" cy="50" r="20" />,
	"sort-ascending": (
		<>
			<path d="M19.5,30H9.91c-0.89,0-1.34-1.08-0.71-1.71L28.59,8.91c0.78-0.78,2.05-0.78,2.83,0l19.38,19.38c0.63,0.63,0.18,1.71-0.71,1.71H40.5c-1.66,0-3,1.34-3,3v55c0,1.1-0.9,2-2,2h-11c-1.1,0-2-0.9-2-2V33C22.5,31.34,21.16,30,19.5,30z" />
			<path d="M88,35H44.5c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2H88c1.1,0,2-0.9,2-2V37C90,35.9,89.1,35,88,35z" />
			<path d="M73,55H44.5c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2H73c1.1,0,2-0.9,2-2V57C75,55.9,74.1,55,73,55z" />
			<path d="M58,75H44.5c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2H58c1.1,0,2-0.9,2-2V77C60,75.9,59.1,75,58,75z" />
		</>
	),
	"sort-descending": (
		<>
			<path d="M19.5,70H9.91c-0.89,0-1.34,1.08-0.71,1.71l19.38,19.38c0.78,0.78,2.05,0.78,2.83,0l19.38-19.38c0.63-0.63,0.18-1.71-0.71-1.71H40.5c-1.66,0-3-1.34-3-3V12c0-1.1-0.9-2-2-2h-11c-1.1,0-2,0.9-2,2v55C22.5,68.66,21.16,70,19.5,70z" />
			<path d="M88,65H44.5c-1.1,0-2-0.9-2-2V52c0-1.1,0.9-2,2-2H88c1.1,0,2,0.9,2,2v11C90,64.1,89.1,65,88,65z" />
			<path d="M73,45H44.5c-1.1,0-2-0.9-2-2V32c0-1.1,0.9-2,2-2H73c1.1,0,2,0.9,2,2v11C75,44.1,74.1,45,73,45z" />
			<path d="M58,25H44.5c-1.1,0-2-0.9-2-2V12c0-1.1,0.9-2,2-2H58c1.1,0,2,0.9,2,2v11C60,24.1,59.1,25,58,25z" />
		</>
	),
	funnel: (
		<path d="M13.89,11.25h72.23c1.63,0,2.57,1.84,1.63,3.16L65.93,44.95C65.33,45.79,65,46.81,65,47.85v21.26c0,1.34-0.67,2.59-1.78,3.33L37.33,89.7c-1,0.66-2.33-0.05-2.33-1.25V47.85c0-1.04-0.33-2.06-0.93-2.91L12.26,14.41C11.31,13.09,12.26,11.25,13.89,11.25z" />
	),
	"funnel-outline": (
		<path d="M59.18,39.52a13,13,0,0,0-2.42,7.55V65.12a2,2,0,0,1-.89,1.67L43.54,75a.5.5,0,0,1-.78-.42V47.07a13,13,0,0,0-2.42-7.56L26.44,20.05a1,1,0,0,1,.81-1.58h45a1,1,0,0,1,.81,1.58Zm26.7-29.05H13.65A2,2,0,0,0,12,13.63L33.83,44.16a5.06,5.06,0,0,1,.93,2.91v40.6a1.49,1.49,0,0,0,1.5,1.5,1.52,1.52,0,0,0,.83-.25L63,71.66a4,4,0,0,0,1.78-3.33V47.07a5,5,0,0,1,.93-2.9L87.51,13.63a2,2,0,0,0-1.63-3.16Z" />
	),
	check: (
		<path d="M42.31,89.58L6.91,57.97c-1.24-1.1-1.34-3-0.24-4.24l6.66-7.46c1.1-1.24,3-1.34,4.24-0.24l25.71,22.96L81.63,8.26c0.88-1.4,2.74-1.82,4.14-0.93l8.45,5.34c1.4,0.88,1.82,2.74,0.93,4.14L49.87,88.52C48.22,91.13,44.62,91.64,42.31,89.58z" />
	),
	cross: (
		<path d="M93.54,13.54l-7.07-7.07c-1.17-1.17-3.07-1.17-4.24,0L50,38.69L17.78,6.46c-1.17-1.17-3.07-1.17-4.24,0l-7.07,7.07c-1.17,1.17-1.17,3.07,0,4.24L38.69,50L6.46,82.22c-1.17,1.17-1.17,3.07,0,4.24l7.07,7.07c1.17,1.17,3.07,1.17,4.24,0L50,61.31l32.22,32.22c1.17,1.17,3.07,1.17,4.24,0l7.07-7.07c1.17-1.17,1.17-3.07,0-4.24L61.31,50l32.22-32.22C94.71,16.61,94.71,14.71,93.54,13.54z" />
	),
	"uncheck-clear": (
		<path d="M86.2,58H13.8c-2.1,0-3.8-1.7-3.8-3.8v-8.4c0-2.1,1.7-3.8,3.8-3.8h72.4c2.1,0,3.8,1.7,3.8,3.8v8.4C90,56.3,88.3,58,86.2,58z" />
	),
	hyperlink: (
		<>
			<path d="M80,78c0,1.1-0.9,2-2,2H22c-1.1,0-2-0.9-2-2V22c0-1.1,0.9-2,2-2h21c1.1,0,2-0.9,2-2v-6c0-1.1-0.9-2-2-2H15c-2.8,0-5,2.2-5,5v70c0,2.8,2.2,5,5,5h70c2.8,0,5-2.2,5-5V57c0-1.1-0.9-2-2-2h-6c-1.1,0-2,0.9-2,2V78z" />
			<path d="M85,10H57c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h15.9L37.9,55.1c-0.8,0.8-0.8,2,0,2.8l4.2,4.2c0.8,0.8,2,0.8,2.8,0L80,27.1V43c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V15C90,12.2,87.8,10,85,10z" />
		</>
	),
	show: (
		<>
			<path d="M50,23.8c-27.5,0-39.3,22.1-41,25.7c-0.2,0.3-0.2,0.7,0,1c1.8,3.7,13.5,25.7,41,25.7s39.3-22.1,41-25.7c0.2-0.3,0.2-0.7,0-1C89.3,45.8,77.5,23.8,50,23.8z M51.6,62.4c-8.2,1-15.1-5.9-14-14c0.7-5.6,5.2-10.1,10.8-10.8c8.2-1,15.1,5.9,14,14C61.7,57.2,57.2,61.7,51.6,62.4z" />
			<path d="M72,76.8c-1.2,0.6-2.4,1.1-3.6,1.5c-0.5,0.2-0.8,0.8-0.6,1.4l2.5,5.3c0.5,1,1.7,1.4,2.7,1l5.4-2.5c1-0.5,1.4-1.7,1-2.7l-2.5-5.3c-0.2-0.5-0.9-0.7-1.4-0.4C74.4,75.6,73.2,76.2,72,76.8z" />
			<path d="M28.1,76.8c-1.2-0.6-2.4-1.2-3.5-1.8c-0.5-0.3-1.1-0.1-1.4,0.4l-2.5,5.3c-0.5,1,0,2.2,1,2.7l5.4,2.5c1,0.5,2.2,0,2.7-1l2.5-5.3c0.2-0.5,0-1.1-0.6-1.4C30.5,77.9,29.3,77.4,28.1,76.8z" />
			<path d="M50,81.2c-1.3,0-2.7,0-3.9-0.1c-0.6,0-1.1,0.4-1.1,1V88c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2v-5.9c0-0.6-0.5-1-1.1-1C52.7,81.2,51.3,81.2,50,81.2z" />
			<path d="M72,23.2c-1.2-0.6-2.4-1.1-3.6-1.5c-0.5-0.2-0.8-0.8-0.6-1.4l2.5-5.3c0.5-1,1.7-1.4,2.7-1l5.4,2.5c1,0.5,1.4,1.7,1,2.7l-2.5,5.3c-0.2,0.5-0.9,0.7-1.4,0.4C74.4,24.4,73.2,23.8,72,23.2z" />
			<path d="M28.1,23.2c-1.2,0.6-2.4,1.2-3.5,1.8c-0.5,0.3-1.1,0.1-1.4-0.4l-2.5-5.3c-0.5-1,0-2.2,1-2.7l5.4-2.5c1-0.5,2.2,0,2.7,1l2.5,5.3c0.2,0.5,0,1.1-0.6,1.4C30.5,22.1,29.3,22.6,28.1,23.2z" />
			<path d="M50,18.8c-1.3,0-2.7,0-3.9,0.1c-0.6,0-1.1-0.4-1.1-1V12c0-1.1,0.9-2,2-2h6c1.1,0,2,0.9,2,2v5.9c0,0.6-0.5,1-1.1,1C52.7,18.8,51.3,18.8,50,18.8z" />
		</>
	),
	hide: (
		<>
			<path d="M77.3,34.3L65.2,47.2c-0.3,0.3-0.4,0.6-0.3,1C65,48.8,65,49.4,65,50c0,8.3-6.7,15-15,15c-0.3,0-0.5,0-0.8,0c-0.4,0-0.7,0.1-1,0.4l-7.5,8c-0.7,0.7-0.3,1.9,0.7,2.1c2.7,0.5,5.6,0.8,8.7,0.8c27.4,0,39.2-22,41-25.7c0.2-0.3,0.2-0.7,0-1.1c-1-2-4.7-9-12-15.2C78.5,33.8,77.7,33.8,77.3,34.3z" />
			<path d="M85.4,17.3l-5.1-4.7c-0.8-0.7-2.1-0.7-2.8,0.1L64.9,26.1c-4.3-1.5-9.3-2.4-14.9-2.4c-27.5,0-39.3,22.1-41,25.7c-0.2,0.3-0.2,0.7,0,1c1.1,2.3,6,11.5,15.9,18.2L14.5,79.9c-0.8,0.8-0.7,2.1,0.1,2.8l5.1,4.7c0.8,0.7,2.1,0.7,2.8-0.1l63-67.2C86.3,19.3,86.2,18,85.4,17.3z M35,50.2c-0.1-7.7,5.8-14.4,13.5-15.2c2.5-0.2,4.9,0.1,7,1L36.5,56.4C35.6,54.6,35,52.5,35,50.2z" />
		</>
	),
	"chevron-left": (
		<path d="M73.1,89.9l3.7-4.7c0.7-0.9,0.5-2.1-0.3-2.8L35.5,50l41-32.3c0.9-0.7,1-1.9,0.3-2.8l-3.7-4.7c-0.7-0.9-1.9-1-2.8-0.3L20.4,49.2c-0.5,0.4-0.5,1.2,0,1.6l49.9,39.4C71.2,90.9,72.4,90.7,73.1,89.9z" />
	),
	"thick-chevron-left": (
		<path  d="M61.31,83.38l7.38-6.76a2,2,0,0,0,.12-2.82L47.62,50.68a1,1,0,0,1,0-1.36L68.81,26.2a2,2,0,0,0-.12-2.82l-7.38-6.76a2,2,0,0,0-2.82.12L29.24,48.65a2,2,0,0,0,0,2.7L58.49,83.26A2,2,0,0,0,61.31,83.38Z" />
	),
	"chevron-right": (
		<path d="M26.9,89.9l-3.7-4.7c-0.7-0.9-0.5-2.1,0.3-2.8l41-32.3l-41-32.3c-0.9-0.7-1-1.9-0.3-2.8l3.7-4.7c0.7-0.9,1.9-1,2.8-0.3l49.9,39.4c0.5,0.4,0.5,1.2,0,1.6L29.7,90.2C28.8,90.9,27.6,90.7,26.9,89.9z" />
	),
	"thick-chevron-right": (
		<path d="M38.69,83.38l-7.38-6.76a2,2,0,0,1-.12-2.82L52.38,50.68a1,1,0,0,0,0-1.36L31.19,26.2a2,2,0,0,1,.12-2.82l7.38-6.76a2,2,0,0,1,2.82.12L70.76,48.65a2,2,0,0,1,0,2.7L41.51,83.26A2,2,0,0,1,38.69,83.38Z" />
	),
	title: (
		<path d="M17.5,9v12c0,1.1,0.9,2,2,2H42v68.8c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V23h22.5c1.1,0,2-0.9,2-2V9c0-1.1-0.9-2-2-2h-61C18.4,7,17.5,7.9,17.5,9z" />
	),
	plus: (
		<path d="M47,10h6c1.1,0,2,0.9,2,2v33h33c1.1,0,2,0.9,2,2v6c0,1.1-0.9,2-2,2H55v34.2c0,1.1-0.9,2-2,2h-6c-1.1,0-2-0.9-2-2V55H10.8c-1.1,0-2-0.9-2-2v-6c0-1.1,0.9-2,2-2H45V12C45,10.9,45.9,10,47,10z" />
	),
	"thin-cross": (
		<path d="M89.6,14.7l-4.2-4.2c-0.8-0.8-2-0.8-2.8,0L50,42.9L17.5,10.4c-0.8-0.8-2-0.8-2.8,0l-4.2,4.2c-0.8,0.8-0.8,2,0,2.8L42.9,50L10.4,82.5c-0.8,0.8-0.8,2,0,2.8l4.2,4.2c0.8,0.8,2,0.8,2.8,0L50,57.1l32.5,32.5c0.8,0.8,2,0.8,2.8,0l4.2-4.2c0.8-0.8,0.8-2,0-2.8L57.1,50l32.5-32.5C90.4,16.7,90.4,15.5,89.6,14.7z" />
	),
	search: (
		<path d="M89.5,82.6,68.72,61.69a32.51,32.51,0,1,0-7.07,7.06L82.5,89.6a1.93,1.93,0,0,0,2.8,0l4.2-4.2A1.93,1.93,0,0,0,89.5,82.6ZM42.5,65A22.5,22.5,0,1,1,65,42.5,22.52,22.52,0,0,1,42.5,65Z" />
	)
};

const SvgWrapper = styled.svg`
	display: inline-block;
	fill: currentColor;
	vertical-align: middle;
`;

const Icon = (props: IconProps) => (
	<SvgWrapper
		className={props.className}
		viewBox="0 0 100 100"
	>
		{PATHS[props.name]}
	</SvgWrapper>
);

export default Icon;
