import { NavLink } from "react-router-dom";

interface LinkProps {
	to: string;
	children?: any;
	[key: string]: any;
}

const Link = (props: LinkProps) => (
	<NavLink
		{...props}
		activeClassName="active"
	>
		{props.children}
	</NavLink>
);

export default Link;
