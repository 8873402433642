import {
	useState,
	Dispatch,
	SetStateAction
} from "react";
import styled from "styled-components";

import {
	then,
	isThenable
} from "@qtxr/utils";

import {
	mkResolver,
	getContent
} from "./common";
import {
	assign,
	getRoundDate,
	getDateString
} from "../utils";

import useTransientState from "../hooks/use-transient-state";

import { default as DateInput } from "./inputs/date";
import Button from "./inputs/button";
import Checkbox from "./inputs/checkbox";

import {
	Control,
	ControlMode,
	ButtonRuntime,
	ButtonControl,
	CheckboxControl,
	ControlBarProps,
	CheckboxRuntime,
	DateRangeControl,
	DateRangeRuntime,
	ControlIntersection
} from "../types/control-bar";
import { EmptyRuntime } from "../types/common";

interface ButtonWrapperProps<Runtime> {
	control: ButtonControl;
	runtime: Partial<Runtime>;
}

interface CheckboxWrapperProps<Runtime> {
	control: CheckboxControl;
	runtime: Partial<Runtime>;
}

interface DateRangeWrapperProps<Runtime> {
	control: DateRangeControl;
	runtime: Partial<Runtime>;
}

const Wrapper = styled.div<ControlBarProps<any>>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	${p => !p.bare ?
		{
			padding: "10px",
			borderRadius: p.theme.borderRadius,
			background: p.theme.darkBackground,
			color: p.theme.accentColor
		} :
		null
	};
`;

const Left = styled.div`
	display: flex;
	align-items: center;
`;

const Right = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-left: 20px;
`;

// Control components
const ButtonWrapper = (props: ButtonWrapperProps<ButtonRuntime>) => {
	const [loading, setLoading] = useTransientState(false);

	const rt = assign<ButtonRuntime>({}, props.runtime),
		r = mkResolver(rt),
		ctrl = props.control as ButtonControl,
		disabled = r(ctrl.disabled, false);

	const handleClick = () => {
		if (typeof ctrl.onClick != "function")
			return;

		const pending = ctrl.onClick(rt);

		if (!isThenable(pending))
			return pending;

		setLoading(true);

		return then(pending, (result: any) => {
			setLoading(false);
			return result;
		});
	};

	return (
		<Button
			disabled={disabled || loading}
			style={r(ctrl.style)}
			onClick={handleClick}
		>
			{getContent(ctrl, rt)}
		</Button>
	);
}

const CheckboxInnerWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const CheckboxLabel = styled.label`
	font-weight: bold;
	margin: 0 8px;
	user-select: none;
	cursor: pointer;
`;

const CheckboxWrapper = (props: CheckboxWrapperProps<CheckboxRuntime>) => {
	const [checked, setChecked] = useControlState(props, false);

	const rt = assign<CheckboxRuntime>({}, props.runtime, {
		checked
	});

	const r = mkResolver(rt),
		ctrl = props.control as CheckboxControl,
		disabled = r(ctrl.disabled, false);

	const toggle = () => {
		setChecked(!checked);

		if (typeof ctrl.onChange == "function") {
			ctrl.onChange(assign<CheckboxRuntime>({}, rt, {
				checked: !checked
			}));
		}
	};

	return (
		<CheckboxInnerWrapper>
			<Checkbox
				outlined
				disabled={disabled}
				checked={checked}
				onChange={toggle}
			/>
			<CheckboxLabel onClick={toggle}>
				{getContent(props.control, rt)}
			</CheckboxLabel>
		</CheckboxInnerWrapper>
	);
};

const DateRangeContainer = styled.div`
	display: flex;
	align-items: center;
`;

const DateRangeInput = styled(DateInput)`
	width: 145px;
	color: ${p => p.theme.color};
	border: none;
`;

const DateRangeInputStart = styled(DateRangeInput)`
	padding-right: 20px;
`;

const DateRangeInputEnd = styled(DateRangeInput)`
	padding-left: 20px;
`;

const DateRangeSeparator = styled.div`
	position: relative;
	width: 16px;
	height: 16px;
	background: ${p => p.theme.cardBackground};
	border-radius: 50%;
	margin: 0 -5px;
	box-shadow: 0 0 0 3px ${p => p.theme.darkBackground};
	
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 8px;
		height: 2px;
		border-radius: 1px;
		background: ${p => p.theme.darkBackground};
		transform: translate(-50%, -50%);
	}
`;

const DateRangeWrapper = (props: DateRangeWrapperProps<DateRangeRuntime>) => {
	const [range, setRange] = useControlState(props, () => {
		const nowDate = new Date(),
			todayDate = getRoundDate(nowDate),
			nextWeekDate = getRoundDate(nowDate, 7);

		return [todayDate.getTime() + todayDate.getTimezoneOffset() * 60 * 1000, nextWeekDate.getTime() + nextWeekDate.getTimezoneOffset() * 60 * 1000];
	});

	const rt = assign<DateRangeRuntime>({}, props.runtime),
		r = mkResolver(rt),
		ctrl = props.control as DateRangeControl,
		disabled = r(ctrl.disabled, false);

	const updateRange = (evt: any, index: number) => {
		const date = new Date(evt.target.value),
			rng = [...range] as [number, number];

		rng[index] = date.getTime() + date.getTimezoneOffset() * 60 * 1000;

		const minTime = getRoundDate(rng[0], 1).getTime();
		if (rng[1] < minTime)
			rng[1] = minTime;

		setRange(rng);

		if (typeof props.control.onChange == "function") {
			props.control.onChange(assign<DateRangeRuntime>({}, rt, {
				range: rng
			}));
		}
	};

	return (
		<DateRangeContainer>
			<DateRangeInputStart
				disabled={disabled}
				value={getDateString(range[0])}
				onChange={evt => updateRange(evt, 0)}
			/>
			<DateRangeSeparator />
			<DateRangeInputEnd
				disabled={disabled}
				value={getDateString(range[1])}
				min={getDateString(getRoundDate(range[0], 1))}
				onChange={evt => updateRange(evt, 1)}
			/>
		</DateRangeContainer>
	);
};

function resolveControl<Runtime>(
	control: ControlIntersection<Runtime>,
	runtime: Runtime,
	key: any
): JSX.Element {
	const res = mkResolver(runtime);

	const mode: ControlMode = res((control as Control<Runtime>).mode) || "button";

	if (typeof control.component == "function") {
		const Component = (control as Control<Runtime>).component!;
		return <Component {...runtime} />;
	}

	switch (mode) {
		case "checkbox":
			return (
				<CheckboxWrapper
					key={key}
					control={control as CheckboxControl}
					runtime={runtime}
				/>
			);

		case "date-range":
			return (
				<DateRangeWrapper
					key={key}
					control={control as DateRangeControl}
					runtime={runtime}
				/>
			);

		case "button":
		default:
			return (
				<ButtonWrapper
					key={key}
					control={control as ButtonControl}
					runtime={runtime}
				/>
			);
	}
}

// Hook that taps into provided values from control configuration,
// enabling external state in addition to local state
function useControlState<T>(
	props: any,
	initialState: T | (() => T)
): [T, Dispatch<SetStateAction<T>>] {
	const [value, setValue] = useState(initialState);

	if (props.control.value === undefined)
		return [value, setValue];

	return [props.control.value as any, setValue];
}

function ControlBar<ExtensionRuntime = EmptyRuntime>(
	props: ControlBarProps<ExtensionRuntime>
) {
	const controls = props.controls.map((control, idx) => {
		return resolveControl<ExtensionRuntime>(control as any, props.runtime, idx)
	});

	return (
		<Wrapper {...props}>
			<Left>
				{props.children}
			</Left>
			<Right>
				{controls}
			</Right>
		</Wrapper>
	);
}

export default ControlBar;
