import PlotSvg from "../index";
import StagedComponent from "./index";

import { Point } from "../../apply-categorization";
import { StyleParam } from "../../../../types/plot-svg";

interface BoundingData {
	minX: number;
	minY: number;
	maxX: number;
	maxY: number;
}

export default class StagedPath extends StagedComponent {
	hasStaticPath: boolean;
	hasDynamicPath: boolean;
	cursor: [number, number];
	bounding: BoundingData;

	ownStyleParams = [
		{ key: "d" },
		{ key: "M", aggregate: true },
		{ key: "m", aggregate: true },
		{ key: "L", aggregate: true },
		{ key: "l", aggregate: true },
		{ key: "H", aggregate: true },
		{ key: "h", aggregate: true },
		{ key: "V", aggregate: true },
		{ key: "v", aggregate: true },
		{ key: "C", aggregate: true },
		{ key: "c", aggregate: true },
		{ key: "S", aggregate: true },
		{ key: "s", aggregate: true },
		{ key: "Q", aggregate: true },
		{ key: "q", aggregate: true },
		{ key: "T", aggregate: true },
		{ key: "t", aggregate: true },
		{ key: "A", aggregate: true },
		{ key: "a", aggregate: true },
		{ key: "Z", aggregate: true },
		{ key: "z", aggregate: true }
	] as StyleParam[];

	constructor(owner: PlotSvg, point: Point | null) {
		super(owner, point);

		this.hasStaticPath = false;
		this.hasDynamicPath = false;

		this.cursor = [0, 0];
		this.bounding = {
			minX: Infinity,
			minY: Infinity,
			maxX: -Infinity,
			maxY: -Infinity
		};
	}

	open(d?: string): this {
		return super.open(d);
	}

	d(d: string): this {
		if (this.hasDynamicPath)
			throw new Error("Cannot run command: path already uses a dynamic path");

		return this.set("d", d);
	}

	M(x: number, y: number): this {
		return this.setCommand("M")(x, y);
	}

	m(dx: number, dy: number): this {
		return this.setCommand("m")(dx, dy);
	}

	L(x: number, y: number): this {
		return this.setCommand("L")(x, y);
	}

	l(dx: number, dy: number): this {
		return this.setCommand("l")(dx, dy);
	}

	H(x: number): this {
		return this.setCommand("H")(x);
	}

	h(dx: number): this {
		return this.setCommand("h")(dx);
	}

	V(y: number): this {
		return this.setCommand("V")(y);
	}

	v(dy: number): this {
		return this.setCommand("v")(dy);
	}

	C(x1: number, y1: number, x2: number, y2: number, x: number, y: number): this {
		return this.setCommand("C")(x1, y1, x2, y2, x, y);
	}

	c(dx1: number, dy1: number, dx2: number, dy2: number, dx: number, dy: number): this {
		return this.setCommand("c")(dx1, dy1, dx2, dy2, dx, dy);
	}

	S(x2: number, y2: number, x: number, y: number): this {
		return this.setCommand("S")(x2, y2, x, y);
	}

	s(dx2: number, dy2: number, dx: number, dy: number): this {
		return this.setCommand("s")(dx2, dy2, dx, dy);
	}

	Q(x1: number, y1: number, x: number, y: number): this {
		return this.setCommand("Q")(x1, y1, x, y);
	}

	q(dx1: number, dy1: number, dx: number, dy: number): this {
		return this.setCommand("q")(dx1, dy1, dx, dy);
	}

	T(x: number, y: number): this {
		return this.setCommand("T")(x, y);
	}

	t(dx: number, dy: number): this {
		return this.setCommand("t")(dx, dy);
	}

	A(rx: number, ry: number, xAxisRotation: number, largeArc: boolean, sweep: boolean, x: number, y: number): this {
		return this.setCommand("A", 0b1100011)(rx, ry, xAxisRotation, largeArc, sweep, x, y);
	}

	a(rx: number, ry: number, xAxisRotation: number, largeArc: boolean, sweep: boolean, dx: number, dy: number): this {
		return this.setCommand("a", 0b1100011)(rx, ry, xAxisRotation, largeArc, sweep, dx, dy);
	}

	Z(): this {
		return this.setCommand("Z")();
	}

	z(): this {
		return this.setCommand("z")();
	}

	setCommand(command: string, scaleMask?: number) {
		if (this.hasStaticPath)
			throw new Error(`Cannot run command '${command}': path already uses a static path`)

		return (...args: (number | boolean)[]) => {
			const transformedArgs = [];
			let component = command;

			for (let i = 0, l = args.length; i < l; i++) {
				let arg = args[i];

				// Apply scaling unconditionally, or selectively based on a provided mask
				if (typeof scaleMask != "number" || (scaleMask >> (l - 1 - i)) & 1)
					arg = this.scale(arg as number);

				transformedArgs.push(arg);
				component += (Number(arg) + " ");
			}

			this.style.d = this.style.d ?
				this.style.d + component :
				component;

			return this;
		};
	}

	close() {
		super.close();
		this.style.d = this.style.d.trim();
	}

	render() {
		this.mount("path");
	}
}
