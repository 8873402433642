import styled from "styled-components";

import StatCard from "../../components/stat-card";

import { StatEntry } from "../../hooks/use-stats";

interface DashboardViewProps {
	stats?: StatEntry[];
	children?: any;
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
`;

const StatsWrapper = styled.div`
	display: flex;
	margin-bottom: 10px;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

const Base = (props: DashboardViewProps) => {
	let stats = null;

	const cards = (props.stats || []).map((stat, idx) => (
		<StatCard
			key={idx}
			label={stat.label}
			value={stat.value}
			loadingState={stat.loadingState}
		/>
	));

	if (cards.length) {
		stats = (
			<StatsWrapper>
				{cards}
			</StatsWrapper>
		);
	}

	return (
		<Wrapper>
			{stats}
			<Content>
				{props.children}
			</Content>
		</Wrapper>
	);
};

export default Base;
