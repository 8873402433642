import styled from "styled-components";

import Icon from "../icon";

import { IconName } from "../../types/icon";
import { InputProps } from "../../types/inputs";

export interface CheckboxProps extends InputProps {
	checked?: boolean;
	mode?: CheckMode;
	outlined?: boolean;
}

interface SmallIconProps {
	outlined?: boolean;
}

export type CheckMode = "unchecked" | "checked" | "clear";

const CheckboxWrapper = styled.div<CheckboxProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: ${p => p.theme.inputWidgetWidth};
	height: ${p => p.theme.inputWidgetWidth};
	${p => p.checked ? {
		background: "currentColor"
	} : {
		background: p.outlined ? "transparent" : p.theme.cardBackground,
		border: "1px solid"
	}};
	border-radius: ${p => p.theme.borderRadius};
`;

const CheckboxTarget = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	opacity: 0;
	cursor: pointer;
`;

const SmallIcon = styled(Icon)<SmallIconProps>`
	width: 10px;
	color: ${p =>
		p.outlined ?
			p.theme.darkBackground :
			p.theme.cardBackground
	};
`;

const Checkbox = (props: CheckboxProps) => {
	let checked = props.checked,
		iconName: IconName = "check";

	if (typeof props.checked != "boolean") {
		checked = props.mode !== "unchecked";

		switch (props.mode) {
			case "checked":
				iconName = "check";
				break;

			case "clear":
				iconName = "uncheck-clear";
				break;
		}
	}

	const icon = checked ?
		<SmallIcon
			outlined={props.outlined}
			name={iconName}
		/> :
		null;

	return (
		<CheckboxWrapper
			checked={checked}
			outlined={props.outlined}
			className={props.className}
		>
			<CheckboxTarget
				id={props.name}
				name={props.name}
				required={props.required}
				disabled={props.disabled}
				type="checkbox"
				checked={checked}
				onChange={props.onChange}
			/>
			{icon}
		</CheckboxWrapper>
	);
};

export default Checkbox;
