import { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";

import { isObject } from "@qtxr/utils";

import { format, request } from "../../utils";

import { MONTHS } from "../../data/constants";

import useGraphConfigCollection from "../../hooks/use-graph-config-collection";
import useGraphBox from "../../hooks/use-graph-box";

import {
	Pie,
	ReachSite,
	ReachGeo,
	Table,
	MultiBarGraph,
	Stacked,
	GraphRow,
	GraphBox
} from "../../components/viz";

import { CellRuntime } from "../../components/viz/graphs/configs/table";
import { AugmentedRequestConfig } from "../../types/utils";
import {
	propsAndQueryParamsProxy,
	toArray,
	toNumber
} from "../../utils/standalone-utils";
import { useAppDispatch } from "../../state/hooks";
import { setSupplyError, supplyParties } from "../../state/features/data";
import { DOWNLOAD_PDF_DIV_ID } from "../../utils/download-pdf";

const MiniGraphBox = styled(GraphBox)`
	height: 240px;
`;

const FullHeightGraphBox = styled(GraphBox)`
	height: 300px;
`;

const ReachGraphBox = styled(GraphBox)`
	height: 400px;
`;

const TableGraphBox = styled(GraphBox)`
	min-height: 100px;
`;

const PieGraphBox = styled(FullHeightGraphBox)`
	width: 400px;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 400px;
`;

const PageTitle = styled.div`
	font-size: 2em;
`;

const RaceTitle = styled.div`
	font-size: 1.5em;
`;

const Title = styled.div`
	font-size: 0.75em;
	font-weight: bold;
	margin: 1em 0 2em 0;
	text-align: center;
	width: 100%;
`;

const DEBUGGING = false; // localStorage.getItem("mb-debugging") === "true";

const Graphs = withTheme((props: any) => {
	const colors = props.theme.graphs.palettes.colors,
		grayscale = props.theme.graphs.palettes.grayscale,
		reachColors = props.theme.graphs.reach;

	const params = propsAndQueryParamsProxy(props);

	const campaign_id = toArray(params.campaigns)?.[0];
	const channels = toArray(params.channels) || [];
	const collection = props.collection;
	const start_date = toNumber(params.start_date);
	const end_date = toNumber(params.end_date);

	const query = {
		campaign_id,
		channels,
		start_date,
		end_date
	};

	const mkRequest = (
		url: string,
		process?: (data: any) => any
	): AugmentedRequestConfig => {
		return {
			url: `/graphs/ott/${url}`,
			query,
			cacheHash: props.hash,
			process
		};
	};

	const mkNgRequest = DEBUGGING
		? (
				url: string,
				process?: (data: any) => any
		  ): AugmentedRequestConfig => {
				return {
					url: `@next-gen/graphs/ott/${url}`,
					query,
					cacheHash: props.hash,
					process
				};
		  }
		: mkRequest;

	const displayUsd = (rt: CellRuntime) => format.dollar(rt.value);
	const displayWholeNum = (rt: CellRuntime) =>
		format.wholeNumber(rt.value);

	const resolveStacked = (
		data: any[],
		keys:
			| string
			| Record<
					string,
					| string
					| [
							string,
							(
								| string
								| ((
										label: string
								  ) => string)
							)
					  ]
			  >
	): any[] => {
		const map = [],
			out = [];

		if (typeof keys == "string") {
			map.push({
				key: keys,
				type: null,
				label: null
			});
		} else {
			Object.entries(keys).forEach(([key, d]) => {
				const type = Array.isArray(d) ? d[0] : d,
					label = Array.isArray(d) ? d[1] : null;

				map.push({
					key,
					type,
					label
				});
			});
		}

		for (const m of map) {
			for (const channel of channels) {
				const entry = {
					label:
						typeof m.label == "function"
							? (m.label as any)(
									channel
							  )
							: m.label || channel,
					timeline: [] as any[]
				};

				for (const d of data) {
					const source = d[m.key],
						value = isObject(source)
							? source[channel]
							: source;

					entry.timeline.push({
						value,
						date: d.date,
						type: m.type
					});
				}

				out.push(entry);
			}
		}

		return out;
	};

	const resolveReachSite = (data: any[], key: string): any[] => {
		return data.map(d => {
			return {
				name: d[key],
				impressions: d.impressions
			};
		});
	};

	const resolveReachGeo = (data: any[], key: string): any[] => {
		return data.map(d => {
			return {
				name: d[key],
				impressions: d.impressions,
				cpm: d.cpm
			};
		});
	};

	const resolvePerformance = (
		data: any[],
		key: string,
		hrefKey?: string
	) => {
		return data.map(d => ({
			name: d[key],
			href: hrefKey ? d[hrefKey] || null : null,
			impressions: d.impressions,
			videoViews: d.video_views,
			completions: d.completions,
			adSpend: d.ad_spend,
			cpm: d.cpm,
			ctr: d.ctr,
			cpv: d.cpv
		}));
	};

	const adSpend = useGraphBox({
		graph: Stacked,
		title: "Ad Spend",
		request: mkNgRequest("timeline", d => {
			return resolveStacked(d, "ad_spend");
		}),
		box: MiniGraphBox,
		// tooltips: d => d.label,
		layout: {
			legends: {
				level: Infinity // hides the legend.
			},
			valueMap: {
				date: "x",
				value: "y"
			},
			groupLevel: -1,
			colors: grayscale
		},
		kpi: (d: any) => {
			// aggregate total across all channels
			let total = (d.points || []).reduce(
				(a: any, b: any) => {
					let r = 0.0;

					for (let key in b) {
						// each row has multiple channels. add each to running total.
						r += b[key].value.y;
					}
					return a + r;
				},
				0
			);

			return `$${format.wholeNumber(total)}`;
		},
		track: collection.track("ad-spend")
	});

	const impressions = useGraphBox({
		graph: Stacked,
		title: "Impressions",
		request: mkNgRequest("timeline", d => {
			return resolveStacked(d, "impressions");
		}),
		box: MiniGraphBox,
		// tooltips: d => d.label,
		kpi: (d: any) => {
			// aggregate total across all channels
			let total = (d.points || []).reduce(
				(a: any, b: any) => {
					let r = 0.0;

					for (let key in b) {
						// each row has multiple channels. add each to running total.
						r += b[key].value.y;
					}
					return a + r;
				},
				0
			);

			return `${format.wholeNumber(total)}`;
		},
		layout: {
			legends: {
				level: Infinity // hides the legend.
			},
			valueMap: {
				date: "x",
				value: "y"
			},
			groupLevel: -1,
			colors: grayscale
		},
		track: collection.track("impressions")
	});

	const cpm = useGraphBox({
		graph: Stacked,
		title: "CPM",
		request: mkNgRequest("timeline", d => {
			return resolveStacked(d, {
				cpm_average: ["stacked", "CPM by Day"]
				// cpv: ["line", "Cost per View"]
			});
		}),
		box: MiniGraphBox,
		kpi: (d: any) => {
			// calculate average!
			let denominator = 0;
			let total = (d.totals || []).reduce(
				(a: any, b: any) => {
					if (b > 0.0) {
						denominator += 1;
					}
					return a + b;
				},
				0
			);

			let average = denominator ? total / denominator : 0;
			return format.dollar(average);
		},
		// tooltips: d => (d.value as MappedValues).type === "line" ?
		// 	`${d.label}: ${(d.value as MappedValues).y}` :
		// 	d.label,
		layout: {
			legends: {
				level: Infinity // hides the legend.
			},
			valueMap: {
				date: "x",
				value: "y",
				type: "type"
			},
			groupLevel: -1,
			colors: [grayscale[0], colors[0]]
		},
		track: collection.track("cpm")
	});

	const videoCompletions = useGraphBox({
		graph: Stacked,
		title: "Video Completions",
		request: mkNgRequest("timeline", d => {
			const ret = resolveStacked(d, {
				completions: "stacked"
			});
			return ret;
		}),
		box: MiniGraphBox,
		kpi: (d: any) => {
			// aggregate total across all channels
			let total = (d.points || []).reduce(
				(a: any, b: any) => {
					let r = 0.0;

					for (let key in b) {
						// each row has multiple channels. add each to running total.
						r += b[key].value.y;
					}
					return a + r;
				},
				0
			);

			return `${format.wholeNumber(total)}`;
		},
		// tooltips: d => (d.value as MappedValues).type === "line" ?
		// 	`${d.label}: ${(d.value as MappedValues).y}` :
		// 	d.label,
		layout: {
			legends: {
				level: Infinity // hides the legend.
			},
			valueMap: {
				date: "x",
				value: "y",
				type: "type"
			},
			groupLevel: -1,
			colors: [
				...grayscale.slice(0, channels.length),
				...colors.slice(0, channels.length)
			]
		},
		track: collection.track("video-completions")
	});

	const impressionsByChannel = useGraphBox({
		graph: Pie,
		title: "Impressions by Channel",
		request: mkNgRequest("timeline", d => {
			// aggregate to a single value per channel
			const ret = {} as any;
			for (var row of d) {
				for (var channel in row["impressions"]) {
					ret[channel] =
						(ret[channel] || 0) +
						(row["impressions"][channel] ||
							0);
				}
			}

			return ret;
		}),
		box: PieGraphBox,
		tooltips: d =>
			`${d.label}: ${format.number(d.value as number)}`,
		layout: {
			groupWrapper: "wrapper",
			colors
		},
		track: collection.track("impressions-by-channel")
	});

	const viewsByDevice = useGraphBox({
		graph: Pie,
		title: "Views by Device",
		request: mkRequest("devices"),
		box: PieGraphBox,
		tooltips: d =>
			`${d.label}: ${format.number(d.value as number)}`,
		layout: {
			groupWrapper: "wrapper",
			colors
		},
		track: collection.track("views-by-device")
	});

	const performanceByWeek = useGraphBox({
		graph: MultiBarGraph,
		title: "Performance by Week",
		request: mkNgRequest("timeline", d => {
			const startDate = new Date(start_date),
				startDay = startDate.getDay(),
				groups = [] as any[][];
			let group = [] as any[],
				dayOffset = 0;

			d.forEach((entry: any, idx: number) => {
				const day = (startDay + idx) % 7;

				if (day === 0) {
					if (group.length) groups.push(group);

					group = [];
				}

				group.push(entry);
			});

			if (group.length) groups.push(group);

			const out = [] as any[];

			for (const g of groups) {
				const entry = {
					// week: `${start.getDate()} ${MONTHS[start.getMonth()]} - ${end.getDate()} ${MONTHS[end.getMonth()]} ${end.getFullYear()}${dayLabel}`,
					adSpend: 0,
					impressions: 0
					// cpm: 0
				};

				for (const day of g) {
					for (const channel of channels) {
						entry.adSpend +=
							day.ad_spend[channel];
						entry.impressions +=
							day.impressions[
								channel
							];
						// entry.cpm += day.cpm[channel];
					}
				}

				// entry.cpm /= (g.length * channels.length);
				dayOffset += g.length;
				out.push(entry);
			}

			return out;
		}),
		box: FullHeightGraphBox,
		tooltips: d => d.label,
		layout: {
			legends: {
				level: 0
			},
			valueMap: {
				date: "x",
				value: "y"
			},
			groupLevel: -1,
			colors
		},
		track: collection.track("performance-by-week")
	});

	const performanceByWeekTable = useGraphBox({
		graph: Table,
		title: "Performance by Week",
		request: mkNgRequest("timeline", d => {
			const startDate = new Date(start_date),
				startYear = startDate.getFullYear(),
				startMonth = startDate.getMonth(),
				startDt = startDate.getDate(),
				startDay = startDate.getDay(),
				groups = [] as any[][];
			let group = [] as any[],
				dayOffset = 0;

			d.forEach((entry: any, idx: number) => {
				const day = (startDay + idx) % 7;

				if (day === 0) {
					if (group.length) groups.push(group);

					group = [];
				}

				group.push(entry);
			});

			if (group.length) groups.push(group);

			const out = [] as any[];

			for (const g of groups) {
				const start = new Date(
						startYear,
						startMonth,
						startDt + dayOffset
					),
					end = new Date(
						startYear,
						startMonth,
						startDt +
							dayOffset +
							g.length -
							1
					),
					dayLabel =
						g.length === 7
							? ""
							: ` (${g.length} day${
									g.length ===
									1
										? ""
										: "s"
							  })`;

				const entry = {
					week: `${start.getDate()} ${
						MONTHS[start.getMonth()]
					} - ${end.getDate()} ${
						MONTHS[end.getMonth()]
					} ${end.getFullYear()}${dayLabel}`,
					adSpend: 0,
					impressions: 0,
					cpm: 0
				};

				for (const day of g) {
					for (const channel of channels) {
						entry.adSpend +=
							day.ad_spend[channel];
						entry.impressions +=
							day.impressions[
								channel
							];
						entry.cpm += day.cpm[channel];
					}
				}

				entry.cpm /= g.length * channels.length;
				dayOffset += g.length;
				out.push(entry);
			}

			return out;
		}),
		box: TableGraphBox,
		layout: {
			legends: null
		},
		store: {
			display: displayUsd,
			footer: (rt: CellRuntime) => {
				if (!rt.index) {
					return rt.print("Total").weight(700);
				}

				if (rt.column.title == "CPM") {
					const total_adspend =
						rt.section.rows.reduce(
							(tot, row) => {
								return (
									tot +
									row
										.cells[1]
										.value
								);
							},
							0
						);
					const total_impressions =
						rt.section.rows.reduce(
							(tot, row) => {
								return (
									tot +
									row
										.cells[2]
										.value
								);
							},
							0
						);

					const average_cpm =
						total_impressions > 0
							? total_adspend /
							  (total_impressions /
									1000)
							: 0.0;
					return rt
						.display(average_cpm)
						.weight(700);
				}
				const total = rt.section.rows.reduce(
					(tot, row) => {
						return (
							tot +
							row.cells[rt.index]
								.value
						);
					},
					0
				);

				return rt.display(total).weight(700);
			},
			columns: [
				{
					title: "Week",
					accessor: "week",
					display: null
				},
				{
					title: "Ad Spend",
					accessor: "adSpend",
					align: "end"
				},
				{
					title: "Impressions",
					accessor: "impressions",
					align: "end",
					display: null
				},
				{ title: "CPM", accessor: "cpm", align: "end" }
			]
		},
		getHeight: d => (d.length + 2) * 25,
		track: collection.track("performance-by-week-table")
	});

	const geoVideoPerformance = useGraphBox({
		graph: ReachGeo,
		title: "Geo Video Impressions",
		request: mkNgRequest("geo/performance", d => {
			return resolveReachGeo(d, "geo");
		}),
		box: ReachGraphBox,
		tooltips: d => {
			const v = d.value as number;

			switch (d.label) {
				case "cpm":
					return `CPM: $${format.number(v)}`;

				case "completions":
					return `Completions: ${format.number(
						v
					)}`;

				case "impressions":
				default:
					return `Impressions: ${format.number(
						v
					)}`;
			}
		},
		layout: {
			labelKey: "name",
			colors: reachColors
		},
		track: collection.track("geo-video-performance")
	});

	const videoPerformance = useGraphBox({
		graph: Table,
		title: "Geo Performance",
		request: mkNgRequest("geo/performance", d => {
			return resolvePerformance(d, "geo");
		}),
		box: TableGraphBox,
		layout: {
			legends: null
		},
		store: {
			columns: [
				{ title: "City", accessor: "name" },
				{
					title: "Ad Spend",
					accessor: "adSpend",
					align: "end",
					display: displayUsd
				},
				{
					title: "Impressions",
					accessor: "impressions",
					align: "end",
					display: displayWholeNum
				},
				{
					title: "Completions",
					accessor: "completions",
					align: "end",
					display: displayWholeNum
				},
				{
					title: "CPM",
					accessor: "cpm",
					align: "end",
					display: displayUsd
				}
			]
		},
		getHeight: d => (d.length + 1) * 25,
		track: collection.track("geo-performance")
	});

	const siteVideoPerformance = useGraphBox({
		graph: ReachSite,
		title: "Site Video Impressions",
		request: mkNgRequest("site/performance", d => {
			return resolveReachSite(d, "site");
		}),
		box: ReachGraphBox,
		tooltips: d => {
			const v = d.value as number;

			switch (d.label) {
				case "completionPercentage":
					return `Completion Percentage: ${format.number(
						v * 100
					)}%`;

				case "impressions":
					return `Impressions: ${format.number(
						v
					)}`;

				case "completions":
				default:
					return `Completions: ${format.number(
						v
					)}`;
			}
		},
		layout: {
			labelKey: "name",
			colors: reachColors
		},
		track: collection.track("site-video-performance")
	});

	const sitePerformance = useGraphBox({
		graph: Table,
		title: "Site Performance",
		request: mkNgRequest("site/performance", d => {
			return resolvePerformance(d, "site", "site");
		}),
		box: TableGraphBox,
		layout: {
			legends: null
		},
		store: {
			columns: [
				{
					title: "Site",
					accessor: "name",
					maxLength: 80
				},
				{
					title: "Ad Spend",
					accessor: "adSpend",
					align: "end",
					display: displayUsd
				},
				{
					title: "Impressions",
					accessor: "impressions",
					align: "end",
					display: displayWholeNum
				},
				{
					title: "Completions",
					accessor: "completions",
					align: "end",
					display: displayWholeNum
				},
				{
					title: "CPM",
					accessor: "cpm",
					align: "end",
					display: displayUsd
				}
			]
		},
		getHeight: d => (d.length + 1) * 25,
		track: collection.track("site-performance")
	});

	return (
		<>
			<GraphRow>
				{adSpend}
				{impressions}
				{cpm}
				{videoCompletions}
			</GraphRow>
			<GraphRow>
				{impressionsByChannel}
				{viewsByDevice}
				{performanceByWeek}
			</GraphRow>
			<GraphRow>{performanceByWeekTable}</GraphRow>
			<GraphRow>{geoVideoPerformance}</GraphRow>
			<GraphRow>{videoPerformance}</GraphRow>
			<GraphRow>{siteVideoPerformance}</GraphRow>
			<GraphRow>{sitePerformance}</GraphRow>
		</>
	);
});

const Content = (props: any) => {
	const [campaignName, setCampaignName] = useState<string>("");
	const [dateRange, setDateRange] = useState<string>("");
	const [raceName, setRaceName] = useState<string>("");

	const params = propsAndQueryParamsProxy(props);
	const campaignId = toArray(params.campaigns)?.map(toNumber)[0];
	const startDate = toNumber(params.start_date);
	const endDate = toNumber(params.end_date);

	useEffect(() => {
		if (endDate && startDate) {
			setDateRange(
				`${new Date(
					startDate
				).toLocaleDateString()} - ${new Date(
					endDate
				).toLocaleDateString()}`
			);
		}
	}, [endDate, startDate]);

	useEffect(() => {
		if (typeof campaignId === "number") {
			request(`/campaigns/${campaignId}`).then(resp => {
				if (resp.success) {
					const name = `${resp.data.race.race_name} ${resp.data.race.year}`;
					setRaceName(name);
					setCampaignName(
						`${resp.data.candidate.full_name}`
					);
					props.onSetRaceName(name);
				}
			});
		}
	}, [campaignId]);

	return (
		<div style={{ overflow: "auto" }} id={DOWNLOAD_PDF_DIV_ID}>
			<Title>
				<PageTitle>OTT Report</PageTitle>
				<RaceTitle>{raceName}</RaceTitle>
				<RaceTitle>{campaignName}</RaceTitle>
				{dateRange}
			</Title>
			<Graphs {...props} hash={props.hash || "0"} />
		</div>
	);
};

const OttStandalone = (props: any) => {
	const fallbackCollection = useGraphConfigCollection();
	const collection = props.collection || fallbackCollection;

	const dispatch = useAppDispatch();
	useEffect(() => {
		const req = async (
			url: string,
			dispatcher: (action: any) => any
		) => {
			const response = await request(url);
			if (response.success)
				dispatch(dispatcher(response.data));
			else if (localStorage.getItem("debugging") === "true")
				dispatch(dispatcher([]));
			else dispatch(setSupplyError(response.errorMessage!));
		};

		req("/parties/list", supplyParties); // required for party color themes
	}, []);

	return <Content {...props} collection={collection} />;
};

export default OttStandalone;
