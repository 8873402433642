// This utility consumes raw data and produces a standardized
// data payload for Viz graphs with meta information, and a
// set of legends to render along with the main visualization
//
// Output:
// data			- an array of objects containing transposed data
// points		- an array of meta data describing common attributes between
//				  any number of data points (accessor, color, etc)
// groups		- an array of meta data describing common attributes between
//				  any number of points meta data

import {
	mkPath,
	isObject
} from "@qtxr/utils";
import Color from "@qtxr/color";

export interface Categorized {
	data: Data[];
	points: Point[];
	groups: Group[];
	isCategorized: true;
}

export interface CategorizationConfig {
	// Runtime entries
	subKey?: string;
	subKeys?: string[];
	keyKey?: string;
	keyKeys?: string[];
	labelKey?: string;
	labelKeys?: string[];
	valueKey?: string;
	valueKeys?: string[];
	shortValueKey?: string;
	shortValueKeys?: string[];
	colorKey?: string;
	colorKeys?: string[];
	valueMap?: (ValueMap | NamedValueMap);
	valueMaps?: (ValueMap | NamedValueMap)[];
	formatLabel?: LabelFormatter;
	formatLabels?: LabelFormatter[];
	// Color configuration
	tintStep?: number;
	baseColor?: string;
	colors?: string[] | ColorMap;
	colorRule?: ColorRule;
	colorIndices?: string[] | ColorIndices;
	// Structure
	groupLevel?: number;
	order?: number[];
	uniquePoints?: boolean;
	spread?: boolean;
	groupWrapper?: string;
}

interface AugmentedCategorizationConfig {
	subKeys: string[];
	keyKeys: string[];
	labelKeys: string[];
	valueKeys: string[];
	shortValueKeys: string[];
	colorKeys: string[];
	valueMaps: CompiledValueMap[];
	formatLabels: LabelFormatter[];
	tintStep: number;
	baseColor: string;
	colors: string[];
	colorMap: ColorMap;
	colorRule: ColorRule | null;
	groupLevel: number;
	order: number[] | null;
	uniquePoints: boolean;
	spread: boolean;
	groupWrapper: string;
}

interface ColorMap {
	[key: string]: string;
}

interface ColorIndices {
	[key: string]: number;
}

export interface MappedValues {
	[key: string]: any;
}

interface ValueMap {
	[key: string]: ValueMapEntry | string;
}

interface ValueMapEntry {
	target: string;
	transform: ValueTransform;
}

interface NamedValueMap {
	key?: string;
	inject?: boolean;
	map: ValueMap;
}

interface ColorRule {
	track?: number[];
}

interface CompiledValueMapEntry {
	key: string;
	target: string;
	transform: ValueTransform | null;
}

interface CompiledValueMap {
	key: string | null;
	inject: boolean;
	entries: CompiledValueMapEntry[];
}

interface Runtime {
	data: Data[];
	points: Point[];
	pointsMap: any;
	pointsIndicesMap: any;
	groups: Group[];
	groupsMap: any;
	minIndex: number;
}

export interface Point {
	label: string;
	rawLabel: string;
	accessor: string;
	color: string;
	total: number;
	average: number;
	min: number;
	max: number;
	size: number;
	data: DataPoint[];
	index: number;
	trace: (Point | Group)[];
	processed: boolean;
	isPoint: true;
}

export interface Group {
	label: string;
	rawLabel: string;
	color: string;
	level: number;
	points: Point[];
	groups: Group[];
	pointsMap: any;
	groupsMap: any;
	index: number;
	trace: (Point | Group)[];
	processed: boolean;
	isGroup: true;
}

export interface Data {
	[key: string]: DataPoint;
}

export interface DataPoint {
	label: string;
	value: MappedValues | number;
	trace: (Point | Group)[];
	isDataPoint: true;
}

interface TraceMeta {
	data: DataPoint;
	trace: (Point | Group)[];
}

interface MappedValuesMeta {
	values: MappedValues;
	map: CompiledValueMap;
}

type ValueTransform = (value: any) => any;
type LabelFormatter = (label: string) => string;

const applyCategorization = (
	data: any,
	config: CategorizationConfig = {}
): Categorized => {
	const conf = augmentConfig(config);
	let root = mkGroup("root", "", -1, null);

	const runtime = {
		data: [],
		points: [],
		pointsMap: {},
		pointsIndicesMap: {},
		groups: [],
		groupsMap: {},
		minIndex: -1
	} as Runtime;

	if (conf.groupWrapper) {
		root = mountGroup(
			conf.groupWrapper,
			"",
			root,
			runtime,
			conf,
			conf.groupWrapper
		);
	}

	categorizeSplit(data, root, runtime, conf);

	let categorized = {
		data: runtime.data,
		points: runtime.points,
		groups: runtime.groups,
		isCategorized: true
	} as Categorized;

	cleanUp(categorized);

	// Handle order change
	if (conf.order) {
		const runOrderChange = conf.order.some((pos, i) => pos !== i);

		if (runOrderChange)
			categorized = reorder(categorized, conf);
	}

	fillColors(categorized, conf);
	cleanUp(categorized);

	return categorized;
};

const augmentConfig = (config: CategorizationConfig): AugmentedCategorizationConfig => {
	const conf = {
		tintStep: config.tintStep || 0.2,
		baseColor: config.baseColor || "white",
		colors: config.colors || ["red", "green", "blue", "purple", "orange", "black", "yellow"],
		colorMap: {},
		colorRule: config.colorRule || null,
		groupLevel: typeof config.groupLevel == "number" ?
			config.groupLevel :
			0,
		order: config.order || null,
		uniquePoints: Boolean(config.uniquePoints),
		spread: Boolean(config.spread),
		groupWrapper: config.groupWrapper || ""
	} as AugmentedCategorizationConfig;

	const c = config;

	conf.subKeys = resolveConfigList("sub", c.subKey, c.subKeys, "sub");
	conf.keyKeys = resolveConfigList("key", c.keyKey, c.keyKeys, "key");
	conf.labelKeys = resolveConfigList("label", c.labelKey, c.labelKeys, "label");
	conf.valueKeys = resolveConfigList("value", c.valueKey, c.valueKeys, "value");
	conf.shortValueKeys = resolveConfigList("short value", c.shortValueKey, c.shortValueKeys, []);
	conf.colorKeys = resolveConfigList("color", c.colorKey, c.colorKeys, "color");

	const valueMaps = resolveConfigList("value map", c.valueMap, c.valueMaps, []);
	conf.valueMaps = valueMaps.map(compileValueMap);

	conf.formatLabels = resolveConfigList("label formatter", c.formatLabel, c.formatLabels, []);

	if (isObject(c.colors)) {
		conf.colorMap = {
			...conf.colorMap,
			...c.colors as ColorMap
		};

		conf.colors = [];
	}

	if (Array.isArray(c.colorIndices)) {
		c.colorIndices.forEach((name, idx) => {
			conf.colorMap[name] = conf.colors[idx % conf.colors.length];
		});
	} else if (isObject(c.colorIndices)) {
		for (const k in c.colorIndices) {
			if (!c.colorIndices.hasOwnProperty(k))
				continue;

			conf.colorMap[k] = conf.colors[c.colorIndices[k] % conf.colors.length];
		}
	}

	return conf;
};

function resolveConfigList<T>(
	target: string,
	entry: T | undefined,
	entries: T[] | undefined,
	def: T[] | T
): T[] {
	if (entry && entries)
		console.warn(`Configuration for ${target} keys contains both an entry array and an entry literal. Only the entry array will be used`);

	let out: T | T[];

	if (entries)
		out = entries;
	else if (entry)
		out = entry;
	else
		out = def;

	return Array.isArray(out) ?
		out :
		[out];
}

const compileValueMap = (map: ValueMap | NamedValueMap) => {
	let m = map as ValueMap,
		key = null,
		inject = false;

	if (map.hasOwnProperty("map") && isObject((map as NamedValueMap).map)) {
		m = (map as NamedValueMap).map;
		key = (map as NamedValueMap).key || null;
		inject = Boolean((map as NamedValueMap).inject);
	}

	const compiled = {
		key,
		inject,
		entries: []
	} as CompiledValueMap;

	for (const k in m) {
		if (!m.hasOwnProperty(k))
			continue;

		let target = m[k] as string,
			transform = null as ValueTransform | null;

		if (typeof m[k] != "string") {
			if (!isObject(m[k]) || m[k].hasOwnProperty("target"))
				throw new TypeError(`Cannot compile value map: value at '${k}' is not a string key or a value map entry`);

			target = (m[k] as ValueMapEntry).target;
			transform = (m[k] as ValueMapEntry).transform;
		}

		compiled.entries.push({
			key: k,
			target,
			transform
		});
	}

	return compiled;
}

const categorizeSplit = (
	data: any,
	group: Group,
	runtime: Runtime,
	config: AugmentedCategorizationConfig
) => {
	if (Array.isArray(data))
		categorizeArray(data, group, runtime, config);
	else
		categorizeObject(data, group, runtime, config);
};

const categorizeArray = (
	data: any[],
	group: Group,
	runtime: Runtime,
	config: AugmentedCategorizationConfig
) => {
	for (const entry of data) {
		const mapped = resolveMappedValues(entry, config, group);
		if (mapped) {
			mountMappedValues(
				mapped,
				group,
				runtime,
				config
			);

			continue;
		}

		const valueKey = getActiveKey(config.valueKeys, group),
			subKey = getActiveKey(config.subKeys, group);
		let adder = null;

		if (entry.hasOwnProperty(valueKey))
			adder = addDataPoint;
		else if (subKey && entry.hasOwnProperty(subKey))
			adder = addGroup;

		// If no adder is resolved, continue categorizing
		// without creating an intermediate node
		if (!adder) {
			categorizeSplit(
				entry,
				group,
				runtime,
				config
			);

			continue;
		}

		const key = resolveKey(entry, config, group);
		if (key == null) {
			console.error("Failed to resolve value or group");
			continue;
		}

		const success = adder(
			key,
			entry,
			group,
			runtime,
			config
		);

		if (!success)
			console.error("Failed to resolve value or group");
	}
};

const categorizeObject = (
	data: any,
	group: Group,
	runtime: Runtime,
	config: AugmentedCategorizationConfig
) => {
	// If the object contains an appropriate subdivision entry,
	// treat it as a group, else treat its children as subgroups
	const valueKey = getActiveKey(config.valueKeys, group),
		subValueKey = getActiveKey(config.shortValueKeys, group),
		subKey = getActiveKey(config.subKeys, group.level + 2),
		labelKey = getActiveKey(config.labelKeys, group);
	let activeGroup = group;

	const add = (
		adder: typeof addGroup | typeof addDataPoint,
		k: string,
		d: any = data,
		label: string = k
	) => {
		return adder(
			k,
			d,
			activeGroup,
			runtime,
			config,
			label
		);
	};

	// If the data has an appropriate value key,
	// treat it as a data point
	if (data.hasOwnProperty(valueKey)) {
		add(addDataPoint, subKey);
		return;
	}

	// If the data has an appropriate subdivision
	// key, treat it as a group
	if (data.hasOwnProperty(subKey)) {
		add(addGroup, subKey);
		return;
	}

	// If the data has an appropriate label, treat it as a
	// group with subdivisions
	if (data.hasOwnProperty(labelKey)) {
		const d = data[getActiveKey(config.subKeys, group)] || data[labelKey];

		if (!d) {
			console.error("Failed to resolve group");
			return;
		}

		activeGroup = mountGroup(
			resolveLabel(data, config, group) || data[labelKey],
			data[getActiveKey(config.colorKeys, group)] || "",
			group,
			runtime,
			config,
			data[labelKey]
		);
	}

	for (const k in data) {
		if (!data.hasOwnProperty(k) || k === labelKey)
			continue;

		const sub = data[k],
			mapped = resolveMappedValues(sub, config, group);

		if (mapped) {
			mountMappedValues(
				mapped,
				group,
				runtime,
				config
			);
		} else if (isObject(sub)) {
			if (sub.hasOwnProperty(subValueKey))
				add(addDataPoint, subValueKey, sub, k);
			else
				add(addGroup, k);
		} else if (Array.isArray(sub))
			add(addGroup, k);
		else
			add(addDataPoint, k);
	}
};

const addDataPoint = (
	key: string,
	entry: any,
	group: Group,
	runtime: Runtime,
	config: AugmentedCategorizationConfig,
	accessor: string = key
): boolean => {
	const valueKey = getActiveKey(config.valueKeys, group);
	let dp: DataPoint;

	if (entry.hasOwnProperty(valueKey)) {
		// If the entry has an explicit value, resolve
		// the label or set the default from the accessor
		dp = mkDataPoint(
			resolveLabel(entry, config, group) || accessor,
			entry[valueKey]
		);
	} else {
		// Else, use the provided key and use the provided
		// accessor as the label
		dp = mkDataPoint(
			accessor,
			entry[key]
		);
	}

	mountDataPoint(
		dp,
		entry[getActiveKey(config.colorKeys, group)] || "",
		group,
		runtime,
		config,
		accessor
	);

	return true;
};

const mountDataPoint = (
	dataPoint: DataPoint,
	color: string,
	group: Group,
	runtime: Runtime,
	config: AugmentedCategorizationConfig,
	accessor: string
): DataPoint => {
	let idx,
		point;

	if (runtime.pointsIndicesMap.hasOwnProperty(accessor))
		idx = runtime.pointsIndicesMap[accessor] + 1;
	else if (config.spread)
		idx = runtime.data.length;
	else
		idx = 0;

	if (idx < runtime.minIndex)
		idx = runtime.minIndex;

	runtime.pointsIndicesMap[accessor] = idx;

	runtime.data[idx] = runtime.data[idx] || {};
	runtime.data[idx][accessor] = dataPoint;

	if (!config.uniquePoints && runtime.pointsMap.hasOwnProperty(accessor))
		point = runtime.pointsMap[accessor];
	else {
		point = mkPoint(
			dataPoint.label,
			mkPath(accessor),
			color,
			group.trace
		);

		if (!config.uniquePoints || !runtime.pointsMap.hasOwnProperty(accessor)) {
			runtime.points.push(point);
			runtime.pointsMap[accessor] = point;
		}
	}

	dataPoint.trace = group.trace.concat(point);

	point.size++;

	if (typeof dataPoint.value == "number") {
		if (dataPoint.value > point.max)
			point.max = dataPoint.value;
		if (dataPoint.value < point.min)
			point.min = dataPoint.value;

		point.total += dataPoint.value;
		point.average = point.total / point.size;
	}

	point.data.push(dataPoint);

	if (!group.pointsMap.hasOwnProperty(accessor)) {
		point.index = group.points.length;
		group.points.push(point);
		group.pointsMap[accessor] = point;
	}

	return dataPoint;
};

const addGroup = (
	key: string,
	entry: any,
	group: Group,
	runtime: Runtime,
	config: AugmentedCategorizationConfig,
	accessor: string = key
): boolean => {
	const data = entry[getActiveKey(config.subKeys, group)] || entry[key];

	if (!data)
		return false;

	const g = mountGroup(
		resolveLabel(entry, config, group) || accessor,
		entry[getActiveKey(config.colorKeys, group)] || "",
		group,
		runtime,
		config,
		accessor
	);

	categorizeSplit(
		data,
		g,
		runtime,
		config
	);

	return true;
};

const mountGroup = (
	label: string,
	color: string,
	group: Group,
	runtime: Runtime,
	config: AugmentedCategorizationConfig,
	accessor: string
): Group => {
	let g;

	if (group.groupsMap.hasOwnProperty(accessor))
		g = group.groupsMap[accessor];
	else {
		g = mkGroup(
			label,
			color,
			group.level + 1,
			group.trace
		);

		if (g.level === config.groupLevel)
			runtime.minIndex++;

		g.index = group.groups.length;
		group.groups.push(g);
		group.groupsMap[accessor] = g;
	}

	if (!runtime.groupsMap.hasOwnProperty(accessor)) {
		runtime.groups.push(g);
		runtime.groupsMap[accessor] = g;
	}

	return g;
};

const mountMappedValues = (
	mapped: MappedValuesMeta,
	group: Group,
	runtime: Runtime,
	config: AugmentedCategorizationConfig
) => {
	const mount = (dp: DataPoint, accessor?: string) => {
		const g = resolveTargetGroup(group);

		mountDataPoint(
			dp,
			g.color,
			g,
			runtime,
			config,
			accessor || dp.label
		);
	};

	// Map values directly to the data output if explicitly specified
	// in a value map, or if the target group is the root
	if (mapped.map.inject || group.level < 0) {
		for (const entry of mapped.map.entries) {
			const dp = mkDataPoint(
				entry.key,
				mapped.values[entry.target]
			);

			mount(dp, entry.target);
		}
	} else {
		const dp = mkDataPoint(
			mapped.map.key || group.label,
			mapped.values
		);

		mount(dp);
	}
};

const mkPoint = (
	label: string,
	accessor: string,
	color: string,
	trace: (Point | Group)[]
): Point => {
	const point = {
		label,
		rawLabel: label,
		accessor,
		color,
		total: 0,
		average: 0,
		min: Infinity,
		max: -Infinity,
		size: 0,
		data: [],
		index: -1,
		trace: [],
		processed: false,
		isPoint: true
	} as Point;

	point.trace = trace.concat(point);

	return point;
};

const mkGroup = (
	label: string,
	color: string,
	level: number,
	trace: (Point | Group)[] | null
): Group => {
	const group = {
		label,
		rawLabel: label,
		color,
		level,
		points: [],
		groups: [],
		pointsMap: {},
		groupsMap: {},
		index: -1,
		trace: [],
		processed: false,
		isGroup: true
	} as Group;

	if (trace)
		group.trace = trace.concat(group);

	return group;
};

const mkDataPoint = (
	label: string,
	value: MappedValues | number
): DataPoint => ({
	label,
	value,
	trace: [],
	isDataPoint: true
});

const resolveKey = (
	data: any,
	config: AugmentedCategorizationConfig,
	groupOrLevel: Group | number
): string | null => {
	const keyKey = getActiveKey(config.keyKeys, groupOrLevel),
		labelKey = getActiveKey(config.labelKeys, groupOrLevel);

	if (data.hasOwnProperty(keyKey))
		return data[keyKey];

	if (data.hasOwnProperty(labelKey))
		return data[labelKey];

	return null;
};

const resolveLabel = (
	data: any,
	config: AugmentedCategorizationConfig,
	groupOrLevel: Group | number
): string => {
	const labelKey = getActiveKey(config.labelKeys, groupOrLevel),
		keyKey = getActiveKey(config.keyKeys, groupOrLevel);

	if (data.hasOwnProperty(labelKey))
		return data[labelKey];

	if (data.hasOwnProperty(keyKey))
		return data[keyKey];

	return "";
};

const resolveMappedValues = (
	data: any,
	config: AugmentedCategorizationConfig,
	groupOrLevel: Group | number
): MappedValuesMeta | null => {
	const map = getActiveEntry(config.valueMaps, groupOrLevel, null);
	if (!map || !isObject(data))
		return null;

	const values = {} as MappedValues;

	for (const entry of map.entries) {
		if (!data.hasOwnProperty(entry.key))
			return null;

		values[entry.target] = entry.transform ?
			entry.transform(data[entry.key]) :
			data[entry.key];
	}

	return {
		values,
		map
	};
};

const processNode = (node: Group | Point) => {

};

const resolveTargetGroup = (group: Group): Group => {
	const trace = group.trace;

	if (trace.length < 2 || trace[trace.length - 1] !== group)
		return group;

	let idx = trace.length - 1;

	while (idx > 0) {
		if (trace[idx - 1].label === group.label)
			idx--;
		else
			break;
	}

	return trace[idx] as Group;
};

const reorder = (
	categorized: Categorized,
	config: AugmentedCategorizationConfig
): Categorized => {
	// Collect data traces
	const traceMetas = [] as TraceMeta[],
		order = config.order!;

	for (const point of categorized.points) {
		for (const dp of point.data) {
			if (dp.trace.length > order.length) {
				console.error("Cannot reorder: cannot map trace");
				return categorized;
			}

			const traceMeta = {
				data: dp,
				trace: []
			} as TraceMeta;

			for (const ord of order) {
				const node = dp.trace[ord];

				if (node)
					traceMeta.trace.push(node);
			}

			traceMetas.push(traceMeta);
		}
	}

	// Construct tree with the traces as guides
	const root = mkGroup("root", "", -1, null);

	const runtime = {
		data: [],
		points: [],
		pointsMap: {},
		pointsIndicesMap: {},
		groups: [],
		groupsMap: {},
		minIndex: -1
	} as Runtime;

	const aDataPoint = (
		group: Group,
		node: Point | Group,
		value: MappedValues | number
	): DataPoint => {
		const dp = mkDataPoint(
			node.label,
			value
		);

		const accessor = (node as Point).isPoint ?
			(node as Point).accessor :
			node.label;

		return mountDataPoint(
			dp,
			"",
			group,
			runtime,
			config,
			accessor
		);
	};

	const aGroup = (group: Group, node: Point | Group): Group => {
		return mountGroup(
			node.label,
			"",
			group,
			runtime,
			config,
			node.label
		);
	};

	let g: Group;

	for (const tm of traceMetas) {
		g = root;

		for (let i = 0, l = tm.trace.length; i < l; i++) {
			if (i === l - 1)
				aDataPoint(g, tm.trace[i], tm.data.value);
			else
				g = aGroup(g, tm.trace[i]);
		}
	}

	return {
		data: runtime.data,
		points: runtime.points,
		groups: runtime.groups,
		isCategorized: true
	};
};

const cleanUp = (categorized: Categorized) => {
	// Remove orphaned nodes
	/*function prune<Node>(nodes: Node[]): Node[] {

	}

	categorized.points = prune(categorized.points);
	categorized.groups = prune(categorized.groups);*/
};

interface TrackNode {
	entries: Record<string, TrackNode>;
	size: number;
	index: number;
}

const mkTrackNode = (index: number): TrackNode => ({
	entries: {},
	size: 0,
	index
});

const fillColors = (
	categorized: Categorized,
	config: AugmentedCategorizationConfig
) => {
	const trackRoot = mkTrackNode(0),
		track = config.colorRule?.track || null;
	let idx = 0;

	const getTrackedIndex = (node: Point | Group): number => {
		if (!track)
			return -1;

		const trace = node.trace;
		let tn = trackRoot;

		for (const idx of track) {
			if (idx < 0 || idx >= trace.length)
				return -1;

			const n = trace[idx];

			if (tn.entries.hasOwnProperty(n.label))
				tn = tn.entries[n.label];
			else {
				const newNode = mkTrackNode(tn.size);
				tn.size++;
				tn.entries[n.label] = newNode;
				tn = newNode;
			}
		}

		return tn.index;
	};

	const runFill = (
		nodes: (Point | Group)[],
		parentColor: string,
		guard?: (node: Point | Group) => boolean
	) => {
		nodes.forEach((node, i) => {
			if (guard && !guard(node))
				return;

			fill(node, parentColor, i);
		});
	};

	const fill = (node: Point | Group, parentColor: string, relativeIndex: number) => {
		if (!node.color) {
			const mappedColor = config.colorMap[node.label],
				trackedIndex = mappedColor ?
					-1 :
					getTrackedIndex(node);

			if (mappedColor)
				node.color = mappedColor;
			else if (!parentColor) {
				node.color = config.colors[idx % config.colors.length];
				idx++;
			} else {
				const idx = trackedIndex > -1 ?
					trackedIndex :
					relativeIndex;

				node.color = new Color(parentColor)
					.mix(config.baseColor, (1 - Math.pow(1 - config.tintStep, idx)) * 2)
					.toString("hex");
			}
		}

		// If the categorization uses a group wrapper, do not trigger relative styling
		// of children as the group is used as a structural element, not visual
		let pColor = node.color;
		if (!parentColor && config.groupWrapper && (node as Group).isGroup && (node as Group).level === 0) {
			pColor = "";
			idx--;
		}

		if ((node as Group).groups)
			runFill((node as Group).groups, pColor);
		if ((node as Group).points)
			runFill((node as Group).points, pColor);
	};

	runFill(categorized.groups, "", n => (n as Group).level === 0);
	runFill(categorized.points, "");
};

// This function is used to get an active entry corresponding to the level
// at which the data is to be found. Throughout this library, when a group
// is used to reference an entry, its level is always one less than the target,
// hence why the level is increased by one if a group is provided
function getActiveEntry<T>(
	entries: T[],
	groupOrLevel: Group | number,
	def: T
): T {
	const level = typeof groupOrLevel == "number" ?
		groupOrLevel :
		groupOrLevel.level + 1;

	return entries[level % entries.length] || def;
}

const getActiveKey = (
	keys: string[],
	groupOrLevel: Group | number,
): string => {
	return getActiveEntry(keys, groupOrLevel, "");
};

// @ts-ignore
window.cat = applyCategorization;

export default applyCategorization;
