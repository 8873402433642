import styled from "styled-components";

const NAWrapper = styled.span`
	display: inline-block;
	padding: 4px 6px;
	border-radius: ${p => p.theme.borderRadius};
	background: ${p => p.theme.overlayBackground};
	color: ${p => p.theme.naColor};
	font-size: 70%;
`;

const NA = () => (
	<NAWrapper>N / A</NAWrapper>
);

export default NA;
