import StagedComponent, {
	scaled,
	mkSetterMaker
} from "./";

import {
	StyleParam,
	BoundingArray
} from "../../../../types/plot-svg";

const ms = mkSetterMaker<StagedLine>();

export default class StagedLine extends StagedComponent {
	ownStyleParams = [
		{ key: "x1", required: true },
		{ key: "y1", required: true },
		{ key: "x2", required: true },
		{ key: "y2", required: true },
		{ key: "V", aggregate: true },
		{ key: "v", aggregate: true },
		{ key: "H", aggregate: true },
		{ key: "h", aggregate: true }
	] as StyleParam[];

	open(x1?: number, y1?: number, x2?: number, y2?: number): this {
		return super.open(x1, y1, x2, y2);
	}

	x1 = ms("x1", (x1: number) => scaled(x1));
	y1 = ms("y1", (y1: number) => scaled(y1));
	x2 = ms("x2", (x2: number) => scaled(x2));
	y2 = ms("y2", (y2: number) => scaled(y2));

	V(y: number): this {
		this.assertStyles("x1");
		this.set("x2", this.style.x1);
		this.setScaled("y2", y);
		return this;
	}

	v(dy: number): this {
		this.assertStyles("x1", "y1");
		this.set("x2", this.style.x1);
		this.set("y2", this.style.y1 + this.scale(dy))
		return this;
	}

	h(dx: number): this {
		this.assertStyles("x1", "y1");
		this.set("x2", this.style.x1 + this.scale(dx));
		this.set("y2", this.style.y1)
		return this;
	}

	H(x: number): this {
		this.assertStyles("y1");
		this.setScaled("x2", x);
		this.set("y2", this.style.y1);
		return this;
	}

	getBounding(): BoundingArray {
		const s = this.style,
			minX = Math.min(s.x1, s.x2),
			minY = Math.min(s.y1, s.y2),
			maxX = Math.max(s.x1, s.x2),
			maxY = Math.max(s.y1, s.y2);

		return [
			minX,
			minY,
			maxX - minX,
			maxY - minY
		];
	}

	render() {
		this.mount("line");
	}
}
