import { request } from "../../utils";

import { MEDIA_TYPES } from "../../data/constants";
import states from "../../data/states.json";

import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import Base from "../base";

import ContentCard from "../../components/content-card";
import ActionTable from "../../components/action-table";

import { ColumnCell } from "../../types/data-table";
import { useAppSelector } from "../../state/hooks";

const Stations = () => {
	const marketNames = useAppSelector(state => state.data.marketNames);

	const COLUMNS: ColumnCell[] = [
		{
			name: "type",
			type: "choice",
			title: "Type",
			accessor: "station_type",
			fit: "shrink",
			editable: true,
			config: {
				options: MEDIA_TYPES,
				labelAccessor: "label",
				valueAccessor: "value"
			}
		},
		{
			name: "state",
			type: "choice",
			title: "State",
			accessor: "state",
			fit: "shrink",
			editable: true,
			config: {
				options: states,
				labelAccessor: "state",
				valueAccessor: "state"
			}
		},
		{
			name: "city",
			title: "City",
			accessor: "city",
			editable: true
		},
		{
			name: "name",
			type: "choice",
			title: "Market",
			accessor: "market_full_name",
			editable: true,
			config: {
				options: marketNames,
				labelAccessor: "state",
				valueAccessor: "state"
			}
		},
		{
			name: "callLetters",
			title: "Call Letters",
			accessor: "call_letters",
			editable: true
		},
		{
			name: "candidateCpp30",
			type: "currency",
			title: "Candidate CPP :30",
			accessor: "candidate_cpp_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateCpp60",
			type: "currency",
			title: "Candidate CPP :60",
			accessor: "candidate_cpp_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateDra30",
			type: "currency",
			title: "Candidate DRA :30",
			accessor: "candidate_dra_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateDra60",
			type: "currency",
			title: "Candidate DRA :60",
			accessor: "candidate_dra_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueCpp30",
			type: "currency",
			title: "Issue CPP :30",
			accessor: "issue_cpp_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueCpp60",
			type: "currency",
			title: "Issue CPP :60",
			accessor: "issue_cpp_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueDra30",
			type: "currency",
			title: "Issue DRA :30",
			accessor: "issue_dra_30",
			fit: "shrink",
			editable: true
		}
	];

	const actions = useTableActions({
		addLabel: "Add Station",
		add: () => ({
			station_type: "tv",
			state: "US",
			city: "",
			market_full_name: "",
			call_letters: "",
			candidate_cpp_30: 0,
			candidate_cpp_60: 0,
			candidate_dra_30: 0,
			candidate_dra_60: 0,
			issue_cpp_30: 0,
			issue_cpp_60: 0,
			issue_dra_30: 0,
			issue_dra_60: 0
		}),
		createEach: row => {
			return request({
				url: "/stations/create",
				body: row
			});
		},
		modifyEach: row => {
			return request({
				url: `/stations/${row.id}`,
				body: row,
				method: "PUT"
			});
		},
		deleteEach: row => {
			return request({
				url: `/stations/${row.id}`,
				method: "DELETE"
			});
		}
	});

	const props = useRemoteActionProps({
		request: "/stations/list",
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} />
			</ContentCard>
		</Base>
	);
};

export default Stations;
