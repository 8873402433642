import styled from "styled-components";

import Icon from "./icon";

const NF = new Intl.NumberFormat();

interface PaginationProps {
	page: number;
	pageSize: number;
	onSelect: (page: number) => void;
	length?: number;
}

const PaginationWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const PaginationButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	padding: 0;
	font: inherit;
	font-size: 90%;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	text-shadow: 0 2px 2px rgb(0, 0, 0, 0.2);
	background: ${p => p.theme.popColor};
	color: ${p => p.theme.cardBackground};
	border: 1px solid ${p => p.theme.popColorDark};
	border-radius: ${p => p.theme.borderRadius};
	outline: none;
	cursor: pointer;

	&:disabled {
		background: ${p => p.theme.background};
		color: ${p => p.theme.huedColor};
		border-color: ${p => p.theme.backgroundAlt};
		cursor: not-allowed;
	}
`;

const PaginationIcon = styled(Icon)`
	height: 12px;
`;

const PaginationContent = styled.span`
	margin: 0 10px;
	min-width: 120px;
	text-align: center
`;

const Pagination = (props: PaginationProps) => {
	const {
		page,
		pageSize,
		length
	} = props;

	const max = typeof length == "number" ?
		length :
		Infinity,
		from = page * pageSize + 1,
		to = Math.min((page + 1) * pageSize, max);

	let content = to >= 1 ?
		(
			<>
				<strong>{NF.format(from)}</strong> to <strong>{NF.format(to)}</strong>
			</>
		) :
		<strong>{to}</strong>;

	if (typeof length == "number") {
		content = (
			<>
				{content} of <strong>{NF.format(length)}</strong>
			</>
		);
	}

	return (
		<PaginationWrapper>
			<PaginationButton
				disabled={!props.page}
				onClick={() => props.onSelect(props.page - 1)}
			>
				<PaginationIcon name="chevron-left" />
			</PaginationButton>
			<PaginationContent>
				{content}
			</PaginationContent>
			<PaginationButton
				disabled={(props.page + 1) * props.pageSize >= max}
				onClick={() => props.onSelect(props.page + 1)}
			>
				<PaginationIcon name="chevron-right" />
			</PaginationButton>
		</PaginationWrapper>
	);
};

export default Pagination;
