import {
	useState,
	useEffect
} from "react";
import useStateCapsule from "./use-state-capsule";

interface TimeoutState<T> {
	id: number;
	queuedValue: T | null;
}

function useDebounce<T>(
	callback: (value: T) => void,
	timeout: number
): [(data: T) => void, (data: T) => void] {
	const [updates, setUpdates] = useState(0);

	const state = useStateCapsule({
		id: -1,
		queuedValue: null
	} as TimeoutState<T>);

	useEffect(
		() => {
			const {
				id,
				queuedValue
			} = state.get();

			if (queuedValue === null)
				return;

			dispatch(queuedValue);

			return () => window.clearTimeout(id);
		},
		[updates]
	);

	const schedule = (value: T) => {
		const st = state.get();
		window.clearTimeout(st.id);

		const tOutId = window.setTimeout(() => {
			state.set({
				...state.get(),
				queuedValue: value
			});

			setUpdates(updates + 1);
		}, timeout);

		state.set({
			...state.get(),
			id: tOutId
		});
	};

	const dispatch = (value: T) => {
		const st = state.get();
		window.clearTimeout(st.id);
		callback(value);

		state.set({
			id: -1,
			queuedValue: null
		});
	};

	return [schedule, dispatch];
}

export default useDebounce;
