import styled, { css } from "styled-components";

import {
	Action,
	ActionProps
} from "../../types/data-table";

import Icon from "../icon";

interface ActionTemplates {
	[key: string]: (Action | ActionName)[];
}

export type ActionName = "confirm" | "cancel" | "capitalize";

const NON_CAPITALIZABLE_WORDS = {
	a: true,
	is: true,
	of: true,
	the: true,
	and: true
};

const CONTROL_BUTTON_STYLE = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	padding: 0;
	color: ${p => p.theme.cardBackground};
	border: none;
	border-radius: ${p => p.theme.borderRadius};
	outline: none;
	cursor: pointer;
`;

const ConfirmButtonWrapper = styled.button`
	${CONTROL_BUTTON_STYLE};
	background: ${p => p.theme.popColorInfo};
`;

const ConfirmSvg = styled(Icon)`
	height: 9px;
`;

const ConfirmButton = (props: any) => (
	<ConfirmButtonWrapper {...props}>
		<ConfirmSvg name="check" />
	</ConfirmButtonWrapper>
);

const ExitButtonWrapper = styled.button`
	${CONTROL_BUTTON_STYLE};
	background: ${p => p.theme.popColorAlert};
`;

const ExitSvg = styled(Icon)`
	height: 8px;
`;

const ExitButton = (props: any) => (
	<ExitButtonWrapper {...props}>
		<ExitSvg name="cross" />
	</ExitButtonWrapper>
);

const CapitalizeButtonWrapper = styled.button`
	${CONTROL_BUTTON_STYLE};
	background: ${p => p.theme.popColor};
`;

const CapitalizeSvg = styled(Icon)`
	height: 9px;
`;

const CapitalizeButton = (props: any) => (
	<CapitalizeButtonWrapper {...props}>
		<CapitalizeSvg name="title" />
	</CapitalizeButtonWrapper>
);

// Action templates corresponding to different column types
// standard is the default template if no other is found for the current column type
const ACTION_TEMPLATES: ActionTemplates = {
	standard: ["confirm", "cancel"]
};

const ACTIONS: Record<ActionName, Action> = {
	confirm: {
		name: "confirm",
		hotkey: "Enter",
		content: (props: ActionProps) => (
			<ConfirmButton onClick={props.execute} />
		),
		executor: (props: ActionProps) => {
			props.set(props.value);
			props.exit();
		}
	},
	cancel: {
		name: "cancel",
		hotkey: "Escape",
		content: (props: ActionProps) => (
			<ExitButton onClick={props.execute} />
		),
		executor: (props: ActionProps) => {
			props.exit();
		}
	},
	capitalize: {
		name: "capitalize",
		content: (props: ActionProps) => (
			<CapitalizeButton onClick={props.execute} />
		),
		executor: (props: ActionProps) => {
			const capitalized = props.value.replace(/\b[\w'-]+\b/g, (match: string) => {
				const lower = match.toLowerCase();

				if (NON_CAPITALIZABLE_WORDS.hasOwnProperty(lower))
					return match;

				return match[0].toUpperCase() + match.substring(1).toLowerCase();
			});

			props.update(capitalized);
		}
	}
};

export {
	ACTIONS,
	ACTION_TEMPLATES
};
