import states from "../data/states.json";

import useTableActions from "../hooks/use-table-actions";
import useRemoteActionProps from "../hooks/use-remote-action-props";

import Base from "./base";
import ActionTable from "../components/action-table";
import ContentCard from "../components/content-card";

import { ColumnCell } from "../types/data-table";
import { request } from "../utils";

const COLUMNS: ColumnCell[] = [
	{
		name: "race",
		title: "Race",
		accessor: "campaign.race.race_name"
	},
	{
		name: "state",
		type: "choice",
		title: "State",
		accessor: "campaign.race.jurisdiction",
		index: "lexical",
		fit: "shrink",
		config: {
			options: states,
			labelAccessor: "state",
			valueAccessor: "state"
		}
	},
	{
		name: "name",
		title: "Campaign Name",
		accessor: "campaign.candidate.full_name",
		index: "lexical"
	},
	{
		name: "medium",
		title: "Medium",
		accessor: "station.station_type",
		editable: true
	},
	{
		name: "callLetters",
		title: "Call Letters",
		accessor: "station.call_letters",
		editable: true
	},
	{
		name: "market",
		title: "Market",
		accessor: "station.market_full_name",
		index: "lexical",
		editable: true
	},
	{
		name: "amount",
		type: "currency",
		title: "Amount",
		accessor: "amount",
		editable: true
	},
	{
		name: "start",
		type: "date",
		title: "Start Date",
		accessor: "start_date",
		editable: true
	},
	{
		name: "end",
		type: "date",
		title: "End Date",
		accessor: "end_date",
		editable: true
	},
	{
		name: "spot_length",
		type: "number",
		title: "Spot Length",
		accessor: "spot_length",
		editable: true
	},
	{
		name: "cpp",
		type: "currency-value",
		title: "CPP",
		accessor: "candidate_cpp",
		editable: true
	}
];

const Flights = () => {
	const actions = useTableActions({
		save: rows => {
			return request({
				url: "/flights/update_all",
				body: { flights: rows },
				method: "PUT"
			});
		},
		delete: async rows => {
			for (let i = 0; i < rows.length; i++) {
				const id = rows[i].id;
				if (typeof id === "number") {
					await request({
						url: `/flights/${id}`,
						method: "DELETE"
					});
				}
			}
		}
	});

	const props = useRemoteActionProps({
		request: "/flights/list",
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} />
			</ContentCard>
		</Base>
	);
};

export default Flights;
