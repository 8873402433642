import { useState } from "react";
import { transparentize } from "polished";
import { useFormikContext } from "formik";
import styled from "styled-components";

import { InputProps } from "../../types/inputs";
import Icon from "../icon";

export interface FileProps extends InputProps {
	accept?: string;
	maxFiles?: number;
}

interface DropProps {
	dropping: boolean;
}

const FileWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	position: relative;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
`;

const FileTarget = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	outline: none;
	cursor: pointer;
	opacity: 0;
`;

const FileContent = styled.div<DropProps>`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 8px;
	padding: 10px;
	border: ${p => p.theme.inputBorder};
	border-radius: inherit;
	pointer-events: none;
	z-index: 1;
`;

const Prompt = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	background: ${p => p.theme.componentBackground};
	min-height: 80px;
	border-radius: inherit;
	pointer-events: none;
`;

const DropOverlay = styled.div<DropProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: inherit;
	background: ${p => transparentize(0.6, p.theme.componentBackground)};
	box-shadow: inset 0 0 0 3px ${p => p.theme.darkBackground};
	pointer-events: none;
	opacity: ${p => p.dropping ? 1 : 0};
	transition: opacity 300ms;
	z-index: 10;
`;

const StagedFile = styled.div`
	display: flex;
	background: ${p => p.theme.darkBackground};
	padding: 6px 6px 6px 12px;
	color: ${p => p.theme.accentColor};
	font-weight: bold;
	border-radius: inherit;
	pointer-events: auto;
`;

const StagedFileNameWrapper = styled.div`
	flex-grow: 1;
	position: relative;
`;

const StagedFileName = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const DeleteButton = styled.button`
	display: flex;
	width: 26px;
	height: 26px;
	margin-left: 6px;
	background: transparent;
	border: none;
	color: inherit;
	padding: 7px;
	outline: none;
	border-radius: inherit;
	cursor: pointer;
	
	&:focus {
		background: ${p => transparentize(0.8, p.theme.accentColor)};
	}
`;

const File = (props: FileProps) => {
	const ctx = useFormikContext();
	const [files, setFiles] = useState([] as File[]);
	const [dropping, setDropping] = useState(false);

	const maxFiles = typeof props.maxFiles == "number" ?
		props.maxFiles :
		Infinity;

	const dispatchChange = (newFiles: File[]) => {
		if (typeof props.onChange === "function") {
			props.onChange({
				type: "change",
				target: {
					name: props.name,
					value: newFiles
				}
			});
		}

		setFiles(newFiles);
		ctx.setFieldValue(props.name!, newFiles);
	};

	const handleChange = (evt: any) => {
		const mergedFiles = nubFiles([...files, ...evt.target.files]),
			newFiles = isFinite(maxFiles) ?
				mergedFiles.slice(-maxFiles) :
				mergedFiles;

		dispatchChange(newFiles);
	};

	const nubFiles = (files: File[]): File[] => {
		const out = [] as File[],
			hashes = {} as Record<string, boolean>;

		for (const file of files) {
			const hash = `${file.name}%%${file.type}%%${file.size}%%${file.lastModified}`;
			if (hashes.hasOwnProperty(hash))
				continue;

			out.push(file);
			hashes[hash] = true;
		}

		return out;
	};

	const outFiles = files.map((file, i) => (
		<StagedFile key={i}>
			<StagedFileNameWrapper>
				<StagedFileName>{file.name}</StagedFileName>
			</StagedFileNameWrapper>
			<DeleteButton
				type="button"
				onClick={() => {
					const newFiles = [...files];
					newFiles.splice(i, 1);
					dispatchChange(newFiles);
				}}
			>
				<Icon name="thin-cross" />
			</DeleteButton>
		</StagedFile>
	));

	return (
		<FileWrapper
			onDragEnter={() => setDropping(true)}
			onDragLeave={() => setDropping(false)}
			onDragEnd={() => setDropping(false)}
			onDrop={() => setDropping(false)}
		>
			<FileTarget
				value=""
				type="file"
				multiple={maxFiles > 1}
				accept={props.accept}
				name={props.name}
				required={props.required}
				onChange={handleChange}
			/>
			<FileContent dropping={dropping}>
				{outFiles}
				<Prompt>Click or drop file</Prompt>
			</FileContent>
			<DropOverlay dropping={dropping} />
		</FileWrapper>
	);
};

export default File;
