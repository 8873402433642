import ShareConfig from "./configs/share";

import Base from "./base";

import { GraphProps, GroupLabelResolver } from "../../../types/viz";

interface ShareProps extends GraphProps {
	groupLabel?: GroupLabelResolver;
	labelAllPoints?: boolean;
}

const Share = (props: ShareProps) => {
	const { groupLabel, labelAllPoints, ...p } = props
	return <Base {...p} configs={ShareConfig} store={{ groupLabel, labelAllPoints }} />;
};

export default Share;
