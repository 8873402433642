import styled, { ThemeProvider } from "styled-components";

import {
	ButtonStyle,
	StyledFunction
} from "../../types/common";

export interface ButtonProps {
	children: any;
	style?: ButtonStyle;
	disabled?: boolean;
	onClick?: any;
	className?: string;
}

interface DisabledProps {
	disabled?: boolean;
}

interface Theme {
	color: StyledFunction;
	background: StyledFunction;
	borderColor: StyledFunction;
	disabledColor: StyledFunction;
	disabledBackground: StyledFunction;
	disabledBorderColor: StyledFunction;
}

const THEMES: Record<ButtonStyle, Theme> = {
	accent: {
		color: p => p.theme.accentColor,
		background: p => p.theme.accent,
		borderColor: p => p.theme.accentAlt,
		disabledColor: p => p.theme.disabledAccentColor,
		disabledBackground: p => p.theme.disabledAccent,
		disabledBorderColor: p => p.theme.disabledAccentAlt
	},
	"light-accent": {
		color: p => p.theme.accentLightColor,
		background: p => p.theme.accentLight,
		borderColor: p => p.theme.accentLightAlt,
		disabledColor: p => p.theme.disabledAccentColor,
		disabledBackground: p => p.theme.disabledAccent,
		disabledBorderColor: p => p.theme.disabledAccentAlt
	},
	alert: {
		color: p => p.theme.alertColor,
		background: p => p.theme.alert,
		borderColor: p => p.theme.alertAlt,
		disabledColor: p => p.theme.disabledAccentColor,
		disabledBackground: p => p.theme.disabledAccent,
		disabledBorderColor: p => p.theme.disabledAccentAlt
	},
	subtle: {
		color: p => p.theme.subtleColor,
		background: p => p.theme.subtle,
		borderColor: p => p.theme.subtleAlt,
		disabledColor: p => p.theme.disabledAccentColor,
		disabledBackground: p => p.theme.disabledAccent,
		disabledBorderColor: p => p.theme.disabledAccentAlt
	}
};

const ButtonWrapper = styled.button<DisabledProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 11px 16px 10px 16px;
	height: ${p => p.theme.buttonHeight};
	min-width: 100px;
	background: ${p =>
		p.disabled ?
			p.theme.disabledBackground :
			p.theme.background
	};
	font: inherit;
	line-height: 1;
	font-weight: bold;
	text-transform: uppercase;
	color: ${p =>
		p.disabled ?
			p.theme.disabledColor :
			p.theme.color
	};
	border: none;
	border-radius: ${p => p.theme.borderRadius};
	text-shadow: 0 1.2px 0 ${p =>
		p.disabled ?
			p.theme.disabledBorderColor :
			p.theme.borderColor
	};
	box-shadow:
		inset 0 0 0 1px ${p =>
			p.disabled ?
				p.theme.disabledBackground :
				p.theme.background
		},
		inset 0 0 0 2px ${p =>
			p.disabled ?
				p.theme.disabledBorderColor :
				p.theme.borderColor
		};
	user-select: none;
	cursor: ${p =>
		p.disabled ?
			"not-allowed" :
			"pointer"
	};
	
	svg {
		height: 1.2em;
	}
`;

const Button = (props: ButtonProps) => {
	const theme = THEMES[props.style || "accent"];

	return (
		<ThemeProvider theme={theme}>
			<ButtonWrapper
				className={props.className}
				onClick={props.onClick}
				disabled={props.disabled}
			>
				{props.children}
			</ButtonWrapper>
		</ThemeProvider>
	);
};

export default Button;
