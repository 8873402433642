const getApiUrl = () => {
	const lsu = localStorage.getItem("mb-api-url");
	if (lsu) return lsu.replace(/\/+$/, "");

	if (process.env.NODE_ENV === "production")
		return "https://v3.smartcomp.app/api/v1";

	return "http://localhost:8080/api/v1";
};

const API_URL = getApiUrl();

const CANDIDATE_TYPES = [
	{
		value: "candidate",
		label: "Candidate"
	},
	{
		value: "pac",
		label: "PAC"
	}
];

const numYears = 10 + (new Date().getFullYear() - 2021);
const CYCLES = Array(numYears)
	.fill(2021)
	.map((v, i) => String(v + i));

const BANDS = ["AM", "FM"];
const PERMISSIONS = ["Admin", "Editor"];

const MONTHS = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec"
];

const RACE_TYPES = [
	{
		value: "primary",
		label: "Primary"
	},
	{
		value: "general",
		label: "General"
	}
];

const MEDIA_TYPES = [
	{
		value: "tv",
		label: "TV"
	},
	{
		value: "cable",
		label: "Cable"
	},
	{
		value: "radio",
		label: "Radio"
	},
	{
		value: "d2",
		label: "D2"
	},
	{
		value: "ncc_go",
		label: "NCC Go!"
	},
	{
		value: "statenet",
		label: "Statenet"
	},
	{
		value: "ncc_digital",
		label: "NCC Digital"
	},
	{
		value: "hometown_sports",
		label: "Home Team Sports"
	},
	{
		value: "ott",
		label: "OTT"
	},
	{
		value: "vod",
		label: "VOD"
	},
	{
		value: "spanish",
		label: "Spanish"
	}
];

export {
	API_URL,
	CANDIDATE_TYPES,
	CYCLES,
	BANDS,
	PERMISSIONS,
	MONTHS,
	RACE_TYPES,
	MEDIA_TYPES
};
