import {
	Formik,
	Form as FormikForm,
	useFormikContext
} from "formik";
import { useState } from "react";

import {
	equals,
	isNativeSimpleObject
} from "@qtxr/utils";
import useDebounce from "../../hooks/use-debounce";

interface FormProps<V = Values> {
	values?: V;
	initialValues?: V;
	onSubmit?: any;
	onChange?: (values: V) => void;
	onSettled?: (values: V) => void;
	children?: any;
	className?: any;
}

interface FocusData {
	name: string;
	value: any;
}

interface Values {
	[key: string]: any;
}

const eq = (a: any, b: any, options?: any): boolean => {
	return equals(
		a,
		b,
		[
			{ comparator },
			options
		]
	);
};

const eqValueInput = (values: any, newValues: any, options?: any) => {
	for (const k in values) {
		if (values.hasOwnProperty(k) && !eq(values[k], newValues[k], options))
			return false;
	}

	return true;
};

const comparator = (a: any, b: any) => {
	if (!a || !b || typeof a != "object" || typeof b != "object")
		return;

	if (isNativeSimpleObject(a))
		return;

	return a === b;
};

function FormInner<V>(props: FormProps<V>) {
	const context = useFormikContext<V>();
	const [lastValues, setLastValues] = useState(context.values);
	const [lastPropValues, setLastPropValues] = useState(props.values || null);
	const [dispatchSettled, setDispatchSettled] = useState(false);
	const [focusData, setFocusData] = useState(null as FocusData | null);
	const [debounceChange] = useDebounce<V>(
		values => props.onChange!(values),
		600
	);
	const [debounceSettled] = useDebounce<V>(
		values => props.onSettled!(values),
		50
	);

	if (context.values !== lastValues) {
		if (typeof props.onChange == "function") {
			if (!eq(context.values, lastValues, "circular"))
				debounceChange(context.values);
		}

		setLastValues(context.values)
	}

	if (props.values && props.values !== lastPropValues) {
		if (!eqValueInput(context.values, props.values, "circular")) {
			requestAnimationFrame(() => {
				context.setValues(props.values!);
			});
		}

		setLastPropValues(props.values);
	}

	if (dispatchSettled) {
		debounceSettled(context.values);
		setDispatchSettled(false);
	}

	const dispatch = () => {
		if (focusData) {
			if ((context.values as any)[focusData.name] !== focusData.value)
				setDispatchSettled(true);

			setFocusData(null);
		} else
			setDispatchSettled(true);
	};

	const isDiscreteValueInput = (node: Element) => {
		switch (node.tagName) {
			case "SELECT":
				return true;

			default:
				return false;
		}
	};

	const handleFocus = (evt: any) => {
		if (typeof props.onSettled != "function")
			return;

		if (!isDiscreteValueInput(evt.target)) {
			setFocusData({
				name: evt.target.name,
				value: evt.target.value
			});
		}
	};

	const handleSettleTriggers = (evt: any) => {
		if (typeof props.onSettled != "function")
			return;

		switch (evt.type) {
			case "change":
				if (isDiscreteValueInput(evt.target))
					dispatch();
				break;

			case "blur":
				if (!isDiscreteValueInput(evt.target))
					dispatch();
				break;
		}
	};

	return (
		<FormikForm
			onFocus={handleFocus}
			onChange={handleSettleTriggers}
			onBlur={handleSettleTriggers}
			className={props.className}
		>
			{props.children}
		</FormikForm>
	);
}

function Form<V = Values>(props: FormProps<V>) {
	const initialValues = props.initialValues || props.values || {},
		onSubmit = props.onSubmit || (() => {});

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
		>
			<FormInner {...props} />
		</Formik>
	);
}

export default Form;
