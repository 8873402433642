import {
	useState,
	Dispatch,
	SetStateAction
} from "react";

import { UPDATE_ROWS_SYM } from "./use-table-actions";

import {
	BulkEditEvent,
	ActionTableProps
} from "../components/action-table";
import {
	Row,
	EditEvent
} from "../types/data-table";

export interface UseActionTablePropsProps extends ActionTableProps {
	update?: Dispatch<SetStateAction<Row[]>>;
}

const useActionProps = (props: UseActionTablePropsProps): ActionTableProps => {
	const {
		update,
		...p
	} = props;

	const [internalRows, setInternalRows] = useState(p.rows);
	const [externalRows, setExternalRows] = useState(p.rows);
	const [sourceCacheKey, setSourceCacheKey] = useState(-1);

	let rows = internalRows,
		updateRows = setInternalRows as Dispatch<SetStateAction<Row[]>>;

	if (typeof update == "function") {
		rows = p.rows;
		updateRows = update;
	} else if (props.actions && typeof props.actions[UPDATE_ROWS_SYM] == "function") {
		rows = p.rows;
		updateRows = props.actions[UPDATE_ROWS_SYM] as any;
	} else if (p.rows !== externalRows) {
		setInternalRows(p.rows);
		setExternalRows(p.rows);

		if (props.sourceCacheKey == null)
			setSourceCacheKey(sourceCacheKey + 1);
	}

	const onEdit = (evt: EditEvent) => {
		const store = evt.runtime.store,
			blockSpread = store.actionState ?
				store.actionState.get().blockSpread :
				false,
			spreading = store.spread &&
				evt.mode === "manual" &&
				evt.originalEvent!.row.checked &&
				evt.column.name !== "checkbox";

		if (spreading && !blockSpread) {
			const { newRows } = evt.spread((set, row) => {
				if (row.row.checked)
					set(evt.value);
			});

			updateRows(newRows);
		} else {
			const { newRows } = evt.set();
			updateRows(newRows);
		}
	};

	const onBulkEdit = (evt: BulkEditEvent) => {
		updateRows(evt.rows);
	};

	return {
		...p,
		rows,
		onEdit,
		onBulkEdit,
		store: {
			...p.store,
			updateRows
		},
		sourceCacheKey: props.sourceCacheKey == null ?
			Math.max(sourceCacheKey, 0) :
			props.sourceCacheKey
	};
};

export default useActionProps;
