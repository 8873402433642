import React, { useEffect } from "react";

type Destructor = () => void;

const useRootEffect = (
	effect: () => Promise<Destructor | void> | Destructor | void,
	deps?: React.DependencyList
) => {
	useEffect(
		() => {
			const destructorOrPromise = effect();

			if (!destructorOrPromise)
				return;
			if (typeof destructorOrPromise == "function")
				return destructorOrPromise;

			let resolved = false,
				resolvedValue: Destructor | void;

			destructorOrPromise.then(value => {
				resolved = true;
				resolvedValue = value;
			});

			return () => {
				if (!resolved) {
					console.error("Failed to run clean-up: asynchronous effect has not resolved");
					return;
				}

				if (!resolvedValue)
					return;

				resolvedValue();
			};
		},
		deps
	);
};

export default useRootEffect;
