import styled from "styled-components";

import { InputProps } from "../../types/inputs";

export interface RadioProps extends InputProps {
	checked?: boolean;
}

const RadioWrapper = styled.div<RadioProps>`
	position: relative;
	width: ${p => p.theme.inputWidgetWidth};
	height: ${p => p.theme.inputWidgetWidth};
	border: 1px solid;
	border-radius: 50%;
	
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: currentColor;
		border-radius: inherit;
		transform: scale(0.75);
		opacity: ${p => p.checked ? 1 : 0};
	}
`;

const RadioTarget = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	opacity: 0;
	cursor: pointer;
`;

const Radio = (props: RadioProps) => (
	<RadioWrapper
		checked={props.checked}
		className={props.className}
	>
		<RadioTarget
			id={props.name}
			name={props.name}
			required={props.required}
			type="radio"
			checked={props.checked}
			onChange={props.onChange}
		/>
	</RadioWrapper>
);

export default Radio;
