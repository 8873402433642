import {
	createSlice,
	Draft,
	PayloadAction
} from "@reduxjs/toolkit";

interface DataState {
	error: string | null;
	loaded: boolean;
	progress: number;
	assetCount: number;
	parties: Party[];
	offices: Office[];
	teams: Team[];
	agencies: Agency[];
	races: Race[];
	marketNames: string[]
}

interface Party {
	id: number;
	full_name: string;
	short_name: string;
	color: string;
}

interface Office {
	id: number;
	full_name: string;
	short_name: string;
	sort_order: number;
	top_5: boolean;
	frequently_used: boolean;
}

interface Team {
	id: number;
	full_name: string;
	state: string;
	race_id: number;
}

interface Agency {
	id: number;
	full_name: string;
	short_name: string;
	logo: string;
}
interface Race {
	id: number,
	party_id: number,
	office_id: number,
	race_type: string;
	race_name: string;
	jurisdiction: string;
	district: string;
	using_station_defaults: boolean;
	office: Office;
	party: Party | null;

}

const INITIAL_STATE = {
	error: null,
	loaded: false,
	progress: 0,
	assetCount: 4,
	parties: [],
	offices: [],
	teams: [],
	agencies: [],
	races: [],
	marketNames: []
} as DataState;

export const slice = createSlice({
	name: "data",
	initialState: INITIAL_STATE,
	reducers: {
		supplyParties: (state: Draft<DataState>, action: PayloadAction<Party[]>) => {
			state.parties = action.payload;
			state.progress++;
		},
		supplyOffices: (state: Draft<DataState>, action: PayloadAction<Office[]>) => {
			state.offices = action.payload;
			state.progress++;
		},
		supplyTeams: (state: Draft<DataState>, action: PayloadAction<Team[]>) => {
			state.teams = action.payload;
			state.progress++;
		},
		supplyAgencies: (state: Draft<DataState>, action: PayloadAction<Agency[]>) => {
			state.agencies = action.payload;
			state.progress++;
		},
		supplyRaces: (state: Draft<DataState>, action: PayloadAction<Race[]>) => {
			state.races = action.payload;
			state.progress++;
		},
		supplyMarketNames: (state: Draft<DataState>, action: PayloadAction<string[]>) => {
			state.marketNames = action.payload.slice().sort();
			state.progress++;
		},
		setLoaded: (state: Draft<DataState>, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
		setSupplyError: (state: Draft<DataState>, action: PayloadAction<string>) => {
			state.error = action.payload;
		}
	}
});

export default slice.reducer;
export const {
	supplyParties,
	supplyOffices,
	supplyTeams,
	supplyAgencies,
	supplyRaces,
	supplyMarketNames,
	setLoaded,
	setSupplyError
} = slice.actions;
