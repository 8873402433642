import {
	useState,
	useEffect, useMemo
} from "react";
import styled from "styled-components";

import useDebounce from "../hooks/use-debounce";
import useLoadingState from "../hooks/use-loading-state";
import useStateDispatch, { StateSetter } from "../hooks/use-state-dispatch";
import useProxiedFunction from "../hooks/use-proxied-function";

import {
	Radio,
	Checkbox
} from "./inputs";
import Icon from "./icon";
import LoadingOverlay from "./loading-overlay";

import {
	State,
	BaseRuntime
} from "../types/common";

export interface WaterfallBoxProps {
	name: string;
	title: string;
	mode: SelectionMode;
	fetch: (runtime: Runtime) => Promise<FetchResponse> | FetchResponse;
	state?: State
	compact?: boolean;
	selection?: Entry[];
	debounce?: number;
	maxSelected?: number;
	placeholder?: string;
	shouldFetch?: (runtime: Runtime) => boolean;
	shouldTrash?: (runtime: Runtime) => boolean;
	onStateChange?: (state: State, runtime: Runtime) => void;
	onSelectionChange?: (selection: Entry[], runtime: Runtime) => void;
}

interface SearchBoxProps {
	query: string;
	onInput: (evt: any) => void;
	onSearch: (query: string) => void;
	debounce?: number;
}

interface EntryBoxProps {
	mode: SelectionMode;
	entry: Entry;
	selected: boolean;
	onToggle: (selected: boolean) => void;
}

interface EntryBoxWrapperProps {
	selected: boolean;
}

interface Runtime extends BaseRuntime {
	query: string;
	setState: StateSetter;
	searching: boolean;
}

export interface Entry {
	key: Key;
	title: string;
	data?: any;
	details?: string[];
}

interface CompactProps {
	compact?: boolean;
}

type SelectionMode = "radio" | "multi";
type FetchResponse = Entry[] | string;
type Key = string | number;

const DEFAULT_STATE = Object.freeze({});

const WaterfallBoxWrapper = styled.article`
	display: flex;
	flex-direction: column;
	width: 350px;
	min-height: 130px;
	overflow: hidden;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	height: 26px;
`;

const BoxTitle = styled.h3`
	font-size: 105%;
	margin: 0 15px 0 0;
`;

const SearchBoxWrapper = styled.div`
	display: flex;
	height: 100%;
	background: ${p => p.theme.componentDarkBackground};
	border-radius: ${p => p.theme.borderRadius};
`;

const SearchInput = styled.input`
	flex-grow: 1;
	height: 100%;
	min-width: 160px;
	background: transparent;
	outline: none;
	border: none;
	font: inherit;
	text-indent: 6px;
`;

const SearchButton = styled.button`
	position: relative;
	width: 28px;
	height: 100%;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	
	&:before {
		content: "";
		position: absolute;
		top: 4px;
		bottom: 4px;
		left: 0;
		border-left: 1px solid;
		opacity: 0.12;
	}
	
	svg {
		height: 14px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 155px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	margin-top: 6px;
	overflow: hidden;
`;

const ScrollWrapper = styled.div`
	padding: 5px;
	overflow: auto;
`;

const SearchBox = (props: SearchBoxProps) => {
	const [debounce, dispatch] = useDebounce(
		props.onSearch,
		props.debounce || 500
	);

	const handleInput = (evt: any) => {
		props.onInput(evt);
		debounce(evt.target.value);
	};

	return (
		<SearchBoxWrapper>
			<SearchInput
				value={props.query}
				onInput={handleInput}
			/>
			<SearchButton
				onClick={() => dispatch(props.query)}
			>
				<Icon name="search" />
			</SearchButton>
		</SearchBoxWrapper>
	);
};

const EntryBoxWrapper = styled.div<EntryBoxWrapperProps & CompactProps>`
	display: flex;
	align-items: center;
	padding: ${p => 
		p.compact ?
			"5px 8px 5px 6px" :
			"7px 10px 7px 8px"
	};
	background: ${p =>
		p.selected ?
			p.theme.componentBackground :
			p.theme.cardBackground
	};
	border-radius: ${p => p.theme.borderRadius};
	user-select: none;
	cursor: pointer;
	
	& + & {
		margin-top: 3px;
	}
`;

const EntryBoxLeft = styled.div`
	flex-grow: 1;
	padding: 5px 0;
	margin: -5px 10px -5px 0;
	overflow: hidden;
`;

const EntryTitle = styled.h3`
	font-size: 105%;
	padding: 5px 0;
	margin: -5px 0;
	line-height: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const EntryDetails = styled.div<CompactProps>`
	display: flex;
	align-items: center;
	margin-top: 2px;
	font-size: ${p => p.compact ? "90%" : "100%"};
`;

const EntryDetail = styled.span`
	line-height: 1;
	opacity: 0.8;
`;

const EntryDetailSeparator = styled.div`
	width: 4px;
	height: 4px;
	border-radius: 2px;
	margin: 0 3px;
	background: currentColor;
	transform: scale(0.6);
	opacity: 0.8;
`;

const EntryBoxRight = styled.div`
	padding: 3px 0;
`;

const EntryBox = (props: EntryBoxProps & CompactProps) => {
	let input,
		details = null;

	if (props.mode === "multi") {
		input = (
			<Checkbox
				checked={props.selected}
				onChange={evt => props.onToggle(evt.target.checked)}
			/>
		)
	} else {
		input = (
			<Radio
				checked={props.selected}
				onChange={evt => props.onToggle(evt.target.checked)}
			/>
		);
	}

	if (props.entry.details && props.entry.details.length) {
		const ds = [];

		for (const detail of props.entry.details) {
			if (ds.length)
				ds.push(<EntryDetailSeparator key={`separator-${ds.length}`} />);

			ds.push(
				<EntryDetail key={`entry-${ds.length}`}>
					{detail}
				</EntryDetail>
			);
		}

		details = (
			<EntryDetails compact={props.compact}>
				{ds}
			</EntryDetails>
		);
	}

	return (
		<EntryBoxWrapper
			compact={props.compact}
			selected={props.selected}
			onClick={() => props.onToggle(!props.selected)}
		>
			<EntryBoxLeft>
				<EntryTitle>{props.entry.title}</EntryTitle>
				{details}
			</EntryBoxLeft>
			<EntryBoxRight>
				{input}
			</EntryBoxRight>
		</EntryBoxWrapper>
	);
};

const WaterfallBox = (props: WaterfallBoxProps) => {
	const [query, setQuery] = useState("");
	const [entries, setEntries] = useState([] as Entry[]);
	const [selection, setSelection] = useState([] as Entry[]);

	const [loadingState, updateLoadingState] = useLoadingState("idle");

	const activeSelection = props.selection ?
		props.selection :
		selection;

	const state = props.state || DEFAULT_STATE;

	const dispatchState = useStateDispatch(
		state,
		state => {
			if (typeof props.onStateChange == "function")
				props.onStateChange(state, runtime);
		}
	);

	const runtime = {
		query,
		state,
		setState: dispatchState,
		searching: false
	} as Runtime;

	useEffect(() => {
		doFetchWithSearch(true);

		if (typeof props.onSelectionChange == "function")
			props.onSelectionChange(activeSelection, runtime);
	}, []);

	useEffect(
		() => {
			doTrashing(runtime);
			doFetchWithGuard(runtime)
		},
		[state]
	);

	const doTrashing = (rt: Runtime) => {
		if (typeof props.shouldTrash != "function")
			return;

		if (props.shouldTrash(rt))
			dispatchSelection([]);
	};

	const doFetch = async (rt: Runtime) => {
		updateLoadingState("loading");

		const e = await props.fetch(rt) as FetchResponse;

		if (typeof e == "string")
			updateLoadingState("error", e);
		else {
			updateLoadingState("success");
			setEntries(e);
		}
	};

	const doFetchWithGuard = (rt: Runtime, fetchUnconditionally?: boolean) => {
		if (typeof props.shouldFetch != "function") {
			if (fetchUnconditionally)
				doFetch(rt);
			return;
		}

		if (props.shouldFetch(rt))
			doFetch(rt);
	};

	const doFetchWithSearch = (fetchUnconditionally?: boolean) => {
		const rt = {
			...runtime,
			searching: true
		};

		doFetchWithGuard(rt, fetchUnconditionally);
	};

	const dispatchSelection = (sel: Entry[]) => {
		setSelection(sel);

		if (typeof props.onSelectionChange == "function")
			props.onSelectionChange(sel, runtime);
	};

	const updateSelection = (entry: Entry, selected: boolean) => {
		const maxSelected = props.mode === "multi" ?
			props.maxSelected || Infinity :
			1;

		// Radios cannot be unselected
		if (props.mode === "radio" && activeSelection.length && activeSelection[0].key === entry.key)
			return;

		if (props.mode === "radio")
			dispatchSelection([entry]);
		else {
			const idx = activeSelection.findIndex(s => s.key === entry.key),
				sel = activeSelection.slice();

			if (idx > -1)
				sel.splice(idx, 1);

			if (selected && activeSelection.length > maxSelected - 1)
				sel.shift();

			if (selected)
				sel.push(entry);

			dispatchSelection(sel);
		}
	};

	const proxiedUpdateSelection = useProxiedFunction(updateSelection);

	const outEntries = useMemo(
		() => {
			return entries.map((entry, idx) => (
				<EntryBox
					compact={props.compact}
					key={idx}
					mode={props.mode}
					entry={entry}
					selected={activeSelection.some(s => s.key === entry.key)}
					onToggle={selected => proxiedUpdateSelection(entry, selected)}
				/>
			));
		},
		[entries, activeSelection]
	);

	return (
		<WaterfallBoxWrapper>
			<Header>
				<BoxTitle>{props.title}</BoxTitle>
				<SearchBox
					query={query}
					debounce={props.debounce}
					onInput={evt => setQuery(evt.target.value)}
					onSearch={() => doFetchWithSearch(true)}
				/>
			</Header>
			<Content>
				<ScrollWrapper>
					{outEntries}
				</ScrollWrapper>
				<LoadingOverlay
					loadingState={loadingState}
					placeholder={props.placeholder}
				/>
			</Content>
		</WaterfallBoxWrapper>
	);
};

export default WaterfallBox;
