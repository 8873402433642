import faker from "faker";

const MILLIS_IN_DAY = 24 * 60 * 60 * 1000;

const MOCKED_REQUESTS = {
	"@next-gen/channels/list": () => {
		return {
			success: true,
			data: [
				{
					name: "Facebook",
					type: "Platform"
				},
				{
					name: "OTT",
					type: "Service"
				},
				{
					name: "YouTube",
					type: "Platform"
				}
			],
			message: "Loaded channels"
		};
	},
	"@next-gen/graphs/ott/timeline": ({ query }) => {
		const [start, end] = query.range
				.split(",")
				.map(iso => new Date(iso).getTime()),
			channels = query.channels.split(",");

		const payload = {
			success: true,
			data: [],
			message: "Loaded timeline"
		};

		const eachChannel = runner => {
			const out = {};

			for (const channel of channels)
				out[channel] = runner();

			return out;
		};

		for (let i = start; i <= end; i += MILLIS_IN_DAY) {
			payload.data.push({
				date: new Date(i).toISOString(),
				ad_spend: eachChannel(
					() => faker.datatype.number({ min: 100, max: 150 })
				),
				impressions: eachChannel(
					() => faker.datatype.number({ min: 1000, max: 1500 })
				),
				cpm: eachChannel(
					() => faker.datatype.number({ min: 100, max: 140 })
				),
				cpm_average: faker.datatype.number({ min: 100, max: 140 }),
				cpv: faker.datatype.number({ min: 0.3, max: 0.7, precision: 0.01 }),
				completions: eachChannel(
					() => faker.datatype.number({ min: 1000, max: 1500 })
				),
				vcr: eachChannel(
					() => faker.datatype.number({ min: 0.1, max: 1, precision: 0.01 })
				)
			});
		}

		return payload;
	},
	"@next-gen/graphs/ott/geo/performance": () => {
		const payload = {
			success: true,
			data: [],
			message: "Loaded geo performance"
		};

		const count = 20;

		for (let i = 0; i < count; i++) {
			const impressions = faker.datatype.number({ min: 1000, max: 1500 }),
				videoViews = Math.round((Math.random() * 0.2 + 0.8) * impressions),
				completions = Math.round((Math.random() * 0.2 + 0.8) * videoViews);

			payload.data.push({
				geo: faker.address.city(),
				impressions,
				video_views: videoViews,
				completions,
				ad_spend: faker.datatype.number({ min: 100, max: 150 }),
				cpm: faker.datatype.number({ min: 100, max: 140 }),
				ctr: faker.datatype.number({ min: 0.1, max: 1, precision: 0.01 }),
				cpv: faker.datatype.number({ min: 0.1, max: 0.7, precision: 0.01 })
			});
		}

		payload.data.sort((item, item2) => item2.impressions - item.impressions);

		return payload;
	},
	"@next-gen/graphs/ott/site/performance": () => {
		const payload = {
			success: true,
			data: [],
			message: "Loaded site performance"
		};

		const count = 20;

		for (let i = 0; i < count; i++) {
			const impressions = faker.datatype.number({ min: 1000, max: 1500 }),
				videoViews = Math.round((Math.random() * 0.2 + 0.8) * impressions),
				completions = Math.round((Math.random() * 0.2 + 0.8) * videoViews);

			payload.data.push({
				site: faker.internet.url(),
				impressions,
				video_views: videoViews,
				completions,
				ad_spend: faker.datatype.number({ min: 100, max: 150 }),
				cpm: faker.datatype.number({ min: 100, max: 140 }),
				ctr: faker.datatype.number({ min: 0.1, max: 1, precision: 0.01 }),
				cpv: faker.datatype.number({ min: 0.1, max: 0.7, precision: 0.01 })
			});
		}

		payload.data.sort((item, item2) => item2.impressions - item.impressions);

		return payload;
	}
};

export {
	MOCKED_REQUESTS
};
