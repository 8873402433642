import LineConfig from "./configs/line";

import Base from "./base";

import { GraphProps } from "../../../types/viz";

const Line = (props: GraphProps) => {
	return <Base {...props} configs={LineConfig} />;
};

export default Line;
