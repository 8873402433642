import StackedConfig from "./configs/stacked";

import Base from "./base";

import { GraphProps } from "../../../types/viz";

interface StackedProps extends GraphProps {
	axesLabels?: {
		y?: Function
	}
}

const Stacked = (props: StackedProps) => {
	const { axesLabels, ...p } = props
	return <Base {...p} configs={StackedConfig} store={{ axesLabels }} />;
};

export default Stacked;
