import {
	useState,
	useEffect
} from "react";

import {
	then,
	isObject
} from "@qtxr/utils";

import useRequest from "./use-request";
import useLoadingState from "./use-loading-state";
import useProxiedConfig from "./use-proxied-config";
import useProxiedFunction from "./use-proxied-function";

import {
	GraphBox,
	GraphBoxProps
} from "../components/viz";

import { GraphProps } from "../types/viz";
import { AugmentedRequestInfo } from "../types/utils";
import { GraphConfigPartitionData } from "./use-graph-config-collection";

interface HookProps<GProps extends Omit<GraphProps, "data">> {
	// Graph box
	title: string;
	box?: (props: GraphBoxProps) => JSX.Element;
	subheading?: string;
	// Graphs
	graph?: GraphEntry<GProps>;
	graphs?: GraphEntry<GProps>[];
	// Meta
	request: AugmentedRequestInfo | (() => Promise<GraphData> | GraphData);
}

interface GraphComponentProps<GProps> {
	component: (props: GProps) => JSX.Element;
	subheading?: string;
}

interface ExtendedGraphProps<GProps> extends GraphComponentProps<GProps> {
	data: any;
}

type GraphEntry<GProps> = ((props: GProps) => JSX.Element) | FullGraphComponentProps<GProps>;
type FullGraphComponentProps<GProps> = Omit<GProps & GraphComponentProps<GProps>, "data">;
type GraphData = any[] | Record<string, any>;

function ExtendedGraph<GProps>(props: ExtendedGraphProps<GProps>) {
	const {
		data,
		component: Graph,
		...p
	} = props;

	const gp = useProxiedConfig(p);

	// @ts-ignore
	return <Graph data={data} {...gp} />;
}

function useGraphBox<GProps extends Partial<GraphProps>>(
	props: HookProps<GProps> & Partial<GProps>
): JSX.Element {
	const {
		title,
		subheading,
		box: Box = GraphBox,
		graph,
		graphs,
		request,
		...graphProps
	} = props;

	const throughRequest = typeof request !== "function",
		requestInfo = throughRequest ?
			request :
			{ url: "", suspended: true };

	const [requestData, requestLoadingState] = useRequest(requestInfo, []);
	const [getterLoadingState, updateGetterLoadingState] = useLoadingState("idle");
	const [getterData, setGetterData] = useState([] as GraphData);

	const data = throughRequest ?
			requestData :
			getterData,
		loadingState = throughRequest ?
			requestLoadingState :
			getterLoadingState;

	useEffect(
		() => {
			if (throughRequest)
				return;

			updateGetterLoadingState("loading");

			then(
				request(),
				(d: GraphData) => {
					updateGetterLoadingState("success");
					setGetterData(d);
				}
			);
		},
		[]
	);

	const gp = useProxiedConfig(graphProps);

	const inComponents = graphs || graph,
		components = Array.isArray(inComponents) ?
			inComponents :
			[inComponents];

	const trackAll = useProxiedFunction((supplement: Partial<GraphConfigPartitionData> | null) => {
		if (typeof gp.track == "function")
			gp.track(supplement);

		for (const component of components) {
			const comp = component as FullGraphComponentProps<GProps>;

			if (isObject(component) && typeof comp.track == "function")
				comp.track(supplement);
		}
	});

	trackAll({
		loadingState
	});

	const outComponents = components.map((comp, i) => {
		if (typeof comp == "function") {
			const Graph = comp;

			if (typeof gp.track == "function") {
				gp.track({
					props: {
						...gp,
						title,
						subheading
					}
				});
			}

			return (
				// @ts-ignore
				<Graph
					key={i}
					subheading={subheading}
					data={data}
					{...gp}
				/>
			);
		}

		const {
			component,
			...p
		} = comp as FullGraphComponentProps<GProps>;

		const eProps = {
			...gp,
			...p
		};

		if (typeof eProps.track == "function") {
			eProps.track!({
				props: {
					...eProps,
					title,
					subheading: eProps.subheading
				}
			});
		}

		return (
			<ExtendedGraph
				key={i}
				data={data}
				component={component}
				{...eProps}
			/>
		);
	});

	useEffect(
		() => {
			return () => trackAll(null);
		},
		[]
	);

	return (
		<Box
			title={title}
			loadingState={loadingState}
			key={props.graphKey ? props.graphKey : undefined}
			titleStyle={props.titleStyle}
		>
			{outComponents}
		</Box>
	);
}

export default useGraphBox;
