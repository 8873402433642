import styled from "styled-components";

import Card from "./card";
import LoadingOverlay from "./loading-overlay";

import { LoadingState } from "../types/utils";

interface StatCardProps {
	value: number;
	label: string;
	loadingState?: LoadingState;
}

const Wrapper = styled(Card)`
	position: relative;
	flex-direction: row;
	padding: 10px 15px;
	
	& + & {
		margin-left: 10px;
	}
`;

const Value = styled.h1`
	margin: 0;
	font-size: 130%;
	line-height: 1;
	color: ${p => p.theme.accent};
`;

const Label = styled.p`
	margin: 0 0 0 0.8em;
	font-size: 105%;
	font-weight: bold;
`;

const StatCard = (props: StatCardProps) => (
	<Wrapper>
		<Value>{props.value}</Value>
		<Label>{props.label}</Label>
		<LoadingOverlay
			loadingState={props.loadingState}
			scale={0.4}
		/>
	</Wrapper>
);

export default StatCard;
