import Icon from "./icon";

import {
	Resolvable,
	BaseRuntime,
	ContentSource
} from "../types/common";

function resolve<T, Runtime>(
	source: Resolvable<T, Runtime>,
	runtime: Runtime,
	def?: T | undefined
): T | undefined {
	let value = source as T;

	if (typeof source == "function")
		value = (source as (runtime: Runtime) => T)(runtime);

	if (value === undefined)
		return def;

	return value;
}

function mkResolver<Runtime>(runtime: Runtime) {
	return function <T>(source: Resolvable<T | undefined, Runtime>, def?: any): T | undefined {
		return resolve(source, runtime, def);
	};
}

function getContent<Runtime = BaseRuntime>(
	source: ContentSource<Runtime>,
	runtime: Runtime
): string | JSX.Element {
	let label = source.label == null ?
			null :
			resolve(source.label, runtime),
		icon = source.icon == null ?
			null :
			resolve(source.icon, runtime);

	if (typeof label == "string")
		label = <span>{label}</span>;
	if (typeof icon == "string")
		icon = <Icon name={icon} />;

	if (source.iconAlignment === "left") {
		return (
			<>
				{icon}
				{label}
			</>
		);
	}

	return (
		<>
			{label}
			{icon}
		</>
	);
}

export {
	resolve,
	mkResolver,
	getContent
};
