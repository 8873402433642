function propsAndQueryParamsProxy(props: any): any {
	return new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop: string) =>
			props[prop] || searchParams.get(prop)
	});
}

const toArray = (arr: string | any[]) => {
	if (Array.isArray(arr)) return arr;
	if (typeof arr === "string") return arr.split(",");
	return undefined;
};

const toNumber = (n: any) => {
	if (typeof n === "string") return parseInt(n);
	return n;
};

export { propsAndQueryParamsProxy, toArray, toNumber };
