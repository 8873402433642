import {
	useMemo,
	useState,
	useEffect
} from "react";

import { resolveRequestConfig } from "../utils";

import useRequest from "./use-request";
import useActionProps from "./use-action-props";

import { Row } from "../types/data-table";
import { RequestInfo } from "../types/utils";
import { ActionTableProps } from "../components/action-table";

interface RemoteActionTableProps extends Omit<ActionTableProps, "rows"> {
	request: RequestInfo;
	process?: (row: any, index: number, rows: any[]) => Row;
}

const useRemoteActionProps = (props: RemoteActionTableProps) => {
	const [initialized, setInitialized] = useState(false);

	const {
		request,
		...baseProps
	} = props;

	const req = useMemo(
		() => {
			let r = resolveRequestConfig(request);

			r.query = r.query ?
				Object.assign({}, r.query) :
				{};

			if (!r.query.hasOwnProperty("start"))
				r.query.start = 0;
			if (!r.query.hasOwnProperty("size") && props.pageSize)
				r.query.size = props.pageSize;

			return r;
		},
		[request]
	);

	const [
		rows,
		loadingState
	] = useRequest(req, [] as any[]);

	const outRows = useMemo(
		() => {
			if (typeof props.process != "function")
				return rows;

			return rows.map(props.process)
		},
		[rows]
	);

	useEffect(
		() => {
			if (!initialized && loadingState.success)
				setInitialized(true);
		},
		[loadingState]
	);

	return useActionProps({
		...baseProps,
		rows: outRows,
		loadingState,
		initialized
	});
};

export default useRemoteActionProps;
