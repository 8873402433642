import { get } from "@qtxr/utils";

import { collectLayout } from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Data,
	Point
} from "../../apply-categorization";

const LineConfig = {
	name: "line-impl",
	collector: () => collectLayout((p: any) => {
		const points = p.data.points as Data[],
			catPoints = p.data.categorized.points as Point[];

		const extrema = points.map(pt => {
			return catPoints.reduce((acc, cp) => {
				const value = get(pt, cp.accessor).value.y;
				acc.min = Math.min(acc.min, value);
				acc.max = Math.max(acc.max, value);
				return acc;
			}, {
				min: Infinity,
				max: -Infinity
			});
		});

		p.data.min = extrema.reduce(
			(acc, ex) => Math.min(acc, ex.min),
			Infinity
		);

		p.data.max = extrema.reduce(
			(acc, ex) => Math.max(acc, ex.max),
			-Infinity
		);

		p.data.max = Math.max(p.data.max, p.data.min + 1);

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const ds = p.dataset,
			w = ds.cWidth - 10,
			h = ds.cHeight - 10,
			min = ds.data.min,
			max = ds.data.max,
			range = max - min,
			points = ds.data.points as Data[],
			catPoints = p.data.categorized.points as Point[],
			stepLength = w / (points.length - 1),
			style = p.inst.config.style;

		for (const cp of catPoints) {
			plot.atIndex(-1);
			plot.with(cp);

			const path = plot.path();
			let x = 5;

			// Plot lines
			for (let i = 0, l = points.length; i < l; i++) {
				const y = (1 - ((get(points[i], cp.accessor).value.y - min) / range)) * h + 5;

				if (i)
					path.L(x, y);
				else
					path.M(x, y);

				x += stepLength;
			}

			path
				.stroke(cp.color)
				.strokeWidth(3)
				.pointer("stroke")
				.outline(5);

			x = 5;

			for (const pt of points) {
				const y = (1 - ((get(pt, cp.accessor).value.y - min) / range)) * h + 5;

				plot.next();

				plot.circle()
					.cx(x)
					.cy(y)
					.r(3.5)
					.fill(style.fullTheme.graphBackground)
					.stroke(cp.color)
					.strokeWidth(1.5)
					.outline(5);

				x += stepLength;
			}
		}

		plot.render();
	},
	dataset: {
		id: "line-impl",
		mode: "own",
		type: "line-impl",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default LineConfig;