import {
	useState,
	useEffect
} from "react";

import {
	then,
	equals
} from "@qtxr/utils";

import {
	request,
	resolveRequestConfig
} from "../utils";

import useLoadingState from "./use-loading-state";
import useStateCapsule from "./use-state-capsule";
import useProxiedFunction from "./use-proxied-function";

import {
	Response,
	LoadingState,
	RequestConfig,
	AugmentedRequestInfo
} from "../types/utils";

interface RequestRuntime {
	id: number;
	settled: boolean;
	abort: () => void;
}

interface RequestEntry {
	config: RequestConfig;
	cacheHash: string | number | null;
	request: Promise<Response>;
}

const REQUEST_POOL = {} as Record<string, RequestEntry>;

const NULL_FN = (data: any) => {};
let id = 0;

const eq = (a: any, b: any, options?: any) => {
	return equals(
		a,
		b,
		[
			{ comparator },
			options
		]
	);
};

const comparator = (a: any, b: any) => {
	if (typeof a != "function" || typeof b != "function" || !(b instanceof AbortSignal))
		return;

	return true;
};

const req = (
	config: RequestConfig,
	cacheHash: string | number | null | undefined
): Promise<Response> => {
	if (cacheHash == null)
		return request(config);

	const key = `${cacheHash}#${config.url.trim().toLowerCase()}`,
		poolEntry = REQUEST_POOL[key];

	if (poolEntry && cacheHash === poolEntry.cacheHash && eq(config, poolEntry.config))
		return poolEntry.request;

	REQUEST_POOL[key] = {
		config,
		cacheHash,
		request: request(config)
	};

	return REQUEST_POOL[key].request;
};

const useRequest = (
	info: AugmentedRequestInfo,
	defaultData?: any
): [any, LoadingState, (data: any) => void, RequestRuntime] => {
	let filteredInfo = info,
		cacheHash = null as string | number | null,
		suspended = false,
		placeholder = "",
		success = NULL_FN,
		error = NULL_FN,
		process = null as ((data: any) => any) | null;

	if (typeof info != "string") {
		const {
			cacheHash: ch,
			suspended: sp,
			placeholder: ph,
			success: su,
			fail: fl,
			process: pr,
			...filtered
		} = info;

		cacheHash = ch == null ?
			cacheHash :
			ch;
		suspended = Boolean(sp);
		placeholder = ph || "";
		success = su || NULL_FN;
		error = fl || NULL_FN;
		process = pr || process;

		filteredInfo = filtered;
	}

	const handleSuccess = useProxiedFunction(success);
	const handleError = useProxiedFunction(error);

	const [currentInfo, setCurrentInfo] = useState(filteredInfo);
	const [payloadData, setPayloadData] = useState(defaultData);
	const [requestRuntime, setRequestRuntime] = useState(() => ({
		settled: true,
		abort: () => {}
	} as RequestRuntime));

	if (!eq(filteredInfo, currentInfo))
		setCurrentInfo(filteredInfo);

	const runtimeState = useStateCapsule(requestRuntime, true);

	const [loadingState, updateLoadingState] = useLoadingState("idle", placeholder);

	useEffect(
		() => {
			if (suspended)
				return;

			const controller = new AbortController();
			let activeConfig = resolveRequestConfig(currentInfo),
				localId = id++;

			activeConfig.signal = controller.signal;

			updateLoadingState("loading");
			setRequestRuntime(rt => {
				if (!rt.settled)
					rt.abort();

				return {
					id: localId,
					settled: false,
					abort: () => controller.abort()
				};
			});

			const f = async (): Promise<void> => {
				const response = await req(activeConfig, cacheHash);

				setRequestRuntime(rt => {
					if (rt.id !== localId)
						return rt;

					if (!response.success) {
						updateLoadingState("error", response.errorMessage!);
						handleError(response.errorMessage!);

						return {
							...rt,
							settled: true
						};
					}

					const stagedProcessed = process ?
						process(response.data) :
						response.data;

					then(stagedProcessed, (processed: any) => {
						if (process && (!processed || typeof (processed as string) == "string")) {
							const msg = processed || response.errorMessage || "Unknown error";
							updateLoadingState("error", msg);
							handleError(msg);
						} else {
							setPayloadData(processed);
							updateLoadingState("success");
							handleSuccess(processed);
						}
					});

					return {
						...rt,
						settled: true
					};
				});
			};

			f();

			return () => {
				const rt = runtimeState.get();

				if (!rt.settled)
					rt.abort();
			};
		},
		[currentInfo, cacheHash, suspended]
	);

	return [
		payloadData,
		loadingState,
		setPayloadData,
		requestRuntime
	];
};

export default useRequest;
