import I18NManager from "@qtxr/i18n";

const I18N = new I18NManager({
	sitemapPath: null
});

I18N.feed({
	name: "viz",
	locale: "en",
	data: {
		viz: {
			time: {
				months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
				days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
				meridiem: ["AM", "PM"],
				dateStr: `[timeAccuracy]
					{$day(viz.time.days), $month(viz.time.months) dd YYYY}
					{$m12(hh):MM $ampm(viz.time.meridiem) - $day(viz.time.days), $month(viz.time.months) dd YYYY}
					{$m12(hh):MM $ampm(viz.time.meridiem) - $day(viz.time.days), $month(viz.time.months) dd YYYY}
					{$m12(hh):MM:SS $ampm(viz.time.meridiem) - $day(viz.time.days), $month(viz.time.months) dd YYYY}`
			}
		}
	}
});

export default I18N;
