import {
	useRef,
	useState,
	useEffect
} from "react";
import { createPortal } from "react-dom";
import styled, { withTheme } from "styled-components";

import { removeToast } from "../../state/features/ui";

import {
	useAppDispatch,
	useAppSelector
} from "../../state/hooks";

import Icon from "../icon";

import { IconName } from "../../types/icon";
import {
	ToastType,
	FullToastConfig
} from "../../types/ui";

interface AugmentedToastConfig extends FullToastConfig {
	offset: number;
	zIndex: number;
	visible: boolean;
	animate: boolean;
	collapsed: boolean;
}

interface IconWrapperProps {
	color: string;
}

interface ToastIconProps {
	theme: any;
	type: ToastType;
}

interface IdMap {
	[key: string]: any;
}

interface ElementMap {
	[key: string]: Element | null;
}

type ToastBoxProps = Omit<AugmentedToastConfig, "id">;

const ToastWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 8px;
	position: fixed;
	top: 60px;
	bottom: 0;
	left: 0;
	right: 20px;
	pointer-events: none;
	z-index: 10000;
`;

const ToastBox = styled.div.attrs<ToastBoxProps>(p => ({
	style: {
		position: p.visible ? "relative" : "absolute",
		transform: `translate(${p.collapsed ? "100%" : "0"}, ${p.offset}px)`,
		opacity: p.collapsed ? 0 : 1,
		transition: p.animate ? "transform 300ms, opacity 300ms" : "none",
		zIndex: p.zIndex
	}
}))<ToastBoxProps>`
	display: flex;
	padding: 10px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	box-shadow: ${p => p.theme.cardShadow};
	pointer-events: auto;
`;

const IconWrapper = styled.div<IconWrapperProps>`
	position: relative;
	width: 30px;
	height: 30px;
	color: ${p => p.color};
`;

const IconBackground = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: currentColor;
	opacity: 0.2;
`;

const FixedIcon = styled(Icon)`
	position: absolute;
	top: 30%;
	left: 30%;
	width: 40%;
	height: 40%;
`;

const ToastIcon = withTheme((props: ToastIconProps) => {
	let color,
		iconName: IconName;

	switch (props.type) {
		case "info":
			color = props.theme.accent;
			iconName = "check";
			break;

		case "warn":
			color = props.theme.warn;
			iconName = "cross";
			break;

		case "error":
			color = props.theme.alert;
			iconName = "cross";
			break;
	}

	return (
		<IconWrapper color={color}>
			<IconBackground />
			<FixedIcon name={iconName} />
		</IconWrapper>
	);
});

const ToastContent = styled.div`
	display: flex;
	align-items: center;
	margin: 0 8px 0 14px;
`;

const Toast = () => {
	const dispatch = useAppDispatch();
	const toastRefs = useRef({} as ElementMap);
	const toasts = useAppSelector(state => state.ui.toasts);

	const [stagedToasts, setStagedToasts] = useState([] as AugmentedToastConfig[]);

	const [additions, setAdditions] = useState(null as IdMap | null);
	const [mounts, setMounts] = useState(null as IdMap | null);
	const [unmounts, setUnmounts] = useState(null as IdMap | null);
	const [removals, setRemovals] = useState(null as IdMap | null);

	useEffect(
		() => {
			const inMap = {} as any,
				outMap = {} as any,
				outToasts = [] as AugmentedToastConfig[];

			for (const toast of toasts)
				inMap[toast.id] = toast;
			for (const toast of stagedToasts)
				outMap[toast.id] = toast;

			for (let i = toasts.length - 1; i >= 0; i--) {
				const toast = toasts[i];

				if (outMap.hasOwnProperty(toast.id))
					outToasts.push(outMap[toast.id]);
				else {
					const augmented = {
						...toast,
						offset: 0,
						zIndex: toast.id,
						visible: false,
						animate: true,
						collapsed: true
					};

					outToasts.push(augmented);

					if (!stagedToasts.length)
						scheduleMount(augmented);
					else
						scheduleAddition(augmented);

					scheduleUnmount(augmented);
				}
			}

			setStagedToasts(outToasts);
		},
		[toasts]
	);

	useEffect(
		() => {
			if (!additions)
				return;

			const outToasts = [];
			let offset = 0;

			for (const toast of stagedToasts) {
				if (additions.hasOwnProperty(toast.id)) {
					const height = toastRefs.current[toast.id]!.getBoundingClientRect().height;
					offset -= (height + 8);

					outToasts.push({
						...toast,
						visible: true
					});

					scheduleMount(toast);
				} else if (offset) {
					outToasts.push({
						...toast,
						offset: toast.offset + offset,
						animate: false
					});
				} else
					outToasts.push(toast);
			}

			setStagedToasts(outToasts);
			setAdditions(null);
		},
		[additions]
	);

	useEffect(
		() => {
			if (!mounts)
				return;

			const outToasts = [];
			let offset = 0;

			for (const toast of stagedToasts) {
				if (mounts.hasOwnProperty(toast.id)) {
					const height = toastRefs.current[toast.id]!.getBoundingClientRect().height;
					offset += (height + 8);

					outToasts.push({
						...toast,
						visible: true,
						collapsed: false
					});
				} else if (offset) {
					outToasts.push({
						...toast,
						offset: toast.offset + offset,
						animate: true
					});
				} else
					outToasts.push(toast);
			}

			setStagedToasts(outToasts);
			setMounts(null);
		},
		[mounts]
	);

	useEffect(
		() => {
			if (!unmounts)
				return;

			const outToasts = [];
			let offset = 0;

			for (const toast of stagedToasts) {
				if (unmounts.hasOwnProperty(toast.id)) {
					const height = toastRefs.current[toast.id]!.getBoundingClientRect().height;
					offset -= (height + 8);

					outToasts.push({
						...toast,
						collapsed: true
					});

					scheduleRemoval(toast);
				} else if (offset) {
					outToasts.push({
						...toast,
						offset: toast.offset + offset,
						animate: true
					});
				} else
					outToasts.push(toast);
			}

			setStagedToasts(outToasts);
			setUnmounts(null);
		},
		[unmounts]
	);

	useEffect(
		() => {
			if (!removals)
				return;

			const outToasts = [];
			let offset = 0;

			for (const toast of stagedToasts) {
				if (removals.hasOwnProperty(toast.id)) {
					const height = toastRefs.current[toast.id]!.getBoundingClientRect().height;
					offset += (height + 8);

					dispatch(removeToast(toast.id));
				} else if (offset) {
					outToasts.push({
						...toast,
						offset: toast.offset + offset,
						animate: false
					});
				} else
					outToasts.push(toast);
			}

			setStagedToasts(outToasts);
			setRemovals(null);
		},
		[removals]
	);

	const scheduleAddition = (toast: AugmentedToastConfig) => {
		requestAnimationFrame(() => {
			setAdditions(add => ({
				...(add || {}),
				[toast.id]: true
			}));
		});
	};

	const scheduleMount = (toast: AugmentedToastConfig) => {
		requestAnimationFrame(() => {
			setMounts(mt => ({
				...(mt || {}),
				[toast.id]: true
			}));
		});
	};

	const scheduleUnmount = (toast: AugmentedToastConfig) => {
		if (!isFinite(toast.timeout))
			return;

		setTimeout(
			() => {
				setUnmounts(del => ({
					...(del || {}),
					[toast.id]: true
				}));
			},
			toast.timeout
		);
	};

	const scheduleRemoval = (toast: AugmentedToastConfig) => {
		setTimeout(
			() => {
				setRemovals(del => ({
					...(del || {}),
					[toast.id]: true
				}));
			},
			300
		);
	};

	const boxes = [];

	for (const toast of stagedToasts) {
		const {
			id,
			...p
		} = toast;

		toastRefs.current = {};

		boxes.push(
			<ToastBox
				{...p}
				key={id}
				ref={el => toastRefs.current[toast.id] = el}
			>
				<ToastIcon type={toast.type} />
				<ToastContent>{toast.message}</ToastContent>
			</ToastBox>
		);
	}

	const wrapper = (
		<ToastWrapper>
			{boxes}
		</ToastWrapper>
	);

	return createPortal(
		wrapper,
		document.getElementById("ui-root")!
	);
};

export default Toast;
