import { request } from "../../utils";

import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import Base from "../base";

import ContentCard from "../../components/content-card";
import ActionTable from "../../components/action-table";

import { ColumnCell } from "../../types/data-table";

const COLUMNS: ColumnCell[] = [
	{
		name: "name",
		title: "Name",
		accessor: "full_name",
		editable: true
	},
	{
		name: "shortName",
		title: "Short Name",
		accessor: "short_name",
		editable: true
	},
	{
		name: "color",
		type: "color",
		title: "Color",
		accessor: "color",
		fit: "shrink",
		editable: true
	}
];

const Parties = () => {
	const actions = useTableActions({
		addLabel: "Add Party",
		add: () => ({
			full_name: "",
			short_name: "",
			color: "#272e3f"
		}),
		createEach: row => {
			return request({
				url: "/parties/create",
				body: row
			});
		},
		modifyEach: row => {
			return request({
				url: `/parties/${row.id}`,
				body: row,
				method: "PUT"
			})
		},
		deleteEach: row => {
			return request({
				url: `/parties/${row.id}`,
				method: "DELETE"
			})
		}
	});

	const props = useRemoteActionProps({
		request: "/parties/list",
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} />
			</ContentCard>
		</Base>
	);
};

export default Parties;
