import { useMemo, useState } from "react";

import { getRoundDate } from "../utils";

import { SelectionPayload } from "../components/waterfall";
import { SettableQuerySettings } from "../types/reports";

const useQuerySettings = (): SettableQuerySettings => {
	const [hash, setHash] = useState(0);
	const [selectionPayload, setSelectionPayload] = useState(
		null as SelectionPayload | null
	);
	const [dateRange, setDateRange] = useState(() => {
		const nowDate = new Date(),
			todayDate = getRoundDate(nowDate),
			nextWeekDate = getRoundDate(nowDate, 7);

		return [todayDate.getTime(), nextWeekDate.getTime()] as [
			number,
			number
		];
	});

	const normalizedDateRange = useMemo(() => {
		return dateRange
			.map(d => new Date(d))
			.map(d =>
				Date.UTC(
					d.getFullYear(),
					d.getMonth(),
					d.getDate()
				)
			) as [number, number];
	}, [dateRange]);

	const refresh = () => {
		setHash(hash + 1);
	};

	return {
		querySettings: {
			hash,
			selectionPayload,
			dateRange,
			normalizedDateRange
		},
		setSelectionPayload,
		setDateRange,
		refresh
	};
};

export default useQuerySettings;
