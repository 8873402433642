import styled from "styled-components";

const Card = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
	flex-basis: 0;
	padding: 15px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
`;

export default Card;
