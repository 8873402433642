import Base from "../base";

import { ColumnCell } from "../../types/data-table";
import { useEffect, useMemo, useState } from "react";
import { request } from "../../utils";
import { ControlUnion, DateRangeRuntime } from "../../types/control-bar";
import useQuerySettings from "../../hooks/use-query-settings";
import ControlBar from "../../components/control-bar";
import {BaseRuntime} from "../../types/common"
import styled from "styled-components";
import useRemoteActionProps from "../../hooks/use-remote-action-props";
import ActionTable from "../../components/action-table";
import { VerticalSeparator } from "../../components/separators";
import { useAppSelector } from "../../state/hooks";
import Dropdown, { DropdownOption } from "../../components/inputs/dropdown";
import DailySnapshot from "./daily-snapshot";
import { downloadPdf } from "../../utils/download-pdf";
import { API_URL } from "../../data/constants";
import useTableActions from "../../hooks/use-table-actions";

const COLUMNS: ColumnCell[] = [
	{
		name: "race",
		title: "Race",
		accessor: "race_name",
		index: "lexical"
	},
	{
		name: "recipient",
		title: "Recipient",
		accessor: "email_list_name",
		index: "lexical"
	}
];

const runtime = {
	state: {}
} as BaseRuntime;

const PreviewHeader = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 15px;
	background: ${p => p.theme.componentBackground};
	border-radius: ${p => p.theme.borderRadius};
	font-weight: bold;
`

const HeaderSpacer = styled.div`
	position: relative;
	height: 100%;
	margin-right: 1rem;
`;

const SnapshotsWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	padding: 15px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

interface SnapshotProps {
	raceId: string,
	startDate: number,
	endDate: number
}

const Content = () => {
	const races = useAppSelector(state => state.data.races)
	const [raceId, setRaceId] = useState<any>()
	const [updatePending, setUpdatePending] = useState(false)
	const [flightDateRange, setFlightDateRange] = useState<[number, number]>()
	const {
		querySettings,
		setDateRange,
	} = useQuerySettings();

	useEffect(() => {
		if (raceId) {
			setUpdatePending(true)

			request({
				query: {
					race_id: raceId
				},
				url: "flights/list"
			}).then(({data}) => {
				if (!Array.isArray(data) || !data.length) return
				const dateRange = data.reduce((dateRange, flight) => {
					try {
						const flightStartTime = new Date(flight.start_date).getTime()
						const flightEndTime = new Date(flight.end_date).getTime() + 24*3600*1000 // add 1 day

						const start = dateRange[0] ? Math.min(flightStartTime, dateRange[0]) : flightStartTime
						const end = dateRange[1] ? Math.max(flightEndTime, dateRange[1]) : flightEndTime
						if (start !== dateRange[0] || end !== dateRange[1]) {
							return [start, end]
						}
					} catch {} // ignore bad data
					return dateRange
				}, [])

				if (dateRange.length) {
					setFlightDateRange(dateRange)
				}
			}).finally(() => setUpdatePending(false))
		}
	}, [raceId])

	const controls = [
		{
			label: "Snapshot",
			disabled: !raceId || updatePending,
			onClick: () => {
				const raceName = races.find(r => r.id === raceId)?.race_name;
				const filename = raceName ? raceName + " Snapshot" : "Snapshot";
				downloadPdf(filename);
			}
		},
		{
			label: "\u{1F50D}",
			disabled: !flightDateRange,
			onClick: () => { if (flightDateRange) setDateRange(flightDateRange) }
		},
		{
			mode: "date-range",
			value: querySettings.dateRange,
			onChange: (rt: DateRangeRuntime) => {
				setDateRange(rt.range);
			}
		}
	] as ControlUnion[];

	const raceOptions = useMemo(() => {
		function sortRaces(a: any, b: any) {
			if (a.race_name === b.race_name) return 0
			if (a.race_name < b.race_name) return -1
			return 1
		}
		return [...races].sort(sortRaces).map(race => ({value: race.id.toString(), label: race.race_name} as DropdownOption))
	}, [races])

	return (
		<div style={{overflow: "auto", width: "50%"}}>
			<PreviewHeader>
				<span>Snapshot Preview for Race</span>
				<HeaderSpacer />
				<Dropdown
					value={raceId?.toString()}
					options={raceOptions}
					name="Race"
					onChange={(evt: any) => {
						const raceId = parseInt(evt?.target?.value)
						if (typeof raceId === "number" && isFinite(raceId)) {
							setRaceId(raceId)
						}
					}}
				/>
			</PreviewHeader>
			<ControlBar controls={controls} runtime={runtime} />
			{raceId && !updatePending && <DailySnapshot race_id={raceId} start_date={querySettings.normalizedDateRange[0]} end_date={querySettings.normalizedDateRange[1]} />}
		</div>
	)
}

const Snapshots = () => {
	const actions = useTableActions({
		export: async (row: any) => {
			await request({
				url: `/cron/generate_daily_snapshot/${row.race_id}/${row.email_list_id}`,
				method: "POST"
			})
		},
	});

	const props = useRemoteActionProps({
		request: {
			url: "/snapshots/today"
		},
		actions,
		columns: COLUMNS,
		expand: true,
		pageSize: 50,
	});

	return (
		<Base limited>
			<SnapshotsWrapper>
				<ActionTable {...props} />
				<VerticalSeparator/>
				<Content />
			</SnapshotsWrapper>
		</Base>
	);
};

export default Snapshots;
