import styled from "styled-components";

import Centered from "./centered";
import LoadingIcon from "./loading-icon";

import { LoadingState } from "../types/utils";

interface LoadingOverlayProps {
	loadingState?: LoadingState | null;
	scale?: number;
	placeholder?: string;
}

const Overlay = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${p => p.theme.modalBackground};
	border-radius: ${p => p.theme.borderRadius};
	backdrop-filter: blur(6px);
	z-index: 10;
`;

const SmallLoadingIcon = styled(LoadingIcon)`
	font-size: 4px;
	color: ${p => p.theme.huedColor};
`;

const LoadingOverlay = (props: LoadingOverlayProps) => {
	const ls = props.loadingState;
	let overlayContent = null;

	if (ls) {
		if (ls.loading) {
			overlayContent = (
				<Centered>
					<SmallLoadingIcon scale={props.scale} />
				</Centered>
			);
		} else if (!ls.idle && !ls.success) {
			overlayContent = (
				<Centered>
					{ls.placeholder || ls.errorMessage || "There was an error"}
				</Centered>
			);
		} else if (ls.idle && (typeof props.placeholder == "string" || ls.placeholder)) {
			overlayContent = (
				<Centered>
					{props.placeholder || ls.placeholder}
				</Centered>
			);
		}

		if (overlayContent)
			overlayContent = <Overlay className="loading-overlay">{overlayContent}</Overlay>;
	}

	if (overlayContent)
		return overlayContent;

	return <></>;
};

export default LoadingOverlay;
