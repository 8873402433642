import {
	useRef,
	useMemo,
	useState,
	useEffect,
	useContext
} from "react";
import { FormikContext } from "formik";
import styled from "styled-components";

import { InputProps } from "../../types/inputs";

export interface DropdownProps extends InputProps {
	value: string;
	options: DropdownOption[];
	name?: string;
	required?: boolean;
	onSet?: (evt: any) => void;
	onChange?: (evt: any) => void;
	valueAccessor?: string;
	labelAccessor?: string;
	comparator?: (optionValue: any, value: any) => boolean;
	className?: string;
}

interface Option {
	value: string;
	label: string;
}

export type DropdownOption = Option | string;

const DropdownWrapper = styled.div`
	display: flex;
	flex-shrink: 0;
	position: relative;
	height: ${p => p.theme.buttonHeight};
	background: ${p => p.theme.cardBackground};
	border: ${p => p.theme.inputBorder};
	border-radius: ${p => p.theme.borderRadius};
`;

const DropdownLabel = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: 0 10px;
`;

const DropdownIcon = styled.div`
	flex-shrink: 0;
	position: relative;
	width: ${p => p.theme.buttonHeight};
	height: 100%;
	
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 8px;
		height: 8px;
		margin: -4px;
		transform: scaleX(1.4) translateY(-20%) rotate(45deg);
		border: ${p => p.theme.inputBorder};
		border-left: none;
		border-top: none;
	}
`;

const DropdownTarget = styled.select`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	opacity: 0;
	cursor: pointer;
`;

const getOptions = (
	options: DropdownOption[],
	valueAccessor: string = "value",
	labelAccessor: string = "label"
): Option[] => {
	return options.map(option => {
		if (typeof option != "string") {
			return {
				...option,
				value: (option as any)[valueAccessor],
				label: (option as any)[labelAccessor]
			};
		}

		return {
			value: option,
			label: option
		};
	});
};

const Dropdown = (props: DropdownProps) => {
	const ctx = useContext(FormikContext);
	const dropdownRef = useRef(null as HTMLSelectElement | null);

	const options = useMemo(
		() => getOptions(props.options, props.valueAccessor, props.labelAccessor),
		[props.options]
	);

	const optionsNodes = useMemo(
		() => {
			const optionsElems = options.map((option, idx) => (
				<option
					key={idx}
					value={option.value}
				>
					{option.label}
				</option>
			));


			return optionsElems;
		},
		[options]
	);

	const [label, setLabel] = useState(() => {
		const activeOption = options.find(option => {
			if (typeof props.comparator == "function")
				return props.comparator(option.value, props.value);

			return option.value === (props.value||"").toString()
		});

		if (activeOption)
			return activeOption.label;

		return "";
	});

	useEffect(() => {
		if (!dropdownRef.current)
			return;

		const idx = dropdownRef.current.selectedIndex;

		if (idx === 0 && ctx && String(options[0].value) !== String(props.value))
			ctx.setFieldValue(props.name!, String(options[0].value));

		updateLabel(idx);
	}, [props.value]);

	const updateLabel = (index: number) => {
		const option = options[index];

		if (option)
			setLabel(option.label);
		else
			setLabel("no selection");
	};

	const handleChange = (evt: any) => {
		updateLabel(evt.target.selectedIndex);

		if (typeof props.onSet == "function")
			props.onSet(evt);
		if (typeof props.onChange == "function")
			props.onChange(evt);
	};

	return (
		<DropdownWrapper className={props.className}>
			<DropdownLabel>
				{label}
			</DropdownLabel>
			<DropdownIcon />
			<DropdownTarget
				id={props.name}
				name={props.name}
				required={props.required}
				value={props.value}
				ref={dropdownRef}
				onChange={handleChange}
			>
				{optionsNodes}
			</DropdownTarget>
		</DropdownWrapper>
	);
};

export default Dropdown;
