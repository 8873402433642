// @ts-ignore
// ignoring type checking as no type defined in html2pdf.js library
// see: https://github.com/eKoopmans/html2pdf.js/issues/398#issuecomment-943868851
import html2pdf from "html2pdf.js"

const DOWNLOAD_PDF_DIV_ID = "pdf-content-container"

const fileOptions = {
  image: { quality: 1, type: 'jpeg' },
  html2canvas: { scale: 3 },
  jsPDF: {
    orientation: "landscape",
    unit: 'in'
  },
  margin: 0.5,
  pagebreak: {
    mode: ["avoid-all"]
  }
}

function downloadPdf(filename: string) {
  const filenameWithExtension = filename.endsWith(".pdf") ? filename : filename + ".pdf"
  console.log(`Downloading PDF file "${filenameWithExtension}"`)
  html2pdf().set(fileOptions).from(document.getElementById(DOWNLOAD_PDF_DIV_ID)).save(filenameWithExtension)
}

export { downloadPdf, DOWNLOAD_PDF_DIV_ID }
