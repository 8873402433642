import styled from "styled-components";

interface ArrowButtonProps {
	disabled?: boolean;
	onClick?: (evt: any) => void;
	children?: any;
	className?: string;
}

interface ButtonProps {
	disabled?: boolean;
}

const ArrowButtonWrapper = styled.button<ButtonProps>`
	display: block;
	position: relative;
	min-width: 150px;
	height: 30px;
	padding: 0 30px 0 10px;
	background: transparent;
	border: none;
	border-radius: ${p => p.theme.borderRadius};
	outline: none;
	font: inherit;
	font-weight: bold;
	text-align: left;
	color: ${p =>
		p.disabled ?
			p.theme.disabledColorAlt :
			p.theme.accentColor
	};
	overflow: hidden;
	cursor: ${p => p.disabled ? "default" : "pointer"};
	pointer-events: none;
	z-index: 1;
	
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		width: 200%;
		padding-bottom: 200%;
		background: ${p =>
			p.disabled ?
				p.theme.disabledBackground :
				p.theme.darkBackground
		};
		border-radius: ${p => p.theme.borderRadius};
		transform-origin: 100% 0;
		transform: rotate(45deg);
		pointer-events: auto;
		z-index: -1;
	}
`;

const ArrowButton = (props: ArrowButtonProps) => {
	return (
		<ArrowButtonWrapper
			className={props.className}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			{props.children}
		</ArrowButtonWrapper>
	);
};

export default ArrowButton;
