import ReachConfig from "./configs/reach-geo";

import Base from "./base";

import { GraphProps } from "../../../types/viz";

const Pie = (props: GraphProps) => {
	return <Base {...props} configs={ReachConfig} />;
};

export default Pie;
