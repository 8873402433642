import { get } from "@qtxr/utils";

import {
	traverse,
	collectLayout
} from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Group,
	Point,
	DataPoint
} from "../../apply-categorization";

const BarConfig = {
	name: "bar",
	collector: () => collectLayout((p: any) => {
		p.data.totals = p.data.nodes.map((node: Point | Group, idx: number) => {
			return traverse.points.reduce(node, (acc, point) => {
				return acc + get(p.data.points[idx], point.accessor).value;
			}, 0);
		});

		p.data.max = Math.max(...p.data.totals);

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const ds = p.dataset,
			w = ds.cWidth,
			h = ds.cHeight - 20,
			max = ds.data.max,
			space = 15,
			barWidth = (w - (space * (ds.data.nodes.length - 1))) / ds.data.nodes.length,
			groupLabel = ds.groupLabel,
			labelAllPoints = ds.labelAllPoints === "true";
		let x = 0,
			groupData = [] as DataPoint[];

		plot.forEach(({ node, style }) => {
			let y = h;

			plot.text(node.label)
				.x(x + barWidth / 2)
				.y(h + 14)
				.size(10)
				.weight(600)
				.anchor("middle")
				.baseline("middle")
				.fill(style.fullTheme.color)
				.inert();

			plot.forEachPoint(pt => {
				const height = (pt.value / max) * h,
					boundaryType = plot.getBoundaryType("nested");

				if (groupLabel && (boundaryType?.includes("start") || boundaryType?.includes("junction") || labelAllPoints))
					plot.pushBounding();
				if (groupLabel)
					groupData.push(pt.data);

				y -= height;

				plot.rect()
					.x(x)
					.y(y)
					.width(barWidth)
					.height(height)
					.fill(pt.color);

				if (!boundaryType?.includes("start")) {
					const separatorWidth = boundaryType?.includes("junction") ?
						1.5 :
						0.5;

					plot.line()
						.x1(x)
						.y1(y + height)
						.h(barWidth)
						.stroke(pt.style.fullTheme.graphBackground)
						.strokeWidth(separatorWidth)
						.stack(1)
						.inert();
				}

				if (groupLabel && (boundaryType?.includes("end") || labelAllPoints)) {
					const bounding = plot.popBounding(labelAllPoints);

					plot.text(groupLabel(groupData))
						.x(bounding.x + bounding.width / 2)
						.y(bounding.y + bounding.height / 2)
						.size(10)
						.weight(600)
						.anchor("middle")
						.baseline("middle")
						.shadow(style.fullTheme.textShadow)
						.fill(style.fullTheme.accentColor)
						.stack(2)
						.inert();

					groupData = [];
				}
			});

			x += space + barWidth;
		});

		plot.render();
	},
	dataset: {
		id: "bar",
		mode: "own",
		type: "bar",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default BarConfig;
