import StagedComponent, {
	scaled,
	mkSetterMaker
} from "./";

import {
	StyleParam,
	TextAnchor,
	AlignmentBaseline
} from "../../../../types/plot-svg";
import {stubString} from "../../common";

const ms = mkSetterMaker<StagedAnchor>();

export default class StagedAnchor extends StagedComponent {
	ownStyleParams = [
		{ key: "text", required: true },
		{ key: "href", required: true },
		{ key: "x", required: true },
		{ key: "y", required: true },
		{ key: "target" },
		{ key: "rel" },
		{ key: "font-size", publicName: "size", default: 12 },
		{ key: "text-anchor", publicName: "anchor" },
		{ key: "alignment-baseline", publicName: "baseline" },
		{ key: "font-weight", publicName: "weight" },
		{ key: "text-shadow", publicName: "shadow", target: "style" },
		{ key: "maxLength", enumerable: false }
	] as StyleParam[];

	open(text?: any, href?: string): this {
		return super.open(text, href);
	}

	text = ms("text", (text: any) => String(text));
	href = ms("href", (text: string) => text);
	x = ms("x", (x: number) => scaled(x));
	y = ms("y", (y: number) => scaled(y));
	target = ms("target", (target: string) => target);
	rel = ms("rel", (rel: string) => rel);
	size = ms("font-size", (size: number) => scaled(size));
	anchor = ms("text-anchor", (anchor: TextAnchor) => anchor);
	baseline = ms("alignment-baseline", (baseline: AlignmentBaseline) => baseline);
	weight = ms("font-weight", (weight: number | string) => weight);
	shadow = ms("text-shadow", (shadow: string) => shadow);
	maxLength = ms("maxLength", (maxLength: number) => maxLength);

	render() {
		this.mount(
			"a",
			"text",
			({ key, value, target, content, applyContent }) => {
				const a = target as SVGAElement,
					text = content as SVGTextElement;

				switch (key) {
					case "text":
						if (this.style.maxLength)
							text.textContent = stubString(value, this.style.maxLength);
						else
							text.textContent = value;
						break;

					case "href":
					case "target":
					case "rel":
						a.setAttribute(key, value);
						break;

					default:
						applyContent();
				}
			}
		);
	}
}
