import styled, { keyframes } from "styled-components";

interface LoadingIconProps {
	scale?: number;
	className?: string;
}

interface BarProps {
	index: number;
}

const containerAnimation = keyframes`
	to {
		transform: translateY(-2.5em);
	}
`;

const ANIMATIONS = [
	keyframes`
		from {
			transform: translateY(0);
		}

		to {
			opacity: 0;
			transform: translateY(-1em);
		}
	`,
	keyframes`
		from {
			opacity: 0.67;
			transform: scaleX(0.8);
		}

		to {
			opacity: 1;
			transform: scaleX(1);
		}
	`,
	keyframes`
		from {
			opacity: 0.33;
			transform: scaleX(0.5);
		}

		to {
			opacity: 0.67;
			transform: scaleX(0.8);
		}
	`,
	keyframes`
		from {
			opacity: 0;
			transform: scaleX(0.1);
		}

		to {
			opacity: 0.33;
			transform: scaleX(0.5);
		}
	`
];

const Wrapper = styled.figure<LoadingIconProps>`
	position: relative;
	transform: ${p =>
		typeof p.scale == "number" ?
			`scale(${p.scale})` :
			null
	};
`;

const Container = styled.div`
	position: relative;
	margin: 0 0 -2.5em 0;
	animation: ${containerAnimation} 1s linear infinite;
`;

const Bar = styled.div<BarProps>`
	width: 10em;
	height: 2em;
	margin-top: ${p => p.index > 0 ? "0.5em" : 0};
	transform-origin: 0 50%;
	border-radius: 0.5em;
	animation: ${p => ANIMATIONS[p.index]} 1s infinite;
	background: currentColor;
`;

const LoadingIcon = (props: LoadingIconProps) => (
	<Wrapper {...props}>
		<Container>
			<Bar index={0} />
			<Bar index={1} />
			<Bar index={2} />
			<Bar index={3} />
		</Container>
	</Wrapper>
);

export default LoadingIcon;
