import BarConfig from "./configs/bar";

import Base from "./base";

import {
	GraphProps,
	LabelResolver,
	GroupLabelResolver,
	TotalLabelResolver
} from "../../../types/viz";

interface BarGraphProps extends GraphProps {
	label?: LabelResolver;
	labelAllPoints?: "true" | "false";
	groupLabel?: GroupLabelResolver;
	totalLabel?: TotalLabelResolver;
}

const Bar = (props: BarGraphProps) => {
	const {
		label,
		labelAllPoints,
		groupLabel,
		totalLabel,
		...p
	} = props;

	return (
		<Base
			{...p}
			configs={BarConfig}
			store={{
				label,
				labelAllPoints,
				groupLabel,
				totalLabel,
			}}
		/>
	);
};

export default Bar;
