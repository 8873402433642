import {
	get,
	isObject
} from "@qtxr/utils";

import {
	stubString,
	collectLayout
} from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Group,
	DataPoint
} from "../../apply-categorization";

interface TableSection {
	name: string | null;
	group: Group | null;
	columns: AugmentedColumn[];
	rows: RawTableRow[];
}

interface RawTableRow {
	cells: RawTableCell[];
}

interface RawTableCell {
	column: AugmentedColumn;
	value: any;
	href: string | null;
	maxLength: number | null;
}

interface TableRow {
	type: TableRowType;
	filled: boolean;
	cells: TableCell[];
}

interface TableCell {
	width: number;
	value: string;
	weight: Weight;
	align: Alignment;
	href: string | null;
	maxLength: number | null;
}

interface CellInstruction {
	align?: Alignment;
	weight?: Weight;
}

export interface TableStore {
	columns: Column[];
	header?: Resolver;
	display?: Resolver;
	footer?: Resolver;
	fontSize?: Number;
	debug?: Boolean;
	rowBorders?: Boolean;
	themeColor?: string;
}

export interface Column {
	title: string;
	accessor: string;
	align?: Alignment;
	weight?: Weight;
	header?: Resolver;
	display?: Resolver;
	footer?: Resolver;
	maxLength?: number;
	hrefAccessor?: string;
}

interface AugmentedColumn extends Column {
	index: number;
}

export interface CellRuntime {
	value: any;
	index: number;
	section: TableSection;
	column: AugmentedColumn;
	columns: AugmentedColumn[];
	display: (value: any) => CellRuntime;
	print: (value: any) => CellRuntime;
	align: (align: Alignment) => CellRuntime;
	weight: (weight: Weight) => CellRuntime;
	instruction: CellInstruction;
	isCellRuntime: true;
}

type Weight = 400 | 600 | 700;
type TableRowType = "header" | "row" | "sub" | "footer";
type Resolver = ((runtime: CellRuntime) => string | CellRuntime) | null;
export type Alignment = "start" | "middle" | "end";

const TableConfig = {
	name: "table",
	collector: () => collectLayout((p: any) => {
		const dataPoints = p.data.categorized.data,
			rawColumns = p.dataset.columns as Column[],
			columns = [] as AugmentedColumn[],
			nested = p.data.categorized.groups.length > 0,
			sections = [] as TableSection[],
			rows = [] as TableRow[],
			columnBiases = [] as number[],
			font = window.getComputedStyle(p.inst.wrapper).font,
			canvas = document.createElement("canvas"),
			ctx = canvas.getContext("2d")!;
		let hasFooter = false;

		ctx.font = font;

		const resolveHref = (dp: DataPoint, column: AugmentedColumn) => {
			if (!column.hrefAccessor)
				return null;

			return get(dp, column.hrefAccessor).value;
		};

		rawColumns.forEach((column, idx) => {
			const newCol = {
				header: p.dataset.header,
				display: p.dataset.display,
				footer: p.dataset.footer,
				...column,
				index: idx
			} as AugmentedColumn;

			if (typeof newCol.header !== "function") {
				newCol.header = (rt) => {
					return rt
						.print(rt.value)
						.weight(700);
				};
			}

			hasFooter = hasFooter || Boolean(newCol.footer);
			columns.push(newCol);
		});

		if (nested) {
			let currentGroup = null as Group | null,
				currentSection = null as TableSection | null;

			for (const dp of dataPoints) {
				const group = get(dp, columns[0].accessor).trace[0];

				if (group !== currentGroup) {
					currentSection = {
						name: group.label,
						group,
						columns,
						rows: []
					};

					sections.push(currentSection);
					currentGroup = group;
				}

				const row = {
					cells: []
				} as RawTableRow;

				for (const column of columns) {
					row.cells.push({
						column,
						value: get(dp, column.accessor).value,
						href: resolveHref(dp, column),
						maxLength: typeof column.maxLength == "number" ?
							column.maxLength :
							null
					});
				}

				currentSection!.rows.push(row);
			}
		} else {
			const section = {
				name: null,
				group: null,
				columns,
				rows: []
			} as TableSection;

			sections.push(section);

			section.rows = (dataPoints as DataPoint[]).map(dp => ({
				cells: columns.map(column => ({
					column,
					value: get(dp, column.accessor).value,
					href: resolveHref(dp, column),
					maxLength: typeof column.maxLength == "number" ?
						column.maxLength :
						null
				}))
			}));
		}

		const cRuntime = {
			value: null,
			index: -1,
			section: sections[0],
			column: columns[0],
			columns,
			display: (value: any) => {
				if (typeof cRuntime.column.display !== "function") {
					cRuntime.value = String(value);
					return cRuntime;
				}

				return getCellValue(
					cRuntime.column.display,
					value,
					cRuntime.index,
					cRuntime.section
				);
			},
			print: (value: any) => {
				cRuntime.value = String(value);
				return cRuntime;
			},
			align: (align: Alignment) => {
				cRuntime.instruction.align = align;
				return cRuntime;
			},
			weight: (weight: Weight) => {
				cRuntime.instruction.weight = weight;
				return cRuntime;
			},
			instruction: {},
			isCellRuntime: true
		} as CellRuntime;

		const getCellValue = (
			resolver: Resolver,
			value: any,
			index: number,
			section: TableSection
		): CellRuntime => {
			cRuntime.value = value;
			cRuntime.index = index;
			cRuntime.section = section;
			cRuntime.column = columns[index];
			cRuntime.instruction = {};

			const resolved = resolver!(cRuntime);

			if (isObject(resolved) && (resolved as CellRuntime).isCellRuntime)
				cRuntime.value = (resolved as CellRuntime).value;
			else
				cRuntime.value = resolved;

			return cRuntime;
		};

		const pushRow = (row: Omit<TableRow, "cells">) => {
			rows.push({
				...row,
				cells: []
			});
		};

		const pushCell = (
			value: any,
			section: TableSection,
			href: string | null,
			maxLength: number | null
		) => {
			const row = rows[rows.length - 1],
				idx = row.cells.length,
				column = columns[idx];
			let resolver: Resolver | undefined,
				val = value,
				weight = column.weight || 400 as Weight,
				align = column.align || "start" as Alignment;

			switch (row.type) {
				case "header":
					resolver = column.header;
					break;

				case "footer":
					resolver = column.footer;
					break;

				default:
					resolver = column.display;
			}

			if (resolver) {
				const rt = getCellValue(resolver, value, idx, section);

				val = rt.value;
				weight = rt.instruction.weight || weight;
				align = rt.instruction.align || align;
			}

			val = String(val);

			const formattedVal = maxLength === null ?
					val :
					stubString(val, maxLength),
				width = ctx.measureText(formattedVal).width;

			columnBiases[idx] = Math.max(width, columnBiases[idx] || 0);

			row.cells.push({
				width,
				value: val,
				weight,
				align,
				href,
				maxLength
			});
		};

		for (const section of sections) {
			pushRow({
				type: "header",
				filled: nested
			});

			for (const column of columns)
				pushCell(column.title, section, null, null);

			section.rows.forEach((row, idx) => {
				pushRow({
					type: nested ? "sub" : "row",
					filled: !nested && idx % 2 === 0
				});

				for (const cell of row.cells)
					pushCell(cell.value, section, cell.href, cell.maxLength);
			});

			if (hasFooter) {
				pushRow({
					type: "footer",
					filled: false
				});

				for (const column of columns)
					pushCell(column.title, section, null, null);
			}
		}

		const biasTotal = columnBiases
				.reduce((total, bias) => total + bias, 0),
			weightedBiases = columnBiases
				.map(bias => bias / biasTotal);

		p.data.rows = rows;
		p.data.nested = nested;
		p.data.weightedBiases = weightedBiases;

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const rows = p.data.rows as TableRow[],
			weightedBiases = p.data.weightedBiases,
			padding = 0,
			columnGap = 15,
			columnPadding = 8,
			lineMargin = 5,
			ds = p.dataset,
			w = ds.cWidth - padding,
			h = ds.cHeight - padding,
			rowHeight = h / rows.length,
			cellWidths = w - columnGap * (weightedBiases.length - 1) - columnPadding * 2,
			theme = p.inst.config.style.fullTheme,
			fontSize = ds.fontSize || 11,
			debug = ds.debug,
			rowBorders = ds.rowBorders,
			themeColor = ds.themeColor;
		let y = padding,
			subLineOrigin = null as number | null;

		for (let i = 0, l = rows.length; i < l; i++) {
			const row = rows[i],
				nextRow = rows[i + 1];
			let x = padding;

			if (row.type === "sub" && subLineOrigin === null)
				subLineOrigin = y + lineMargin;

			if (row.type === "footer" || rowBorders) {
				plot.line()
					.x1(padding)
					.y1(y)
					.x2(w - padding)
					.y2(y)
					.stroke(theme.separatorColor)
					.strokeWidth(1.5);
			}

			if (debug) {
				console.log("row", row);
			}

			if (row.filled && !rowBorders) {
				plot.rect()
					.x(x)
					.y(y)
					.width(w)
					.height(rowHeight)
					.rx(3)
					.ry(3)
					.fill(theme.cardBackground);
			}

			if (themeColor && row.type === "header") {
				plot.rect()
				.x(x)
				.y(y)
				.width(w)
				.height(rowHeight)
				.rx(3)
				.ry(3)
				.fill(themeColor);
			}

			for (let j = 0, l2 = row.cells.length; j < l2; j++) {
				const cell = row.cells[j] as TableCell,
					subOffset = !j && subLineOrigin !== null ?
						12 :
						0;
				let cw = cellWidths * weightedBiases[j],
					tx = x + columnPadding;

				if (cell.align === "middle")
					tx += cw / 2;
				else if (cell.align === "end")
					tx += cw;

				let text;

				if (cell.href) {
					text = plot.anchor(cell.value, cell.href)
						.target("_blank")
						.rel("noreferrer noopener");
				} else
					text = plot.text(cell.value);

				if (cell.maxLength !== null)
					text.maxLength(cell.maxLength);

				const textColor = themeColor && themeColor !== theme.graphBackground && row.type === "header" ? "white" : (cell.href ? theme.accentAlt : theme.color)

				text
					.x(tx + subOffset)
					.y(y + rowHeight / 2 + 1)
					.size(fontSize)
					.weight(cell.weight)
					.baseline("middle")
					.anchor(cell.align || "start")
					.fill(textColor);

				x += (cw + columnGap);
			}

			y += rowHeight;

			if (subLineOrigin !== null && (!nextRow || nextRow.type === "footer" || nextRow.type === "header")) {
				plot.line()
					.x1(10)
					.y1(subLineOrigin)
					.x2(10)
					.y2(y - lineMargin)
					.stroke(theme.separatorColor)
					.strokeWidth(1.5);

				subLineOrigin = null;
			}
		}

		plot.render();
	},
	dataset: {
		id: "table",
		mode: "own",
		type: "table",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default TableConfig;