import { useState } from "react";
import styled from "styled-components";

import useDebounce from "../../hooks/use-debounce";

import { default as SiteBase } from "../base";

import Waterfall, {
	SelectionPayload,
	WaterfallEntries
} from "../../components/waterfall";
import Centered from "../../components/centered";
import ControlBar from "../../components/control-bar";

import {
	State,
	BaseRuntime
} from "../../types/common";
import { Entry } from "../../components/waterfall-box";
import { ControlUnion } from "../../types/control-bar";

interface BaseProps {
	children: any;
	waterfall: WaterfallEntries;
	controls?: ControlUnion<ControlRuntime>[];
	initialState?: State;
	onSelectionChange?: (payload: SelectionPayload) => void;
	// Pass-through for SiteBase
	flush?: boolean;
	limited?: boolean;
	className?: string;
}

interface ControlRuntime extends BaseRuntime {
	selectionEntries: SelectionEntry[];
}

interface SelectionEntry {
	name: string;
	selection: Entry[];
}

const ContentWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	overflow: hidden;
`;

const InnerContentWrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: hidden;
`;

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 15px 0 20px 0;
	border-radius: ${p => p.theme.borderRadius};
	overflow-y: auto;
`;

const Base = (props: BaseProps) => {
	const [selectionEntries, setSelectionEntries] = useState([] as SelectionEntry[]);
	const [selectionPayload, setSelectionPayload] = useState(null as SelectionPayload | null);

	const [debounce, dispatch] = useDebounce((value: SelectionPayload) => {
		setSelectionEntries(value.selection);
		setSelectionPayload(value);

		if (typeof props.onSelectionChange == "function")
			props.onSelectionChange(value);
	}, 500);

	// Dispatch change immediately for the first selection
	// change (called as the component is first rendered)
	const handleSelectionChange = (payload: SelectionPayload) => {
		if (selectionPayload)
			debounce(payload);
		else
			dispatch(payload);
	};

	const runtime = {
		state: {},
		selectionEntries
	} as ControlRuntime;

	let content;

	if (selectionPayload && selectionPayload.valid)
		content = props.children;
	else {
		content = (
			<Centered>
				{selectionPayload?.errorMessage}
			</Centered>
		);
	}

	return (
		<SiteBase
			flush={props.flush}
			limited={props.limited}
			className={props.className}
		>
			<ContentWrapper>
				<Waterfall
					entries={props.waterfall}
					initialState={props.initialState}
					onSelectionChange={handleSelectionChange}
				/>
				<InnerContentWrapper>
					<ControlBar
						controls={props.controls || []}
						runtime={runtime}
					/>
					<InnerContent>
						{content}
					</InnerContent>
				</InnerContentWrapper>
			</ContentWrapper>
		</SiteBase>
	);
};

export default Base;
