import { CANDIDATE_TYPES } from "../../data/constants";
import states from "../../data/states.json";

import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import { useAppSelector } from "../../state/hooks";

import Base from "../base";

import ContentCard from "../../components/content-card";
import ActionTable from "../../components/action-table";

import { ColumnCell } from "../../types/data-table";
import {request} from "../../utils";

const Campaigns = () => {
	const {
		parties,
		offices,
		races,
		agencies,
		teams
	} = useAppSelector(state => state.data);

	const columns: ColumnCell[] = [
		{
			name: "race",
			title: "Race",
			type: "choice",
			accessor: "race",
			fit: "shrink",
			editable: true,
			config: {
				options: races,
				labelAccessor: "race_name",
				valueAccessor: "id"
			}
		},
		{
			name: "state",
			type: "choice",
			title: "State",
			accessor: "state",
			index: "lexical",
			fit: "shrink",
			editable: true,
			config: {
				options: states,
				labelAccessor: "state",
				valueAccessor: "state"
			}
		},
		{
			name: "officeName",
			type: "choice",
			title: "Office Name",
			accessor: "office_name",
			config: {
				options: offices,
				labelAccessor: "full_name",
				valueAccessor: "id"
			},
			editable: true
		},
		{
			name: "candidateName",
			title: "Candidate",
			accessor: "candidateName",
			editable: true
		},
		{
			name: "shortName",
			title: "Short Name",
			accessor: "shortName",
			editable: true
		},
		{
			name: "candidateType",
			type: "choice",
			title: "Type",
			accessor: "candidateType",
			editable: true,
			config: {
				options: CANDIDATE_TYPES,
				labelAccessor: "label",
				valueAccessor: "value"
			}
		},
		{
			name: "party",
			type: "choice",
			title: "Party",
			accessor: "party",
			editable: true,
			config: {
				options: parties,
				labelAccessor: "full_name",
				valueAccessor: "id"
			}
		},
		{
			name: "team",
			title: "Team",
			accessor: "team",
			editable: true,
			type: "choice",
			config: {
				options: teams,
				labelAccessor: "full_name",
				valueAccessor: "id"
			}
		},
		{
			name: "team",
			title: "Team",
			accessor: "team",
			editable: true,
			// type: "choice",
			config: {
				options: teams,
				labelAccessor: "full_name",
				valueAccessor: "id"
			}
		},
		{
			name: "agency",
			title: "Agency",
			accessor: "agency",
			type: "choice",
			config: {
				options: agencies,
				labelAccessor: "full_name",
				valueAccessor: "id"
			},
			editable: true
		},
		{
			name: "cmagId",
			title: "CMAG ID",
			accessor: "cmag_id",
			editable: true
		}
	];

	const actions = useTableActions({
		addLabel: "Add Campaign",
		add: () => ({
			race: 1,
			state: "US",
			office_name: "president",
			candidate_name: "",
			short_name: "",
			type: "Candidate",
			party: 1,
			team: 1,
			agency: 1,
			cmag_id: "",
		}),
		createEach: row => {
			return request({
				url: "/campaigns/create",
				body: {
					...row,
				}
			});
		},
		modifyEach: row => {
			return request({
				url: `/campaigns/${row.id}`,
				body: row,
				method: "PUT"
			})
		},
		deleteEach: row => {
			return request({
				url: `/campaigns/${row.id}`,
				method: "DELETE"
			})
		}
	});

	const props = useRemoteActionProps({
		request: "/campaigns/list",
		columns: columns,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} />
			</ContentCard>
		</Base>
	);
};

export default Campaigns;
