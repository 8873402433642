import {
	get,
	clone,
	isObj,
	casing,
	inject,
	forEach,
	mergesort,
	roundToLen,
	isPrimitive,
	isArrayLike
} from "@qtxr/utils";

import I18N from "../../data/i18n";

import applyLayout, { Layout } from "./apply-layout";

import {
	Group,
	Point,
	DataPoint
} from "./apply-categorization";
import {
	Tooltip,
	TooltipTemplate
} from "../../types/viz";

const BASE_CONFIG = {
	graphId: "standard",
	config: {
		lockViewport: true,
		fixedTooltips: true,
		style: {},
		styleAlt: {}
	},
	i18n: I18N,
	getter: {
		processors: {
			collectors: {},
			plotters: {}
		}
	},
	graphData: {
		standard: {
			template: "standard",
			modules: {
				graph: {
					store: {
						delta: null
					},
					defaults: {
						minHeight: 100,
						maxHeight: 800,
						padding: {
							t: 10,
							r: 0,
							b: 10,
							l: 0
						}
					},
					defaultClone: {
						accessors: {
							dataset: false
						},
						canvasType: false
					}
				},
				overlay: true,
				loader: true,
				tooltip: []
			}
		}
	}
};

const mkGraphConfig = (...args: any[]) => {
	const config = clone(BASE_CONFIG);

	for (let i = 0, l = args.length; i < l; i++) {
		const arg = args[i];

		if (typeof arg == "string") {
			i++;

			if (!isObj(args[i]))
				throw new Error("Failed to fill in: keyed input data must be coupled with an object");

			const target = get(config, arg);
			inject(target, args[i], "override");
		} else if (arg)
			inject(config, normalizeConfig(arg), "override");
	}

	const graphConf = config.graphData.standard.modules.graph;
	graphConf.datasets = mergeDatasets(graphConf.datasets);

	return config;
};

const normalizeConfig = (config: any): any => {
	const outConf = inject(config, {
		getter: {
			processors: {
				collectors: {},
				plotters: {}
			}
		},
		graphData: {
			standard: {
				modules: {
					graph: {
						datasets: []
					}
				}
			}
		}
	}, "cloneTarget");

	const name = outConf.name,
		graphConf = outConf.graphData.standard.modules.graph;
	delete outConf.name;

	if (outConf.collector) {
		if (!name)
			throw new Error("Cannot normalize config: no name is specified for collector");

		outConf.getter.processors.collectors[name] = outConf.collector;
		delete outConf.collector;
	}

	if (outConf.plotter) {
		if (!name)
			throw new Error("Cannot normalize config: no name is specified for plotter");

		outConf.getter.processors.plotters[name] = outConf.plotter;
		delete outConf.plotter;
	}

	if (outConf.modules) {
		inject(outConf.graphData.standard.modules, outConf.modules, "override");
		delete outConf.modules;
	}

	if (outConf.dataset || outConf.datasets) {
		const dsIn = outConf.datasets || outConf.dataset,
			ds = Array.isArray(dsIn) ?
				dsIn :
				[dsIn];

		graphConf.datasets = graphConf.datasets.concat(ds);

		delete outConf.dataset;
		delete outConf.datasets;
	}

	if (outConf.store) {
		graphConf.store = outConf.store;
		delete outConf.store;
	}

	if (outConf.defaults) {
		graphConf.defaults = outConf.defaults;
		delete outConf.defaults;
	}

	if (outConf.defaultClone) {
		graphConf.defaultClone = outConf.defaultClone
		delete outConf.defaultClone;
	}

	return outConf;
};

const mergeDatasets = (datasets: any[]): any[] => {
	const out = [] as any[],
		map = {};

	for (const dataset of datasets) {
		if (map.hasOwnProperty(dataset.id))
			inject((map as any)[dataset.id], dataset);
		else {
			const cloned = clone(dataset);
			(map as any)[dataset.id] = cloned;
			out.push(cloned);
		}
	}

	return out;
};

const normalizeDictData = (data: any, options: any = {}) => {
	const out = [] as any[],
		arrayLike = isArrayLike(data);
	let total = 0,
		min = Infinity,
		max = -Infinity,
		onlyPrimitives = 1;

	forEach(data, (value: any, key: string) => {
		const item = arrayLike ?
			value :
			{
				key: options.casing ?
					casing(key).to(options.casing) :
					key,
				value,
				perc: null,
				peekedValue: null
			};

		let peekedValue;

		if (arrayLike)
			peekedValue = value && value.peekedValue;
		else {
			peekedValue = options.peek ?
				get(item.value, options.peek) :
				value;
		}

		if (typeof peekedValue == "number") {
			total += peekedValue;
			if (peekedValue > max)
				max = peekedValue;
			if (peekedValue < min)
				min = peekedValue;
		}

		onlyPrimitives &= isPrimitive(peekedValue);

		item.peekedValue = peekedValue;
		out.push(item);
	});

	for (let i = 0, l = out.length; i < l; i++) {
		const item = out[i];

		if (typeof item.peekedValue == "number")
			item.perc = roundToLen((out[i].value / total) * 100, 4);
	}

	return {
		data: onlyPrimitives && options.sort !== false ?
			mergesort(out, (a: any, b: any) => a.peekedValue < b.peekedValue ? 1 : -1) :
			out,
		total,
		min,
		max
	};
};

const collectLayout = (callback: (p: any) => any) => {
	return (p: any) => {
		if (!p.index)
			return p.data;

		let layout = p.data.points as Layout;
		if (!layout || !layout.isLayout)
			layout = applyLayout(layout || []);

		p.data.points = layout.data;
		p.data.categorized = layout.categorized;
		p.data.legends = layout.legends;
		p.data.nodes = layout.nodes;
		p.data.endIdx = p.data.points.length - 1;
		p.data.kpi = layout.kpi;

		return callback(p);
	};
};

const collectTooltips = (...tooltips: (Tooltip | TooltipTemplate)[]) => {
	const tips = [],
		templates = [] as TooltipTemplate[];

	for (let i = 0, l = tooltips.length; i < l; i++) {
		const tip = tooltips[i],
			defaultTip = {
				symbol: "square: fill"
			} as any;

		const outTip = typeof tip == "function" ?
			defaultTip :
			{ ...defaultTip, ...tip };

		const template = typeof tip == "function" ?
			tip :
			tip.template;

		outTip.template = `{{ tip${i} }}`;

		tips.push(outTip);
		templates.push(template);
	}

	return {
		getTooltipData: (evt: any, payload: any, feedback: any) => {
			const target = evt.target,
				index = target.getAttribute("data-index"),
				accessor = target.getAttribute("data-accessor");

			if (!index) {
				feedback.cancelRender();
				return;
			}

			const dp = payload.dataset.data.points[Number(index)];

			const data = get(
				dp,
				accessor,
				null
			) as DataPoint | null;

			if (!data) {
				feedback.cancelRender();
				return;
			}

			const color = (data.trace[data.trace.length - 1] as Point).color;

			const out = {
				color,
				fill: color
			} as any;

			templates.forEach((template, idx) => {
				out[`tip${idx}`] = template(data, payload);
			});

			return out;
		},
		tips
	};
};

interface TraverseNodeSplit<CallbackNode> {
	(node: Point | Group, callback: (node: CallbackNode) => void): void;
	reduce: (
		node: Point | Group,
		callback: (accumulator: any, node: CallbackNode) => any,
		initialValue?: any
	) => any
}

function traverse<CallbackNode = Point | Group>(
	node: Point | Group,
	callback: (node: CallbackNode) => void,
	onPoints?: boolean,
	onGroups?: boolean
) {
	const trv = (n: Point | Group) => {
		if ((n as Point).isPoint) {
			if (onPoints)
				callback(n as any);
			return;
		} else if (onGroups)
			callback(n as any);

		if ((n as Group).groups) {
			for (const group of (n as Group).groups)
				trv(group);
		}

		if ((n as Group).points) {
			for (const point of (n as Group).points)
				trv(point);
		}
	}

	trv(node);
}

const points: TraverseNodeSplit<Point> = (
	node: Point | Group,
	callback: (node: Point) => void
) => {
	traverse(node, callback, true, false);
};

traverse.points = points;

const groups: TraverseNodeSplit<Group> = (
	node: Point | Group,
	callback: (node: Group) => void
) => {
	traverse(node, callback, false, true);
};

traverse.groups = groups;

traverse.reduce = function map<CallbackNode>(
	node: Point | Group,
	callback: (accumulator: any, node: CallbackNode) => any,
	initialValue?: any,
	onPoints?: boolean,
	onGroups?: boolean
) {
	let acc = initialValue,
		shouldSkip = initialValue === undefined,
		skipping = false,
		skipped = true;

	traverse(
		node,
		(n: CallbackNode) => {
			if (skipping) {
				skipping = false;
				skipped = true;
			} else if (shouldSkip) {
				acc = n;
				skipping = true;
				shouldSkip = false;
				skipped = false;
			}

			if (skipped)
				acc = callback(acc, n);
		},
		onPoints,
		onGroups
	);

	if (shouldSkip)
		throw new TypeError("Reduce with no initial value")

	return acc;
}

points.reduce = (
	node: Point | Group,
	callback: (accumulator: any, node: Point) => any,
	initialValue?: any
) => {
	return traverse.reduce(node, callback, initialValue, true, false);
};

groups.reduce = (
	node: Point | Group,
	callback: (accumulator: any, node: Group) => any,
	initialValue?: any
) => {
	return traverse.reduce(node, callback, initialValue, false, true);
};

const stubString = (string: string, maxLength: number) => {
	if (string.length < maxLength)
		return string;

	const stub = string.substring(0, maxLength - 3);

	if (stub[stub.length - 1] === ".")
		return stub.replace(/\.+^/, "...");

	return stub + "...";
};

export {
	mkGraphConfig,
	normalizeDictData,
	collectLayout,
	collectTooltips,
	traverse,
	stubString
};
