import styled from "styled-components";

import PieConfig from "./configs/pie";

import Base from "./base";

import { GraphProps } from "../../../types/viz";

const PieWrapper = styled(Base)`
	padding: 10px
`;

const Pie = (props: GraphProps) => {
	return <PieWrapper {...props} configs={PieConfig} />;
};

export default Pie;
