import { useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import Icon from "../icon";

import { InputProps } from "../../types/inputs";

interface MultiProps extends InputProps {
	value: string[];
	readOnly?: boolean;
}

interface EntryProps {
	onDelete: () => void;
	readOnly?: boolean;
	children?: any;
}

interface MultiDropProps {
	dropProps: DropProps;
}

interface DropProps {
	selection: string[];
	add: (value: string) => void;
	delete: (value: string) => void;
}

const MultiWrapper = styled.div`
	position: relative;
`;

const MultiContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	min-height: 60px;
	max-height: 100px;
	background: ${p => p.theme.cardBackground};
	border: ${p => p.theme.inputBorder};
	border-radius: ${p => p.theme.borderRadius};
	overflow: auto;
	padding: 5px 0 0 5px;
`;

const EntryWrapper = styled.div`
	display: flex;
	align-items: center;
	max-width: 50%;
	height: 28px;
	padding: 3px 8px;
	margin: 0 5px 5px 0;
	background: ${p => p.theme.darkBackground};
	color: ${p => p.theme.subtle};
	border-radius: ${p => p.theme.borderRadius};
`;

const EntryContent = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const EntryDeleteButton = styled.button`
	flex-shrink: 0;
	padding: 0 5px;
	width: 20px;
	height: 20px;
	border: none;
	outline: none;
	margin: 0 -3px 0 5px;
	background: transparent;
	color: inherit;
	cursor: pointer;
`;

const Entry = (props: EntryProps) => {
	const deleteButton = !props.readOnly ? (
		<EntryDeleteButton type="button" onClick={props.onDelete}>
			<Icon name="thin-cross" />
		</EntryDeleteButton>
	) : null;

	return (
		<EntryWrapper>
			<EntryContent>{props.children}</EntryContent>
			{deleteButton}
		</EntryWrapper>
	);
};

const regexEmail = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+/;

const MultiDrop = (props: MultiDropProps) => {
	const [value, setValue] = useState("");

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (
			e.key === "Enter" ||
			e.key === "Tab" ||
			e.key === " " ||
			e.key === ","
		) {
			const matches = value.match(regexEmail);
			if (matches && matches.length > 0) {
				props.dropProps.add(matches[0]);
				setValue("");
			}
		}
	};

	return (
		<input
			type="text"
			value={value}
			onChange={e => {
				setValue(e.target.value);
			}}
			onKeyDown={handleKeyDown}
		/>
	);
};

const MultiInput = (props: MultiProps) => {
	const ctx = useFormikContext();

	const name = props.name!,
		value = (ctx.values as any)[props.name!] as string[];

	const dispatchDelete = (index: number) => {
		const entries = value.slice();
		entries.splice(index, 1);
		ctx.setFieldValue(name, entries);
	};

	const entries = value.map((v, i) => (
		<Entry
			key={v}
			readOnly={props.readOnly}
			onDelete={() => dispatchDelete(i)}
		>
			{v}
		</Entry>
	));

	const dropProps = {
		selection: value,
		add: (v: string) => {
			ctx.setFieldValue(name, [...value, v]);
		},
		delete: (v: string) => {
			const idx = value.indexOf(v);
			if (idx === -1) return;

			const entries = value.slice();
			entries.splice(idx, 1);
			ctx.setFieldValue(name, entries);
		}
	};

	return (
		<MultiWrapper onClick={evt => evt.stopPropagation()}>
			<MultiContent>
				{entries}
				<MultiDrop dropProps={dropProps} />
			</MultiContent>
		</MultiWrapper>
	);
};

export default MultiInput;
