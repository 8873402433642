import {
	set,
	inject
} from "@qtxr/utils";

import useStateCapsule from "./use-state-capsule";

import {
	State,
	PartitionedState
} from "../types/common";

interface PartitionedStateModifier<M> {
	(stateOrAccessor: State | StateUpdater | string, value?: any): void;
	_at: string | null;
	at: (key: string) => PartitionedStateModifier<M>;
}

export interface PartitionedStateSetter extends PartitionedStateModifier<PartitionedStateSetter> {
	gently: GentlePartitionedStateSetter;
}

type GentlePartitionedStateSetter = PartitionedStateModifier<GentlePartitionedStateSetter>;

type StateUpdater = (pendingState: State, currentState: State) => State;
type Modifier = (key: string, stateOrAccessor: State | StateUpdater | string, value?: any) => void;
type Scheduler = (pendingState: PartitionedState | null) => PartitionedState;

const usePartitionedStateDispatch = (
	currentState: PartitionedState,
	dispatcher: (state: PartitionedState) => void
): PartitionedStateSetter => {
	const state = useStateCapsule({
		pendingState: null as PartitionedState | null
	});

	const schedule = (
		key: string,
		scheduler: Scheduler
	) => {
		const { pendingState } = state.get();

		if (!pendingState) {
			requestAnimationFrame(() => {
				const result = inject(
					currentState,
					state.get().pendingState,
					"override|cloneTarget"
				);

				state.set({
					pendingState: null
				});

				dispatcher(result);
			});
		}

		state.set({
			pendingState: scheduler(pendingState)
		});
	};

	const dispatchSet = (
		key: string,
		pendingState: PartitionedState | null,
		injectOptions: any,
		stateOrAccessor: State | StateUpdater | string,
		value?: any
	): PartitionedState => {
		let state;

		if (typeof stateOrAccessor == "string") {
			const val = typeof value == "function" ?
				value(pendingState || {}, currentState) :
				value;

			state = {};
			set(state, stateOrAccessor, val);
		} else if (typeof stateOrAccessor == "function")
			state = stateOrAccessor(pendingState || {}, currentState);
		else
			state = stateOrAccessor;

		state = {
			[key]: state
		};

		if (!pendingState)
			return state;

		return inject(pendingState, state, injectOptions);
	};

	const setter: Partial<PartitionedStateSetter> = wrapModifier(
		(
			key: string,
			stateOrAccessor: State | StateUpdater | string,
			value?: any
		) => {
			const scheduler: Scheduler = pendingState => {
				return dispatchSet(
					key,
					pendingState,
					"override|cloneTarget",
					stateOrAccessor,
					value
				);
			};

			schedule(key, scheduler);
		}
	);

	setter.gently = wrapModifier(
		(
			key: string,
			stateOrAccessor: State | StateUpdater | string,
			value?: any
		) => {
			const scheduler: Scheduler = pendingState => {
				return dispatchSet(
					key,
					pendingState,
					"cloneTarget",
					stateOrAccessor,
					value
				);
			};

			schedule(key, scheduler);
		}
	);

	return setter as PartitionedStateSetter;
};

function wrapModifier<M>(modifier: Modifier): PartitionedStateModifier<M> {
	const wrapper: PartitionedStateModifier<M> = (
		stateOrAccessor: State | StateUpdater | string,
		value?: any
	) => {
		modifier(
			wrapper._at || "",
			stateOrAccessor,
			value
		);
	};

	wrapper._at = null;

	wrapper.at = (key: string) => {
		wrapper._at = key;
		return wrapper;
	};

	return wrapper;
}

export default usePartitionedStateDispatch;
