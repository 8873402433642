import { useEffect, useMemo, useState } from "react";
import styled, { withTheme } from "styled-components";
import { GraphBox, WeeklyGrp } from "../../components/viz";
import useGraphBox from "../../hooks/use-graph-box";
import useGraphConfigCollection, {
	GraphConfigCollection
} from "../../hooks/use-graph-config-collection";
import { setSupplyError, supplyParties } from "../../state/features/data";
import { useAppDispatch } from "../../state/hooks";
import { QuerySettings } from "../../types/reports";
import { AugmentedRequestConfig } from "../../types/utils";
import { request } from "../../utils";
import { DOWNLOAD_PDF_DIV_ID } from "../../utils/download-pdf";
import { propsAndQueryParamsProxy } from "../../utils/standalone-utils";
import { getDateRange } from "./daily-snapshot";

interface GraphsProps {
	querySettings: QuerySettings;
	collection: GraphConfigCollection;
	theme: any;
}

const PageTitle = styled.div`
	font-size: 2em;
`;

const RaceTitle = styled.div`
	font-size: 1.5em;
	line-height: 2em;
`;

const Title = styled.div`
	font-size: 0.75em;
	font-weight: bold;
	margin: 2em 0;
	text-align: center;
	width: 100%;
`;

const WeeklyGRPGraphBox = styled(GraphBox)`
	height: 100%;
`;

export function parseList(campaigns: any[] | string | null | undefined) {
	if (Array.isArray(campaigns) && campaigns.length > 0) return campaigns;
	if (typeof campaigns === "string")
		return campaigns.split(",").map(key => ({ key }));

	return null;
}

function getCampaigns(params: any) {
	const campaignsFromQuery: any[] | null = parseList(params.campaigns);

	if (campaignsFromQuery) {
		return campaignsFromQuery;
	}
	return [];
}

function getTeams(params: any) {
	const teamsFromQuery: any[] | null = parseList(params.teams);

	if (teamsFromQuery) {
		return teamsFromQuery;
	}
	return [];
}

const Graphs = withTheme((props: GraphsProps) => {
	const { selections } = props.querySettings.selectionPayload!,
		dateRange = props.querySettings.dateRange,
		collection = props.collection,
		raceId = selections.raceId;

	const [numGraphs, setNumGraphs] = useState(0);

	const selectedCampaigns = selections.campaigns
		? (selections.campaigns as any[])
		: undefined;
	const campaignsQuery = selectedCampaigns
		? selectedCampaigns.map(c => c.key)
		: undefined;

	const selectedTeams = selections.teams
		? (selections.teams as any[])
		: undefined;
	const teamsQuery = selectedTeams
		? selectedTeams.map(c => c.key)
		: undefined;

	const mode = selections.mode;

	const colors = props.theme.graphs.palettes.colors;

	const query = {
		range: dateRange.map(ts => new Date(ts).toISOString()).join(),
		campaigns: campaignsQuery,
		teams: teamsQuery,
		mode
	};

	const mkRequest = (
		url: string,
		process?: (data: any) => any
	): AugmentedRequestConfig => {
		return {
			url: `/races/${raceId}/graphs${url}`,
			query,
			cacheHash: props.querySettings.hash,
			process
		};
	};

	const weeklyGrp = useGraphBox({
		graph: WeeklyGrp,
		title: "",
		getHeight: () => numGraphs * 200 + 80,
		request: mkRequest(
			"/spending/weekly_grp_by_market",
			(d: any) => {
				const maxNumGraphs = Object.values(d).reduce(
					(max: number, datesArr: any) => {
						datesArr.forEach(
							(entry: any) => {
								const markets =
									entry?.value;
								if (markets) {
									max =
										Math.max(
											Object.keys(
												markets
											)
												.length,
											max
										);
								}
							}
						);
						return max;
					},
					0
				);
				setNumGraphs(maxNumGraphs);

				return d;
			}
		),
		box: WeeklyGRPGraphBox,
		tooltips: d => d.label,
		layout: {
			legends: {
				level: 0
			},
			colors: colors,
			// valueMap: {
			// 	date: "x",
			// 	value: "y"
			// },
			groupLevel: -1
		},
		track: collection.track("weekly-grp")
	});

	return weeklyGrp;
});

const Content = (props: any) => {
	const params = propsAndQueryParamsProxy(props);

	const dateRange = getDateRange(params);
	const raceId: any = params.race_id;
	const campaigns: any = getCampaigns(params);
	const teams: any = getTeams(params);
	const mode = params.mode || "grp";

	const [raceName, setRaceName] = useState<string>("");

	useEffect(() => {
		if (raceId) {
			const raceRequest = request({
				url: `/races/${raceId}`
			});
			Promise.all([raceRequest]).then(([raceResponse]) => {
				if (!raceResponse.success) return;
				const name = `${raceResponse.data.race_name} ${raceResponse.data.year}`;
				setRaceName(name);
				props.onSetRaceName(name);
			});
		}
	}, [raceId]);

	const fallbackCollection = useGraphConfigCollection();
	const collection = props.collection || fallbackCollection;

	const asOf = useMemo(() => {
		const date = new Date();
		return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
	}, []);

	return (
		<div style={{ overflow: "auto" }} id={DOWNLOAD_PDF_DIV_ID}>
			<Title>
				<PageTitle>Weekly Breakdown</PageTitle>
				<RaceTitle>{raceName}</RaceTitle>
				as of {asOf}
			</Title>
			{raceId && (campaigns || teams) && (
				<Graphs
					querySettings={{
						dateRange,
						hash: 0,
						normalizedDateRange: dateRange,
						selectionPayload: {
							errorMessage: "",
							errors: [],
							selection: [],
							selections: {
								raceId,
								campaigns,
								teams,
								mode
							},
							valid: true
						}
					}}
					collection={collection}
				/>
			)}
		</div>
	);
};

const WeeklyGrpStandalone = (props: any) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const req = async (
			url: string,
			dispatcher: (action: any) => any
		) => {
			const response = await request(url);
			if (response.success)
				dispatch(dispatcher(response.data));
			else if (localStorage.getItem("debugging") === "true")
				dispatch(dispatcher([]));
			else dispatch(setSupplyError(response.errorMessage!));
		};

		req("/parties/list", supplyParties); // required for party color themes
	}, []);

	return <Content {...props} />;
};

export default WeeklyGrpStandalone;
