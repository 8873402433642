import {
	useMemo,
	useState,
	useEffect
} from "react";
import styled from "styled-components";

import Dropdown, { DropdownOption } from "./inputs/dropdown";
import WaterfallBox, {
	Entry,
	WaterfallBoxProps
} from "./waterfall-box";

import { State } from "../types/common";

export interface WaterfallProps {
	entries: WaterfallEntries;
	initialState?: State;
	onSelectionChange?: (payload: SelectionPayload) => void;
}

interface AugmentedWaterfallBoxProps extends WaterfallBoxProps {
	validate?: (entry: ValidationPayload) => string | null | undefined;
}

interface WaterfallSplitter {
	target: string;
	options: DropdownOption[];
	map: WaterfallBoxPropsMap;
}

interface WaterfallBoxPropsMap {
	[key: string]: AugmentedWaterfallBoxProps;
}

interface SelectionEntry {
	name: string;
	selection: Entry[];
	box: AugmentedWaterfallBoxProps;
}

interface Selections {
	[key: string]: Entry[];
}

interface ErrorEntry {
	errorMessage: string;
	box: AugmentedWaterfallBoxProps;
}

export interface SelectionPayload {
	valid: boolean;
	selection: SelectionEntry[];
	selections: Selections;
	errors: ErrorEntry[];
	errorMessage: string | null;
}

export interface ValidationPayload {
	entry: SelectionEntry;
	state: State;
	selection: SelectionEntry[];
}

type WaterfallEntry = AugmentedWaterfallBoxProps | WaterfallSplitter;
export type WaterfallEntries = (AugmentedWaterfallBoxProps | AugmentedWaterfallBoxProps[])[];

const WaterfallWrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	gap: 15px;
	margin-right: 10px;
	padding: 0 20px 20px 0;
	overflow-y: auto;
`;

const WaterfallDropdown = styled(Dropdown)`
	border: none;
`;

const Waterfall = (props: WaterfallProps) => {
	const [state, setState] = useState(props.initialState || {} as State);

	const rawEntries = useMemo(
		() => {
			return props.entries.map(entry => {
				if (!Array.isArray(entry))
					return wrapEntry(entry);

				const map = {} as WaterfallBoxPropsMap,
					options = [] as DropdownOption[];

				for (const e of entry) {
					map[e.name] = wrapEntry(e);
					options.push({
						value: e.name,
						label: e.title
					});
				}

				return {
					target: entry[0].name,
					options,
					map
				} as WaterfallSplitter;
			}) as WaterfallEntry[];
		},
		[props.entries]
	);

	const [entries, setEntries] = useState(rawEntries);

	useEffect(
		() => {
			if (typeof props.onSelectionChange != "function")
				return;

			const payload = {
				valid: true,
				selection: [],
				selections: {},
				errors: [],
				errorMessage: null
			} as SelectionPayload;

			for (const entry of entries) {
				const e = (entry as WaterfallSplitter).target ?
					(entry as WaterfallSplitter).map[(entry as WaterfallSplitter).target] :
					entry as AugmentedWaterfallBoxProps;

				const selectionEntry = {
					name: e.name,
					selection: e.selection,
					box: e
				} as SelectionEntry;

				payload.selection.push(selectionEntry);
				payload.selections[e.name] = e.selection!;
			}

			for (const entry of payload.selection) {
				if (typeof entry.box.validate != "function")
					continue;

				const res = entry.box.validate({
					entry,
					state,
					selection: payload.selection
				}) as string | null | undefined;

				if (typeof res == "string") {
					payload.errors.push({
						errorMessage: res,
						box: entry.box
					});
				}
			}

			if (payload.errors.length) {
				payload.valid = false;
				payload.errorMessage = payload.errors[0].errorMessage;
			}

			props.onSelectionChange(payload);
		},
		[entries]
	);

	const updateEntrySelection = (name: string, selection: Entry[]) => {
		setEntries(es => {
			return es.map(e => {
				if (
					(e as WaterfallSplitter).target &&
					(e as WaterfallSplitter).map.hasOwnProperty(name)
				) {
					return {
						...e,
						map: {
							...(e as WaterfallSplitter).map,
							[name]: {
								...(e as WaterfallSplitter).map[name],
								selection
							}
						}
					};
				}

				if ((e as AugmentedWaterfallBoxProps).name === name) {
					return {
						...e,
						selection
					};
				}

				return e;
			}) as WaterfallEntry[];
		});
	};

	const updateNav = (target: string, entry: WaterfallEntry) => {
		const outEntries = entries.map(e => {
			if (e !== entry)
				return e;

			return {
				...e,
				target
			};
		});

		setEntries(outEntries);
	};

	const children = [] as JSX.Element[];
	let key = 0;

	for (const entry of entries) {
		let e = entry as AugmentedWaterfallBoxProps;

		if ((entry as WaterfallSplitter).target) {
			children.push(
				<WaterfallDropdown
					key={key++}
					value={(entry as WaterfallSplitter).target}
					options={(entry as WaterfallSplitter).options}
					onChange={evt => updateNav(evt.target.value, entry)}
				/>
			);

			e = (entry as WaterfallSplitter).map[(entry as WaterfallSplitter).target];
		}

		e = {
			...e,
			state
		};

		const onStateChange = e.onStateChange;
		e.onStateChange = (s, runtime) => {
			setState(s);

			if (typeof onStateChange == "function")
				onStateChange(s, runtime);
		};

		const onSelectionChange = e.onSelectionChange;
		e.onSelectionChange = (selection, runtime) => {
			updateEntrySelection(e.name, selection);

			if (
				typeof onSelectionChange == "function" &&
				(selection.length || !e.selection || e.selection.length)
			)
				onSelectionChange(selection, runtime);
		};

		children.push(
			<WaterfallBox
				{...e as AugmentedWaterfallBoxProps}
				key={e.name}
			/>
		);
	}

	return (
		<WaterfallWrapper>
			{children}
		</WaterfallWrapper>
	);
};

const wrapEntry = (entry: AugmentedWaterfallBoxProps): AugmentedWaterfallBoxProps => {
	return {
		...entry,
		selection: entry.selection || []
	};
};

export default Waterfall;
