import useStateCapsule from "./use-state-capsule";

interface ProxyFunctions {
	[key: string | symbol]: ProxyFunction;
}

interface ProxyFunction {
	(...args: any[]): any;
	isProxyFunction: true;
}

interface ConfigBase {
	[key: string | symbol]: any;
}

// Replaces config methods with static proxies,
// leaving config objects easily differentiable
function useProxiedConfig<Config extends ConfigBase>(
	config: Config
): Config {
	const proxies = useStateCapsule({} as ProxyFunctions);
	const currentConfig = useStateCapsule(config, true);

	const outConfig = {} as Config;
	let newProxies = null as ProxyFunctions | null;

	const addProperty = (key: string | symbol) => {
		if (!config.hasOwnProperty(key))
			return;

		const entry = config[key];

		if (typeof entry == "function" && !(entry as any).isProxyFunction) {
			let p = proxies.get()[key];

			if (!p) {
				const newProxy = (...args: any[]) => {
					const fn = currentConfig.get()[key] as any;
					return fn(...args);
				};
				newProxy.isProxyFunction = true;

				newProxies = newProxies || {};
				newProxies[key] = newProxy as ProxyFunction;
			}

			(outConfig as ConfigBase)[key] = p as any;
		} else
			(outConfig as ConfigBase)[key] = entry;
	};

	for (const k in config)
		addProperty(k);

	for (const sym of Object.getOwnPropertySymbols(config))
		addProperty(sym);

	if (newProxies) {
		proxies.set({
			...proxies.get(),
			...newProxies
		});
	}

	return outConfig;
}

export default useProxiedConfig;
