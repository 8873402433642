import TableConfig from "./configs/table";

import Base from "./base";

import { GraphProps } from "../../../types/viz";
import { TableStore } from "./configs/table";

interface TableGraphProps extends GraphProps {
	store: TableStore;
}

const Table = (props: TableGraphProps) => {
	return <Base {...props} configs={TableConfig} />;
};

export default Table;
