import { get } from "@qtxr/utils";

import { collectLayout } from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Data,
	Point
} from "../../apply-categorization";

const StackedConfig = {
	name: "stacked",
	collector: () => collectLayout((p: any) => {
		const points = p.data.points as Data[],
			catPoints = p.data.categorized.points as Point[];

		p.data.totals = points.map(pt => {
			return catPoints.reduce((acc, cp) => {
				return acc + get(pt, cp.accessor).value.y;
			}, 0)
		});

		p.data.max = Math.max(...p.data.totals);

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const ds = p.dataset,
			w = ds.cWidth,
			h = ds.cHeight,
			topMargin=15,  // add buffer at the top to allow for KPIs or other text.
			max = ds.data.max,
			points = ds.data.points as Data[],
			catPoints = ds.data.categorized.points as Point[],
			step = w / (points.length - 1),
			offsets = [] as number[],
			style = p.inst.config.style;

		const yAxisLabel = ds.axesLabels?.y
		const leftOffset = yAxisLabel ? 60 : 0;

		if (yAxisLabel) {
			plot.line()
				.x1(leftOffset - 5)
				.y1(topMargin)
				.x2(leftOffset - 5)
				.y2(h)
				.stroke(style.fullTheme.color)
				.strokeWidth(1)
				.inert()
			const axisHeight = h - topMargin
			const numSteps = 10
			const step = axisHeight / numSteps
			for (let i = 2; i <= numSteps; i++) {
				plot.text(`${yAxisLabel(i * (max/numSteps))} -`)
					.x(leftOffset - 5)
					.y(h + topMargin - i * step)
					.size(10)
					.weight(600)
					.anchor("end")
					.baseline("hanging")
					.fill(style.fullTheme.color)
					.inert();
			}
		}

		for (const cp of catPoints) {
			plot.next();
			plot.with(cp);

			const boundaryType = plot.getBoundaryType(cp, "nested"),
				path = plot.path();
			let x = leftOffset;

			for (let i = 0, l = points.length; i < l; i++) {
				offsets[i] = offsets[i] || 0;

				if (i) // after the first point we want a connected line
					path.L(x, topMargin + h - offsets[i]);
				else // if its the first point, just move the "cursor" to the starting location
					path.M(x, topMargin + h - offsets[i]);

				x += step;
			}

			for (let i = points.length - 1; i >= 0; i--) {
				const pt = points[i],
					height = (get(pt, cp.accessor).value.y / max) * (h-topMargin);

				offsets[i] += height;
				x -= step;
				path.L(x, topMargin + h - offsets[i]);
			}

			path.fill(cp.color);

			if (cp !== catPoints[catPoints.length - 1]) {
				const separator = plot.path()
					.M(0, topMargin + h - offsets[0]);

				for (let i = 1, l = points.length; i < l; i++) {
					x += step;
					separator.L(x, topMargin + h - offsets[i]);
				}

				separator
					.stroke(style.fullTheme.componentBackground)
					.strokeWidth(boundaryType?.includes("end") ? 1.5 : 0.5)
					.stack(1)
					.inert();
			}
		}


		// add KPI, if one exists
		let kpi_str = undefined;
		if ((typeof ds.data.kpi =="function")) {
			kpi_str = ds.data.kpi(ds.data);
		}
		else if ((typeof ds.data.kpi =="string")) {
			kpi_str = ds.data.kpi;
		}


		if (kpi_str) {
			plot.text(kpi_str)
				.x(w - 10)
				.y(10)
				.size(20)
				.weight(900)
				.anchor("end")
				.baseline("hanging")
				.fill(style.fullTheme.color)
				.inert();
		}

		plot.render();
	},
	dataset: {
		id: "stacked",
		mode: "own",
		type: "stacked",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default StackedConfig;
