import LineBarConfig from "./configs/line-bar";

import Base from "./base";

import { GraphProps } from "../../../types/viz";

const LineBar = (props: GraphProps) => {
	return <Base {...props} configs={LineBarConfig} />;
};

export default LineBar;
