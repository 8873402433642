import { useEffect } from "react";
import styled from "styled-components";

import { request } from "./utils";

import {
	useAppDispatch,
	useAppSelector
} from "./state/hooks";
import {
	supplyParties,
	supplyOffices,
	supplyTeams,
	supplyAgencies,
	supplyRaces,
	supplyMarketNames,
	setLoaded,
	setSupplyError
} from "./state/features/data";

import Toast from "./components/ui/toast";

// Routes
import Routes from "./views";
import Icon from "./components/icon";

interface LoadingBarProps {
	count: number;
	progress: number;
}

const LoadingScreen = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${p => p.theme.background};
	color: ${p => p.theme.color};
`;

const ErrorIcon = styled(Icon)`
	/*fill: #efcad0;
	stroke: #d65177;*/
	fill: #d6dee6;
	stroke: #93a6b9;
	stroke-width: 2;
	height: 50px;
`;

const LoadingMessage = styled.p`
	margin: 2em 0 0;
	text-align: center;
`;

const LoadingBarWrapper = styled.div`
	width: 200px;
	height: 6px;
	border-radius: 2px;
	background: ${p => p.theme.cardBackground};
	overflow: hidden;
`;

const LoadingBarBar = styled.div<LoadingBarProps>`
	width: 100%;
	height: 100%;
	border-radius: inherit;
	background: ${p => p.theme.accent};
	transform: ${p => `translateX(${((p.count - p.progress) / p.count) * -100}%)`};
	transition: transform 300ms;
`;

const LoadingBar = (props: LoadingBarProps) => {
	return (
		<LoadingBarWrapper>
			<LoadingBarBar
				count={props.count}
				progress={props.progress}
			/>
		</LoadingBarWrapper>
	);
};

const App = () => {
	const {
		error,
		loaded,
		progress,
		assetCount
	} = useAppSelector(state => state.data);

	const dispatch = useAppDispatch();

	useEffect(
		() => {
			if (loaded)
				return;

			const req = async (
				url: string,
				dispatcher: (action: any) => any
			) => {
				const response = await request(url);

				if (response.success)
					dispatch(dispatcher(response.data));
				else if (localStorage.getItem("debugging") === "true")
					dispatch(dispatcher([]));
				else
					dispatch(setSupplyError(response.errorMessage!));
			};

			req("/parties/list", supplyParties);
			req("/races/list", supplyRaces);
			req("/offices/list", supplyOffices);
			req("/teams/list", supplyTeams);
			req("/agencies/list", supplyAgencies);
			req("/markets/names", supplyMarketNames)
		},
		[]
	);

	useEffect(
		() => {
			if (progress === assetCount) {
				setTimeout(
					() => dispatch(setLoaded(true)),
					400
				);
			}
		},
		[progress]
	);

	if (error) {
		return (
			<LoadingScreen>
				<ErrorIcon name="cross" />
				<LoadingMessage>
					Failed to load initial data
					<br />
					Reload the page to try again
				</LoadingMessage>
			</LoadingScreen>
		);
	}

	if (!loaded) {
		return (
			<LoadingScreen>
				<LoadingBar
					count={assetCount}
					progress={progress}
				/>
				<LoadingMessage>
					Loading initial data
				</LoadingMessage>
			</LoadingScreen>
		);
	}

	return (
		<>
			<Routes/>
			{/* UI components */}
			<Toast/>
		</>
	);
};

export default App;
