import applyCategorization, {
	Data,
	Point,
	Group,
	Categorized,
	CategorizationConfig
} from "./apply-categorization";

import {
	Legend,
	LegendsProps
} from "../../types/viz";

export interface Layout {
	data: Data[];
	kpi?: string|null,
	nodes: (Point | Group)[];
	legends: LegendsProps | Legend[] | null;
	categorized: Categorized;
	isLayout: true;
}

export interface LayoutConfig extends CategorizationConfig {
	level?: number;
	groupLevel?: number;
	legends?: LayoutLegendConfig | null;
}

interface LayoutLegendConfig extends Partial<LegendsProps> {
	level?: number;
}

const applyLayout = (data: any, config: LayoutConfig = {}, kpi: any = undefined): Layout => {
	const dataLevel = typeof config.level == "number" ?
			config.level :
			0,
		groupLevel = typeof config.groupLevel == "number" ?
			config.groupLevel :
			dataLevel,
		categorized = applyCategorization(data, { ...config, groupLevel });
	let legends = {} as LegendsProps,
		legendsLevel = -1;

	if (Array.isArray(config.legends)) {
		legends = {
			legends: config.legends
		};
	} else if (config.legends) {
		const {
			level: lvl,
			...ls
		} = config.legends;

		legendsLevel = typeof lvl == "number" ?
			lvl :
			legendsLevel;
		legends = ls as LegendsProps;
	}

	if (!legends.hasOwnProperty("legends")) {
		if (config.legends === null)
			legends.legends = [];
		else
			legends.legends = resolveLegends(categorized, legendsLevel);
	}


	return {
		data: categorized.data,
		nodes: resolveSource(categorized, dataLevel),
		legends,
		categorized,
		isLayout: true,
		kpi: kpi
	};
};

const resolveSource = (categorized: Categorized, level: number): (Point | Group)[] => {
	let source = categorized.points as (Point | Group)[];

	if (level > -1 && categorized.groups.length) {
		source = [];

		for (const group of categorized.groups) {
			if (group.level === level)
				source.push(group);
		}
	}

	return source;
};

const resolveLegends = (categorized: Categorized, level: number): Legend[] => {
	const legends = [] as Legend[],
		source = resolveSource(categorized, level);

	for (const node of source) {
		legends.push({
			title: node.label,
			color: node.color
		});
	}

	return legends;
};

// @ts-ignore
window.lay = applyLayout;

export default applyLayout;
