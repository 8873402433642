import { useMemo } from "react";

import { mkGraphConfig } from "../common";

import Graph from "./index";

import {
	CoreGraphProps,
	BaseGraphProps
} from "../../../types/viz";

const Base = (props: BaseGraphProps) => {
	const config = useMemo(
		() => {
			const conf = Array.isArray(props.configs) ?
				props.configs :
				[props.configs];

			return mkGraphConfig(...conf, props.config);
		},
		[props.config, props.configs]
	);

	const p = {
		...props,
		store: props.store,
		config
	} as Omit<CoreGraphProps, "theme">;

	return <Graph {...p} />
};

export default Base;
