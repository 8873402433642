import { get } from "@qtxr/utils";

import useRequest from "./use-request";

import {AugmentedRequestInfo, LoadingState} from "../types/utils";

interface StatsConfig {
	request: AugmentedRequestInfo;
	entries: Entry[];
}

interface Entry {
	accessor: string;
	label: string;
}

export interface StatEntry {
	label: string;
	value: number;
	loadingState: LoadingState;
}

const useStats = (config: StatsConfig): StatEntry[] => {
	const [data, loadingState] = useRequest(config.request);

	if (loadingState.success) {
		return config.entries.map(entry => {
			const value = get(data, entry.accessor) as number;

			return {
				label: entry.label,
				value,
				loadingState
			};
		});
	}

	return config.entries.map(entry => ({
		label: entry.label,
		value: 0,
		loadingState
	}));
};

export default useStats;
