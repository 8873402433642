import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import useTableActions from "../hooks/use-table-actions";
import useRemoteActionProps from "../hooks/use-remote-action-props";
import useRequest from "../hooks/use-request";

import states from "../data/states.json";

import { Multi, Form, InputRow, InputBox } from "../components/inputs";
import Base from "./base";
import SplitBox, { SplitProps } from "../components/split-box";
import ActionTable from "../components/action-table";

import { ColumnCell } from "../types/data-table";
import emails from "./settings/emails";
import { request } from "../utils";

const COLUMNS: ColumnCell[] = [
	{
		name: "state",
		type: "choice",
		title: "State",
		accessor: "jurisdiction",
		index: "lexical",
		config: {
			options: states,
			labelAccessor: "state",
			valueAccessor: "state"
		}
	},
	{
		name: "name",
		title: "Name",
		accessor: "race_name",
		index: "lexical"
	},
	{
		name: "office",
		title: "Office",
		accessor: "office.full_name",
		index: "lexical"
	},
	{
		name: "lists",
		type: "hidden",
		title: "Lists",
		accessor: "email_lists",
		config: {
			options: emails,
			labelAccessor: "email_lists",
			valueAccessor: "id"
		}
	}
];

const MARKET_COLUMNS: ColumnCell[] = [
	{
		name: "state",
		title: "",
		accessor: "market.state",
		index: "lexical",
		fit: "shrink",
		sort: null,
		filter: null
	},
	{
		name: "market",
		title: "Market",
		accessor: "market_name",
		index: "lexical"
	},
	{
		name: "candidateCpp30",
		type: "currency",
		title: "C. CPP :30",
		accessor: "candidate_cpp_30",
		fit: "shrink"
	},
	{
		name: "candidateCpp60",
		type: "currency",
		title: "C. CPP :60",
		accessor: "candidate_cpp_60",
		fit: "shrink"
	},
	{
		name: "candidateDra30",
		type: "currency",
		title: "C. DRA :30",
		accessor: "candidate_dra_30",
		fit: "shrink"
	},
	{
		name: "candidateDra60",
		type: "currency",
		title: "C. DRA :60",
		accessor: "candidate_dra_60",
		fit: "shrink"
	},
	{
		name: "issueCpp30",
		type: "currency",
		title: "I. CPP :30",
		accessor: "issue_cpp_30",
		fit: "shrink"
	},
	{
		name: "issueCpp60",
		type: "currency",
		title: "I. CPP :60",
		accessor: "issue_cpp_60",
		fit: "shrink"
	},
	{
		name: "issueDra30",
		type: "currency",
		title: "I. DRA :30",
		accessor: "issue_dra_30",
		fit: "shrink"
	},
	{
		name: "issueDra60",
		type: "currency",
		title: "I. DRA :60",
		accessor: "issue_dra_60",
		fit: "shrink"
	}
];

const ContentWrapper = styled.div`
	max-width: 600px;
`;

const GrowingInputBox = styled(InputBox)`
	flex-grow: 1;
`;
const DropdownBox = styled(InputBox)`
	min-width: 150px;
	width: 100%;
`;

const MarketsTable = styled(ActionTable)`
	margin-top: 20px;
`;

const Content = (props: SplitProps) => {
	const [emailListOptions, setEmailListOptions] = useState([]);
	const p = useRemoteActionProps({
		request: {
			url: "/races/markets",
			query: {
				race_id: props.focusedRow.id
			}
		},
		columns: MARKET_COLUMNS,
		pageSize: 50,
		spreadEdit: true,
		expand: true
	});

	const [emailLists] = useRequest("/email_lists/list", []);

	useEffect(() => {
		setEmailListOptions(
			emailLists.map((race: any) => ({
				label: race.name,
				value: race.id
			}))
		);
	}, [emailLists]);

	return (
		<ContentWrapper>
			<Form
				values={props.focusedRow}
				onChange={e => props.edit(e)}
			>
				<InputRow>
					<DropdownBox
						name="email_lists"
						title="Email Lists"
						input={Multi}
						options={emailListOptions}
					/>
				</InputRow>
			</Form>
			<MarketsTable {...p} />
		</ContentWrapper>
	);
};

const Races = () => {
	const actions = useTableActions({
		deleteEach: row => {
			return request({
				method: "DELETE",
				url: `/races/${row.id}`
			});
		},
		save: async rows => {
			return await Promise.all(
				rows.flatMap(row => {
					return row.email_lists.map(
						(list: number) => {
							request({
								method: "PUT",
								url: `/email_lists/${list}/${row.id}`
							});
						}
					);
				})
			).then(results => results[0]);
		}
	});

	const props = useRemoteActionProps({
		request: {
			url: "/races/list"
		},
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<SplitBox
				{...props}
				title="Race"
				notice="No race focused"
				content={Content}
			/>
		</Base>
	);
};

export default Races;
