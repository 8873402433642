import { createSlice } from "@reduxjs/toolkit";

interface ClientState {

}

const initialState = {

} as ClientState;

localStorage.setItem("mb-client-data", JSON.stringify(initialState));

export const slice = createSlice({
	name: "client",
	initialState,
	reducers: {}
});

export default slice.reducer;
export const {} = slice.actions;
