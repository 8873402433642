import { useState } from "react";

import { equals } from "@qtxr/utils";

import { request } from "../../utils";

import useGraphConfigCollection from "../../hooks/use-graph-config-collection";
import useQuerySettings from "../../hooks/use-query-settings";

import Base from "./base";

import { ControlUnion, DateRangeRuntime } from "../../types/control-bar";
import {
	ValidationPayload,
	WaterfallEntries
} from "../../components/waterfall";
import { API_URL, CANDIDATE_TYPES } from "../../data/constants";
import WeeklyGrpStandalone, { parseList } from "./weekly-grp-standalone";
import { downloadPdf } from "../../utils/download-pdf";

interface RaceEntry {
	race_id: number;
	race_name: string;
	year: number;
	campaign_count: number;
	race_jurisdiction: string;
}

const INITIAL_STATE = {
	raceId: -1,
	activeRaceId: -1
};

function validateTeamOrCampaignSelected({ selection }: ValidationPayload) {
	const teamsSelection = selection.find(s => s.name === "teams");
	const campaignsSelection = selection.find(s => s.name === "campaigns");
	return !!teamsSelection?.selection.length !==
		!!campaignsSelection?.selection.length
		? null
		: "Select at least one campaign OR at least one team";
}

const WATERFALL = [
	{
		name: "race",
		title: "Race",
		mode: "radio",
		fetch: async ({ query }) => {
			const res = await request({
				url: "/races/search",
				query: {
					query
				}
			});

			if (!res.success) return "Failed to find races";

			return (res.data as RaceEntry[]).map(entry => ({
				title: entry.race_name,
				details: [
					`${entry.campaign_count} ${
						entry.campaign_count === 1
							? "campaign"
							: "campaigns"
					}`,
					entry.year
				],
				key: entry.race_id,
				data: entry
			}));
		},
		onSelectionChange: (sel, { setState }) => {
			setState("raceId", sel[0].data.race_id);
			setState("state", sel[0].data.race_jurisdiction);
		},
		validate: ({ entry }) =>
			entry.selection.length ? null : "Select a race",
		compact: true
	},
	{
		name: "mode",
		title: "GRP or USD",
		mode: "radio",
		fetch: () =>
			Promise.resolve([
				{ key: "grp", title: "GRP" },
				{ key: "usd", title: "USD" }
			]),
		compact: true
	},
	{
		name: "campaigns",
		title: "Campaigns",
		mode: "multi",
		shouldFetch: ({ state, searching }) => {
			return (
				state.raceId !== -1 &&
				(searching ||
					state.raceId !== state.activeRaceId)
			);
		},
		shouldTrash: ({ state }) => {
			return (
				state.raceId !== -1 &&
				state.raceId !== state.activeRaceId
			);
		},
		fetch: async ({ query, state, setState }) => {
			setState("activeRaceId", state.raceId);

			const res = await request({
				url: "/campaigns/search",
				query: {
					query,
					race_id: state.raceId
				}
			});

			if (!res.success) return "Failed to find campaigns";

			return (res.data as any[]).map(entry => ({
				title: entry.candidate.full_name,
				details: [
					entry.party,
					CANDIDATE_TYPES.find(
						c =>
							c.value ===
							entry.candidate
								.candidate_type
					)?.label || ""
				],
				key: entry.id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting campaigns",
		validate: validateTeamOrCampaignSelected,
		compact: true
	},
	{
		name: "teams",
		title: "Teams",
		mode: "multi",
		shouldFetch: ({ state, searching }) => {
			return (
				state.raceId >= 0 &&
				(searching ||
					state.raceId !== state.activeRaceId)
			);
		},
		shouldTrash: ({ state }) => {
			return (
				state.raceId >= 0 &&
				state.raceId !== state.activeRaceId
			);
		},
		fetch: async ({ state }) => {
			const res = await request({
				url: "/teams/list",
				query: {
					race_id: state.raceId
				}
			});

			if (!res.success) return "Failed to find Teams";

			return (res.data as any[]).map(entry => ({
				title: entry.full_name,
				details: [entry.keywords],
				key: entry.id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting teams",
		validate: validateTeamOrCampaignSelected,
		compact: true
	}
] as WaterfallEntries;

interface PdfProps {
	campaigns: any[] | null;
	teams: any[] | null;
	raceId: string | number;
	startDate: number;
	endDate: number;
	mode: string;
}

const WeeklyGrpReport = () => {
	const { querySettings, setSelectionPayload, setDateRange, refresh } =
		useQuerySettings();

	const [pendingDateRange, setPendingDateRange] = useState(
		querySettings.dateRange
	);
	const [raceName, setRaceName] = useState<string>();

	const collection = useGraphConfigCollection();

	const rangeEquals = equals(querySettings.dateRange, pendingDateRange);
	const raceId =
		querySettings.selectionPayload?.selections?.race?.[0]?.key;
	const maybeCampaigns =
		querySettings.selectionPayload?.selections?.campaigns;
	const maybeTeams = querySettings.selectionPayload?.selections?.teams;
	const campaigns =
		Array.isArray(maybeCampaigns) && maybeCampaigns.length > 0
			? maybeCampaigns
			: undefined;
	const teams =
		Array.isArray(maybeTeams) && maybeTeams.length > 0
			? maybeTeams
			: undefined;
	const mode = (querySettings.selectionPayload?.selections?.mode?.[0]
		?.key || "grp") as string;

	const controls = [
		{
			mode: "date-range",
			value: pendingDateRange,
			onChange: (rt: DateRangeRuntime) => {
				if (!collection.initialized)
					setDateRange(rt.range);

				setPendingDateRange(rt.range);
			}
		},
		{
			label: "Refresh",
			style: "subtle",
			disabled: rangeEquals,
			onClick: () => {
				if (pendingDateRange)
					setDateRange(pendingDateRange);

				refresh();
			}
		},
		{
			label: "Export",
			disabled:
				!collection.initialized ||
				!collection.settled ||
				!rangeEquals ||
				!raceId ||
				((!campaigns || !campaigns.length) &&
					(!teams || !teams.length)),
			onClick: () => {
				const filename = raceName + " Weekly Breakdown";
				downloadPdf(filename);
			}
		}
	] as ControlUnion[];

	return (
		<Base
			waterfall={WATERFALL}
			initialState={INITIAL_STATE}
			onSelectionChange={setSelectionPayload}
			controls={controls}
		>
			<WeeklyGrpStandalone
				campaigns={campaigns}
				teams={teams}
				collection={collection}
				race_id={raceId}
				start_date={querySettings.normalizedDateRange[0]}
				end_date={querySettings.normalizedDateRange[1]}
				mode={mode}
				onSetRaceName={setRaceName}
			/>
		</Base>
	);
};

export default WeeklyGrpReport;
