import {
	matchPath,
	useHistory,
	useLocation,
	useRouteMatch
} from "react-router";
import {
	useMemo,
	useState,
	useEffect
} from "react";
import {
	Route,
	Switch
} from "react-router-dom";
import styled from "styled-components";

import {
	get,
	set,
	inject
} from "@qtxr/utils";

import {
	getContent,
	mkResolver
} from "./common";
import StateCapsule from "../utils/state-capsule";

import useLoadingState from "../hooks/use-loading-state";
import useStateCapsule from "../hooks/use-state-capsule";
import useStateDispatch from "../hooks/use-state-dispatch";
import usePartitionedStateDispatch from "../hooks/use-partitioned-state-dispatch";

import ControlBar from "./control-bar";

import {
	Node,
	ControlHook,
	ViewRuntime,
	PatchedNode,
	StateUpdater,
	PatchedNodesMap,
	ControlHooksMap,
	NavigationAction,
	HierarchicalProps,
	LocalStateModifier,
	NavigationActionMode,
	HierarchicalActionControlBase
} from "../types/hierarchical";
import {
	ButtonRuntime,
	ControlIntersection
} from "../types/control-bar";
import {
	State,
	PartitionedState
} from "../types/common";
import { LoadingStateType } from "../types/utils";

interface NavigationProps {
	node: PatchedNode;
	runtime: ViewRuntime;
	hooks: StateCapsule<ControlHooksMap>;
	children?: any;
}

interface NavigationTabProps {
	first: boolean;
	last: boolean;
	onClick: () => void;
	children?: any;
}

interface ActionButtonProps {
	action: HierarchicalActionControlBase;
	runtime: ViewRuntime;
	hooks: StateCapsule<ControlHooksMap>;
}

interface ViewSwitchProps {
	routes: PatchedNode[];
	nodes: PatchedNodesMap;
	runtime: ViewRuntime;
	parentPath: string;
}

interface ViewContentProps {
	node: PatchedNode;
	nodes: PatchedNodesMap;
	runtime: ViewRuntime;
	parentPath: string;
}

interface ContentWrapperProps {
	visible: boolean;
}

interface PatchedNodesData {
	root: PatchedNode;
	nodes: PatchedNodesMap;
}

interface CapProps {
	notched: boolean;
}

interface NormalizedPathData {
	path: string;
	accessors: AliasedAccessor[];
}

interface ActiveNodeData {
	node: PatchedNode | null;
	path: string | null;
	accessors: AliasedAccessor[];
}

interface AliasedAccessor {
	accessor: string;
	alias: string;
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
`;

const NavigationWrapper = styled.div`
	display: flex;
`;

const NavigationTabWrapper = styled.button<NavigationTabProps>`
	display: flex;
	align-items: center;
	position: relative;
	padding-left: ${
		p => p.first ?
			"25px" :
			"45px"
	};
	padding-right: ${
		p => p.last ?
			"25px" :
			"38px"
	};
	height: ${p => p.theme.buttonHeight};
	background: transparent;
	font: inherit;
	font-size: 105%;
	font-weight: bold;
	color: ${p => p.theme.color};
	border: none;
	outline: none;
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
	cursor: ${
		p => p.last ?
			"default" :
			"pointer"
	};;
	pointer-events: none;
	z-index: 1;
	
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 25px;
		right: 25px;
		background: ${p => p.theme.cardBackground};
		pointer-events: auto;
		z-index: -1;
	}
	
	& + & {
		margin-left: -19px;
	}

	> * + svg {
		margin-left: 0.5em;
	}
`;

const StartCap = styled.div<CapProps>`
	position: absolute;
	top: 0;
	right: 100%;
	width: ${p => p.theme.buttonHeight};
	height: ${p => p.theme.buttonHeight};
	transform-origin: 100% 0;
	${
		p => p.notched ? {
			marginRight: "-25px",
			transform: "scaleX(0.9) translateY(50%) rotate(45deg)",
			borderRadius: `0 ${p.theme.borderRadius} 0 100%`,
			boxShadow: `0 0 0 15px ${p.theme.cardBackground}`
		} : {
			marginRight: "-30px",
			background: p.theme.cardBackground,
			pointerEvents: "auto"
		}
	};
	z-index: -1;

	&:before,
	&:after {
		content: "";
		display: ${p =>  p.notched ? "block" : "none"};
		position: absolute;
		width: 50px;
		height: 50px;
		pointer-events: auto;
	}
	
	&:before {
		top: 0;
		left: ${p => p.theme.buttonHeight};
	}

	&:after {
		top: -50px;
	}
`;

const EndCap = styled.div<CapProps>`
	position: absolute;
	top: 0;
	right: 0;
	width: ${p => p.theme.buttonHeight};
	height: ${p => p.theme.buttonHeight};
	transform-origin: 100% 0;
	${p => p.notched ? {
		transform: "scaleX(0.9) translateY(50%) rotate(45deg)",
		borderRadius: `0 ${p.theme.innerBorderRadius} 0 100%`,
	} : null};
	background: ${p => p.theme.cardBackground};
	pointer-events: auto;
	z-index: -1;
`;

const NavigationTab = (props: NavigationTabProps) => (
	<NavigationTabWrapper {...props}>
		<StartCap notched={!props.first} />
		{props.children}
		<EndCap notched={!props.last} />
	</NavigationTabWrapper>
);

const Navigation = (props: NavigationProps) => {
	const crumbs = props.node.breadcrumbs;

	const navs = crumbs.map((crumb, idx) => {
		const first = !idx,
			last = idx === crumbs.length - 1,
			clickHandler = last ?
				() => {} :
				() => props.runtime.navigate(crumb);

		return (
			<NavigationTab
				key={idx}
				first={first}
				last={last}
				onClick={clickHandler}
			>
				{getContent(crumb, props.runtime)}
			</NavigationTab>
		);
	});

	const actions = (props.node.actions || []).map((action, idx) => (
		<ActionButton
			key={idx}
			action={action as HierarchicalActionControlBase}
			runtime={props.runtime}
			hooks={props.hooks}
		/>
	));

	return (
		<NavigationWrapper>
			{navs}
			{actions}
		</NavigationWrapper>
	);
};

const ActionButtonWrapper = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: ${p => p.theme.buttonHeight};
	height: ${p => p.theme.buttonHeight};
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	margin-left: 5px;
	font: inherit;
	font-weight: bold;
	color: ${p => p.theme.color};
	border: none;
	outline: none;
	cursor: pointer;

	svg {
		width: 15px;
		
		&:first-child:not(:last-child) {
			margin-left: 0.3em;
		}

		&:last-child:not(:first-child) {
			margin-right: 0.3em;
		}
	}
	
	span {
		margin: 0 0.7em;
	}
`;

const ActionButton = (props: ActionButtonProps) => {
	const handleClick = () => {
		const res = mkResolver(props.runtime);

		switch (props.action.type) {
			case "navigation": {
				const target = res(props.action.target);
				if (typeof target != "string")
					throw new TypeError("Failed to navigate: invalid target");

				props.runtime.navigate(target);
				break;
			}

			case "reload":
				props.runtime.reload();
				break;
		}
	};

	const hookifiedHandleClick = hookifyCallback(
		props.hooks,
		props.action.hook,
		handleClick
	);

	return (
		<ActionButtonWrapper onClick={() => hookifiedHandleClick(props.runtime)}>
			{getContent(props.action, props.runtime)}
		</ActionButtonWrapper>
	);
};

const ContentWrapper = styled.div<ContentWrapperProps>`
	display: ${p => p.visible ? "flex" : "none"};
	flex-direction: column;
	flex-grow: 1;
	margin-top: 10px;
	overflow: hidden;
`;

const ViewContent = (props: ViewContentProps) => {
	const match = useRouteMatch();
	let view = null;

	if (typeof props.node.view == "function")
		view = <props.node.view {...props.runtime} />;
	else if (props.node.view)
		view = props.node.view;

	if (!view)
		return <></>;

	return (
		<ContentWrapper visible={match.isExact}>
			{view}
		</ContentWrapper>
	);
};

const ViewSwitch = (props: ViewSwitchProps) => {
	const routes = props.routes.map(node => {
		const runtime = {
			...props.runtime,
			node
		} as ViewRuntime;

		const { path } = getNormalizedPathData(node, props.parentPath);

		const childSwitch = node.children && node.children.length ?
			(
				<ViewSwitch
					routes={node.children}
					nodes={props.nodes}
					runtime={runtime}
					parentPath={path}
				/>
			) :
			null;

		return (
			<Route
				key={node.name}
				path={path}
			>
				<ViewContent
					node={node}
					nodes={props.nodes}
					runtime={runtime}
					parentPath={props.parentPath}
				/>
				{childSwitch}
			</Route>
		);
	});

	return <Switch>{routes}</Switch>;
};

const getPatchedNodes = (node: Node): PatchedNode[] => {
	const out = [] as PatchedNode[];

	const traverse = (n: Node, crumbs: PatchedNode[], path: string) => {
		const patched = {
			...n,
			path: path ?
				`${path}/${n.name}` :
				`path:${n.name}`,
			breadcrumbs: [],
			children: []
		} as PatchedNode;

		patched.breadcrumbs = crumbs.concat(patched);
		out.push(patched);

		if (Array.isArray(n.children)) {
			for (const child of n.children) {
				const p = traverse(child, patched.breadcrumbs, patched.path);
				patched.children!.push(p);
			}
		}

		return patched;
	};

	traverse(node, [], "");
	return out;
};

const getPatchedNodesData = (node: Node): PatchedNodesData => {
	const ps = getPatchedNodes(node),
		nodes = {} as PatchedNodesMap;

	for (const patched of ps) {
		const name = patched.name;

		if (nodes.hasOwnProperty(patched.path))
			throw new Error(`Failed to collect map: duplicate nodes with name '${patched.name}' at path '${patched.path}'`);

		if (nodes.hasOwnProperty(name))
			nodes[name] = [nodes[name] as PatchedNode];

		if (Array.isArray(nodes[name]))
			(nodes[name] as PatchedNode[]).push(patched);
		else
			nodes[patched.name] = patched;

		nodes[patched.path] = patched;
	}

	return {
		root: ps[0],
		nodes
	};
};

const buildPath = (state: State, node: PatchedNode, parentPath: string) => {
	const components = node.breadcrumbs.map(crumb => {
		const path = typeof crumb.urlComponent == "string" ?
			crumb.urlComponent :
			crumb.name;

		return path.replace(/:([^/]+)/g, (match, accessor) => {
			return String(get(state, accessor));
		});
	});

	components.unshift(parentPath);

	return components
		.join("/")
		.replace(/\/\//g, "/");
};

const getActiveNodeData = (
	root: PatchedNode,
	location: any,
	parentPath: string
): ActiveNodeData => {
	const find = (
		node: PatchedNode,
		pPath: string,
		accessors: AliasedAccessor[]
	): ActiveNodeData => {
		const pathData = getNormalizedPathData(node, pPath),
			path = pathData.path,
			match = matchPath(location.pathname, {
				path
			});

		const out = {
			node: null,
			path: null,
			accessors: accessors.concat(pathData.accessors)
		} as ActiveNodeData;

		if (match && match.isExact) {
			out.node = node;
			out.path = path;
			return out;
		}

		if (!node.children)
			return out;

		for (const child of node.children) {
			const res = find(child, path, out.accessors);

			if (res.node)
				return res;
		}

		return out;
	};

	return find(root, parentPath, []);
};

const getNormalizedPathData = (node: PatchedNode, parentPath: string): NormalizedPathData => {
	const path = typeof node.urlComponent == "string" ?
			node.urlComponent :
			node.name,
		accessors = [] as AliasedAccessor[];

	const normalizedPath = path.replace(/:([^/]+)/g, (match, accessor) => {
		const alias = `${node.name}_${accessors.length}`;

		accessors.push({
			accessor,
			alias
		});

		return ":" + alias;
	});

	const fullPath = `${parentPath}/${normalizedPath}`
		.replace(/\/\//g, "/");

	return {
		path: fullPath,
		accessors
	};
};

const resolveNav = (
	nodeNodeNameOrCommand: PatchedNode | string,
	activeNode: PatchedNode | null,
	nodes: PatchedNodesMap
): PatchedNode | null => {
	const nName = typeof nodeNodeNameOrCommand == "string" ?
		nodeNodeNameOrCommand :
		nodeNodeNameOrCommand.name;

	if (nodes.hasOwnProperty(nName)) {
		if (Array.isArray(nodes[nName]))
			return resolveOptimalNode(activeNode, nodes[nName] as PatchedNode[]);

		return nodes[nName] as PatchedNode;
	}

	switch (nodeNodeNameOrCommand) {
		case "back":
			if (activeNode)
				return activeNode.breadcrumbs[activeNode.breadcrumbs.length - 2] || null;
			break;
	}

	return null;
};

const resolveOptimalNode = (
	activeNode: PatchedNode | null,
	nodes: PatchedNode[]
): PatchedNode => {
	if (!activeNode)
		return nodes[0];

	for (const node of nodes) {
		for (const crumb of node.breadcrumbs) {
			if (crumb.path === activeNode.path)
				return node;
		}
	}

	return nodes[0];
};

const resolvePreMountState = (
	node: PatchedNode,
	runtime: ViewRuntime
): State | null => {
	if (!node.preMountState)
		return null;

	const res = mkResolver(runtime);
	return res(node.preMountState) || null;
};

const resolvePreMountStateAll = (
	nodes: PatchedNode[],
	runtime: ViewRuntime
): State | null => {
	let out = null;

	for (let i = nodes.length - 1; i >= 0; i--) {
		const state = resolvePreMountState(
			nodes[i],
			runtime
		);

		if (state) {
			out = out || {};
			inject(out, state);
		}
	}

	return out;
};

const resolvePreMountStateDiff = (
	fromNode: PatchedNode | null,
	toNode: PatchedNode,
	runtime: ViewRuntime
): State | null => {
	let nodes;

	if (!fromNode)
		nodes = toNode.breadcrumbs;
	else {
		const tmpNodes = [] as PatchedNode[];

		for (let i = 0, l = toNode.breadcrumbs.length; i < l; i++) {
			if (
				!fromNode.breadcrumbs[i] ||
				toNode.breadcrumbs[i].name !== fromNode.breadcrumbs[i].name
			)
				tmpNodes.push(toNode.breadcrumbs[i]);
		}

		nodes = tmpNodes;
	}

	return resolvePreMountStateAll(nodes, runtime);
};

const wrapControl = (
	hooks: StateCapsule<ControlHooksMap>,
	control: HierarchicalActionControlBase
): HierarchicalActionControlBase => {
	const ctrl = {
		...control
	} as ControlIntersection<ViewRuntime, HierarchicalActionControlBase>;

	if (ctrl.type === "navigation" && typeof ctrl.target == "string") {
		const onClick = ctrl.onClick;

		ctrl.onClick = (rt: ButtonRuntime & ViewRuntime) => {
			const res = mkResolver(rt);
			rt.navigate(res(ctrl.target)!);

			if (typeof onClick == "function")
				onClick(rt);
		};
	}

	ctrl.onClick = hookifyCallback(
		hooks,
		ctrl.hook,
		ctrl.onClick
	);

	return ctrl;
};

const hookifyCallback = (
	hooks: StateCapsule<ControlHooksMap>,
	hook: ControlHook | string | undefined,
	callback: ((runtime: ViewRuntime) => any) | undefined
): (runtime: ViewRuntime) => void => {
	return (runtime: ViewRuntime) => {
		const guard = async () => {
			let h = hook,
				canContinue = true;

			if (typeof h == "string") {
				const hookName = `${runtime.activeNode!.path} - ${h}`;
				h = hooks.get()[hookName];
			}

			if (typeof h == "function") {
				const result = await h(runtime);
				canContinue = result === true || result === undefined;
			}

			if (callback && canContinue)
				callback(runtime);
		};

		guard();
	};
};

const Hierarchical = (props: HierarchicalProps) => {
	const [navigationAction, setNavigationAction] = useState(null as NavigationAction | null);

	const hooks = useStateCapsule({} as ControlHooksMap);
	const runtimeState = useStateCapsule(null as ViewRuntime | null);

	const [loadingState, updateLoadingState] = useLoadingState("idle");

	const {
		root,
		nodes
	} = useMemo(
		() => getPatchedNodesData(props.hierarchy),
		[props.hierarchy]
	);

	const dispatchState = useStateDispatch(
		props.state,
		state => {
			if (typeof props.onStateChange == "function")
				props.onStateChange(state, runtime);
		}
	);

	const history = useHistory();
	const location = useLocation();
	const routeMatch = useRouteMatch();

	const parentPath = props.parentPath || routeMatch.path.replace(/^\//, ""),
		nodeName = typeof props.node == "string" ?
			props.node :
			props.node.name,
		nodePath = typeof props.node == "string" ?
			props.node :
			(props.node as PatchedNode).path || "";
	let node = null as PatchedNode | null;

	if (nodePath && nodes.hasOwnProperty(nodePath))
		node = nodes[nodePath] as PatchedNode;
	else if (nodes.hasOwnProperty(nodeName)) {
		const nds = nodes[nodeName] as PatchedNode | PatchedNode[];

		if (Array.isArray(nds)) {
			console.warn(`Found duplicate node with name '${nodeName}'. Selected first match`);
			node = nds[0];
		} else
			node = nds;
	}

	const activeNodeData = getActiveNodeData(root, location, parentPath),
		activeNode = activeNodeData.node;

	const updateState = (
		dispatcher: (stateOrAccessor: State | StateUpdater | string, value?: any) => void,
		updaterOrAccessor: StateUpdater | string,
		updater?: StateUpdater
	) => {
		const state = runtimeState.get()!.state;

		if (typeof updaterOrAccessor == "string")
			dispatcher(updaterOrAccessor, updater!(get(state, updaterOrAccessor)));
		else
			dispatcher(updaterOrAccessor(state));
	};

	function wrapModifier<M>(
		modifier: (key: string, ...args: any[]) => void
	): LocalStateModifier<M> {
		const wrapper: LocalStateModifier<any> = (...args: any[]) => {
			const rt = runtimeState.get()!,
				key = wrapper._at || rt.activeNode!.name || "";

			modifier(key, ...args);
		};

		wrapper._at = null;

		wrapper.at = (key: string) => {
			wrapper._at = key;
			return wrapper;
		};

		return wrapper;
	}

	const runNavigation = (
		mode: NavigationActionMode,
		nodeOrNodeName: PatchedNode | string
	): void => {
		// Only one navigation may occur at a time
		// as to reduce the risk of multiple navigation triggers
		setNavigationAction(action => {
			if (action)
				return action;

			const node = resolveNav(
				nodeOrNodeName,
				activeNode,
				nodes
			);

			if (!node) {
				console.warn(`Cannot navigate: no applicable node`);
				return null;
			}

			// Skip frame to let any state updates propagate
			// before running state diff
			requestAnimationFrame(() => {
				const rt = runtimeState.get()!;

				const pms = resolvePreMountStateDiff(
					activeNode,
					node,
					rt
				);

				if (pms)
					rt.setStateGently(pms);

				// Skip a frame to let any state changes collect and dispatch
				// before completing navigation and subsequent render
				requestAnimationFrame(() => {
					const r = runtimeState.get()!,
						path = buildPath(r.state, node, parentPath);

					switch (mode) {
						case "push":
							history.push(path!);
							break;

						case "replace":
							history.replace(path!);
							break;
					}

					setNavigationAction(null);
				});
			});

			return {
				mode,
				target: node
			} as NavigationAction;
		});
	};

	const runtime = {
		name: (activeNode && activeNode.name) || null,
		node: activeNode,
		activeNode,
		nodes,
		state: props.state,
		localState: {},
		localStates: {},
		loadingState,
		setState: (stateOrAccessor: State | string, value?: any): void => {
			if (typeof props.onStateChange != "function")
				return;

			dispatchState(stateOrAccessor, value);
		},
		setStateGently: (stateOrAccessor: State | string, value?: any): void => {
			if (typeof props.onStateChange != "function")
				return;

			dispatchState.gently(stateOrAccessor, value);
		},
		updateState: (updaterOrAccessor: StateUpdater | string, updater?: StateUpdater) => {
			if (typeof props.onStateChange != "function")
				return;

			updateState(dispatchState, updaterOrAccessor, updater);
		},
		updateStateGently: (updaterOrAccessor: StateUpdater | string, updater?: StateUpdater) => {
			if (typeof props.onStateChange != "function")
				return;

			updateState(dispatchState.gently, updaterOrAccessor, updater);
		},
		setLocalState: wrapModifier(
			(
				key: string,
				stateOrAccessor: State | string,
				value?: any
			): void => {
				dispatchLocalStates.at(key)(stateOrAccessor, value);
			}
		),
		setLocalStateGently: wrapModifier(
			(
				key: string,
				stateOrAccessor: State | string,
				value?: any
			): void => {
				dispatchLocalStates.at(key)(stateOrAccessor, value);
			}
		),
		updateLocalState: wrapModifier(
			(
				key: string,
				updaterOrAccessor: StateUpdater | string,
				updater?: StateUpdater
			): void => {
				updateState(dispatchLocalStates.at(key), updaterOrAccessor, updater);
			}
		),
		updateLocalStateGently: wrapModifier(
			(
				key: string,
				updaterOrAccessor: StateUpdater | string,
				updater?: StateUpdater
			): void => {
				updateState(dispatchLocalStates.gently.at(key), updaterOrAccessor, updater);
			}
		),
		setLoadingState(type: LoadingStateType, message?: string) {
			updateLoadingState(type, message);
		},
		navigate: (nodeOrNodeName: PatchedNode | string): void => {
			runNavigation("push", nodeOrNodeName);
		},
		redirect: (nodeOrNodeName: PatchedNode | string): void => {
			runNavigation("replace", nodeOrNodeName);
		},
		reload: () => {
			const rt = runtimeState.get()!;

			const pms = resolvePreMountState(
				rt.activeNode!,
				rt
			);

			if (pms)
				rt.setState(pms);

			rt.redirect(rt.activeNode!);
		},
		hook: (name: string, handler: ControlHook) => {
			const rt = runtimeState.get()!,
				hookName = `${rt.activeNode!.path} - ${name}`;

			hooks.set({
				...hooks.get(),
				[hookName]: handler
			});
		},
		navigationAction,
		patched: false
	} as ViewRuntime;

	// Local states
	const localStatesCapsule = useStateCapsule({} as PartitionedState);
	const [localStatesUpdates, setLocalStatesUpdates] = useState(0);

	useMemo(
		() => {
			const currentState = localStatesCapsule.get();

			const newState = {} as PartitionedState,
				breadcrumbs = runtime.activeNode?.breadcrumbs || [],
				res = mkResolver(runtime);

			for (const node of breadcrumbs) {
				if (currentState.hasOwnProperty(node.name))
					newState[node.name] = currentState[node.name];
				else {
					newState[node.name] = res(node.preMountLocalState) || {}
				}
			}

			localStatesCapsule.set(newState);
		},
		[activeNode]
	);

	const localStates = localStatesCapsule.get();

	const dispatchLocalStates = usePartitionedStateDispatch(
		localStates,
		state => {
			localStatesCapsule.set(state);
			setLocalStatesUpdates(localStatesUpdates + 1);
		}
	);

	runtime.localState = activeNode ?
		localStates[activeNode.name] :
		{};
	runtime.localStates = localStates;

	runtimeState.set(runtime);

	// Resolve/update URL-based state
	useMemo(
		() => {
			let newState = null as State | null;

			const match = matchPath(location.pathname, {
				path: activeNodeData.path!
			});

			for (const aa of activeNodeData.accessors) {
				const value = (match!.params as any)[aa.alias],
					stateValue = get(props.state, aa.accessor);

				if (value === stateValue)
					continue;

				newState = newState || {};
				set(newState, aa.accessor, value);
			}

			if (activeNode) {
				if (newState) {
					runtime.state = inject(runtime.state, newState, "override|cloneTarget");
					runtime.patched = true;
				}

				const pms = resolvePreMountStateAll(activeNode.breadcrumbs, runtime);

				if (pms) {
					newState = newState || {};
					inject(newState, pms, "override");
				}
			}

			if (newState) {
				runtime.state = inject(runtime.state, newState, "override|cloneTarget");
				runtime.patched = true;
				runtime.setState(newState);
			}
		},
		[]
	);

	useEffect(
		() => {
			if (!activeNode || navigationAction)
				return;

			const path = buildPath(runtime.state, activeNode, parentPath);

			if (path !== location.pathname)
				history.replace(path);
		},
		[activeNode, props.state, runtime.state]
	);

	useEffect(
		() => {
			if (
				activeNode &&
				!navigationAction &&
				node !== activeNode &&
				typeof props.onNavigate == "function"
			)
				props.onNavigate(activeNode, runtime);
		},
		[node, activeNode]
	);

	const controls = useMemo(
		() => {
			return ((activeNode && activeNode.controls) || []).map(control => {
				return wrapControl(hooks, control)
			});
		},
		[activeNode]
	);

	const nav = activeNode ?
		(
			<Navigation
				node={activeNode!}
				runtime={runtime}
				hooks={hooks}
			/>
		) :
		null;

	return (
		<Wrapper className={props.className}>
			<ControlBar
				controls={controls}
				runtime={runtime}
			>
				{nav}
			</ControlBar>
			<ViewSwitch
				routes={[root]}
				nodes={nodes}
				runtime={runtime}
				parentPath={parentPath}
			/>
		</Wrapper>
	);
}

export default Hierarchical;
