import { BANDS } from "../../../data/constants";
import states from "../../../data/states.json";

import useTableActions from "../../../hooks/use-table-actions";
import useRemoteActionProps from "../../../hooks/use-remote-action-props";

import Base from "../../base";

import ContentCard from "../../../components/content-card";
import ActionTable from "../../../components/action-table";

import { ColumnCell } from "../../../types/data-table";

const COLUMNS: ColumnCell[] = [
	{
		name: "state",
		type: "choice",
		title: "State",
		accessor: "state",
		index: "lexical",
		fit: "shrink",
		editable: true,
		config: {
			options: states,
			labelAccessor: "state",
			valueAccessor: "state"
		}
	},
	{
		name: "callLetters",
		title: "Call Letters",
		accessor: "call_letters",
		editable: true
	},
	{
		name: "band",
		type: "choice",
		title: "Band",
		accessor: "band",
		editable: true,
		config: {
			option: BANDS
		}
	},
	{
		name: "market",
		title: "Market",
		accessor: "market",
		editable: true
	},
	{
		name: "msaName",
		title: "MSA Name",
		accessor: "msa_name",
		editable: true
	},
	{
		name: "identifier",
		title: "Identifier",
		accessor: "identifier",
		editable: true
	},
	{
		name: "created",
		type: "date-time",
		title: "Candidate",
		accessor: "candidate_name",
	}
];

const Radio = () => {
	const actions = useTableActions({
		addLabel: "Add Radio Station",
		add: () => ({
			state: "US",
			call_letters: "",
			band: "",
			market: "",
			msa_name: "",
			identifier: "",
			created: Math.round(Date.now() / 1000)
		}),
		createEach: row => {
			console.log("CREATE");
		},
		modifyEach: row => {
			console.log("MODIFY");
		},
		deleteEach: row => {
			console.log("DELETE");
		}
	});

	const props = useRemoteActionProps({
		request: "/stations/radio/list",
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} />
			</ContentCard>
		</Base>
	);
};

export default Radio;
