import { get } from "@qtxr/utils";

import PlotSvg from "../index";

import {
	Style,
	Bounding,
	Validator,
	PresetKey,
	StyleParam,
	RunnerArgs,
	DefaultPaint,
	BoundingArray,
	SetterRuntime,
	PointerEventType,
	PendingSetterRuntime
} from "../../../../types/plot-svg";
import { Point } from "../../apply-categorization";

interface ValueTransport {
	mode: ValueTransportMode;
	value: any;
	isValueTransport: true;
}

export type SetterMaker<Component extends StagedComponent> = <Args extends Array<any>>(
	key: string,
	setter: (...args: Args) => any,
	sideEffect?: (runtime: SetterRuntime<Component>) => void
) => (...args: Args) => Component;

type ValueTransportMode = "scaled";

const ms = mkSetterMaker<StagedComponent>();

export default class StagedComponent {
	owner: PlotSvg;
	point: Point | null;
	index: number;
	style: Style;
	closed: boolean;
	valid: boolean;
	hasPaint: boolean;
	defaultPaint: DefaultPaint;

	isInert: boolean;
	zIndex: number;

	static commonStyleParams = [
		{ key: "fill", default: "transparent" },
		{ key: "stroke" },
		{ key: "stroke-width", publicName: "strokeWidth", validate: "positive" },
		{ key: "pointer-events", publicName: "pointer", target: "style" },
		{ key: "opacity", target: "style" },
		{ key: "outline", enumerable: false },
		{ key: "transform-origin", target: "style" },
		{ key: "transform", target: "style" }
	] as StyleParam[];

	ownStyleParams = [] as StyleParam[];

	static commonValidators = {
		positive: (value: number) => value >= 0
	} as Record<string, Validator<StagedComponent>>;

	ownValidators = {} as Record<string, unknown>;

	constructor(owner: PlotSvg, point: Point | null) {
		this.owner = owner;
		this.point = point;
		this.index = owner.index;

		this.style = {};

		this.closed = false;
		this.valid = true;
		this.hasPaint = false;
		this.defaultPaint = "fill";

		this.isInert = false;
		this.zIndex = 0;
	}

	// Semi-private API: setup
	open(...args: any[]): this {
		args.forEach((arg, idx) => {
			const param = this.ownStyleParams[idx];

			if (param) {
				const name = param.publicName || param.key;

				if (typeof (this as any)[name] == "function") {
					if (arg !== undefined)
						(this as any)[name](arg);
				} else
					console.error(`Unknown method '${param.key}'`);
			} else
				console.error(`Unknown parameter at index ${idx}`);
		});

		return this;
	}

	// Semi-private API: style setting
	set(key: string, value: any): this {
		if (!this.valid)
			return this;

		// if ()

		this.style[key] = value;
		return this;
	}

	setScaled(key: string, value: number): this {
		return this.set(key, this.scale(value));
	}

	scale(value: number): number {
		const res = this.owner.runtime.inst.config.canvRes;
		return value * res;
	}

	// Semi-private API: assertions
	assertStyles(opName: string, ...keys: string[]) {
		for (const key of keys) {
			if (this.style.hasOwnProperty(key))
				continue;

			const param = this.getStyleParam("key");
			let message = `Failed to complete operation '${opName}'`;

			if (param) {
				if (param.publicName)
					message += `: style with name ${param.key} (added with ${param.publicName}) has not been added`;
				else
					message += `: style with name ${param.key} has not been added`;
			}

			throw new Error(message);
		}
	}

	// Public API
	// Common styles with presets
	fill = ms(
		"fill",
		(color?: string) => color,
		({ component }) => component.hasPaint = true
	);
	stroke = ms(
		"stroke",
		(color?: string) => color,
		({ component }) => component.hasPaint = true
	);
	strokeWidth = ms("stroke-width", (width?: number) => scaled(width));
	pointer = ms("pointer-events", (type?: PointerEventType) => type);
	opacity = ms("opacity", (opacity?: number) => opacity);
	outline = ms("outline", (outline?: number) => scaled(outline));
	origin = ms("transform-origin", (transform: string) => transform);
	transform = ms("transform", (transform: string) => transform);

	// Common modifiers (no preset, style)
	stack(position: number): this {
		this.zIndex = position;
		return this;
	}

	inert(): this {
		this.isInert = true;
		return this;
	}

	// Semi-private API: dispatch
	close() {
		if (this.closed)
			return;

		if (!this.hasPaint) {
			switch (this.defaultPaint) {
				case "fill":
					this.fill();
					break;

				case "stroke":
					this.stroke();
					break;
			}
		}

		this.closed = true;
	}

	forEachStyle(callback: (value: any, key: string, style: Style, param: StyleParam) => void) {
		const dispatch = (param: StyleParam) => {
			if (param.aggregate || param.enumerable === false)
				return;

			let value = null;

			if (this.style.hasOwnProperty(param.key))
				value = this.style[param.key];
			else if (param.hasOwnProperty("default"))
				value = param.default;
			else {
				if (param.required)
					console.error(`Cannot apply style: style '${param.key}' is not defined`);

				return;
			}

			callback(value, param.key, this.style, param);
		};

		for (const param of StagedComponent.commonStyleParams)
			dispatch(param);
		for (const param of this.ownStyleParams)
			dispatch(param);
	}

	applyStyle(target: SVGElement, key: string, value: any, param?: StyleParam) {
		const v = String(value),
			p = param || this.getStyleParam(key);

		if (p && typeof p.target == "string") {
			const t = get(target, p.target);
			t[key] = v;
		} else
			target.setAttribute(key, v);
	}

	getStyleParam(key: string): StyleParam | null {
		for (const param of StagedComponent.commonStyleParams) {
			if (param.key === key || param.publicName === key)
				return param;
		}

		for (const param of this.ownStyleParams) {
			if (param.key === key || param.publicName === key)
				return param;
		}

		return null;
	}

	mount(
		nodeNameOrElement: SVGElement | string,
		contentOrRunner?: SVGElement | string | ((args: RunnerArgs) => void),
		runner?: (args: RunnerArgs) => void,
	) {
		if (!this.valid)
			return;

		const content = typeof contentOrRunner == "function" ?
			null :
			(
				typeof contentOrRunner == "string" ?
					document.createElementNS("http://www.w3.org/2000/svg", contentOrRunner) :
					contentOrRunner || null
			);

		const run = typeof contentOrRunner == "function" ?
			contentOrRunner :
			runner;

		const target = typeof nodeNameOrElement == "string" ?
			document.createElementNS("http://www.w3.org/2000/svg", nodeNameOrElement) :
			nodeNameOrElement;
		let outlineTarget = null as SVGElement | null;

		if (this.style.hasOwnProperty("outline")) {
			outlineTarget = typeof nodeNameOrElement == "string" ?
				document.createElementNS("http://www.w3.org/2000/svg", nodeNameOrElement) :
				nodeNameOrElement.cloneNode() as SVGElement;

			outlineTarget.classList.add("outline");
		}

		if (run) {
			// To optimize style application running, this object
			// is mutated for every style parameter
			const args = {
				key: "",
				value: null,
				param: null as unknown,
				target,
				content,
				apply: () => {
					this.applyStyle(target, args.key, args.value, args.param);
				},
				applyContent: () => {
					if (content)
						this.applyStyle(content, args.key, args.value, args.param);
				},
				isOutline: false
			} as RunnerArgs;

			this.forEachStyle((value, key, style, param) => {
				args.key = key;
				args.value = value;
				args.param = param;

				if (outlineTarget) {
					args.isOutline = true;
					args.target = outlineTarget;

					run(args);

					args.isOutline = false;
				}

				args.target = target;
				run(args);
			});
		} else {
			this.forEachStyle((value, key, style, param) => {
				if (outlineTarget)
					this.applyStyle(outlineTarget, key, value, param);

				this.applyStyle(target, key, value, param);
			});
		}

		const applyAdditionalStyle = (tg: SVGElement) => {
			if (this.isInert)
				(tg as any).style.pointerEvents = "none";

			if (this.point && !this.isInert) {
				tg.setAttribute("data-index", String(this.index));
				tg.setAttribute("data-accessor", this.point.accessor);
			}
		}

		let root = target;

		// Apply additional node style
		if (outlineTarget)
			applyAdditionalStyle(outlineTarget);
		applyAdditionalStyle(target);

		// Wrap target(s) if necessary
		if (this.shouldWrap()) {
			const wrapper = document.createElementNS("http://www.w3.org/2000/svg", "g");

			if (outlineTarget) {
				const strokeWidth = this.style["stroke-width"] || 0;
				outlineTarget.style.fill = "transparent";
				outlineTarget.style.stroke = "transparent";
				outlineTarget.style.strokeWidth = strokeWidth + this.style.outline * 2;
				outlineTarget.style.strokeLinecap = "round";
				outlineTarget.style.strokeLinejoin = "round";
				wrapper.append(outlineTarget);
			}

			wrapper.append(target);
			root = wrapper;
		}

		// Fix quirks
		if (this.style.hasOwnProperty("transform"))
			root.style.transformBox = "fill-box";

		// Add node meta
		if (this.point && !this.isInert)
			root.classList.add("plot-node");

		if (content)
			target.append(content);

		this.owner.runtime.canvas.append(root);
	}

	shouldWrap() {
		if (this.style.hasOwnProperty("opacity") && this.style.opacity < 1)
			return true;

		return this.style.hasOwnProperty("outline");
	}

	getBounding(): Bounding | BoundingArray | null {
		return null;
	}

	render() {
		throw new Error("This component does not implement a render method");
	}
}

// Setter utilities
const VALUE_TRANSPORT = {
	mode: "scaled",
	value: null,
	isValueTransport: true
} as ValueTransport;

function mkSetter<Args extends Array<any>, Component extends StagedComponent>(
	key: string,
	setter: (...args: Args) => any,
	sideEffect?: (runtime: SetterRuntime<Component>) => void
): OmitThisParameter<(this: Component, ...args: Args) => Component> {
	let runtime = null as SetterRuntime<Component> | null,
		validate = null as Validator<Component> | null,
		dispatch = null as ((args: Args) => Component) | null;

	return function (...args: Args) {
		if (runtime)
			return dispatch!(args);

		const rt = {
			key,
			component: this,
			param: null
		} as PendingSetterRuntime<Component>;

		// Resolve parameter
		rt.param = StagedComponent.commonStyleParams.find(param => param.key === key) || null;

		if (!rt.param)
			rt.param = this.ownStyleParams.find(param => param.key === key) || null;

		if (!rt.param)
			throw new Error(`Failed to make setter: no parameter with key '${key}' found`);

		// Resolve validator (if applicable)
		if (rt.param.validate) {
			const vd = rt.param.validate;

			if (typeof vd == "function")
				validate = vd;
			else if (StagedComponent.commonValidators.hasOwnProperty(vd))
				validate = StagedComponent.commonValidators[vd];
			else if (this.ownValidators.hasOwnProperty(vd))
				validate = (this.ownValidators as Record<string, Validator<Component>>)[vd];
			else
				throw new Error(`Failed to make setter: no validator by name '${vd}'`);
		}

		const hasPreset = this.owner.presetKeys.indexOf(key as PresetKey) > -1

		dispatch = (a: Args) => {
			if (!this.valid)
				return this;

			if (hasPreset && !a.length)
				a[0] = this.owner.getPresetValue(key);

			a[0] = setter.apply(this, a);
			a[0] = resolveValue(a, this);

			if (validate) {
				this.valid = validate(a[0], runtime!);

				if (!this.valid)
					return this;
			}

			this.style[key] = a[0];

			if (sideEffect)
				sideEffect(runtime!);

			return this;
		};

		runtime = rt as SetterRuntime<Component>;
		return dispatch(args);
	};
}

function mkSetterMaker<Component extends StagedComponent>(): SetterMaker<Component> {
	return function wrappedSetterMaker<Args extends Array<any>>(
		this: Component,
		key: string,
		setter: (...args: Args) => any,
		sideEffect?: (runtime: SetterRuntime<Component>) => void
	): OmitThisParameter<(this: Component, ...args: Args) => Component> {
		return mkSetter(key, setter, sideEffect);
	};
}

function resolveValue<Args extends Array<ValueTransport | any>, Component extends StagedComponent>(
	args: Args,
	component: Component
): any {
	let val = args[0];
	if (val !== VALUE_TRANSPORT)
		return val;

	val = VALUE_TRANSPORT.value;

	switch (VALUE_TRANSPORT.mode) {
		case "scaled": {
			const res = component.owner.runtime.inst.config.canvRes;
			return (val! as number) * res;
		}
	}
}

const scaled = (value?: number): ValueTransport => {
	VALUE_TRANSPORT.mode = "scaled";
	VALUE_TRANSPORT.value = value;
	return VALUE_TRANSPORT;
};

export {
	mkSetter,
	mkSetterMaker,
	scaled
};
