import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import Select from "react-select";

import Icon from "../icon";

import { InputProps } from "../../types/inputs";

type MultiOption = {
	label: string;
	value: any;
};

interface MultiProps extends InputProps {
	value: string[];
	readOnly?: boolean;
	options: MultiOption[];
}

interface MultiWrapperProps {
	expanded: boolean;
}

interface EntryProps {
	onDelete: () => void;
	readOnly?: boolean;
	children?: any;
}

interface MultiDropProps {
	dropProps: DropProps;
}

interface DropProps {
	selection: string[];
	add: (value: string) => void;
	delete: (value: string) => void;
	options: MultiOption[];
}

const MultiWrapper = styled.div`
	position: relative;
`;

const MultiContent = styled.div<MultiWrapperProps>`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	min-height: 60px;
	max-height: 100px;
	background: ${p => p.theme.cardBackground};
	border: ${p => p.theme.inputBorder};
	border-radius: ${p =>
		p.expanded
			? `${p.theme.borderRadius} ${p.theme.borderRadius} 0 0`
			: p.theme.borderRadius};
	overflow: auto;
	padding: 5px 0 0 5px;
`;

const EntryWrapper = styled.div`
	display: flex;
	align-items: center;
	max-width: 50%;
	height: 28px;
	padding: 3px 8px;
	margin: 0 5px 5px 0;
	background: ${p => p.theme.darkBackground};
	color: ${p => p.theme.subtle};
	border-radius: ${p => p.theme.borderRadius};
`;

const EntryContent = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const EntryDeleteButton = styled.button`
	flex-shrink: 0;
	padding: 0 5px;
	width: 20px;
	height: 20px;
	border: none;
	outline: none;
	margin: 0 -3px 0 5px;
	background: transparent;
	color: inherit;
	cursor: pointer;
`;

const EntryAddButton = styled.button`
	width: 28px;
	height: 28px;
	margin: 0 5px 5px 0;
	padding: 0 6px;
	background: ${p => p.theme.background};
	border-radius: ${p => p.theme.borderRadius};
	color: ${p => p.theme.subtleColor};
	border: none;
	outline: none;
	cursor: pointer;
`;

const Entry = (props: EntryProps) => {
	const deleteButton = !props.readOnly ? (
		<EntryDeleteButton type="button" onClick={props.onDelete}>
			<Icon name="thin-cross" />
		</EntryDeleteButton>
	) : null;

	return (
		<EntryWrapper>
			<EntryContent>{props.children}</EntryContent>
			{deleteButton}
		</EntryWrapper>
	);
};

const Multi = (props: MultiProps) => {
	const ctx = useFormikContext();
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		const globalClickHandler = () => {
			setExpanded(false);
		};

		document.body.addEventListener("click", globalClickHandler);

		return () =>
			document.body.removeEventListener(
				"click",
				globalClickHandler
			);
	}, []);

	const name = props.name!,
		value = (ctx.values as any)[props.name!] as string[];

	const dispatchDelete = (index: number) => {
		const entries = value.slice();
		entries.splice(index, 1);
		ctx.setFieldValue(name, entries);
	};

	const entries =
		value?.map((v, i) => {
			const label = props.options.find(
				opt => opt.value === v
			)?.label;
			return (
				<Entry
					key={v}
					readOnly={props.readOnly}
					onDelete={() => dispatchDelete(i)}
				>
					{label}
				</Entry>
			);
		}) || [];

	const addButton = !props.readOnly ? (
		<EntryAddButton type="button" onClick={() => setExpanded(true)}>
			<Icon name="plus" />
		</EntryAddButton>
	) : null;

	const handleChange = (e: any) => {
		ctx.setFieldValue(name, [...value, e.value]);
		setExpanded(false);
	};

	const options = props.options.filter(opt => !value.includes(opt.value));

	const drop = expanded ? (
		<Select options={options} onChange={handleChange} />
	) : null;

	return (
		<MultiWrapper onClick={evt => evt.stopPropagation()}>
			<MultiContent expanded={expanded}>
				{entries}
				{addButton}
			</MultiContent>
			{drop}
		</MultiWrapper>
	);
};

export default Multi;
