import { get } from "@qtxr/utils";

import { collectLayout } from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Data,
	DataPoint,
	Point
} from "../../apply-categorization";

const ShareConfig = {
	name: "share",
	collector: () => collectLayout((p: any) => {
		const points = p.data.points as Data[],
			catPoints = p.data.categorized.points as Point[];

		p.data.totals = points.map(pt => {
			return catPoints.reduce((acc, cp) => {
				return acc + get(pt, cp.accessor).value;
			}, 0)
		});

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const ds = p.dataset,
			w = ds.cWidth,
			h = ds.cHeight - 20,
			points = ds.data.points as Data[],
			space = 8,
			barWidth = (w - (space * (points.length - 1))) / points.length,
			groupLabel = ds.groupLabel,
			labelAllPoints = !!ds.labelAllPoints;
		let x = 0,
			groupData = [] as DataPoint[];

		plot.forEach(({ index, node, style }) => {
			const total = ds.data.totals[index];
			let y = h;

			plot.text(node.label)
				.x(x + barWidth / 2)
				.y(h + 14)
				.size(10)
				.weight(600)
				.anchor("middle")
				.baseline("middle")
				.fill(style.fullTheme.color)
				.inert();

			plot.forEachPoint(pt => {
				const height = (pt.value / total) * h,
					boundaryType = plot.getBoundaryType("nested");

				if (groupLabel && (boundaryType?.includes("start") || boundaryType?.includes("junction") || labelAllPoints))
					plot.pushBounding();
				if (groupLabel)
					groupData.push(pt.data);

				y -= height;

				plot.rect()
					.x(x)
					.y(y)
					.width(barWidth)
					.height(height)
					.fill(pt.color);

				if (!boundaryType?.includes("start")) {
					const separatorWidth = boundaryType?.includes("junction") ?
						1.5 :
						0.5;

					plot.line()
						.x1(x)
						.y1(y + height)
						.h(barWidth)
						.stroke(pt.style.fullTheme.graphBackground)
						.strokeWidth(separatorWidth)
						.stack(1);
				}

				if (groupLabel && (boundaryType?.includes("end") || labelAllPoints)) {
					const bounding = plot.popBounding();

					if (bounding.height > 10) {
						plot.text(groupLabel(groupData))
							.x(bounding.x + bounding.width / 2)
							.y(bounding.y + bounding.height / 2)
							.size(10)
							.weight(600)
							.anchor("middle")
							.baseline("middle")
							.shadow(style.fullTheme.textShadow)
							.fill(style.fullTheme.accentColor)
							.stack(2)
							.inert();
					}
					if (labelAllPoints) {
						groupData.pop();
					}
				}
			});

			groupData = [];

			x += (barWidth + space);
		});

		plot.render();
	},
	dataset: {
		id: "share",
		mode: "own",
		type: "share",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default ShareConfig;