import styled from "styled-components";

import useToast from "../../hooks/use-toast";
import useRequest from "../../hooks/use-request";
import useActionProps from "../../hooks/use-action-props";
import useTableActions from "../../hooks/use-table-actions";
import useGlobalActionColumns from "../../hooks/use-global-action-columns";

import { CYCLES, RACE_TYPES, CANDIDATE_TYPES } from "../../data/constants";
import states from "../../data/states.json";

import { mkLoadingState, request, resolveOfficeKey } from "../../utils";

import { useAppSelector } from "../../state/hooks";

import {
	Text,
	Multi,
	Button,
	Dropdown,
	InferredText,
	Form,
	InputRow,
	InputBox
} from "../../components/inputs";
import {
	VerticalSeparator,
	HorizontalSeparator
} from "../../components/separators";
import Base from "./base";
import Centered from "../../components/centered";
import ActionTable from "../../components/action-table";

import { CellProps, ColumnCell } from "../../types/data-table";
import { ViewRuntime } from "../../types/hierarchical";
import { useEffect, useMemo, useState } from "react";
import Markets from "../settings/markets";

const EMPTY_ROWS = [] as any[];

const COLUMNS: ColumnCell[] = [
	{
		name: "candidate",
		title: "Candidate / Committee",
		accessor: "candidate.full_name",
		index: "lexical"
	},
	{
		name: "shortName",
		title: "Short Name",
		accessor: "candidate.short_name",
		index: "lexical"
	},
	{
		name: "type",
		type: "choice",
		title: "Type",
		accessor: "candidate.candidate_type",
		config: {
			options: CANDIDATE_TYPES,
			labelAccessor: "label",
			valueAccessor: "value"
		}
	},
	{
		name: "team",
		title: "Team",
		accessor: "team.full_name",
		index: "lexical"
	}
];

const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 15px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

const BottomContent = styled.div`
	display: flex;
	flex-grow: 1;
	min-height: 10rem;
	overflow: hidden;
`;

const PromptBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
`;

const PromptText = styled.p`
	margin: 0 0 1.5em;
	max-width: 300px;
	text-align: center;
`;

const RightContent = styled.div`
	display: flex;
	flex-direction: column;
`;

const DropdownBox = styled(InputBox)`
	min-width: 130px;
`;

const OfficeDropdownBox = styled(InputBox)`
	min-width: 180px;
`;

const WideInputBox = styled(InputBox)`
	min-width: 300px;
`;

const NameInputBox = styled(InputBox)`
	flex-grow: 1;
`;

const ListInputBox = styled(InputBox)`
	min-width: 400px;
`;

const findExistingRace = (races: any[], race: any) => {
	const byId = races.find(r => r.id === race.id);
	if (byId) return byId;

	return races.find(
		r =>
			r.office?.id === race.office &&
			(r.race_type === "general" ||
				r.party?.id === parseInt(race.partyId)) &&
			r.race_type === race.type &&
			r.jurisdiction === race.state &&
			r.year === parseInt(race.cycle) &&
			r.district === race.district
	);
};

const Race = (props: ViewRuntime) => {
	const toast = useToast();
	const offices = useAppSelector(state => state.data.offices);
	const parties = useAppSelector(state => state.data.parties);
	const fetchedRaces = useAppSelector(state => state.data.races);
	const [newRaces, setNewRaces] = useState<any[]>([]);
	const races = [...fetchedRaces, ...newRaces];
	const [emailLists] = useRequest("/email_lists/list", []);

	const emailListOptions = useMemo(() => {
		return emailLists.map((race: any) => ({
			label: race.name,
			value: race.id
		}));
	}, [emailLists]);

	const raceId = props.state.race?.id;

	const columns = useGlobalActionColumns(COLUMNS, (p: CellProps) => {
		const d = p.row.row.data;

		props.setState("campaign", {
			id: d.id,
			name: d.candidate.full_name,
			shortName: d.candidate.short_name,
			candidate_type: d.candidate.candidate_type,
			party: d.party.id,
			team: d.team.id,
			agency: d.agency?.id,
			candidateId: d.candidate.id,
			cmagId: d.cmag_id,
			ottId: d.ott_id,
			googleAdsId: d.google_id,
			facebookId: d.facebook_id
		});

		props.navigate("campaign");
	});

	props.hook("save", async () => {
		const rData = props.state.race;

		const url =
			rData.id > -1
				? `/races/${rData.id}/save`
				: "/races/create";

		const method = rData.id > -1 ? "PUT" : "POST";

		const payload = {
			race_name: rData.name,
			race_type: rData.type,
			jurisdiction: rData.state,
			district: rData.district,
			year: String(rData.cycle),
			office_id: Number(rData.office)
		};

		if (rData.type === "primary")
			(payload as any).party_id = Number(rData.partyId);

		const response = await request({
			url,
			body: payload,
			method
		});

		if (!response.success) {
			toast.error(response.errorMessage!);
			return false;
		}

		setNewRaces((currRaces: any[]) => {
			return [...currRaces, response.data];
		});
		const newRaceId = response.data.id;
		props.setState("race", { id: newRaceId });

		props.updateState("races.searchHash", v => v + 1);
		toast("Saved race");
		return true;
	});

	const raceIdForCampaignQuery = () => {
		if (
			typeof props.state.race.id === "number" &&
			isFinite(props.state.race.id)
		) {
			return props.state.race.id;
		}
		return -1;
	};
	const [rawData, _loadingState] = useRequest(
		{
			url: "/campaigns/list",
			query: {
				race_id: raceIdForCampaignQuery()
			},
			success: data => {
				if (data)
					props.setState("race.id", data.race.id);
				else props.setState("race.id", -1);
				// save race props
				const r = data.race;
				props.setState("race", {
					state: r.jurisdiction,
					type: r.race_type,
					office: r.office.id,
					officeKey: resolveOfficeKey(
						r.office.short_name
					),
					party: r.party ? r.party.id : -1,
					district: r.district,
					name: r.race_name,
					cycle: r.year
				});
			},
			cacheHash: props.state.race.searchHash
		},
		null
	);

	const [rows, loadingState] = (function () {
		if (
			!_loadingState.success &&
			_loadingState.errorMessage === "No race found"
		) {
			return [EMPTY_ROWS, mkLoadingState("success")];
		}
		return [rawData?.campaigns || EMPTY_ROWS, _loadingState];
	})();

	const actions = useTableActions({
		save: () => true,
		deleteEach: row => {
			return request({
				method: "DELETE",
				url: `/campaigns/${row.id}`
			});
		}
	});

	const p = useActionProps({
		rows,
		columns,
		pageSize: 50,
		actions,
		loadingState,
		expand: true
	});

	const updateState = (values: any) => {
		const officeId = Number(values.office);

		const updatedValues = {
			...values,
			stateKey: values.state.toLowerCase(),
			office: officeId,
			officeKey: resolveOfficeKey(
				offices.find(office => office.id === officeId)
					?.short_name || ""
			)
		};

		const existingRace = findExistingRace(races, updatedValues) || {
			id: -1
		};
		props.setState("race", {
			...updatedValues,
			id: existingRace.id,
			name: updatedValues.name || existingRace.race_name
		});
	};

	const openCampaign = () => {
		props.setState("race.id", props.state.race.id);
		props.setState("campaign.id", -1);
		props.navigate("new-campaign");
	};

	let content;

	if (rows.length || loadingState.loading)
		content = <ActionTable {...p} />;
	else if (!loadingState.success)
		content = <Centered>{loadingState.errorMessage}</Centered>;
	else {
		content = (
			<PromptBox>
				<PromptText>
					This is an empty race. With these
					settings, a race will be created
					automatically.
				</PromptText>
				<Button onClick={openCampaign}>
					Add Campaign
				</Button>
			</PromptBox>
		);
	}

	const generateInferred = (values: any) => {
		const office = offices.find(
			(office: any) => office.id === Number(values.office)
		);
		const officeLabel = office?.short_name || office?.full_name;

		return `${values.state} - ${officeLabel}${
			values.district ? ` (${values.district})` : ""
		}`;
	};

	const partyDropdown =
		props.state.race.type === "primary" ? (
			<DropdownBox
				name="partyId"
				title="Party"
				input={Dropdown}
				options={parties}
				labelAccessor="full_name"
				valueAccessor="id"
			/>
		) : null;

	return (
		<Base>
			<Content>
				<Form
					values={props.state.race}
					onChange={updateState}
				>
					<InputRow>
						<InputBox
							name="state"
							title="State"
							input={Dropdown}
							options={states}
							labelAccessor="state"
							valueAccessor="state"
						/>
						<OfficeDropdownBox
							name="office"
							title="Office"
							input={Dropdown}
							options={offices}
							labelAccessor="full_name"
							valueAccessor="id"
						/>
						<DropdownBox
							name="type"
							title="Type"
							input={Dropdown}
							options={RACE_TYPES}
						/>
						{partyDropdown}
						<WideInputBox
							name="district"
							title="District"
							autoComplete="off"
							input={Text}
						/>
						<NameInputBox
							name="name"
							title="Name"
							autoComplete="off"
							input={InferredText}
							watch={[
								"state",
								"office",
								"district"
							]}
							generate={
								generateInferred
							}
						/>
						<InputBox
							name="cycle"
							title="Cycle"
							input={Dropdown}
							options={CYCLES}
						/>
					</InputRow>
				</Form>
				<HorizontalSeparator />
				<BottomContent>
					{content}
					<VerticalSeparator />
					<RightContent>
						<Form
							values={
								props.state.race
							}
							onChange={values => {
								return updateState(
									values
								);
							}}
						>
							<InputRow>
								<ListInputBox
									name="email_lists"
									title="Email Lists"
									input={
										Multi
									}
									options={
										emailListOptions
									}
									readOnly={
										true
									}
								/>
							</InputRow>
						</Form>
						<HorizontalSeparator />
					</RightContent>
				</BottomContent>
				{raceId >= 0 && (
					<>
						<HorizontalSeparator />
						<BottomContent>
							<Markets
								raceId={raceId}
							/>
						</BottomContent>
					</>
				)}
			</Content>
		</Base>
	);
};

export default Race;
