import useStateCapsule from "./use-state-capsule";

function useProxiedFunction<F extends (...args: any[]) => any>(
	fn: F
): F {
	const capsule = useStateCapsule(fn, true);

	const proxy = (...args: Parameters<F>): ReturnType<F> => {
		return capsule.get()(...args);
	};

	return proxy as F;
}

export default useProxiedFunction;
