import { get } from "@qtxr/utils";

import { collectLayout } from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Data, DataPoint,
	Point
} from "../../apply-categorization";

const MultiBarConfig = {
	name: "multi-bar",
	collector: () => collectLayout((p: any) => {
		const points = p.data.points as Data[],
			catPoints = p.data.categorized.points as Point[];

		p.data.totals = points.map(pt => {
			return catPoints.reduce((acc, cp) => {
				return acc + get(pt, cp.accessor).value;
			}, 0)
		});

		p.data.max = Math.max(...p.data.totals);
		return p.data;
	}),
	plotter: () => (p: any) => {

		const plot = new PlotSvg(p);

		const ds = p.dataset,
			w = ds.cWidth,
			h = ds.cHeight,
			points = ds.data.points as Data[],
			catPoints = ds.data.categorized.points as Point[],
			spacingBetweenBarGroups = 10,
			spacingBetweenBars = 2,
			numBarGroups = points.length,
			barGroupWidth = (w - (spacingBetweenBarGroups * (numBarGroups- 1))) / numBarGroups,
			barsPerGroup = ds.data.legends.legends.length,
			barWidth = Math.max(5, ((barGroupWidth - (spacingBetweenBars * (barsPerGroup - 1))) / barsPerGroup)),
			stepGroup = barGroupWidth + spacingBetweenBarGroups;


		for (let cpIdx=0; cpIdx < catPoints.length; cpIdx++) { // each category is an one of the series / legend items.
			let cp = catPoints[cpIdx];
			let max = cp.max; // we want to normalize each series for the maximum of that series (as opposed to all series' in the chart)
			plot.next();
			plot.with(cp);

			let x = 0;

			for (let i = 0, l = points.length; i < l; i++) {
				// each iteration is a bar in the graph for this particular category / series.

				const pt = points[i],
					dataValue = get(pt, cp.accessor).value,
					barHeight = dataValue ? Math.max((dataValue / max) * h, 1) : 1,
					xOffset = cpIdx * (barWidth + spacingBetweenBars) // each series is has a calculated offset within the bargroup cluster so they appear side by side.
				;

				plot.rect()
					.x(x + xOffset)
					.y(h - barHeight)
					.width(barWidth)
					.height(barHeight)
					.fill(cp.color);

				x += stepGroup;	// advance to the next cluster of bars.
			}

		}

		plot.render();
	},
	dataset: {
		id: "multi-bar",
		mode: "own",
		type: "multi-bar",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default MultiBarConfig;
