import {
	useMemo,
	useState
} from "react";
import styled, { css } from "styled-components";

import { getBoxFrame } from "../utils";

import Link from "./link";
import Icon from "./icon";

import {
	BoxFrame,
	BoxFrameArgs,
} from "../types/utils";
import { IconName } from "../types/icon";

interface ContextProps {
	items: ContextItem[];
	anchor?: Anchor;
	label?: string | (() => JSX.Element);
	frame?: BoxFrameArgs;
	children?: any;
}

interface WrapperProps {
	expanded: boolean;
}

interface ContextBodyNubProps {
	boxFrame: BoxFrame;
	children?: any;
	onClick?: (evt: any) => void;
}

interface ContextBodyProps {
	anchor: Anchor;
	boxFrame: BoxFrame;
	expanded: boolean;
}

interface ContextContentProps {
	items: ContextItem[];
	props: ContextProps;
	runtime: Runtime;
}

interface ItemIconProps {
	name: string;
	highlighted: boolean;
}

interface ExpandoTriggerProps {
	expanded: boolean;
	onClick: () => any;
}

interface ExpandoItemProps {
	props: ContextProps;
	items: ContextItem[];
	runtime: Runtime;
	expanded: boolean;
	toggleExpanded: () => void;
	children?: any;
}

export interface ContextItem {
	label: string;
	url?: string;
	children?: ContextItem[];
	onClick?: (evt: any) => void;
}

interface Runtime {
	expand: () => void;
	collapse: () => void;
}

type Anchor = "left" | "right";

const ContextWrapper = styled.div<WrapperProps>`
	position: relative;
	z-index: ${p => p.expanded ? 1000 : null};
`;

const ContextClickOff = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
`;

const ContextTrigger = styled.div`
	cursor: pointer;
`;

const ContextBodyWrapper = styled.div<Omit<ContextBodyProps, "boxFrame">>`
	position: absolute;
	display: ${p => p.expanded ? "block" : "none"};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const ContextBodyNubWrapper = styled.div<ContextBodyNubProps>`
	position: absolute;
	top: ${p => -p.boxFrame.top - 10}px;
	bottom: ${p => -p.boxFrame.bottom}px;
	left: ${p => -p.boxFrame.left - 10}px;
	right: ${p => -p.boxFrame.right - 10}px;
	padding: 10px 10px 0;
	overflow: hidden;
	pointer-events: none;
`;

const ContextBodyNubContent = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-weight: bold;
	text-transform: uppercase;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => `${p.theme.borderRadius} ${p.theme.borderRadius} 0 0`};
	box-shadow: ${p => p.theme.cardShadow};
	cursor: pointer;
	pointer-events: auto;
	user-select: none;

	&:before {
		content: "";
		position: absolute;
		left: 10px;
		right: 10px;
		bottom: 0;
		border-top: 1px solid ${p => p.theme.separatorColor};
	}
`;

const ContextBodyNub = (props: ContextBodyNubProps) => (
	<ContextBodyNubWrapper {...props}>
		<ContextBodyNubContent onClick={props.onClick}>
			{props.children}
		</ContextBodyNubContent>
	</ContextBodyNubWrapper>
);

const TransparentContextBodyNub = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
`;

const ContextBody = styled.div<ContextBodyProps>`
	position: absolute;
	top: 100%;
	left: ${p => p.anchor === "left" ? `${-p.boxFrame.left}px` : null};
	right: ${p => p.anchor === "right" ? `${-p.boxFrame.right}px` : null};
	min-width: ${p =>
		p.boxFrame.left + p.boxFrame.right ?
			`calc(100% + ${p.boxFrame.left + p.boxFrame.right}px)` :
			"100%"
	};
	padding: 10px 0;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.anchor === "left" ?
		`0 ${p.theme.borderRadius} ${p.theme.borderRadius} ${p.theme.borderRadius}` :
		`${p.theme.borderRadius} 0 ${p.theme.borderRadius} ${p.theme.borderRadius}`
	};
	box-shadow: ${p => p.theme.cardShadow};
	z-index: -1;
`;

const ITEM_STYLE = css`
	display: flex;
	position: relative;
	height: 34px;
	color: inherit;
	user-select: none;

	&:hover,
	&.active {
		background: ${p => p.theme.subtleAlt};
	}
`;

const ItemWrapper = styled.div`
	${ITEM_STYLE};
`;

const LinkItemWrapper = styled(Link)`
	${ITEM_STYLE};
`;

const ExpandoWrapper = styled.div`
	position: relative;
`;

const ExpandoTrigger = styled.div<ExpandoTriggerProps>`
	${ITEM_STYLE};
	background: ${p => p.expanded ? p.theme.subtleAlt : "transparent"};
	cursor: pointer;
`;

const ExpandoBodyWrapper = styled.div<ExpandoItemProps>`
	position: absolute;
	top: 0;
	left: ${p => p.props.anchor === "left" ? "100%" : null};
	right: ${p => p.props.anchor === "right" ? "100%" : null};
	background: ${p => p.theme.cardBackground};
	padding: 5px 0;
	margin: -5px 5px;
	box-shadow: ${p => p.theme.cardShadow};
	border-radius: ${p => p.theme.borderRadius};
	z-index: -1;
`;

const ExpandoBody = (props: ExpandoItemProps) => {
	return (
		<ExpandoBodyWrapper {...props}>
			<ContextContent
				items={props.items}
				props={props}
				runtime={props.runtime}
			/>
		</ExpandoBodyWrapper>
	);
};

const ExpandoItem = (props: ExpandoItemProps) => {
	const body = props.expanded ?
		<ExpandoBody {...props} /> :
		null;

	return (
		<ExpandoWrapper>
			<ExpandoTrigger
				expanded={props.expanded}
				onClick={props.toggleExpanded}
			>
				{props.children}
			</ExpandoTrigger>
			{body}
		</ExpandoWrapper>
	);
};

const ItemIcon = styled(Icon)<ItemIconProps>`
	width: 34px;
	height: 34px;
	padding: 11px;
	margin-left: 2px;
	opacity: ${p => p.highlighted ? 0.7 : 0.2};
`;

const ItemContent = styled.div`
	display: flex;
	align-items: center;
	font-size: 1rem;
	font-weight: bold;
	white-space: nowrap;
	text-transform: uppercase;
	margin-right: 20px;
`;

const ContextContent = (props: ContextContentProps) => {
	const [subExpandedIndex, setSubExpandedIndex] = useState(-1);

	const items = props.items.map((item, idx) => {
		const hasChildren = Array.isArray(item.children),
			iconName: IconName = hasChildren ?
				"thick-chevron-left" :
				"thick-chevron-right";
		let Wrapper: any = ItemWrapper,
			wrapperProps = {};

		if (hasChildren) {
			Wrapper = ExpandoItem;
			wrapperProps = {
				props: props.props,
				items: item.children,
				expanded: idx === subExpandedIndex,
				toggleExpanded: () => {
					if (idx === subExpandedIndex)
						setSubExpandedIndex(-1);
					else
						setSubExpandedIndex(idx);
				}
			};
		} else if (item.url) {
			Wrapper = LinkItemWrapper;
			wrapperProps = {
				to: item.url
			};
		}

		const defaultOnClick = () => {
			if (props.runtime)
				props.runtime.collapse();

			setSubExpandedIndex(-1);
		};

		return (
			<Wrapper
				key={idx}
				onClick={defaultOnClick}
				{...wrapperProps}
			>
				<ItemIcon
					name={iconName}
					highlighted={hasChildren}
				/>
				<ItemContent>{item.label}</ItemContent>
			</Wrapper>
		);
	});

	return (
		<>
			{items}
		</>
	);
};

const Context = (props: ContextProps) => {
	const [expanded, setExpanded] = useState(false);

	const boxFrame = useMemo(() => {
		return getBoxFrame(props.frame);
	}, [props.frame]);

	const anchor: Anchor = props.anchor || "left";

	const expand = () => setExpanded(true);
	const collapse = () => setExpanded(false);

	const runtime: Runtime = {
		expand,
		collapse
	};

	const Nub = props.label ?
		ContextBodyNub :
		TransparentContextBodyNub;

	const label = typeof props.label == "function" ?
		<props.label /> :
		props.label || "";

	const clickOff = expanded ?
		<ContextClickOff onClick={collapse} /> :
		null;

	const body = expanded ?
		(
			<ContextBody
				anchor={anchor}
				boxFrame={boxFrame}
				expanded={expanded}
			>
				<ContextContent
					items={props.items}
					props={props}
					runtime={runtime}
				/>
			</ContextBody>
		) :
		null;

	return (
		<ContextWrapper expanded={expanded}>
			{clickOff}
			<ContextTrigger onClick={expand}>
				{props.children}
			</ContextTrigger>
			<ContextBodyWrapper
				anchor={anchor}
				expanded={expanded}
			>
				<Nub
					boxFrame={boxFrame}
					onClick={collapse}
				>
					{label}
				</Nub>
				{body}
			</ContextBodyWrapper>
		</ContextWrapper>
	);
};

export default Context;
