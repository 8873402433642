import {
	useRef,
	useMemo,
	useState,
	useEffect
} from "react";
import styled, { withTheme } from "styled-components";

import Viz from "@qtxr/viz";

import {
	mkGraphConfig,
	collectTooltips
} from "../common";
import applyLayout, { Layout } from "../apply-layout";

import Legends from "../legends";
import VizWrapper from "./viz-wrapper";

import {
	VizImpl,
	LegendsProps,
	CoreGraphProps,
	LegendsPosition,
} from "../../../types/viz";

interface WrapperProps {
	bare?: boolean;
	position?: LegendsPosition | null;
}

interface LayoutData {
	legends: LegendsProps;
	layout: Layout | null;
}

const Wrapper = styled.article<WrapperProps>`
	display: flex;
	flex-direction: ${p =>
		!p.position || p.position === "top" || p.position === "bottom" ?
			"column" :
			"row"
	};
	flex-grow: 1;
	flex-basis: 0;
	padding: ${p => p.bare ? null : "8px"};
	background: ${p => p.bare ? null : p.theme.graphBackground};
	border-radius: ${p => p.bare ? null : p.theme.borderRadius};
	overflow: hidden;
`;

const Subheading = styled.div`
	font-weight: bold;
	margin-bottom: 5px;
`;

const CoreGraph = (props: CoreGraphProps) => {
	const wrapperRef = useRef(null as HTMLDivElement | null);
	const [viz, setViz] = useState(null as VizImpl | null);

	const minHeight = typeof props.getHeight == "function" ?
		props.getHeight(props.data) :
		null;

	const vizWrapper = useMemo(
		() => (
			<VizWrapper
				className={props.className}
				ref={wrapperRef}
			/>
		),
		[]
	);

	const applyLegendData = (p: LegendsProps): LegendsProps => {
		const out = { ...p };

		if (!out.position)
			out.position = "top";

		if (!out.direction) {
			out.direction = out.position === "top" || out.position === "bottom" ?
				"row" :
				"column";
		}

		return out;
	};

	const lProps = useMemo(
		() => {
			if (!props.legends)
				return null

			let lgd;

			if (Array.isArray(props.legends)) {
				lgd = {
					legends: props.legends
				} as LegendsProps;
			} else
				lgd = { ...props.legends } as LegendsProps;

			if (!lgd.legends.length)
				return null;

			return applyLegendData(lgd);
		},
		[props.legends]
	) as LegendsProps | null;

	const {
		legends,
		layout
	} = useMemo(
		() => {
			if (!props.layout && !props.applyLayout) {
				return {
					legends: lProps,
					layout: null
				};
			}

			const lo = applyLayout(props.data, props.layout || {}, props.kpi);
			let lgds = lProps;

			if (lo.legends && !Array.isArray(lo.legends)) {
				lgds = applyLegendData({
					...(lo.legends || {}),
					...(lProps || {})
				});
			}

			return {
				legends: lgds,
				layout: lo
			};
		},
		[lProps, props.layout, props.data]
	) as LayoutData;

	const baseConfig = useMemo(
		() => {
			const styleConfig = {
				config: {
					style: {
						...props.theme.graphs,
						fullTheme: props.theme
					}
				}
			};

			const minHeightConfig = {
				dataset: {
					minHeight: minHeight || null
				}
			};
			let tooltipConfig = {};

			if (props.tooltips) {
				const tips = Array.isArray(props.tooltips) ?
					props.tooltips :
					[props.tooltips];

				tooltipConfig = {
					dataset: {
						tooltip: collectTooltips(...tips)
					}
				};
			}

			return mkGraphConfig(
				minHeightConfig,
				styleConfig,
				tooltipConfig,
				props.config,
			);
		},
		[props.config, props.tooltips, minHeight]
	);

	useEffect(
		() => {
			let ro = null as ResizeObserver | null;

			const v = new Viz(
				wrapperRef.current,
				baseConfig
			);

			if (typeof ResizeObserver !== "undefined") {
				ro = new ResizeObserver(() => {
					if (!v.flags.resizing)
						v.hardUpdate();
				});
				ro.observe(wrapperRef.current!);
			}

			// @ts-ignore
			window.vs = window.vs || [];
			// @ts-ignore
			window.vs.push(v);

			setViz(v);

			return () => {
				if (ro)
					ro.disconnect();
			};
		},
		[baseConfig]
	);

	useEffect(
		() => {
			if (!viz)
				return;

			const data = {} as any,
				dataSource = layout || props.data,
				datasets = viz.currentGraphData.datasets;
			let count = 0;

			// First loop through datasets to find explicitly declared data
			// (data provided as individual datasets by dataset ID or "any" key)
			for (const dataset of datasets) {
				if (!dataSource.isLayout && dataSource.hasOwnProperty(dataset.id)) {
					data[dataset.id] = dataSource[dataset.id];
					count++;
				} else if (dataSource.hasOwnProperty("any")) {
					data[dataset.id] = dataSource.any;
					count++;
				}
			}

			// Supply store data
			if (props.store) {
				for (const k in props.store) {
					if (!props.store.hasOwnProperty(k))
						continue;

					for (const dataset of datasets)
						dataset[k] = props.store[k];
				}
			}

			// Handle implicit data (data provided is a singular dataset)
			if (!count) {
				for (const dataset of datasets)
					data[dataset.id] = dataSource;
			} else if (count !== datasets.length)
				throw new Error("Cannot add data: mixed explicit and implicit data");

			viz.setData(data);

			if (typeof props.track == "function") {
				props.track({
					data,
					layout,
					store: props.store
				});
			}
		},
		[viz, layout, props.data]
	);

	if (typeof props.track == "function") {
		props.track({
			config: baseConfig
		});
	}

	const subheading = props.subheading ?
		<Subheading>{props.subheading}</Subheading> :
		null;

	let content;

	if (!legends || !legends.legends.length)
		content = vizWrapper;
	else if (legends.position === "top" || legends.position === "left") {
		content = (
			<>
				{subheading}
				<Legends {...legends} />
				{vizWrapper}
			</>
		);
	} else {
		content = (
			<>
				{subheading}
				{vizWrapper}
				<Legends {...legends} />
			</>
		);
	}

	return (
		<Wrapper
			bare={props.bare}
			position={legends?.position}
			className={props.className}
		>
			{content}
		</Wrapper>
	);
};

const Graph = withTheme(CoreGraph);

export default Graph;
