import styled from "styled-components";

const ContentCard = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 5px 5px 10px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

export default ContentCard;
