import styled from "styled-components";

import useTableActions from "../../hooks/use-table-actions";

import Base from "../base";

import {
	Text,
	MultiInput,
	InputBox,
	Form,
	InputRow,
	Dropdown
} from "../../components/inputs";
import SplitBox, { SplitProps } from "../../components/split-box";

import { ColumnCell } from "../../types/data-table";
import { useAppSelector } from "../../state/hooks";
import { request } from "../../utils";
import useRemoteActionProps from "../../hooks/use-remote-action-props";
import { useMemo } from "react";
import { Field } from "formik";

const Logo = styled.img`
	position: relative;
	width: 150px;
	height: auto;
	background: ${p => p.theme.background};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

const ContentWrapper = styled.div`
	max-width: 600px;
`;

const GrowingInputBox = styled(InputBox)`
	flex-grow: 1;
`;

const Content = (props: SplitProps) => {
	const agencies = useAppSelector(state => state.data.agencies);
	const agencyOptions = [
		{ value: "", label: "(none)" },
		...agencies.map(agency => ({
			value: agency.id.toString(),
			label: agency.full_name
		}))
	];
	const logo = useMemo(() => {
		const agency = agencies.find(
			a => a.id.toString() === props.focusedRow.agency_id
		);
		return agency?.logo || "";
	}, [props.focusedRow.agency_id]);

	return (
		<ContentWrapper>
			<Form values={props.focusedRow} onChange={props.edit}>
				<InputRow>
					<GrowingInputBox
						name="name"
						title="Unique Name"
						autoComplete="off"
						input={Text}
					/>
				</InputRow>
				<InputRow>
					<GrowingInputBox
						name="emails"
						title="Recipients"
						input={MultiInput}
					/>
				</InputRow>
				<InputRow>
					<GrowingInputBox
						name="agency_id"
						title="Agency"
						input={Dropdown}
						options={agencyOptions}
					/>
					{logo && (
						<Logo
							title="Agency Logo"
							src={logo}
						/>
					)}
				</InputRow>
				<Field type="hidden" name="agency_name" />
				<Field type="hidden" name="agency_logo" />
			</Form>
		</ContentWrapper>
	);
};

const Emails = () => {
	const agencies = useAppSelector(state => state.data.agencies);

	const COLUMNS: ColumnCell[] = [
		{
			name: "name",
			title: "Unique Name",
			accessor: "name",
			index: "lexical"
		},
		{
			name: "agency",
			title: "Agency",
			accessor: "agency_id",
			content: id => (
				<>
					{agencies.find(
						a =>
							a.id.toString() ===
							id.value.toString()
					)?.full_name || ""}
				</>
			)
		},
		{
			name: "members",
			title: "Members",
			accessor: "emails",
			content: id => <>{id.value?.length || 0}</>
		},
		{
			name: "logo",
			type: "hidden",
			title: "Logo",
			accessor: "agency_logo"
		}
	];

	const actions = useTableActions({
		addLabel: "Add Email List",
		add: () => ({
			name: "",
			emails: [],
			agency_id: "",
			agency_logo: "",
			agency_name: ""
		}),
		createEach: row => {
			return request({
				url: "/email_lists/create",
				body: { ...row }
			});
		},
		modifyEach: row => {
			return request({
				url: `/email_lists/${row.id}`,
				body: { ...row },
				method: "PUT"
			});
		},
		deleteEach: row => {
			return request({
				url: `/email_lists/${row.id}`,
				method: "DELETE"
			});
		}
	});

	const props = useRemoteActionProps({
		request: "/email_lists/list",
		columns: COLUMNS,
		pageSize: 50,
		process: email_list => {
			const { agency, agency_id, ...rest } = email_list;
			return {
				...rest,
				agency_id,
				agency_name: agency.full_name,
				agency_logo: agency.logo
			};
		},
		actions,
		expand: true
	});

	return (
		<Base limited>
			<SplitBox
				{...props}
				autoFocus
				title="Email List"
				notice="No email list focused"
				content={Content}
			/>
		</Base>
	);
};

export default Emails;
