import StagedComponent, {
	scaled,
	mkSetterMaker
} from "./";

import { stubString } from "../../common";

import {
	StyleParam,
	TextAnchor,
	AlignmentBaseline
} from "../../../../types/plot-svg";

const ms = mkSetterMaker<StagedText>();

export default class StagedText extends StagedComponent {
	ownStyleParams = [
		{ key: "text", required: true },
		{ key: "x", required: true },
		{ key: "y", required: true },
		{ key: "font-size", publicName: "size", default: 12 },
		{ key: "text-anchor", publicName: "anchor" },
		{ key: "alignment-baseline", publicName: "baseline" },
		{ key: "font-weight", publicName: "weight" },
		{ key: "text-shadow", publicName: "shadow", target: "style" },
		{ key: "maxLength", enumerable: false },
		{ key: "hideIfTooLong", enumerable: false }
	] as StyleParam[];

	open(text?: any): this {
		return super.open(text);
	}

	text = ms("text", (text: any) => String(text));
	x = ms("x", (x: number) => scaled(x));
	y = ms("y", (y: number) => scaled(y));
	size = ms("font-size", (size: number) => scaled(size));
	anchor = ms("text-anchor", (anchor: TextAnchor) => anchor);
	baseline = ms("alignment-baseline", (baseline: AlignmentBaseline) => baseline);
	weight = ms("font-weight", (weight: number | string) => weight);
	shadow = ms("text-shadow", (shadow: string) => shadow);
	maxLength = ms("maxLength", (maxLength: number) => maxLength);
	hideIfTooLong = ms("hideIfTooLong", (hideIfTooLong: boolean) => hideIfTooLong)

	render() {
		this.mount("text", ({ key, value, target, apply }) => {
			if (key === "text") {
				if (this.style.hideIfTooLong && value.length > this.style.maxLength)
					target.textContent = ""
				else if (this.style.maxLength)
					target.textContent = stubString(value, this.style.maxLength);
				else
					target.textContent = value;
			} else
				apply();
		});
	}
}
