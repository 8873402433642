import styled, {
	css,
	withTheme
} from "styled-components";

import { get } from "@qtxr/utils";

import {
	Legend,
	LegendStyle,
	FullLegendsProps,
} from "../../types/viz";

interface IconProps {
	color: string;
}

interface LegendEntryProps {
	legend: Legend;
	theme: any;
}

const LegendsWrapper = styled.div<FullLegendsProps>`
	display: flex;
	flex-direction: ${p => p.direction === "row" ? "row" : "column"};
	flex-flow: ${p => p.direction === "row" ? "wrap" : null};
	gap: ${p => p.direction === "row" ? "8px" : "5px"};

	${p => ({
		paddingTop: p.position === "bottom" ? "10px" : undefined,
		paddingRight: p.position === "left" ? "10px" : undefined,
		paddingBottom: p.position === "top" ? "10px" : undefined,
		paddingLeft: p.position === "right" ? "10px" : undefined
	})};
`;

const LegendEntryWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const LegendTitle = styled.div`
	margin-left: 4px;
	font-size: 90%;
	line-height: 1;
`;

const LegendEntry = (props: LegendEntryProps) => {
	const Icon = ICONS[props.legend.style || "square"],
		color = get(props.theme, props.legend.color, props.legend.color);

	return (
		<LegendEntryWrapper>
			<Icon color={color} />
			<LegendTitle>{props.legend.title}</LegendTitle>
		</LegendEntryWrapper>
	);
};

const ICON_STYLE = css`
	width: ${p => p.theme.legendIconHeight};
	height: ${p => p.theme.legendIconHeight};
`;

const SquareIcon = styled.div<IconProps>`
	${ICON_STYLE};
	border-radius: 2px;
	background: ${p => p.color};
`;

const ICONS: Record<LegendStyle, (props: IconProps) => JSX.Element> = {
	square: SquareIcon,
	circle: () => <></>,
	line: () => <></>
};

const LegendsCore = (props: FullLegendsProps) => {
	const legends = props.legends.map((legend, idx) => (
		<LegendEntry
			key={idx}
			legend={legend}
			theme={props.theme.graphs}
		/>
	));

	return (
		<LegendsWrapper {...props}>
			{legends}
		</LegendsWrapper>
	);
};

const Legends = withTheme(LegendsCore);

export default Legends;
