import WeeklyGrpGraphConfig from "./configs/weekly-grp";

import Base from "./base";

import { GraphProps } from "../../../types/viz";

const WeeklyGrpGraph = (props: GraphProps) => {
	return <Base {...props} configs={WeeklyGrpGraphConfig} />;
};

export default WeeklyGrpGraph;