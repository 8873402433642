import { pushToast } from "../state/features/ui";
import { useAppDispatch } from "../state/hooks";

import {
	ToastType,
	ToastConfig,
	FullToastConfig
} from "../types/ui";

interface Dispatchers extends Record<ToastType, Dispatcher> {
	(config: ToastConfig | string, timeout?: number): void;
}

type Dispatcher = (config: ToastConfig | string, timeout?: number) => void;

let toastId = 0;

const useToast = (defaultTimeout: number = 3000): Dispatchers => {
	const dispatch = useAppDispatch();

	const runDispatch = (type: ToastType, config: ToastConfig | string, timeout?: number) => {
		let outConfig: Partial<FullToastConfig>;

		if (typeof config == "string")
			outConfig = { message: config };
		else
			outConfig = { ...config };

		if (!outConfig.hasOwnProperty("type"))
			outConfig.type = type;
		if (!outConfig.hasOwnProperty("timeout"))
			outConfig.timeout = defaultTimeout;

		if (typeof timeout == "number")
			outConfig.timeout = timeout;

		outConfig.id = toastId++;
		dispatch(pushToast(outConfig as FullToastConfig));
	};

	const mountDispatcher = (type: ToastType): Dispatcher => {
		return (config: ToastConfig | string, timeout?: number) => {
			runDispatch(type, config, timeout);
		};
	};

	const dispatchers = mountDispatcher("info") as Dispatchers;

	dispatchers.info = mountDispatcher("info");
	dispatchers.warn = mountDispatcher("warn");
	dispatchers.error = mountDispatcher("error");

	return dispatchers;
};

export default useToast;