import styled from "styled-components";

interface TextSeparatorProps {
	children?: any;
}

interface ClassNameProps {
	className?: string;
}

const HorizontalSeparatorOuter = styled.div`
	display: flex;
	flex-shrink: 0;
	color: ${p => p.theme.separatorColor};
`;

const HorizontalSeparatorInner = styled.div`
	flex-grow: 1;
	height: ${p => p.theme.separatorSpacing};
	border-top: 1px solid;
	margin-top: ${p => p.theme.separatorSpacing};
`;

const HorizontalSeparator = (props: ClassNameProps) => (
	<HorizontalSeparatorOuter className={props.className}>
		<HorizontalSeparatorInner />
	</HorizontalSeparatorOuter>
);

const VerticalSeparatorOuter = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	color: ${p => p.theme.separatorColor};
`;

const VerticalSeparatorInner = styled.div`
	flex-grow: 1;
	width: ${p => p.theme.separatorSpacing};
	border-left: 1px solid;
	margin-left: ${p => p.theme.separatorSpacing};
`;

const VerticalSeparator = (props: ClassNameProps) => (
	<VerticalSeparatorOuter className={props.className}>
		<VerticalSeparatorInner />
	</VerticalSeparatorOuter>
);

const TextSeparatorWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const TextPreSeparator = styled(HorizontalSeparator)`
	width: 10px;
	flex-shrink: 0;
	color: inherit;
	opacity: 0.2;
`;

const TextSeparatorContent = styled.h3`
	font-size: 100%;
	margin: 0 0.3em;
`;

const TextPostSeparator = styled(HorizontalSeparator)`
	flex-grow: 1;
	color: inherit;
	opacity: 0.2;
`;

const TextSeparator = (props: TextSeparatorProps) => (
	<TextSeparatorWrapper>
		<TextPreSeparator />
		<TextSeparatorContent>{props.children}</TextSeparatorContent>
		<TextPostSeparator />
	</TextSeparatorWrapper>
);

export {
	HorizontalSeparator,
	VerticalSeparator,
	TextSeparator
};
