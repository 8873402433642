import styled from "styled-components";

const LogoWrapper = styled.svg`
	width: 100%;
	vertical-align: middle;
`;

const Logo = () => (
	<LogoWrapper
		width="170"
		height="60"
		viewBox="0 0 170 60"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="30" cy="30" r="29.5" stroke="#CCD0DB"/>
		<path d="M42.0142 14.5152L8.61649 18.2725C6.71505 18.4864 5.49712 20.4017 6.10985 22.2143L15.3958 49.6852C16.1204 51.8289 18.4774 52.9452 20.595 52.1477L52.6607 40.0709C53.7167 39.6732 54.2348 38.4812 53.8051 37.4378L45.1236 16.3542C44.6138 15.1159 43.345 14.3655 42.0142 14.5152Z" stroke="#6A8EB1"/>
		<path d="M41.7669 15.9241L9.80249 19.8649C8.54851 20.0195 7.7503 21.2837 8.14984 22.4823L16.728 48.2169C17.2629 49.8214 19.0176 50.6669 20.6057 50.0853L51.6449 38.7188C52.1785 38.5234 52.4423 37.9232 52.2253 37.398L43.8602 17.1455C43.5167 16.3138 42.66 15.814 41.7669 15.9241Z" fill="#A7B9D4"/>
		<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="1" width="46" height="50">
			<path d="M52.6229 37.6619V3.96722C52.6229 2.86265 51.7275 1.96722 50.6229 1.96722H7.37704V19.8394C7.37704 20.0544 7.4117 20.2679 7.47968 20.4718L16.728 48.2169C17.2629 49.8214 19.0176 50.6669 20.6057 50.0853L51.9668 38.6009C52.3609 38.4566 52.6229 38.0816 52.6229 37.6619Z" fill="#A7B9D4"/>
		</mask>
		<g mask="url(#mask0)">
			<path d="M21.6229 13.7705H19.6885C19.1362 13.7705 18.6885 14.2182 18.6885 14.7705V53.0984C18.6885 53.6507 19.1362 54.0984 19.6885 54.0984H21.6229C22.1752 54.0984 22.6229 53.6507 22.6229 53.0984V14.7705C22.6229 14.2182 22.1752 13.7705 21.6229 13.7705Z" fill="#3B6FA1"/>
			<path d="M37.3606 7.86887H35.4262C34.8739 7.86887 34.4262 8.31658 34.4262 8.86887V47.1967C34.4262 47.749 34.8739 48.1967 35.4262 48.1967H37.3606C37.9129 48.1967 38.3606 47.749 38.3606 47.1967V8.86887C38.3606 8.31658 37.9129 7.86887 37.3606 7.86887Z" fill="#3B6FA1"/>
			<path d="M48.1803 17.7049H46.2459C45.6936 17.7049 45.2459 18.1526 45.2459 18.7049V57.0328C45.2459 57.5851 45.6936 58.0328 46.2459 58.0328H48.1803C48.7326 58.0328 49.1803 57.5851 49.1803 57.0328V18.7049C49.1803 18.1526 48.7326 17.7049 48.1803 17.7049Z" fill="#3B6FA1"/>
			<path d="M25.5574 26.5574H23.6229C23.0706 26.5574 22.6229 27.0051 22.6229 27.5574V65.8853C22.6229 66.4376 23.0706 66.8853 23.6229 66.8853H25.5574C26.1096 66.8853 26.5574 66.4376 26.5574 65.8853V27.5574C26.5574 27.0051 26.1096 26.5574 25.5574 26.5574Z" fill="#2C5E8E"/>
			<path d="M44.2459 24.5902H42.3115C41.7592 24.5902 41.3115 25.0379 41.3115 25.5902V63.918C41.3115 64.4703 41.7592 64.918 42.3115 64.918H44.2459C44.7982 64.918 45.2459 64.4703 45.2459 63.918V25.5902C45.2459 25.0379 44.7982 24.5902 44.2459 24.5902Z" fill="#5F88B0"/>
			<path d="M33.4262 13.7705H31.4918C30.9395 13.7705 30.4918 14.2182 30.4918 14.7705V53.0984C30.4918 53.6507 30.9395 54.0984 31.4918 54.0984H33.4262C33.9785 54.0984 34.4262 53.6507 34.4262 53.0984V14.7705C34.4262 14.2182 33.9785 13.7705 33.4262 13.7705Z" fill="#6F9DCA"/>
		</g>
		<path d="M88.1418 24.7629C88.1418 26.5723 87.4884 27.9978 86.1817 29.0396C84.884 30.0813 83.0747 30.6022 80.7535 30.6022C78.6152 30.6022 76.7236 30.2001 75.0787 29.396V25.4482C76.4311 26.0514 77.5734 26.4763 78.5055 26.723C79.4468 26.9698 80.3058 27.0931 81.0825 27.0931C82.0146 27.0931 82.7274 26.9149 83.2209 26.5585C83.7235 26.2022 83.9748 25.6721 83.9748 24.9685C83.9748 24.5755 83.8651 24.2283 83.6458 23.9267C83.4265 23.616 83.1021 23.319 82.6726 23.0357C82.2522 22.7525 81.3886 22.3001 80.0819 21.6787C78.8573 21.103 77.939 20.5501 77.3267 20.0201C76.7144 19.4901 76.2255 18.8733 75.86 18.1696C75.4945 17.466 75.3117 16.6435 75.3117 15.7023C75.3117 13.9295 75.9103 12.5359 77.1074 11.5215C78.3136 10.5072 79.9768 10 82.0969 10C83.1386 10 84.1301 10.1234 85.0714 10.3701C86.0218 10.6168 87.0133 10.9641 88.0459 11.4119L86.6751 14.7153C85.606 14.2767 84.7195 13.9706 84.0159 13.797C83.3214 13.6233 82.636 13.5365 81.9598 13.5365C81.1556 13.5365 80.5388 13.7238 80.1093 14.0985C79.6798 14.4732 79.465 14.9621 79.465 15.5652C79.465 15.9399 79.5519 16.2689 79.7255 16.5521C79.8991 16.8263 80.1733 17.0959 80.5479 17.3609C80.9317 17.6167 81.8319 18.0828 83.2483 18.759C85.1216 19.6546 86.4056 20.5547 87.1001 21.4594C87.7946 22.3549 88.1418 23.4561 88.1418 24.7629Z" fill="#323E5A"/>
		<path d="M100.259 30.3281L95.4342 14.6057H95.3108C95.4844 17.8041 95.5712 19.9379 95.5712 21.007V30.3281H91.7743V10.2879H97.5588L102.302 25.6127H102.384L107.414 10.2879H113.199V30.3281H109.238V20.8426C109.238 20.3948 109.242 19.8785 109.251 19.2936C109.27 18.7088 109.333 17.1553 109.443 14.6331H109.32L104.152 30.3281H100.259Z" fill="#323E5A"/>
		<path d="M130.525 30.3281L129.072 25.5579H121.766L120.313 30.3281H115.735L122.808 10.2056H128.003L135.103 30.3281H130.525ZM128.058 21.994C126.714 17.6716 125.956 15.2271 125.782 14.6605C125.618 14.0939 125.499 13.6462 125.426 13.3172C125.124 14.4869 124.261 17.3792 122.835 21.994H128.058Z" fill="#323E5A"/>
		<path d="M141.889 19.184H143.259C144.603 19.184 145.594 18.9601 146.234 18.5123C146.873 18.0645 147.193 17.3609 147.193 16.4014C147.193 15.451 146.864 14.7747 146.206 14.3727C145.558 13.9706 144.548 13.7695 143.177 13.7695H141.889V19.184ZM141.889 22.6382V30.3281H137.639V10.2879H143.479C146.202 10.2879 148.217 10.7859 149.524 11.782C150.83 12.7689 151.484 14.2721 151.484 16.2917C151.484 17.4705 151.159 18.5214 150.511 19.4444C149.862 20.3582 148.943 21.0756 147.755 21.5965C150.771 26.1016 152.736 29.0122 153.65 30.3281H148.934L144.15 22.6382H141.889Z" fill="#323E5A"/>
		<path d="M163.903 30.3281H159.653V13.8244H154.212V10.2879H169.345V13.8244H163.903V30.3281Z" fill="#323E5A"/>
		<path d="M79.9061 41.0961C79.0717 41.0961 78.4257 41.4108 77.968 42.0402C77.5102 42.6647 77.2814 43.5372 77.2814 44.6577C77.2814 46.9891 78.1563 48.1549 79.9061 48.1549C80.6403 48.1549 81.5295 47.9713 82.5736 47.6042V49.4636C81.7154 49.8212 80.7571 50 79.6987 50C78.1777 50 77.0144 49.5399 76.2086 48.6197C75.4029 47.6948 75 46.3693 75 44.6434C75 43.5563 75.1979 42.6051 75.5936 41.7898C75.9893 40.9698 76.5567 40.3428 77.2957 39.909C78.0395 39.4703 78.9096 39.251 79.9061 39.251C80.9216 39.251 81.9419 39.4965 82.967 39.9876L82.2518 41.7898C81.8609 41.6039 81.4675 41.4418 81.0718 41.3035C80.6761 41.1653 80.2875 41.0961 79.9061 41.0961Z" fill="#888F9F"/>
		<path d="M94.2953 44.6148C94.2953 46.3455 93.8662 47.6757 93.008 48.6054C92.1497 49.5351 90.9197 50 89.3177 50C87.7157 50 86.4856 49.5351 85.6274 48.6054C84.7692 47.6757 84.3401 46.3407 84.3401 44.6005C84.3401 42.8602 84.7692 41.5324 85.6274 40.617C86.4904 39.6968 87.7252 39.2367 89.332 39.2367C90.9387 39.2367 92.1664 39.6992 93.0151 40.6241C93.8685 41.5491 94.2953 42.8793 94.2953 44.6148ZM86.6644 44.6148C86.6644 45.7829 86.8861 46.6625 87.3295 47.2537C87.7729 47.8449 88.4356 48.1405 89.3177 48.1405C91.0865 48.1405 91.971 46.9653 91.971 44.6148C91.971 42.2595 91.0913 41.0818 89.332 41.0818C88.4499 41.0818 87.7848 41.3798 87.3367 41.9758C86.8885 42.567 86.6644 43.4467 86.6644 44.6148Z" fill="#888F9F"/>
		<path d="M100.889 49.857L98.3717 41.654H98.3074C98.3979 43.3227 98.4432 44.436 98.4432 44.9938V49.857H96.4622V39.4012H99.4802L101.955 47.3968H101.998L104.622 39.4012H107.64V49.857H105.573V44.908C105.573 44.6744 105.576 44.405 105.581 44.0998C105.59 43.7947 105.624 42.9842 105.681 41.6683H105.616L102.92 49.857H100.889Z" fill="#888F9F"/>
		<path d="M112.496 44.3215H113.226C113.908 44.3215 114.418 44.188 114.756 43.9211C115.095 43.6493 115.264 43.2559 115.264 42.741C115.264 42.2213 115.121 41.8375 114.835 41.5896C114.554 41.3417 114.11 41.2177 113.505 41.2177H112.496V44.3215ZM117.502 42.6624C117.502 43.7876 117.15 44.6481 116.444 45.2441C115.743 45.8401 114.744 46.1381 113.447 46.1381H112.496V49.857H110.279V39.4012H113.619C114.887 39.4012 115.85 39.6753 116.508 40.2236C117.171 40.7672 117.502 41.5801 117.502 42.6624Z" fill="#888F9F"/>
		<path d="M125.498 49.857H119.476V39.4012H125.498V41.2177H121.693V43.5134H125.233V45.3299H121.693V48.0261H125.498V49.857Z" fill="#888F9F"/>
		<path d="M131.72 49.857H129.503V41.2463H126.664V39.4012H134.559V41.2463H131.72V49.857Z" fill="#888F9F"/>
		<path d="M136.168 49.857V39.4012H138.385V49.857H136.168Z" fill="#888F9F"/>
		<path d="M145.051 49.857H142.834V41.2463H139.995V39.4012H147.89V41.2463H145.051V49.857Z" fill="#888F9F"/>
		<path d="M149.499 49.857V39.4012H151.716V49.857H149.499Z" fill="#888F9F"/>
		<path d="M160.312 39.4012H162.551L158.997 49.857H156.579L153.032 39.4012H155.271L157.237 45.6232C157.347 45.9903 157.459 46.4194 157.573 46.9105C157.693 47.3968 157.766 47.7353 157.795 47.926C157.848 47.4874 158.026 46.7197 158.331 45.6232L160.312 39.4012Z" fill="#888F9F"/>
		<path d="M169.896 49.857H163.874V39.4012H169.896V41.2177H166.091V43.5134H169.631V45.3299H166.091V48.0261H169.896V49.857Z" fill="#888F9F"/>
	</LogoWrapper>
);

export default Logo;
