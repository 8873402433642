import { equals } from "@qtxr/utils";

import useStateCapsule from "./use-state-capsule";
import useProxiedConfig from "./use-proxied-config";

const NULL_CONFIG = Object.freeze({});

function useCachedConfig<Config extends object>(
	config: Config | null | undefined
): Config {
	const conf = useProxiedConfig(config || NULL_CONFIG as Config);
	const currentConfig = useStateCapsule(conf);

	const cachedConf = currentConfig.get();

	if (!equals(conf, cachedConf, "circular")) {
		currentConfig.set(conf);
		return conf;
	}

	return cachedConf;
}

export default useCachedConfig;
