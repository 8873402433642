import { useMemo } from "react";

import useProxiedFunction from "./use-proxied-function";

import {
	CellProps,
	ColumnCell
} from "../types/data-table";

type Action = (props: CellProps) => void;

const useGlobalActionColumns = (
	columns: ColumnCell[],
	action: Action,
	mounter?: (column: ColumnCell, action: Action) => Action
): ColumnCell[] => {
	const actionProxy = useProxiedFunction(action);

	return useMemo(
		() => columns.map(col => ({
			...col,
			action: typeof mounter == "function" ?
				mounter(col, actionProxy) :
				actionProxy
		})),
		[columns, mounter]
	);
};

export default useGlobalActionColumns;
