import { useState } from "react";
import styled from "styled-components";

import LoadingOverlay from "../loading-overlay";

import { LoadingState } from "../../types/utils";

export interface GraphBoxProps {
	title: string;
	bare?: boolean;
	children?: any;
	subheading?: string;
	loadingState?: LoadingState;
	className?: string;
	titleStyle?: any;
}

const GraphRow = styled.div<{breakBefore?: string}>`
	display: flex;
	gap: 15px;
	break-inside: avoid;
	${p => p.breakBefore ? `break-before: ${p.breakBefore};` : ""}

	& + & {
		margin-top: 15px;
	}
`;

const GraphBoxWrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;
	padding: 5px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
	justify-content: flex-start;
`;

const GraphBoxHeader = styled.div<{titleStyle?: any}>`
	padding: 10px;
	font-weight: bold;
	background: ${p => p.theme.graphBackground};
	border-radius: ${p => p.theme.borderRadius};
	${p => p.titleStyle};
`;

const GraphBoxContent = styled.div`
	display: flex;
	flex-grow: 1;
	position: relative;
	margin-top: 5px;
	overflow: hidden;
`;

const GraphBox = (props: GraphBoxProps) => {
	const [loadedData, setLoadedData] = useState(() => {
		if (!props.loadingState) return true;

		return props.loadingState.success;
	});

	if (!loadedData && props.loadingState?.success) setLoadedData(true);

	const content = loadedData ? props.children : null;

	if (props.bare) {
		return (
			<GraphBoxContent className={props.className}>
				{content}
				<LoadingOverlay
					loadingState={props.loadingState}
				/>
			</GraphBoxContent>
		);
	}

	return (
		<GraphBoxWrapper className={props.className}>
			{props.title && (
				<GraphBoxHeader titleStyle={props.titleStyle}>{props.title}</GraphBoxHeader>
			)}
			<GraphBoxContent>
				{content}
				<LoadingOverlay
					loadingState={props.loadingState}
				/>
			</GraphBoxContent>
		</GraphBoxWrapper>
	);
};

const BareGraphBox = (props: GraphBoxProps) => {
	return <GraphBox {...props} bare />;
};

export { GraphRow, GraphBox, BareGraphBox, GraphBoxContent };
