import { useState } from "react";

import { mkLoadingState } from "../utils";

import {
	LoadingState,
	LoadingStateType
} from "../types/utils";

const useLoadingState = (
	initialState?: LoadingStateType,
	initialMessage?: string
): [LoadingState, (type: LoadingStateType, message?: string) => void] => {
	const [loadingState, setLoadingState] = useState(
		() => mkLoadingState(initialState || "loading", initialMessage)
	);

	const updater = (type: LoadingStateType, message?: string) => {
		setLoadingState(
			mkLoadingState(type, message)
		);
	};

	return [
		loadingState,
		updater
	];
};

export default useLoadingState;
