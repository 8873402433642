import styled from "styled-components";

import { InputProps } from "../../types/inputs";

export interface DateProps extends InputProps {
	value: string;
	min?: string;
	max?: string;
}

const DateWrapper = styled.input`
	height: ${p => p.theme.buttonHeight};
	background: ${p => p.theme.cardBackground};
	border: ${p => p.theme.inputBorder};
	border-radius: ${p => p.theme.borderRadius};
	padding: 0 10px;
	outline: none;
	font: inherit;
	color: inherit;
`;

const Date = (props: DateProps) => {
	return <DateWrapper type="date" {...props} />;
};

export default Date;
