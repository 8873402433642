import MultiBarConfig from "./configs/multi-bar";

import Base from "./base";

import { GraphProps } from "../../../types/viz";

const MultiBarGraph = (props: GraphProps) => {
	return <Base {...props} configs={MultiBarConfig} />;
};

export default MultiBarGraph;