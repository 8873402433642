import { useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import { InputProps } from "../../types/inputs";

import Icon from "../icon";
import LoadingIcon from "../loading-icon";

interface AvatarProps extends InputProps {
	value: string;
	dimensions: Dimensions;
	readOnly?: boolean;
}

interface Dimensions {
	width: number;
	height: number;
}

const AvatarWrapper = styled.div`
	position: relative;
	width: 150px;
	height: 150px;
	background: ${p => p.theme.background};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

const DefaultScreen = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
`;

const DefaultScreenIcon = styled(Icon)`
	color: ${p => p.theme.subtleColor};
	width: 30px;
`;

const SmallLoadingIcon = styled(LoadingIcon)`
	color: ${p => p.theme.subtleColor};
	font-size: 3px;
`;

const ImageScreen = styled.img`
	position: absolute;
	width: 100%;
	height: 100%;
`;

const FileOverlay = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.01;
	cursor: pointer;

	&:hover + .clear-overlay .clear-popup {
		transform: translate(-50%, 0);
	}
`;

const ClearOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
`;

const ClearPopup = styled.div`
	position: absolute;
	left: 50%;
	bottom: 0;
	padding: 5px 5px 0;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius} ${p => p.theme.borderRadius} 0 0;
	transform: translate(-50%, 100%);
	transition: transform 300ms;
	overflow: hidden;
	pointer-events: auto;
	
	&:hover {
		transform: translate(-50%, 0);
	}
`;

const ClearButton = styled.button`
	padding: 5px 8px;
	background: ${p => p.theme.background};
	border: none;
	outline: none;
	font: inherit;
	color: inherit;
	text-transform: uppercase;
	font-size: 80%;
	font-weight: bold;
	border-radius: ${p => p.theme.borderRadius};
	cursor: pointer;
`;

const Avatar = (props: AvatarProps) => {
	const ctx = useFormikContext();
	const [loading, setLoading] = useState(false);

	const name = props.name!,
		value = (ctx.values as any)[props.name!] as string;

	let screen,
		clearOverlay = null;

	if (value) {
		screen = <ImageScreen src={value}/>;

		clearOverlay = (
			<ClearOverlay className="clear-overlay">
				<ClearPopup className="clear-popup">
					<ClearButton onClick={() => ctx.setFieldValue(props.name!, "")}>
						Clear
					</ClearButton>
				</ClearPopup>
			</ClearOverlay>
		);
	} else if (loading) {
		screen = (
			<DefaultScreen>
				<SmallLoadingIcon />
			</DefaultScreen>
		);
	} else {
		screen = (
			<DefaultScreen>
				<DefaultScreenIcon name="plus" />
			</DefaultScreen>
		);
	}

	const handleChange = (evt: any) => {
		setLoading(true);

		const file = evt.target.files[0],
			reader = new FileReader();

		reader.onload = () => {
			const image = new Image();

			image.onload = () => {
				const canvas = document.createElement("canvas"),
					c = canvas.getContext("2d")!,
					dim = props.dimensions;
				let iWidth = image.naturalWidth,
					iHeight = image.naturalHeight;

				canvas.width = dim.width;
				canvas.height = dim.height;

				const iAspect = iWidth / iHeight,
					dAspect = dim.width / dim.height;

				const scale = iAspect > dAspect ?
					dim.height / iHeight :
					dim.width / iWidth;

				iWidth *= scale;
				iHeight *= scale;

				c.drawImage(
					image,
					(dim.width - iWidth) / 2,
					(dim.height - iHeight) / 2,
					iWidth,
					iHeight
				);

				setLoading(false);
				ctx.setFieldValue(name, canvas.toDataURL());
			};

			image.onerror = () => {
				setLoading(false);
				ctx.setFieldValue(name, "");
			};

			image.src = reader.result as string;
		};

		reader.onerror = () => {
			setLoading(false);
			ctx.setFieldValue(name, "");
		};

		reader.readAsDataURL(file);
	};

	return (
		<AvatarWrapper>
			{screen}
			<FileOverlay
				type="file"
				value=""
				onChange={handleChange}
			/>
			{clearOverlay}
		</AvatarWrapper>
	);
};

export default Avatar;
