import { get } from "@qtxr/utils";

import { collectLayout } from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Data,
	Point,
	DataPoint
} from "../../apply-categorization";

type Accessor = "line" | "bar";

const LineBarConfig = {
	name: "line-bar",
	collector: () => collectLayout((p: any) => {
		const points = p.data.points as DataPoint[],
			catPoints = p.data.categorized.points as Point[],
			maximums = {} as Record<Accessor, number>;

		for (const cp of catPoints) {
			maximums[cp.accessor as Accessor] = points.reduce((acc, pt) => {
				return Math.max(acc, get(pt, cp.accessor).value);
			}, 0);
		}

		p.data.maximums = maximums;

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const ds = p.dataset,
			w = ds.cWidth,
			points = ds.data.points as Data[],
			catPoints = ds.data.categorized.points as Point[],
			maximums = ds.data.maximums,
			space = 4,
			barWidth = (w - (space * (points.length - 1))) / points.length,
			catPointsMap = {} as Record<Accessor, Point>;



		let x = 0;

		for (const cp of catPoints)
			catPointsMap[cp.accessor as Accessor] = cp;

		plot.with(catPointsMap.bar);


		// Plot bars
		for (const pt of points) {
			plot.next();

			let h = ds.cHeight;
			const barLabel = (catPointsMap as any).barLabel;
			const textSize = 10;
			if (barLabel && barWidth >= textSize) {
				const text = get(pt, barLabel.accessor).value;
				const textWidthPx = textSize * 0.5718 * (text.length - 0.25);
				let textX = (barWidth)/2 + textSize * 0.7 + x;
				let textY = h - (textWidthPx * 0.9);
				let textColor = "#000"
				plot.text(text)
					.x(textX)
					.y(textY)
					.size(textSize)
					.baseline("hanging")
					.stroke(textColor)
					.transform("rotate(90deg)");
				h -= textWidthPx;
			}

			const value = get(pt, catPointsMap.bar.accessor).value,
				height = (value / maximums.bar) * h;

			plot.rect()
				.x(x)
				.y(h - height)
				.width(barWidth)
				.height(height)
				.fill(catPointsMap.bar.color);

			x += (barWidth + space);
		}

		plot.render();
	},
	dataset: {
		id: "line-bar",
		mode: "own",
		type: "line-bar",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default LineBarConfig;
