import { get } from "@qtxr/utils";

import { collectLayout } from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Data,
	Point,
	Group
} from "../../apply-categorization";
import { format } from "../../../../utils";

const WeeklyGrpGraphConfig = {
	name: "weekly-grp",
	collector: () => collectLayout((p: any) => {
		const points = p.data.points as Data[],
			catPoints = p.data.categorized.points as Point[];

		// p.data.totals = points.map(pt => {
		// 	return catPoints.reduce((acc, cp) => {
		// 		return acc + get(pt, cp.accessor).value;
		// 	}, 0)
		// });


		p.data.localMaximums = points.map(pt => {
			return catPoints.map((cp) => {
				return Math.max(...(Object.values(get(pt, cp.accessor).value)) as number[]);
			}, 0)
		});


		p.data.max = Math.max(...p.data.localMaximums.reduce((acc: number[], localMaxList: number[]) => {return acc.concat(localMaxList)}, []));
		return p.data;
	}),
	plotter: () => (p: any) => {

		const plot = new PlotSvg(p);

		const ds = p.dataset,
			points = ds.data.points as Data[],
			catGroups = ds.data.categorized.groups as Group[],
			spacingBetweenBarGroups = 10,
			spacingBetweenBars = 2,
			spacingBetweenSubcharts = 10,
			leftMargin = 120,
			topMargin = 15,
			w = ds.cWidth - leftMargin,
			h = ds.cHeight - topMargin,
			numBarGroups = catGroups[0].points.length,
			max = ds.data.max,
			barGroupWidth = (w - (spacingBetweenBarGroups * (numBarGroups- 1))) / numBarGroups,
			barsPerGroup = ds.data.legends.legends.length,
			barWidth = Math.max(5, ((barGroupWidth - (spacingBetweenBars * (barsPerGroup - 1))) / barsPerGroup)),
			stepGroup = barGroupWidth + spacingBetweenBarGroups;

		let catPoints = ds.data.categorized.points as Point[];

		const marketLabels = Object.keys(catPoints[0].data[0].value); //.map((e:any) => e.label );
		for (let labelIdx=0, l=marketLabels.length; labelIdx < l; labelIdx++) {
			const subGraphHeight = (h - spacingBetweenSubcharts * (l-1)) / l;
			const topOfSubgraph =  (subGraphHeight + spacingBetweenSubcharts)*(labelIdx) + topMargin;
			const middleOfSubgraph = topOfSubgraph + subGraphHeight * 0.5;
			const bottomOfSubgraph = topOfSubgraph + subGraphHeight;

			plot.text(marketLabels[labelIdx]).x(10).y(middleOfSubgraph).size(12).weight(600).anchor("start").baseline("middle").fill("#333").inert();

			const numYAxesPoints = 8
			for (let i = 1; i < numYAxesPoints; i += 2) {
				const axesLabelCenter = topOfSubgraph + subGraphHeight * (i / numYAxesPoints);
				plot.setStrokeWidthOnce("1");
				plot.line(leftMargin - spacingBetweenBarGroups/2, axesLabelCenter)
					.h(w + 2)
					.stroke("#BBB")
					.inert();
			}

			plot.setStrokeWidthOnce("3");
			plot.line(0, bottomOfSubgraph)
				.h(ds.cWidth)
				.stroke("#333")
				.inert();
		}


		for (let cgIdx=0; cgIdx < catGroups.length; cgIdx++) { // each category is an one of the series / legend items (eg, party)
			let cg = catGroups[cgIdx];
			let cps = cg.points;

			let x = leftMargin;

			for (let cpIdx = 0, l = cps.length; cpIdx < l; cpIdx++) {
				// break;
				// each iteration is a bar in the graph for this particular category / series.
				const point = cps[cpIdx];
				plot.next();
				plot.with(point);


				// get(pt, cp.accessor).market_breakdown = [get(pt, cp.accessor).value, get(pt, cp.accessor).value, get(pt, cp.accessor).value]
				// console.log(point);
				const valByMarket= point.data[cgIdx].value as any;
				for (let marketIdx = 0, l = marketLabels.length; marketIdx  < l; marketIdx ++) {
					const marketLabel = marketLabels[marketIdx]
					const dataValue = valByMarket[marketLabel],
						subGraphHeight = (h - spacingBetweenSubcharts * (l-1)) / l,
						barHeight = dataValue ? Math.max((dataValue / max) * subGraphHeight, 1) : 1,
						xOffset = cgIdx * (barWidth + spacingBetweenBars) // each series is has a calculated offset within the bargroup cluster so they appear side by side.
					;

					// console.log(
					// 	{
					// 		dataValue, subGraphHeight, barHeight, xOffset, barWidth,
					// 		y: subGraphHeight - barHeight + subGraphHeight*marketIdx + spacingBetweenSubcharts*marketIdx,
					// 		x: x + xOffset
					//
					// 	}
					// )

					plot.rect()
						.x(x + xOffset)
						.y(subGraphHeight - barHeight + subGraphHeight*marketIdx + spacingBetweenSubcharts*marketIdx + topMargin)
						.width(barWidth)
						.height(barHeight)
						.fill(cg.color)
					;

					const textSize = 10;
					const text = (dataValue || 0).toString();
					const textWidthPx = textSize * 0.5718 * (text.length - 0.25);
					let textX = x + xOffset + barWidth/2 + textSize*0.5718;
					let textY = subGraphHeight - barHeight + subGraphHeight*marketIdx + spacingBetweenSubcharts*marketIdx - textWidthPx + topMargin;
					let textColor = "#000"
					if (textWidthPx > subGraphHeight - barHeight) {
						textY = subGraphHeight - barHeight + subGraphHeight*marketIdx + spacingBetweenBarGroups*marketIdx + textSize * 0.5718 + topMargin;
						textColor = "#FFF"
					}
					plot.text(dataValue || 0)
						.x(textX)
						.y(textY)
						.size(textSize)
						.baseline("hanging")
						.stroke(textColor)
						.transform("rotate(90deg)")
				}

				x += stepGroup;	// advance to the next cluster of bars.
			}
		}

		const dateLabels = catPoints.map(p => {
			if (!p.label.includes("-")) return p.label
			return p.label.substring(5).replace("-", "/")
		});
		dateLabels.forEach((label, i) => {
			const x = leftMargin - spacingBetweenBarGroups/2 + stepGroup*i;
			plot.line(x, 0)
				.v(ds.cHeight)
				.stroke("#CCC")
				.inert();

			const textSize = 10;
			const textWidthPx = textSize * 0.5718 * (label.length - 0.25);
			plot.text(label)
				.x(x + Math.max(0, (barGroupWidth + spacingBetweenBarGroups - textWidthPx) / 2))
				.y(textSize)
				.size(textSize)
				.baseline("center")
				.stroke("#000");
		})

		plot.render();
	},
	dataset: {
		id: "weekly-grp",
		mode: "own",
		type: "weekly-grp",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default WeeklyGrpGraphConfig;
