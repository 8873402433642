import styled from "styled-components";

import { get } from "@qtxr/utils";
import { getUTCDate } from "../../utils";

import NA from "../na";
import OutLink from "../out-link";
import { Checkbox } from "../inputs";

import {
	ColumnCell,
	ContentProps,
	ColumnTemplate,
	AbstractInputProps
} from "../../types/data-table";

export type ColumnType =
	"default" |
	"list" |
	"date" |
	"date-time" |
	"link" |
	"choice" |
	"number" |
	"currency" |
	"currency-value" |
	"toggle" |
	"color" |
	"hidden";

interface SwatchProps {
	color: string;
}

const DEF_CONTENT = (props: ContentProps) => <>{props.presentationValue}</>,
	DEF_INPUT = (column: ColumnCell) => {
		return (props: AbstractInputProps) => <input type={column.type} {...props} />;
	},
	DEF_NORMALIZE_VALUE = (value: any) => value == null ? "" : String(value),
	DEF_PRESENTATION_VALUE = (value: any) => value,
	DEF_CONVERT_VALUE = (value: any) => value;

// Presentation: list column pills
const PillWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 0 -3px -3px 0
`;

const Pill = styled.div`
	margin: 0 3px 3px 0;
	padding: 4px 8px;
	background: ${p => p.theme.popBackgroundAlt};
	border-radius: ${p => p.theme.borderRadius};
	box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.05);
`;

// Presentation: color
const Swatch = styled.div<SwatchProps>`
	width: 16px;
	height: 16px;
	border-radius: ${p => p.theme.borderRadius};
	background: ${p => p.color};
`;

const SwatchInputWrapper = styled.div<SwatchProps>`
	position: relative;
	width: 32px;
	height: 18px;
	border-radius: ${p => p.theme.borderRadius};
	margin: 5px 8px;
	background: ${p => p.color};
`;

const SwatchInputTarget = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
`;

const SwatchInput = (props: AbstractInputProps) => (
	<SwatchInputWrapper color={props.value}>
		<SwatchInputTarget type="color" {...props} />
	</SwatchInputWrapper>
);

// Utilities
const getChoiceValue = (column: ColumnCell, value: any): any => {
	const conf = column.config;

	const found = (conf.options || [])
		.find((option: any) => {
			if (option === null || typeof option !== "object")
				return option === value;

			const optionValue = get(option, conf.valueAccessor)

			if (typeof conf.comparator === "function")
				return conf.comparator(optionValue, value);

			if (typeof optionValue?.toString === "function" && typeof value?.toString === "function") {
				return optionValue.toString() === value.toString()
			}

			return optionValue === value;
		});

	if (found == null)
		return "";

	return get(found, conf.labelAccessor, found);
};

const COLUMNS: Record<ColumnType, ColumnTemplate | ColumnCell> = {
	default: {
		content: DEF_CONTENT,
		nullContent: NA,
		input: DEF_INPUT,
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: DEF_CONVERT_VALUE
	},
	list: {
		content: ({ value }) => {
			const pills = value.map((v: any, i: number) => (
				<Pill key={i}>{v}</Pill>
			));

			return (
				<PillWrapper>
					{pills}
				</PillWrapper>
			);
		},
		nullContent: NA,
		input: DEF_INPUT,
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: DEF_CONVERT_VALUE
	},
	date: {
		content: ({ value, presentationValue }) => (
			<span title={value}>{presentationValue}</span>
		),
		nullContent: NA,
		input: DEF_INPUT,
		normalizeValue: (value: any) => {
			if (!value)
				return "";

			const d = getUTCDate(value),
				month = d.getUTCMonth() + 1,
				date = d.getUTCDate();

			return `${
				d.getUTCFullYear()
			}-${
				month < 10 ? "0" + month : month
			}-${
				date < 10 ? "0" + date : date
			}`;
		},
		getPresentationValue: (value: any) => {
			const d = getUTCDate(value);
			return `${d.getUTCMonth() + 1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
		},
		index: "timestamp",
		convertValue: DEF_CONVERT_VALUE
	},
	"date-time": {
		content: ({ value, presentationValue }) => (
			<span title={value}>{presentationValue}</span>
		),
		nullContent: NA,
		input: DEF_INPUT,
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: (value: any) => {
			const d = getUTCDate(value);

			const hour = d.getUTCHours(),
				minute = d.getUTCMinutes(),
				minuteString = minute ?
					`:${String(minute).padStart(2, "0")}` :
					"",
				meridiem = hour < 12 ?
					"AM" :
					"PM";

			return `${
				d.getUTCMonth() + 1
			}/${
				d.getUTCDate()
			}/${
				d.getUTCFullYear()
			} ${
				hour % 12 || 12
			}${
				minuteString
			} ${
				meridiem
			}`;
		},
		index: "timestamp",
		convertValue: DEF_CONVERT_VALUE
	},
	link: {
		content: ({ row, column, value }) => {
			const url = get(row.row, column.config.urlAccessor);
			if (!url || typeof url != "string")
				return <>{value}</>;

			return (
				<OutLink
					to={url}
					onClick={(evt: any) => evt.stopPropagation()}
				>
					{value}
				</OutLink>
			);
		},
		nullContent: NA,
		input: DEF_INPUT,
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: DEF_CONVERT_VALUE
	},
	choice: {
		content: DEF_CONTENT,
		nullContent: NA,
		input: (column: ColumnCell) => {
			return (props: AbstractInputProps) => {
				const options = (column.config.options || []).map((option: any, i: number) => (
					<option
						key={i}
						value={get(option, column.config.valueAccessor, option)}
					>
						{get(option, column.config.labelAccessor, option)}
					</option>
				));

				return (
					<select {...props}>
						{options}
					</select>
				);
			};
		},
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: (value: any, column: ColumnCell) => getChoiceValue(column, value),
		convertValue: DEF_CONVERT_VALUE
	},
	number: {
		content: DEF_CONTENT,
		nullContent: NA,
		input: DEF_INPUT,
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: value => Number(value) || 0
	},
	currency: {
		content: (props: ContentProps) => <>${(props.value || 0).toFixed(2)}</>,
		nullContent: NA,
		input: () => {
			return (props: AbstractInputProps) => <input type="number" {...props} />;
		},
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: value => Number(value) || 0
	},
	"currency-value": {
		content: (props: ContentProps) => <>{(props.value || 0).toFixed(2)}</>,
		nullContent: NA,
		input: () => {
			return (props: AbstractInputProps) => <input type="number" {...props} />;
		},
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: value => Number(value) || 0
	},
	toggle: {
		content: (props: ContentProps) => (
			<Checkbox
				checked={props.value}
				onChange={() => props.set(!props.value)}
			/>
		),
		nullContent: NA,
		input: DEF_INPUT,
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: DEF_CONVERT_VALUE,
		hasOwnEditHandler: true
	},
	color: {
		content: ({ value }) => <Swatch color={value} />,
		nullContent: NA,
		input: () => (props: AbstractInputProps) => (
			<SwatchInput {...props} />
		),
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: DEF_CONVERT_VALUE
	},
	hidden: {
		content: DEF_CONTENT,
		nullContent: NA,
		input: DEF_INPUT,
		normalizeValue: DEF_NORMALIZE_VALUE,
		getPresentationValue: DEF_PRESENTATION_VALUE,
		convertValue: DEF_CONVERT_VALUE
	}
};

export {
	COLUMNS
};
