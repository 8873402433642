import {
	createSlice,
	Draft,
	PayloadAction
} from "@reduxjs/toolkit";
import { FullToastConfig } from "../../types/ui";

interface UIState {
	toasts: FullToastConfig[];
}

const INITIAL_STATE = {
	toasts: []
} as UIState;

export const slice = createSlice({
	name: "ui",
	initialState: INITIAL_STATE,
	reducers: {
		pushToast: (state: Draft<UIState>, action: PayloadAction<FullToastConfig>) => {
			state.toasts.push(action.payload);
		},
		removeToast: (state: Draft<UIState>, action: PayloadAction<number>) => {
			const idx = state.toasts.findIndex(toast => toast.id === action.payload);

			if (idx !== -1)
				state.toasts.splice(idx, 1);
		}
	}
});

export default slice.reducer;
export const {
	pushToast,
	removeToast
} = slice.actions;
