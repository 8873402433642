import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import Base from "../base";

import ContentCard from "../../components/content-card";
import ActionTable from "../../components/action-table";

import { ColumnCell } from "../../types/data-table";

const COLUMNS: ColumnCell[] = [
	{
		name: "sortOrder",
		type: "number",
		title: "Sort Order",
		accessor: "sort_order",
		fit: "shrink",
		editable: true
	},
	{
		name: "mediumName",
		title: "Medium Name",
		accessor: "medium_name",
		editable: true
	}
];

const Media = () => {
	const actions = useTableActions({
		addLabel: "Add Medium",
		add: runtime => ({
			sort_order: runtime.rows.length + 1,
			medium_name: ""
		}),
		createEach: row => {
			console.log("CREATE");
		},
		modifyEach: row => {
			console.log("MODIFY");
		},
		deleteEach: row => {
			console.log("DELETE");
		}
	});

	const props = useRemoteActionProps({
		request: "/media/list",
		process: (medium: string, idx) => ({
			sort_order: idx + 1,
			medium_name: medium
		}),
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} />
			</ContentCard>
		</Base>
	);
};

export default Media;
