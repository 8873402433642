import { useMemo } from "react";

import StateCapsule from "../utils/state-capsule";

const useStateCapsule = <T>(value: T, volatile?: boolean): StateCapsule<T> => {
	const capsule = useMemo(
		() => {
			const c = new StateCapsule<T>(value);
			c.set(value);
			return c;
		},
		[]
	);

	if (volatile)
		capsule.set(value);

	return capsule;
};

export default useStateCapsule;
