import {
	useMemo,
	useState,
	useEffect
} from "react";
import styled, {
	css,
	keyframes
} from "styled-components";

import { isThenable } from "@qtxr/utils";

import useStateCapsule from "../hooks/use-state-capsule";

import { resolveColumns } from "./data-table";

import ActionTable, {
	interceptRefs,
	mkOddRowBackgroundGetter,
	mkEvenRowBackgroundGetter,
	RowWrapper,
	ActionBase,
	ActionRuntime,
	ActionTableProps
} from "./action-table";
import Icon from "./icon";
import Centered from "./centered";

import {
	Row,
	Runtime,
	CellProps,
	RowPacket,
	ColumnCell,
	ContentProps
} from "../types/data-table";

interface SplitBoxProps<P = EmptySplitProps> extends ActionTableProps {
	content?: (props: SplitProps<P>) => JSX.Element;
	title?: string;
	notice?: string;
	autoFocus?: boolean;
	splitProps?: P;
	children?: any;
}

export type SplitProps<P = EmptySplitProps> = BaseSplitProps & P;

interface BaseSplitProps {
	focusedRow: Row;
	focusedRowPacket: RowPacket;
	focus: (row: Row) => void;
	blur: () => void;
	edit: (data: Row) => void;
}

interface EmptySplitProps {

}

interface OpenIconProps {
	hide: boolean;
	animate: boolean;
}

interface RightContentProps {
	blur: () => void;
	title?: string;
	children?: any;
}

interface OutlineProps {
	type: string;
	contentProps: ContentProps;
	getterMaker: (theme: any) => (props: ContentProps) => string | null;
	theme?: any;
}

interface FocusState {
	focusedRow: Row;
	activeFocusedRow: Row;
	lastFocusedRow: Row;
	blurring: boolean;
}

interface FocusRequest {
	existingRows: Row[];
}

const BoxWrapper = styled.section`
	display: flex;
	flex-grow: 1;
	padding: 5px 5px 10px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
	
	.left-content {
		.action-table {
			margin-right: -40px;
		}

		.header {
			margin-right: 40px;
		}

		.heading {
			margin-bottom: 3px;
		}

		.cells {
			padding-right: 70px;
		}

		.control-bar-wrapper {
			right: 45px;
		}

		.overlay {
			right: 40px;
		}

		.loading-overlay {
			width: auto;
			right: 40px;
		}
	}
`;

const Left = styled.div`
	display: flex;
	flex-grow: 2;
`;

const LeftContent = styled.div`
	display: flex;
	width: 100%;
`;

const OPEN_ICON_KEYFRAMES = keyframes`
	to {
		transform: translateX(100%) scale(0.5);
		opacity: 0;
	}
`;

const OpenIcon = styled(Icon)<OpenIconProps>`
	height: 12px;
	opacity: 0.6;
	${p =>
		p.hide ?
			(p.animate ?
				css`animation: ${OPEN_ICON_KEYFRAMES} 300ms forwards 1` :
				"visibility: hidden"
			) :
			null
	};
`;

const resolveOutlineBackground = (props: OutlineProps) => {
	const inherited = props.getterMaker(props.theme)(props.contentProps);
	if (inherited)
		return inherited;

	return props.type === "even" ?
		props.theme.tableRowBackground :
		props.theme.cardBackground;
};

const OUTLINE_BACKGROUND = css<OutlineProps>`
	background: ${resolveOutlineBackground};
`;

const OUTLINE_STYLE = css<OutlineProps>`
	${OUTLINE_BACKGROUND};
	position: absolute;
	top: -3px;
	bottom: -3px;
	left: 0;
	right: 0;
	border-radius: inherit;
	border: 2px solid ${p => p.theme.darkBackground};
	z-index: 1;
`;

const OutlineStart = styled.div<OutlineProps>`
	${OUTLINE_STYLE};
	left: -2px;
	border-right: none;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
`;

const OutlineMiddle = styled.div<OutlineProps>`
	${OUTLINE_STYLE};
	border-left: none;
	border-right: none;
`;

const OutlineEndWrapper = styled.div<OutlineProps>`
	${OUTLINE_STYLE};
	right: -20px;
	border: none;
	overflow: hidden;
	background: transparent;
	pointer-events: none;
`;

const OutlineEndNub = styled.div<OutlineProps>`
	${OUTLINE_BACKGROUND};
	content: "";
	position: absolute;
	top: 50%;
	right: 0;
	width: 200%;
	height: 200%;
	transform-origin: 100% 0;
	box-shadow: inset 0 0 0 2.4px ${p => p.theme.darkBackground};
	border-radius: ${p => p.theme.borderRadius};
	transform: scaleX(0.8) rotate(45deg);
	overflow: hidden;
	clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
	pointer-events: auto;
	
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		box-shadow: inset 0 0 0 2px ${p => p.theme.darkBackground};
		transform-origin: 100% 0;
		transform: rotate(-45deg) translateY(-50%) translateX(10px);
		pointer-events: none;
	}
`;

const OutlineEnd = (props: OutlineProps) => (
	<OutlineEndWrapper {...props} >
		<OutlineEndNub {...props} />
	</OutlineEndWrapper>
);

const Right = styled.div`
	display: flex;
	// flex-grow: 1;
	min-width: 600px;
	padding-left: 15px;
	margin-left: 5px;
	// margin-top: 50px;
	margin-top: 8px;
	overflow-y: auto;
	border-left: 1px solid ${p => p.theme.separatorColor};
`;

const RightContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-right: 5px;
`;

const RightContentHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	background: ${p => p.theme.componentBackground};
	border-radius: ${p => p.theme.borderRadius};
	font-weight: bold;
`;

const RightInnerContent = styled.div`
	margin-top: 10px;
`;

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	margin: -10px -5px;
	outline: none;
	border: none;
	background: transparent;
	color: inherit;
	cursor: pointer;
	padding: 8px;
`;

const RightContent = (props: RightContentProps) => {
	return (
		<RightContentWrapper>
			<RightContentHeader>
				<span>{props.title || "Focused Row"}</span>
				<CloseButton onClick={props.blur}>
					<Icon name="thin-cross" />
				</CloseButton>
			</RightContentHeader>
			<RightInnerContent>
				{props.children}
			</RightInnerContent>
		</RightContentWrapper>
	);
};

function SplitBox<P>(props: SplitBoxProps<P>) {
	const [focusedRow, setFocusedRow] = useState(null as Row | null);
	const [focusedRowPacket, setFocusedRowPacket] = useState(null as RowPacket | null);
	const [lastFocusedRow, setLastFocusedRow] = useState(null as Row | null);
	const [activeFocusedRow, setActiveFocusedRow] = useState(null as Row | null)
	const [focusRequest, setFocusRequest] = useState(null as FocusRequest | null);

	const focusState = useStateCapsule({
		focusedRow,
		activeFocusedRow,
		lastFocusedRow,
		blurring: false
	} as FocusState, true);

	const {
		content,
		notice,
		children,
		autoFocus,
		splitProps,
		...tProps
	} = props;

	const updateRow = (row: Row, runtime: Runtime) => {
		setLastFocusedRow(focusState.get().focusedRow);
		setFocusedRow(row);

		runtime.nextTick((rt) => {
			const r = rt.getRowPacket(row);
			setFocusedRowPacket(r);
		});
	};

	const getOutlineBackground = (
		p: ContentProps,
		type: "odd" | "even"
	): JSX.Element | string | null => {
		if (p.row.row.data === focusState.get().focusedRow) {
			const oProps = {
				type,
				contentProps: p,
				getterMaker: type === "odd" ?
					mkOddRowBackgroundGetter :
					mkEvenRowBackgroundGetter
			};

			if (p.column.location.first)
				return <OutlineStart {...oProps} />;

			if (p.column.location.last)
				return <OutlineEnd {...oProps} />;

			return <OutlineMiddle {...oProps} />;
		}

		return null;
	};

	const backgrounds = {
		even: (p: ContentProps) => getOutlineBackground(p, "even"),
		odd: (p: ContentProps) => getOutlineBackground(p, "odd")
	};

	const columns = useMemo(
		() => {
			const cols = [] as ColumnCell[];

			const action = (p: CellProps) => {
				const { newRow } = p.row.refresh();
				updateRow(newRow!, p.processOptions.get().runtime!);
				setTimeout(() => {
					setActiveFocusedRow(newRow);
				}, 300);
			};

			const resolvedColumns = resolveColumns(props.columns);

			for (const column of resolvedColumns) {
				const outCol = {
					...column,
					backgrounds
				};

				if (!outCol.editable && typeof props.onEdit == "function")
					outCol.action = action;

				cols.push(outCol);
			}

			cols.push({
				name: "open",
				title: "",
				accessor: "open",
				content: (p) => (
					<OpenIcon
						name="chevron-right"
						hide={p.row.row.data === focusState.get().focusedRow}
						animate={focusState.get().activeFocusedRow !== focusState.get().focusedRow}
					/>
				),
				fit: "shrink",
				sort: null,
				filter: null,
				editable: true,
				hasOwnEditHandler: true,
				backgrounds,
				action
			});

			return cols;
		},
		[props.columns]
	);

	useEffect(
		() => {
			if (!focusedRowPacket)
				return;

			const targetRow = props.rows[focusedRowPacket.index],
				rowA = focusedRowPacket.row.data,
				rowB = focusState.get().focusedRow;

			// If row data is not available at a specified index in the
			// provided rows array, it has been removed from the system,
			// and the focus UI should be collapsed
			if (rowA !== targetRow && rowB !== targetRow) {
				setFocusedRow(null);
				setLastFocusedRow(null);
				setFocusedRowPacket(null);
			}
		},
		[props.rows, focusedRowPacket]
	);

	const actions = useMemo(
		() => {
			if (!autoFocus)
				return tProps.actions;

			if (!tProps.actions || !tProps.actions.add) {
				console.warn("Cannot apply autofocus: no add action provided to hook into");
				return tProps.actions;
			}

			const handle = (tProps.actions.add as ActionBase<any>).handle;

			const triggerFocus = (result: boolean) => {
				if (result) {
					setFocusRequest({
						existingRows: tProps.rows
					});
				}

				return result;
			};

			const add = {
				...tProps.actions.add,
				handle: (runtime: ActionRuntime) => {
					const result = handle(runtime);

					if (!isThenable(result))
						return triggerFocus(result);

					return result.then(triggerFocus);
				}
			};

			return {
				...tProps.actions,
				add
			};
		},
		[autoFocus, tProps.actions]
	);

	const tableProps = interceptRefs(
		{
			...tProps,
			columns,
			compact: true,
			actions,
			checkPlacement: -1,
			checkProps: {
				backgrounds
			},
			obscureScrollBar: true,
			diff: p => {
				return Boolean(p.oldRow) && p.oldRow!.row.data === lastFocusedRow;
			},
			onNewRow: evt => {
				if (evt.row === focusedRow)
					setFocusedRowPacket(evt.rowPacket);

				if (typeof tProps.onNewRow == "function")
					tProps.onNewRow(evt);
			}
		},
		focusedRow,
		(newRow, evt) => {
			if (!focusState.get().blurring) {
				updateRow(newRow, evt.runtime);
				setActiveFocusedRow(newRow);
			}
		}
	);
	let cnt;

	const sProps = {
		...splitProps,
		focusedRow,
		focusedRowPacket,
		focus: (row: Row) => setFocusedRow(row),
		blur: () => {
			focusState.set({
				...focusState.get(),
				blurring: true
			});

			setFocusedRow(null);
			setLastFocusedRow(null);

			if (focusedRowPacket) {
				setFocusedRowPacket(null);
				focusedRowPacket.refresh();
			}

			focusState.set({
				...focusState.get(),
				blurring: false
			});
		},
		edit: (data: Row) => {
			const wrapper = {
				...focusedRowPacket!.row,
				data
			} as RowWrapper;

			focusedRowPacket!.edit(wrapper);
		}
	} as SplitProps<P>;

	if (focusRequest) {
		const existingSet = new Set(focusRequest.existingRows),
			newRows = tProps.rows.filter(row => !existingSet.has(row));

		if (newRows.length) {
			setFocusedRow(newRows[0]);
			setActiveFocusedRow(newRows[0]);
			setLastFocusedRow(focusedRow);
			setFocusedRowPacket(null);
		}

		setFocusRequest(null);
	}

	if (focusedRow && focusedRowPacket) {
		if (props.content)
			cnt = <props.content {...sProps} />;
		else
			cnt = props.children;

		cnt = (
			<RightContent
				title={props.title}
				blur={sProps.blur}
			>
				{cnt}
			</RightContent>
		);
	} else {
		cnt = (
			<Centered>
				{props.notice || "Nothing focused"}
			</Centered>
		);
	}

	return (
		<BoxWrapper>
			<Left className="left-content">
				<LeftContent>
					<ActionTable {...tableProps} />
				</LeftContent>
			</Left>
			<Right>
				{cnt}
			</Right>
		</BoxWrapper>
	);
}

export default SplitBox;
