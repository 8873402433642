import {
	set,
	inject
} from "@qtxr/utils";

import useStateCapsule from "./use-state-capsule";

import { State } from "../types/common";

export interface StateSetter {
	(stateOrAccessor: State | StateUpdater | string, value?: any): void;
	gently: (stateOrAccessor: State | StateUpdater | string, value?: any) => void;
	shallow: (stateOrAccessor: State | StateUpdater | string, value?: any) => void;
}

type StateUpdater = (pendingState: State, currentState: State) => State;

const useStateDispatch = (
	currentState: State,
	dispatcher: (state: State) => void
): StateSetter => {
	const state = useStateCapsule({
		pendingState: null as State | null
	});

	const schedule = (scheduler: (pendingState: State | null) => State) => {
		const { pendingState } = state.get();

		if (!pendingState) {
			requestAnimationFrame(() => {
				const result = inject(
					currentState,
					state.get().pendingState,
					"override|cloneTarget"
				);

				state.set({
					pendingState: null
				});

				dispatcher(result);
			});
		}

		state.set({
			pendingState: scheduler(pendingState)
		});
	};

	const dispatchSet = (
		pendingState: State | null,
		injectOptions: any,
		stateOrAccessor: State | StateUpdater | string,
		value?: any
	): State => {
		let state;

		if (typeof stateOrAccessor == "string") {
			const val = typeof value == "function" ?
				value(pendingState || {}, currentState) :
				value;

			state = {};
			set(state, stateOrAccessor, val);
		} else if (typeof stateOrAccessor == "function")
			state = stateOrAccessor(pendingState || {}, currentState);
		else
			state = stateOrAccessor;

		if (!pendingState)
			return state;

		return inject(pendingState, state, injectOptions);
	};

	const setter = (stateOrAccessor: State | StateUpdater | string, value?: any) => {
		schedule(pendingState => {
			return dispatchSet(
				pendingState, "cloneTarget|override",
				stateOrAccessor, value
			);
		});
	};

	setter.gently = (stateOrAccessor: State | StateUpdater | string, value?: any) => {
		schedule(pendingState => {
			return dispatchSet(
				pendingState, "cloneTarget",
				stateOrAccessor, value
			);
		});
	};

	setter.shallow = (stateOrAccessor: State | StateUpdater | string, value?: any) => {
		schedule(pendingState => {
			return dispatchSet(
				pendingState, "cloneTarget|shallow",
				stateOrAccessor, value
			);
		});
	};

	return setter;
};

export default useStateDispatch;
