import RadarConfig from "./configs/radar";

import Base from "./base";

import { GraphProps } from "../../../types/viz";

interface RadarGraphProps extends GraphProps {
	labels?: string[]
}

const Radar = (props: RadarGraphProps) => {
	const { labels, ...p } = props
	return <Base {...p} configs={RadarConfig} store={{ labels }} />;
};

export default Radar;
