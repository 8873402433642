import {
	useField,
	useFormikContext
} from "formik";
import { useEffect } from "react";

import Text, { TextProps } from "./text";

interface InferredTextProps extends TextProps {
	watch: string | string[];
	generate: (values: any) => string;
}

const InferredText = (props: InferredTextProps) => {
	const ctx = useFormikContext();
	const [field] = useField(props.name!);

	const values = ctx.values as any,
		touched = ctx.touched as any,
		name = props.name!,
		watch = Array.isArray(props.watch) ?
			props.watch :
			[props.watch],
		deps = [
			name,
			ctx.setFieldValue
		];

	for (const watchKey of watch) {
		deps.push(
			values[watchKey],
			touched[watchKey]
		);
	}

	useEffect(
		() => {
			const value = props.generate(values);

			if (!touched[name] && values[name] !== (ctx.initialValues as any)[name])
				ctx.setFieldValue(name, value);
			else if (!values[name]) {
				ctx.setFieldValue(name, value);
				clearTouched();
			} else if (values[name] === value)
				clearTouched();
		},
		deps
	);

	const clearTouched = () => {
		requestAnimationFrame(() => {
			ctx.setTouched({
				...touched,
				[name]: false
			});
		});
	};

	const handleBlur = (evt: any) => {
		const value = props.generate(values);

		if (!values[name]) {
			ctx.setFieldValue(
				name,
				props.generate(values)
			);

			clearTouched();
		} else if (values[name] === value)
			clearTouched();

		if (typeof props.onBlur == "function")
			props.onBlur(evt);
	};

	return (
		<Text
			{...props}
			{...field}
			onBlur={handleBlur}
		/>
	);
};

export default InferredText;
