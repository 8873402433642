import { collectLayout } from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Data,
	Point
} from "../../apply-categorization";

const ReachConfig = {
	name: "reach",
	collector: () => collectLayout((p: any) => {
		const points = p.data.points as Data[];

		p.data.impressionsMax = points.reduce((acc, point) => (
			Math.max(acc, point.impressions.value as number)
		), 0);

		p.data.cpmMax = points.reduce((acc, point) => (
			Math.max(acc, point.cpm.value as number)
		), 0);

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);
		const ds = p.dataset,
			points = p.data.points as Data[],
			catPoints = p.data.categorized.points as Point[],
			rightPadding = 40,
			bottomPadding = 75,
			w = ds.cWidth - rightPadding,
			h = ds.cHeight - bottomPadding,
			max = ds.data.impressionsMax,
			space = 15,
			barWidth = (w - (space * (points.length - 1))) / points.length,
			pointsMap = {} as any,
			style = p.inst.config.style;
		let x = 0;

		for (const cp of catPoints)
			pointsMap[cp.label] = cp;

		// Plot boxes
		for (const pt of points) {
			const impressionsHeight = ((pt.impressions.value as number) / max) * h;


			plot.next();
			plot.with(pointsMap.impressions);

			plot.rect()
				.x(x)
				.y(h - impressionsHeight)
				.width(barWidth)
				.height(impressionsHeight)
				.fill(pointsMap.impressions.color);

			plot.with(pointsMap.completions);


			plot.text(pt.impressions.trace[0].label)
				.x(x + barWidth / 2)
				.y(h + 10)
				.size(10)
				.weight(600)
				.baseline("middle")
				.maxLength(16)
				.fill(style.fullTheme.color)
				.origin("0 50%")
				.transform("rotate(45deg)")
				.inert();

			x += (barWidth + space);
		}


		// Plot line
		const line = plot.path();
		x = barWidth / 2;

		for (let i = 0, l = points.length; i < l; i++) {
			const percentage =  (points[i].cpm.value as number) / ds.data.cpmMax;
			const y = (1 - percentage) * (h - 40) + 5;

			if (i)
				line.L(x, y);
			else
				line.M(x, y);

			x += (barWidth + space);
		}

		line
			.stroke(pointsMap.cpm.color)
			.strokeWidth(2)
			.stack(2)
			.pointer("none");

		x = barWidth / 2;
		plot.atIndex(-1);

		for (let i = 0, l = points.length; i < l; i++) {
			const percentage =  (points[i].cpm.value as number) / ds.data.cpmMax;
			const y = (1 - percentage) * (h - 40) + 5;

			plot.next();
			plot.with(pointsMap.cpm);

			plot.circle()
				.cx(x)
				.cy(y)
				.r(3)
				.fill(pointsMap.cpm.color)
				.outline(5)
				.stack(2);

			x += (barWidth + space);
		}

		plot.render();
	},
	dataset: {
		id: "reach",
		mode: "own",
		type: "reach",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default ReachConfig;
