import { get } from "@qtxr/utils";

import { collectLayout } from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Data,
	Point
} from "../../apply-categorization";

const ORDERS = 3,
	BASE_DELIMITERS = [1, 2, 5, 10, 20, 25, 40, 50, 75, 100, 150, 200, 500, 750],
	DELIMITERS = [] as number[];

for (let i = 0; i < ORDERS; i++) {
	const mul = Math.pow(10, i * 3);

	for (const delimiter of BASE_DELIMITERS)
		DELIMITERS.push(delimiter * mul);
}

const getDelimiter = (value: number, div: number = 4): number => {
	for (const delimiter of DELIMITERS) {
		const divisions = Math.ceil(value / delimiter);

		if (divisions <= div)
			return delimiter;
	}

	return DELIMITERS[DELIMITERS.length - 1];
};

const RadarConfig = {
	name: "radar",
	collector: () => collectLayout((p: any) => {
		const points = p.data.points as Data[],
			catPoints = p.data.categorized.points as Point[];

		p.data.maxes = points.map(pt => {
			return catPoints.reduce((acc, cp) => {
				return Math.max(acc, get(pt, cp.accessor).value);
			}, -Infinity)
		});

		p.data.max = Math.max(...p.data.maxes);

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const ds = p.dataset,
			w = ds.cWidth,
			h = ds.cHeight,
			max = ds.data.max,
			points = ds.data.points as Data[],
			catPoints = ds.data.categorized.points as Point[],
			labelSize = ds.labels ? 10 : 0,
			r = Math.min(w, h) / 2 - labelSize,
			innerR = r - 10,
			cx = w / 2,
			cy = h / 2,
			stepAngle = (1 / points.length) * 2 * Math.PI,
			offsetAngle = Math.PI / 2,
			style = p.inst.config.style,
			delimiter = getDelimiter(max),
			delimiterSteps = Math.ceil(max / delimiter),
			activeMax = delimiterSteps * delimiter;

		for (let i = 0, l = points.length; i < l; i++) {
			const ang = i * stepAngle - offsetAngle;

			plot.line()
				.x1(cx)
				.y1(cy)
				.x2(cx + Math.cos(ang) * r)
				.y2(cy + Math.sin(ang) * r)
				.stroke(style.fullTheme.separatorColorAlt)
				.strokeWidth(1);

			if (ds.labels && ds.labels[i]) {
				plot.text(ds.labels[i])
					.x(cx + Math.cos(ang) * (r + labelSize / 2))
					.y(cy + Math.sin(ang) * (r + labelSize / 2))
					.size(labelSize)
					.weight(600)
					.anchor("middle")
					.baseline("middle")
					.inert()
			}
		}

		for (let i = 0; i < delimiterSteps; i++) {
			const radius = innerR * (i + 1) * (1 / delimiterSteps);

			plot.circle()
				.cx(cx)
				.cy(cy)
				.r(radius)
				.stroke(style.fullTheme.separatorColorAlt)
				.strokeWidth(1);

			plot.text(delimiter * (i + 1))
				.x(cx - Math.cos(Math.PI/4) * radius)
				.y(cy - Math.sin(Math.PI/4) * radius)
				.size(8)
				.weight(600)
				.anchor("middle")
				.baseline("middle")
				.shadow(style.fullTheme.graphTextShadow)
				.fill(style.fullTheme.color);
		}

		for (const cp of catPoints) {
			plot.next();
			plot.with(cp);

			const path = plot.path();

			for (let i = 0, l = points.length; i < l; i++) {
				const ang = i * stepAngle - offsetAngle,
					radius = innerR * (get(points[i], cp.accessor).value / activeMax),
					x = cx + Math.cos(ang) * radius,
					y = cy + Math.sin(ang) * radius;

				if (i)
					path.L(x, y);
				else
					path.M(x, y);
			}

			path
				.z()
				.stroke(cp.color)
				.strokeWidth(3)
				.pointer("stroke")
				.outline(5);
		}

		plot.render();
	},
	dataset: {
		id: "radar",
		mode: "own",
		type: "radar",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default RadarConfig;