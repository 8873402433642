import StagedComponent, {
	scaled,
	mkSetterMaker
} from "./";

import {
	StyleParam,
	BoundingArray
} from "../../../../types/plot-svg";

const ms = mkSetterMaker<StagedCircle>();

export default class StagedCircle extends StagedComponent {
	ownStyleParams = [
		{ key: "cx", required: true },
		{ key: "cy", required: true },
		{ key: "r", required: true }
	] as StyleParam[];

	open(cx?: number, cy?: number, r?: number): this {
		return super.open(cx, cy, r);
	}

	cx = ms("cx", (cx: number) => scaled(cx));
	cy = ms("cy", (cy: number) => scaled(cy));
	r = ms("r", (r: number) => scaled(r));

	getBounding(): BoundingArray {
		const s = this.style;

		return [
			s.cx - s.r,
			s.cy - s.r,
			s.r * 2,
			s.r * 2
		];
	}

	render() {
		this.mount("circle");
	}
}
