import styled, { withTheme } from "styled-components";

import { format, request } from "../../utils";

import useGraphConfigCollection from "../../hooks/use-graph-config-collection";
import useGraphBox from "../../hooks/use-graph-box";

import {
	Bar,
	Pie,
	Line,
	Stacked,
	ForkedBar,
	HorizontalBar,
	GraphRow,
	GraphBox,
	Table
} from "../../components/viz";

import { DataPoint } from "../../components/viz/apply-categorization";
import { AugmentedRequestConfig } from "../../types/utils";
import { useAppDispatch } from "../../state/hooks";
import { useEffect, useState } from "react";
import { setSupplyError, supplyParties } from "../../state/features/data";
import {
	propsAndQueryParamsProxy,
	toArray,
	toNumber
} from "../../utils/standalone-utils";
import { CellRuntime, Column } from "../../components/viz/graphs/configs/table";
import { DOWNLOAD_PDF_DIV_ID } from "../../utils/download-pdf";

const FullHeightGraphBox = styled(GraphBox)`
	min-height: 330px;
`;

const TableGraphBox = styled(GraphBox)`
	min-height: 100px;
`;

const PageTitle = styled.div`
	font-size: 2em;
`;

const RaceTitle = styled.div`
	font-size: 1.5em;
	line-height: 2em;
`;

const Title = styled.div`
	font-size: 0.75em;
	font-weight: bold;
	margin: 1em 0 2em 0;
	text-align: center;
	width: 100%;
`;

const displayUsd = (rt: CellRuntime) => format.dollar(rt.value);
const displayNumber = (rt: CellRuntime) => format.number(rt.value);

const Graphs = withTheme((props: any) => {
	const params = propsAndQueryParamsProxy(props);

	const [teamDetails, setTeamDetails] = useState<any[]>();

	const dateRange = [
		toNumber(params.start_date),
		toNumber(params.end_date)
	];

	const collection = props.collection;

	const mode =
		params.campaigns && params.campaigns.length > 0
			? "campaigns"
			: "teams";
	const colors = props.theme.graphs.palettes.colors;
	const partyColors = props.theme.graphs.palettes.parties;

	const raceId = toNumber(params.race_id);
	const selectedCampaigns = toArray(params.campaigns);
	const selectedMarkets = toArray(params.markets);
	const selectedMedia = toArray(params.media);
	const selectedTeams = toArray(params.teams);

	const query = {
		media: selectedMedia?.join(),
		markets: selectedMarkets?.join("|"),
		range: dateRange.map(ts => new Date(ts).toISOString()).join(),
		teams: selectedTeams,
		campaigns: selectedCampaigns
	};

	useEffect(() => {
		if (!params.teams) {
			setTeamDetails(undefined);
		} else {
			request({
				url: "/teams/list",
				query: { race_id: raceId }
			}).then(res => {
				if (res.success) {
					const data = res.data as any[];
					setTeamDetails(data);
				}
			});
		}
	}, [raceId, params.teams]);

	const mkRequest = (
		url: string,
		process?: (data: any) => any
	): AugmentedRequestConfig => {
		return {
			url: `/races/${raceId}/graphs${url}`,
			query,
			cacheHash: props.hash,
			process
		};
	};

	const processSOVData = (sov: any) => {
		const campaigns = [],
			media = selectedMedia?.map(e => e.toLowerCase()) || [];

		for (const k in sov) {
			if (!sov.hasOwnProperty(k)) continue;

			const candidates = sov[k];

			for (const candidate of candidates) {
				const entry = {
					name: candidate.candidate_name,
					tv: 0,
					radio: 0,
					cable: 0,
					grp: 0,
					total: 0
				} as any;

				for (const medium of media) {
					const d = candidate[medium] || {
						grp: 0,
						usd: 0
					};

					entry[medium] = d.usd;
					entry.total += d.usd;
					entry.grp += d.grp;
				}

				campaigns.push(entry);
			}
		}

		return campaigns;
	};

	const filterColumns = (columns: Column[]): Column[] => {
		const media = selectedMedia?.reduce((map, entry) => {
			map[entry.toLowerCase()] = true;
			return map;
		}, {} as any);

		return columns.filter(column => {
			return !(
				(column.accessor === "tv" ||
					column.accessor === "cable" ||
					column.accessor === "radio") &&
				!media.hasOwnProperty(column.accessor)
			);
		});
	};

	const mediaSpend = useGraphBox({
		graph: Bar,
		title:
			mode === "campaigns"
				? "Spend by Campaign"
				: "Spend by Team",
		request: mkRequest("/spending/medium"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.label}: ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) => {
			const total = d.reduce(
				(acc, data) => acc + (data.value as number),
				0
			);
			if (total > 0) {
				return format.dollar(total);
			}
			return "";
		},
		layout: {
			colors,
			colorIndices: ["tv", "cable", "radio", "addressable"]
		},
		labelAllPoints: "true",
		track: collection.track("spending-medium")
	});

	const groupColors =
		mode === "campaigns"
			? partyColors
			: teamDetails?.reduce(
					(obj, team, i) => ({
						...obj,
						[team.full_name]: colors[i]
					}),
					{}
			  ) || colors;

	const partySpend = useGraphBox({
		graph: ForkedBar,
		title: "Media Type Spend",
		request: mkRequest("/spending/party"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.trace[d.trace.length - 1].label} (${
				d.trace[1].label
			}): ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) => {
			const label = format.dollar(
				d.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
			);
			return label;
		},
		labelAllPoints: "true",
		layout: {
			labelKey: "candidate_name",
			order: [2, 0, 1],
			colors: partyColors
		},
		track: collection.track("spending-party")
	});

	const sovSpend = useGraphBox({
		title: "Share of Voice",
		request: mkRequest("/spending/sov"),
		box: FullHeightGraphBox,
		graphs: [
			{
				component: Pie,
				subheading: "GRPs",
				tooltips: d =>
					`${d.trace[1].label} (${
						d.trace[0].label
					}): ${format.number(
						d.value as number
					)}`,
				layout: {
					labelKey: "candidate_name",
					valueKey: "grp",
					colors: partyColors,
					uniquePoints: true
				},
				track: collection.track("sov-grp")
			},
			{
				component: Pie,
				subheading: "USD",
				tooltips: d =>
					`${d.trace[1].label}, ${d.label} (${
						d.trace[0].label
					}): ${format.dollar(
						d.value as number
					)}`,
				layout: {
					legends: {
						level: 1
					},
					labelKey: "candidate_name",
					level: 1,
					shortValueKey: "usd",
					colors: partyColors,
					uniquePoints: true
				},
				track: collection.track("sov-usd")
			}
		]
	});

	const cumulativeSpend = useGraphBox({
		graph: Stacked,
		title: "Cumulative Investment (USD)",
		request: mkRequest("/spending/cumulative"),
		box: FullHeightGraphBox,
		tooltips: d => `${d.trace[1].label} (${d.trace[0].label})`,
		axesLabels: {
			y: (d: number) => format.dollar(d)
		},
		layout: {
			labelKey: "candidate_name",
			valueMap: {
				amount: "y",
				date: "x"
			},
			groupLevel: -1,
			colors: partyColors
		},
		track: collection.track("cumulative-investment")
	});

	const grps = useGraphBox({
		graph: Line,
		title: selectedMarkets?.length ? "GRPs" : "Top GRPs",
		request: mkRequest("/spending/cumulative/filtered"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.trace[1].label}: ${format.number(
				(d.value as any).y
			)}`,
		layout: {
			legends: {
				level: 1
			},
			labelKey: "candidate_name",
			valueMap: {
				date: "x",
				grp: "y"
			},
			groupLevel: -1,
			colors: groupColors
		},
		track: collection.track("grps")
	});

	const grpMarket = useGraphBox({
		graph: HorizontalBar,
		title: selectedMarkets?.length
			? "GRP by Market"
			: "Top GRP by Market",
		request: mkRequest("/spending/sov/filtered"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.label}: ${format.number(d.value as number)}`,
		groupLabel: (d: DataPoint[]) =>
			d
				.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
				.toString(),
		labelAllPoints: true,
		layout: {
			labelKey: "candidate",
			valueKey: "grp",
			colors: partyColors
		},
		track: collection.track("grp-market")
	});

	const usdMarket = useGraphBox({
		graph: HorizontalBar,
		title: "Spend by Market",
		request: mkRequest("/spending/sov/filtered"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.label}: ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) =>
			format.dollar(
				d.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
			),
		labelAllPoints: true,
		layout: {
			labelKey: "candidate",
			valueKey: "usd",
			colors: partyColors
		},
		track: collection.track("usd-market")
	});

	const topSpenders = useGraphBox({
		graph: Table,
		title: "Top Spenders",
		request: mkRequest("/spending/sov/media", d => {
			return processSOVData(d);
		}),
		box: TableGraphBox,
		layout: {
			legends: null
		},
		store: {
			display: displayUsd,
			columns: filterColumns([
				{
					title: "Campaign",
					accessor: "name",
					display: null
				},
				{ title: "TV", accessor: "tv", align: "end" },
				{
					title: "GRP",
					accessor: "grp",
					align: "end",
					display: displayNumber
				},
				{
					title: "Cable",
					accessor: "cable",
					align: "end"
				},
				{
					title: "Radio",
					accessor: "radio",
					align: "end"
				},
				{
					title: "Total",
					accessor: "total",
					align: "end"
				}
			])
		},
		getHeight: d => (d.length + 1) * 25,
		track: collection.track("top-spenders")
	});
	/*
	const marketSpend = useGraphBox({
		graph: Table,
		title: "Spending by Market",
		request: mkRequest("/spending/sov", d => {
			return [
				{
					market: "All Markets",
					campaigns: processSOVData(d)
				}
			];
		}),
		box: TableGraphBox,
		layout: {
			legends: null,
			labelKey: "market"
		},
		store: {
			header: displayHeader,
			display: displayUsd,
			columns: filterColumns([
				{ title: "Campaign", accessor: "name", display: null },
				{ title: "TV", accessor: "tv", align: "end" },
				{ title: "GRP", accessor: "grp", align: "end", display: displayNumber },
				{ title: "Cable", accessor: "cable", align: "end" },
				{ title: "Radio", accessor: "radio", align: "end" },
				{ title: "Total", accessor: "total", align: "end" }
			])
		},
		getHeight: d => {
			const rows = d.reduce(
				(tot: number, dt: any) => {
					return tot + 1 + dt.campaigns.length;
				},
				0
			);

			return rows * 25;
		},
		track: collection.track("market-spend")
	});
*/
	return (
		<>
			<GraphRow>
				{mediaSpend}
				{partySpend}
			</GraphRow>
			<GraphRow>
				{sovSpend}
				{cumulativeSpend}
			</GraphRow>
			{/*<GraphRow>{grps}</GraphRow>*/}
			<GraphRow>{grpMarket}</GraphRow>
			<GraphRow>{usdMarket}</GraphRow>
			{/*<GraphRow>{topSpenders}</GraphRow>*/}
			{/*
			<GraphRow>
				{marketSpend}
			</GraphRow>
			*/}
		</>
	);
});

const Content = (props: any) => {
	const [dateRange, setDateRange] = useState<string>("");
	const [raceName, setRaceName] = useState<string>("");
	const params = propsAndQueryParamsProxy(props);
	const raceId = toNumber(params.race_id);
	const startDate = toNumber(params.start_date);
	const endDate = toNumber(params.end_date);

	useEffect(() => {
		if (raceId) {
			request({ url: `/races/${raceId}` }).then(resp => {
				if (resp.success) {
					const name = `${resp.data.race_name} ${resp.data.year}`;
					setRaceName(name);
					props.onSetRaceName(name);
				}
			});
		}
	}, [raceId]);

	useEffect(() => {
		if (endDate && startDate) {
			setDateRange(
				`${new Date(
					startDate
				).toLocaleDateString()} - ${new Date(
					endDate
				).toLocaleDateString()}`
			);
		}
	}, [endDate, startDate]);

	return (
		<div style={{ overflow: "auto" }} id={DOWNLOAD_PDF_DIV_ID}>
			<Title>
				<PageTitle>Spending Report</PageTitle>
				<RaceTitle>{raceName}</RaceTitle>
				{dateRange}
			</Title>
			<Graphs {...props} hash={props.hash || "0"} />;
		</div>
	);
};

const SpendingStandalone = (props: any) => {
	const fallbackCollection = useGraphConfigCollection();
	const collection = props.collection || fallbackCollection;

	const dispatch = useAppDispatch();
	useEffect(() => {
		const req = async (
			url: string,
			dispatcher: (action: any) => any
		) => {
			const response = await request(url);
			if (response.success)
				dispatch(dispatcher(response.data));
			else if (localStorage.getItem("debugging") === "true")
				dispatch(dispatcher([]));
			else dispatch(setSupplyError(response.errorMessage!));
		};

		req("/parties/list", supplyParties); // required for party color themes
	}, []);

	return <Content {...props} collection={collection} />;
};

export default SpendingStandalone;
