import { get } from "@qtxr/utils";

import {
	traverse,
	collectLayout
} from "../../common";
import PlotSvg from "../../plot-svg";

import {
	DataPoint,
	Group,
	Point
} from "../../apply-categorization";

const ForkedBarConfig = {
	name: "forked-bar",
	collector: () => collectLayout((p: any) => {
		p.data.maxes = p.data.nodes.map((node: Point | Group, idx: number) => {
			let max = -Infinity;

			for (const group of (node as Group).groups) {
				const localMax = traverse.points.reduce(group, (acc, point) => {
					return acc + get(p.data.points[idx], point.accessor).value;
				}, 0);

				if (localMax > max)
					max = localMax;
			}

			return max;
		});

		p.data.max = Math.max(...p.data.maxes);

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const ds = p.dataset,
			offset = 50,
			w = ds.cWidth - offset,
			h = ds.cHeight,
			max = ds.data.max,
			space = 15,
			groupHeight = (h - (space * (ds.data.nodes.length - 1))) / ds.data.nodes.length,
			groupLabel = ds.groupLabel,
			labelAllPoints = ds.labelAllPoints === "true";
		let y = 0,
			groupData = [] as DataPoint[];;

		plot.forEach(({ node, style }) => {
			plot.text(node.label)
				.x(offset - 15)
				.y(y + groupHeight / 2)
				.size(10)
				.weight(600)
				.anchor("end")
				.baseline("middle")
				.fill(style.fullTheme.color)
				.inert();

			plot.line()
				.x1(offset - 5)
				.y1(y)
				.x2(offset - 5)
				.y2(y + groupHeight)
				.stroke(style.fullTheme.color)
				.strokeWidth(1)
				.inert();

			const internalH = groupHeight - 10,
				internalSpace = 5,
				barHeight = (internalH - (internalSpace * ((node as Group).groups.length - 1))) / (node as Group).groups.length;
			let internalY = y + 5;

			for (const group of (node as Group).groups) {
				let internalX = 0;

				plot.at(group);

				plot.forEachPoint(pt => {
					const width = (pt.value / max) * w,
						boundaryType = plot.getBoundaryType("nested");
					if (groupLabel && (boundaryType?.includes("start") || boundaryType?.includes("junction") || labelAllPoints)) {
						plot.pushBounding();
					}
					if (groupLabel)
						groupData.push(pt.data)

					plot.rect()
						.x(internalX + offset)
						.y(internalY)
						.width(width)
						.height(barHeight)
						.fill(pt.color);

					if (!boundaryType?.includes("end")) {
						plot.line()
							.x1(internalX + offset + width)
							.y1(internalY)
							.v(barHeight)
							.stroke(pt.style.fullTheme.graphBackground)
							.stack(1);
					}

					if (groupLabel && (boundaryType?.includes("end") || labelAllPoints)) {
						const bounding = plot.popBounding(labelAllPoints);

						plot.text(groupLabel(groupData))
							.x(bounding.x + bounding.width / 2)
							.y(bounding.y + bounding.height / 2)
							.maxLength(bounding.width / 6)
							.hideIfTooLong(true)
							.size(10)
							.weight(600)
							.anchor("middle")
							.baseline("middle")
							.shadow(style.fullTheme.textShadow)
							.fill(style.fullTheme.accentColor)
							.stack(2)
							.inert();

						groupData = [];
					}

					internalX += width;
				});

				internalY += internalSpace + barHeight;
			}

			y += space + groupHeight;
		});

		plot.render();
	},
	dataset: {
		id: "forked-bar",
		mode: "own",
		type: "forked-bar",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default ForkedBarConfig;
