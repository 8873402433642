import styled from "styled-components";

const Wrapper = styled.section`
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
`;

const Centered = (props: any) => (
	<Wrapper>
		<div>
			{props.children}
		</div>
	</Wrapper>
);

export default Centered;
