// This hook ensures that state edit calls made after a component has been
// unmounted fail gracefully. As this is a silent operation, care must be
// taken to ensure that program function is not negatively. Therefore, it
// is recommended that useState is used in place of this hook until the
// component it is bound to has reached stability
import {
	useState,
	useEffect,
	Dispatch,
	SetStateAction
} from "react";

import useStateCapsule from "./use-state-capsule";

function useTransientState<S>(
	initialState: (() => S) | S
): [S, Dispatch<SetStateAction<S>>] {
	const [state, setState] = useState(initialState);
	const setStateCapsule = useStateCapsule(setState);

	useEffect(
		() => () => setStateCapsule.set(() => {}),
		[]
	);

	const dispatch = (value: SetStateAction<S>) => {
		setStateCapsule.get()(value);
	};

	return [state, dispatch];
}

export default useTransientState;
