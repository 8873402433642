import {useMemo, useState} from "react";

import { mkLoadingState } from "../utils";

import { Store } from "../types/viz";
import { Layout } from "../components/viz/apply-layout";
import { LoadingState } from "../types/utils";

export interface GraphConfigPartitionData {
	data: any;
	config: any;
	props: any;
	store: Store | null;
	layout: Layout | null;
	loadingState: LoadingState;
}

export type ConfigAssigner = (supplement: Partial<GraphConfigPartitionData> | null) => void;

export class GraphConfigCollection {
	collection: Map<string, GraphConfigPartitionData>;
	triggerUpdate: () => void;

	constructor(triggerUpdate: () => void) {
		this.collection = new Map();
		this.triggerUpdate = triggerUpdate;
	}

	get(name: string): GraphConfigPartitionData {
		const partition = this.collection.get(name) || null;
		if (partition)
			return partition;

		return getDefaultPartition();
	}

	clear() {
		this.collection.clear();
	}

	track(name: string): ConfigAssigner {
		return (supplement: Partial<GraphConfigPartitionData> | null) => {
			let partition = this.collection.get(name);
			if (!partition)
				partition = getDefaultPartition();

			const initialized = this.initialized,
				settled = this.settled;

			if (supplement === null)
				this.collection.delete(name);
			else {
				this.collection.set(
					name,
					{
						...partition,
						...supplement
					}
				);
			}

			if (this.initialized !== initialized || this.settled !== settled)
				this.triggerUpdate();
		};
	}

	get initialized(): boolean {
		if (!this.collection.size)
			return false;

		let initialized = true;

		this.collection.forEach(part => {
			initialized = initialized || !part.loadingState.idle;
		});

		return initialized;
	}

	get settled(): boolean {
		let settled = true;

		this.collection.forEach(part => {
			settled = settled && !(part.loadingState.idle || part.loadingState.loading);
		});

		return settled;
	}
}

const getDefaultPartition = (): GraphConfigPartitionData => ({
	data: null,
	config: {},
	props: {},
	store: null,
	layout: null,
	loadingState: mkLoadingState("idle")
});

const useGraphConfigCollection = (): GraphConfigCollection => {
	const setUpdates = useState(0)[1];

	return useMemo(
		() => {
			return new GraphConfigCollection(
				() => setUpdates(upd => upd + 1)
			)
		},
		[]
	);
};

export default useGraphConfigCollection;
