import { Field } from "formik";
import styled from "styled-components";

interface InputBoxProps {
	// Input box-level props
	input: any;
	title?: string;
	className?: string;
	// Shared / input props
	name?: string;
	required?: boolean;
	[key: string]: any;
}

interface LabelProps {
	htmlFor?: string;
	required?: boolean;
	children?: any;
}

const InputRow = styled.div`
	display: flex;
	gap: 12px;

	& + & {
		margin-top: 8px;
	}
`;

const InputColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 12px;
`;

const LabelWrapper = styled.label<LabelProps>`
	font-weight: bold;
	margin-bottom: 3px;
	font-size: 95%;
`;

const AsteriskWrapper = styled.span`
	font-size: 90%;
	margin-left: 0.2em;
	color: ${p => p.theme.alert};
`;

const Label = (props: LabelProps) => {
	const asterisk = props.required ?
		<AsteriskWrapper>*</AsteriskWrapper> :
		null;

	return (
		<LabelWrapper htmlFor={props.htmlFor}>
			{props.children}
			{asterisk}
		</LabelWrapper>
	);
};

const InputBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const InputBox = (props: InputBoxProps) => {
	const {
		title,
		input,
		className,
		...inputProps
	} = props;

	const name = props.name ?
		`inp-${props.name}` :
		undefined;

	return (
		<InputBoxWrapper className={className}>
			<Label
				required={props.required}
				htmlFor={name}
			>
				{title}
			</Label>
			<Field
				as={input}
				id={name}
				{...inputProps}
			/>
		</InputBoxWrapper>
	);
};

export {
	InputRow,
	InputColumn,
	InputBox
};
