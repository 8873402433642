import HorizontalBarConfig from "./configs/horizontal-bar";

import Base from "./base";

import {
	GraphProps,
	LabelResolver,
	GroupLabelResolver,
	TotalLabelResolver
} from "../../../types/viz";

interface HorizontalBarGraphProps extends GraphProps {
	label?: LabelResolver;
	groupLabel?: GroupLabelResolver;
	totalLabel?: TotalLabelResolver;
	labelAllPoints?: boolean;
}

const HorizontalBar = (props: HorizontalBarGraphProps) => {
	const { label, labelAllPoints, groupLabel, totalLabel, ...p } = props;

	return (
		<Base
			{...p}
			configs={HorizontalBarConfig}
			store={{
				label,
				labelAllPoints,
				groupLabel,
				totalLabel
			}}
		/>
	);
};

export default HorizontalBar;
