import styled from "styled-components";

import Icon from "./icon";

interface OutLinkProps {
	to: string;
	children: any;
	onClick?: (evt: any) => void;
}

const OutLinkWrapper = styled.a`
	color: ${p => p.theme.popColor};
	
	&:hover {
		text-decoration: underline;
	}
`;

const OutLinkSvg = styled(Icon)`
	height: 10px;
	margin-left: 0.25em;
`;

const OutLink = (props: OutLinkProps) => (
	<OutLinkWrapper
		href={props.to}
		target="_blank"
		rel="noreferrer noopener"
		onClick={props.onClick}
	>
		{props.children}
		<OutLinkSvg name="hyperlink" />
	</OutLinkWrapper>
);

export default OutLink;
