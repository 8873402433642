import StagedComponent, {
	scaled,
	mkSetterMaker
} from "./";

import {
	StyleParam,
	BoundingArray
} from "../../../../types/plot-svg";

const ms = mkSetterMaker<StagedRect>();

export default class StagedRect extends StagedComponent {
	ownStyleParams = [
		{ key: "x", required: true },
		{ key: "y", required: true },
		{ key: "width", required: true, validate: "positive" },
		{ key: "height", required: true, validate: "positive" },
		{ key: "rx", validate: "positive" },
		{ key: "ry", validate: "positive" }
	] as StyleParam[];

	open(x?: number, y?: number, width?: number, height?: number): this {
		return super.open(x, y, width, height);
	}

	x = ms("x", (x: number) => scaled(x));
	y = ms("y", (y: number) => scaled(y));
	width = ms("width", (width: number) => scaled(width));
	height = ms("height", (height: number) => scaled(height));
	rx = ms("rx", (rx: number) => scaled(rx));
	ry = ms("ry", (ry: number) => scaled(ry));

	getBounding(): BoundingArray {
		const s = this.style;

		return [
			s.x,
			s.y,
			s.width,
			s.height
		];
	}

	render() {
		this.mount("rect");
	}
}
