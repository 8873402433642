import styled from "styled-components";

import { InputProps } from "../../types/inputs";

export interface TextProps extends InputProps {
	placeholder?: string;
}

const TextWrapper = styled.input`
	height: ${p => p.theme.buttonHeight};
	background: ${p => p.theme.cardBackground};
	border: ${p => p.theme.inputBorder};
	border-radius: ${p => p.theme.borderRadius};
	padding: 0 10px;
	outline: none;
	font: inherit;
	color: inherit;
`;

const Text = (props: TextProps) => {
	return <TextWrapper {...props} />;
};

export default Text;
