import { request } from "../../utils";

import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import Base from "../base";

import ContentCard from "../../components/content-card";
import ActionTable from "../../components/action-table";

import { ColumnCell } from "../../types/data-table";

const COLUMNS: ColumnCell[] = [
	{
		name: "sortOrder",
		title: "Sort Order",
		accessor: "sort_order",
		fit: "shrink",
		editable: true
	},
	{
		name: "name",
		title: "Full Name",
		accessor: "full_name",
		editable: true
	},
	{
		name: "shortName",
		title: "Short Name",
		accessor: "short_name",
		editable: true
	},
	{
		name: "frequentlyUsed",
		type: "toggle",
		title: "Frequently Used",
		accessor: "frequently_used",
		fit: "shrink",
		editable: true
	},
	{
		name: "top5",
		type: "toggle",
		title: "Top 5",
		accessor: "top_5",
		fit: "shrink",
		editable: true
	}
];

const Offices = () => {
	const actions = useTableActions({
		addLabel: "Add Office",
		add: runtime => ({
			sort_order: runtime.rows.length + 1,
			full_name: "",
			short_name: "",
			frequently_used: false,
			top_5: false
		}),
		createEach: row => {
			return request({
				url: "/offices/create",
				body: row
			});
		},
		modifyEach: row => {
			return request({
				url: `/offices/${row.id}`,
				body: row,
				method: "PUT"
			});
		},
		deleteEach: row => {
			return request({
				url: `/offices/${row.id}`,
				method: "DELETE"
			})
		}
	});

	const props = useRemoteActionProps({
		request: "/offices/list",
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} />
			</ContentCard>
		</Base>
	);
};

export default Offices;
