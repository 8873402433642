import { IndexerFunction } from "../../types/data-table";

import { getUTCDate } from "../../utils";

export type IndexName = "default" | "lexical" | "timestamp";

const INDEXERS: Record<IndexName, IndexerFunction> = {
	default: (value: any) => value,
	lexical: (value: any) => {
		return String(value)
			.trim()
			.toLowerCase();
	},
	timestamp: (value: any) => getUTCDate(value).getTime()
};

export {
	INDEXERS
};
