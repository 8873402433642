import ForkedBarConfig from "./configs/forked-bar";

import Base from "./base";

import { GraphProps, GroupLabelResolver } from "../../../types/viz";

interface ForkedBarGraphProps extends GraphProps {
	groupLabel?: GroupLabelResolver,
	labelAllPoints?: "true" | "false"
}

const ForkedBar = (props: ForkedBarGraphProps) => {
	const { groupLabel, labelAllPoints, ...p } = props;
	return <Base {...p} configs={ForkedBarConfig} store={{ groupLabel, labelAllPoints }} />;
};

export default ForkedBar;
