import { cleanRegex } from "../../utils";

import { Filter } from "../../types/data-table";

interface FilterTemplates {
	[key: string]: (Filter | FilterName)[];
}

export type FilterName =
	"default" |
	"includes" |
	"exact" |
	"startsWith" |
	"endsWith" |
	"contains" |
	"less" |
	"more" |
	"before" |
	"after" |
	"on" |
	"regex";

// Filter templates corresponding to different column types
// standard is the default template if no other is found for the current column type
const FILTER_TEMPLATES: FilterTemplates = {
	standard: ["exact", "includes", "contains", "startsWith", "endsWith", "more", "less", "regex"],
	date: ["exact", "includes", "contains", "before", "after", "on", "regex"]
};

const FILTERS: Record<FilterName, Filter> = {
	default: {
		name: "default",
		title: "Default",
		factory: (filterString: string) => {
			const regex = new RegExp(cleanRegex(filterString), "i");
			return (value: any) => regex.test(String(value));
		}
	},
	includes: {
		name: "includes",
		title: "Includes",
		factory: (filterString: string) => {
			const regex = new RegExp(cleanRegex(filterString), "i");
			return (value: any) => regex.test(String(value));
		}
	},
	exact: {
		name: "exact",
		title: "Exactly",
		factory: (filterString: string) => {
			const regex = new RegExp(`^${cleanRegex(filterString)}$`, "i");
			return (value: any) => regex.test(String(value));
		}
	},
	startsWith: {
		name: "startsWith",
		title: "Starts with",
		factory: (filterString: string) => {
			const regex = new RegExp(`^${cleanRegex(filterString)}`, "i");
			return (value: any) => regex.test(String(value));
		}
	},
	endsWith: {
		name: "endsWith",
		title: "Ends with",
		factory: (filterString: string) => {
			const regex = new RegExp(`${cleanRegex(filterString)}$`, "i");
			return (value: any) => regex.test(String(value));
		}
	},
	contains: {
		name: "contains",
		title: "Contains",
		factory: (filterString: string) => {
			const regex = new RegExp(`\\b${cleanRegex(filterString)}\\b`, "i");
			return (value: any) => regex.test(String(value));
		}
	},
	less: {
		name: "less",
		title: "Less than",
		factory: (filterString: string) => {
			return (value: any) => lessThan(value, filterString);
		}
	},
	more: {
		name: "more",
		title: "More than",
		factory: (filterString: string) => {
			return (value: any) => lessThan(value, filterString, true);
		}
	},
	before: {
		name: "before",
		title: "Before",
		factory: (filterString: string) => {
			const cmpDate = new Date(filterString);

			if (isNaN(cmpDate.valueOf()))
				return (value: any) => false;

			const timestamp = cmpDate.getTime();

			return (value: any) => {
				const date = value instanceof Date ?
					value :
					new Date(value);

				return date.getTime() < timestamp;
			};
		},
		validate: (filterString: string) => filterString ? null : "Invalid date"
	},
	after: {
		name: "after",
		title: "After",
		factory: (filterString: string) => {
			const cmpDate = new Date(filterString);

			if (isNaN(cmpDate.valueOf()))
				return (value: any) => false;

			const timestamp = cmpDate.getTime();

			return (value: any) => {
				const date = value instanceof Date ?
					value :
					new Date(value);

				return date.getTime() > timestamp;
			};
		},
		validate: (filterString: string) => filterString ? null : "Invalid date"
	},
	on: {
		name: "on",
		title: "On",
		factory: (filterString: string) => {
			const cmpDate = new Date(filterString);

			if (isNaN(cmpDate.valueOf()))
				return (value: any) => false;

			const cmpDayCode = getDayCode(cmpDate);

			return (value: any) => {
				const date = value instanceof Date ?
					value :
					new Date(value);

				return getDayCode(date) === cmpDayCode;
			};
		},
		validate: (filterString: string) => filterString ? null : "Invalid date"
	},
	regex: {
		name: "regex",
		title: "Regex",
		factory: (filterString: string) => {
			try {
				const regex = new RegExp(filterString, "i");
				return (value: any) => regex.test(value);
			} catch {
				return (value: any) => true;
			}
		},
		validate: (filterString: string) => {
			try {
				new RegExp(filterString, "i");
			} catch {
				return "Invalid regular expression";
			}
		}
	}
};

const lessThan = (a: any, b: string, reverse?: boolean) => {
	const {
		type,
		invalid,
		value
	} = resolveValue(a, b);

	if (invalid)
		return false;

	const aV = reverse ? value : a,
		bV = reverse ? a : value;

	switch (type) {
		case "date":
			return aV.getTime() < bV.getTime();

		default:
			return aV < bV;
	}
};

const getDayCode = (date: Date): number => {
	return date.getFullYear() * 500 +
		date.getMonth() * 50 +
		date.getDate();
};

const resolveValue = (refValue: any, stringValue: string) => {
	if (refValue instanceof Date) {
		const value = new Date();

		return {
			type: "date",
			invalid: isNaN(value.valueOf()),
			value
		};
	}

	switch (typeof refValue) {
		case "number": {
			const value = Number(stringValue);

			return {
				type: "number",
				invalid: isNaN(value),
				value
			};
		}
	}

	return {
		type: "any",
		invalid: false,
		value: stringValue
	};
};

export {
	FILTERS,
	FILTER_TEMPLATES
};
